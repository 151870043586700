import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as DictionariesSelectors from '../store/dictionaries/dictionaries.selectors';
import * as DictionariesActions from '../store/dictionaries/dictionaries.actions';
import { ICrmCountry } from '../interfaces/dictionaries';

@Injectable({
  providedIn: 'root',
})
export class DictionariesFacadeService {
  private store = inject(Store);

  get countriesDict$(): Observable<Array<ICrmCountry>> {
    return this.store.select(DictionariesSelectors.selectCountriesDict);
  }

  queryCountries() {
    this.store.dispatch(DictionariesActions.queryCountries());
  }
}
