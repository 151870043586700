import { Component, EventEmitter, Input, Output } from '@angular/core';
import { matches, requestV3 } from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';

@Component({
  selector: 'app-request-level0',
  templateUrl: './request-level0.component.html',
  styleUrls: ['./request-level0.component.scss']
})
export class RequestLevel0Component {
  @Input() disable: boolean;
  @Input() request: requestV3;
  @Input() manualRequest: matches;
  @Input() type: string;
  @Input() icon: string;
  @Input() style: string;
  @Output() cta = new EventEmitter<string>();
  @Input() ctas: {
    selected?: boolean;
    edit?: boolean;
    view?: boolean;
    user?: boolean;
  };

  constructor(
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService
  ) {}

  freelanceAddedToRequest() {
    if (
      this.request?.enlistedProfiles &&
      this.contextService.selectedConsultant &&
      this.type === 'longlist'
    ) {
      return this.request.enlistedProfiles.some(
        (x) => x.id === this.contextService.selectedConsultant.id
      );
    }
    return false;
  }

  openInternal(event: Event) {
    event.stopPropagation();
    if (this.request) {
      window.open('requests/' + this.request.id, '_blank');
    }
  }
  ifEmptyReturnDash(value: string | null) {
    if (value) {
      return 'Starting on ' + this.getDate(value);
    }
    return '-';
  }
  getDate(value: string) {
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }
}
