import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import {
  declarationInformation,
  declarationOfInterest,
  declarationOfInterestReply,
  dropdown,
  matches,
  requestV3,
  shortlistedProfiles,
  by,
  freeStatus
} from '../../../../../interface/shared.interface';
import { Location } from '@angular/common';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ActionTypes, ContextService } from '../../../../../services/platform/context.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { ClaimingStatus, FreelancerStatus } from '../../../../shared/interfaces/claiming.interface';
import { ProfileSelectionChange } from '../../../../shared/interfaces/requests';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Component({
  selector: 'app-shortlisted-profile',
  templateUrl: './shortlisted-profile.component.html',
  styleUrls: ['./shortlisted-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortlistedProfileComponent {
  selectedRequest = input.required<requestV3>();
  consultant = input.required<shortlistedProfiles>();
  consultantStatus = input<freeStatus[]>([]);
  selectedProfiles = input<shortlistedProfiles[]>([]);

  isSelected = computed<boolean>(() => {
    return this.selectedProfiles().some((x) => x.id === this.consultant().id);
  });

  isCheckboxDisabled = computed<boolean>(() => {
    const consultant = this.consultant();
    return (
      [
        ClaimingStatus.PENDING_REPLY as string,
        ClaimingStatus.INTERESTED as string,
        ClaimingStatus.NOT_INTERESTED as string,
      ].includes(consultant.declarationInformation?.status) ||
      consultant.salesStatus === FreelancerStatus.NOT_SOLD ||
      !!consultant?.declarationInformation
    );
  });

  updateResults = output<any>();
  loadMore = output<number>();
  checkedStateChange = output<ProfileSelectionChange>();

  openedDropdownId = signal<string>(null);

  expandLevel2 = signal<boolean>(false);

  get isDisabled(): boolean {
    return this.getSelected(this.consultant().salesStatus)?.disable || false;
  }

  constructor(
    private contextService: ContextService,
    private platformService: PlatformService,
    private matomoTracker: MatomoTracker,
    private helperFunctions: HelperFunctionsService,
    private location: Location,
    private snackBarService: SnackBarService
  ) {}

  getCountry(value: string): string {
    if (value) {
      return this.helperFunctions.getCountry(value.toLocaleUpperCase());
    }
    return '';
  }

  openProfile(event: Event) {
    event.stopPropagation();
    this.location.go(`/consultants/consultant/${ this.consultant().id}`);
  }

  openDropdown(event: Event) {
    event.stopPropagation();
    this.openedDropdownId.set(this.consultant().id);
  }

  closeDropdown(event: Event) {
    event.stopPropagation();
    this.openedDropdownId.set('');
  }

  toggleSelection() {
    if (this.isCheckboxDisabled()) {
      return;
    }

    this.checkedStateChange.emit({
      selected: !this.isSelected(),
      consultant: this.consultant()
    });
  }

  toggleLevel2() {
    this.expandLevel2.update((expand) => !expand);
  }

  openNote(event: Event) {
    event.stopPropagation();
    this.contextService.selectedConsultant = this.consultant();
    this.contextService.openMatchModal = 'addNotes';
    this.matomoTracker.trackEvent('Request', 'Open modal - Add to notes');
  }

  selectedSaleNotes(result: shortlistedProfiles, event: dropdown | matches) {
    event = event as dropdown;
    result.salesStatus = event.name!;

    this.selected(result);
  }
  getFullName(value: by): string {
    return (
      value.firstName + ' ' + (value.middleName ?? '') + ' ' + value.lastName
    );
  }

  selected(consultant: shortlistedProfiles) {
    this.matomoTracker.trackEvent(
      'Request',
      'Changed sale status to - ' + consultant.salesStatus
    );
    this.platformService
      .updateConsultantStatus(this.selectedRequest().id, consultant.id, consultant.salesStatus)
      .then((response) => {
        if (response.status === 202) {
          const findex = this.consultantStatus().findIndex(
            (x) => x.name === consultant.salesStatus
          );
          if (findex < 2) {
            this.snackBarService.open({
              message: `Updated sales status for ${this.getFullName(consultant)}`,
              type: SnackBarTypes.SuccessAlt
            });
          } else {
            this.snackBarService.open({
              message: `${this.getFullName(consultant)} is now moved to the shortlist`,
              type: SnackBarTypes.SuccessAlt
            });
          }
          this.updateResults.emit(null);
        }
      })
      .catch(() => {
        this.snackBarService.open({
          message: `Failed to update sales status for ${this.getFullName(consultant)}`,
          type: SnackBarTypes.ErrorAlt
        });
      });
  }

  validateRate(expectedRate: { currency: string; ratePerHour: number }): string {
    if (expectedRate.currency && expectedRate.ratePerHour) {
      return expectedRate.ratePerHour + ' ' + expectedRate.currency;
    }
    return '-';
  }

  cleanText(text: string): string {
    const parts = text
      .split('\n')
      .map((part) => part.trim())
      .filter((part) => part.length > 0);

    return parts.length > 0 ? parts[parts.length - 1] : '';
  }

  getLengthOfNotes(text: string): number {
    if (!text) {
      return 0;
    }
    const parts = text
      .split('\n')
      .map((part) => part.trim())
      .filter((part) => part.length > 0);
    return parts.length;
  }

  getDate(value: string): string {
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }

  dateDifference(timestamp: number): string {
    const d = new Date(0);
    d.setUTCSeconds(timestamp);
    return this.helperFunctions.calculateDateDifference(d);
  }

  getDateTimestamp(timestamp: number, hourMinutes = false): string {
    return this.helperFunctions.getDate(timestamp, hourMinutes);
  }

  getProcessLength(name: string): string | number {
    const findex = this.consultantStatus().findIndex((x) => x.name === name);
    if (findex === -1) {
      return '0';
    }

    return this.consultantStatus()[findex]?.progress;
  }

  getStatus(value: declarationInformation): { type: string, icon: string } {
    const icon = { type: 'icon', icon: '' };
    if (value.status === ClaimingStatus.PENDING_REPLY) {
      icon.icon = 'loader';
    }
    if (value.status === ClaimingStatus.INTERESTED) {
      icon.icon = 'check';
    }
    if (value.status === ClaimingStatus.NOT_INTERESTED) {
      icon.icon = 'X';
    }
    if (value.status === ClaimingStatus.NO_REPLY) {
      icon.icon = 'X';
    }
    return icon;
  }

  getColor(value: declarationInformation): string {
    if (value.status === ClaimingStatus.PENDING_REPLY) {
      return 'warning--Light';
    }
    if (value.status === ClaimingStatus.INTERESTED) {
      return 'success--Light';
    }
    if (value.status === ClaimingStatus.NOT_INTERESTED) {
      return 'danger--Light';
    }
    if (value.status === ClaimingStatus.NO_REPLY) {
      return 'danger--Light';
    }
    return '';
  }

  getSelected(value: string): dropdown {
    let find: dropdown;
    if (this.consultantStatus()) {
      find = this.consultantStatus().find((x) => x.name === value);
    }
    return find;
  }

  openMessaging(event: Event) {
    event.stopPropagation();

    if (this.consultant().declarationInformation) {
      this.platformService
        .declarationOfInterest(
          this.consultant().declarationInformation.freelancerDeclarationOfInterestId,
          'getFree'
        )
        .then((response: unknown) => {
          this.contextService.declarationOfInterestReply = response as declarationOfInterestReply;
          this.contextService.actions.next({ action: ActionTypes.ReplyMessage });
        })
        .catch(() => {
          this.snackBarService.open({
            message: `Failed to load reply messages`,
            type: SnackBarTypes.ErrorAlt
          });
        });
    } else {
      this.contextService.openMatchModal = 'messaging';
      this.platformService
        .declarationOfInterest(this.selectedRequest().id, 'post', undefined, {
          freelancers: [this.consultant().id],
          languageCode: 'ENG',
        })
        .then((response: unknown) => {
          this.contextService.claiming = response as declarationOfInterest;
          this.contextService.actions.next({ action: ActionTypes.Messaging });
        })
        .catch(() => {
          this.snackBarService.open({
            message: `Failed to load sent message`,
            type: SnackBarTypes.ErrorAlt
          });
        });
    }
  }

  externalLink(value: dropdown | matches) {
    value = value as dropdown;
    if (value.link) {
      this.matomoTracker.trackEvent(
        'Request',
        'External Link - ' + value.displayName
      );
      window.open(value.link);
    }
  }
}
