import { Pagination } from '../../shared/interfaces/pagination';
import { DataModifier } from '../../shared/interfaces/requests';

export enum ConsultantMatchingType {
  CV = 0,
  FILES = 1,
}

export enum ConsultantPorfilePhotoVariant {
  XL = 'XL',
  L = 'L',
}

export interface IConsultantCvFile {
  filename: string;
  annotationId: string;
}

export interface IConsultantExpectedRate {
  currency: string;
  ratePerHour: number;
}

export interface IConsultantExperienceItem {
  customerName: string;
  description?: string;
  endDate: string;
  experienceType: string;
  jobTitle?: string;
  keySkills: string[];
  startDate: string;
}

export interface IConsultantExperience {
  experienceCount: number;
  experiences: IConsultantExperienceItem[];
  workedWith7N: boolean;
  yearsOfExperience: number;
}

export interface IConsultantLocation {
  adCountryCode: string;
  addressLine1?: string;
  adressLine2?: string;
  adressLine3?: string;
  city: string;
  country: string;
  countryCode: string;
  locationId?: string;
  locationMapsLink?: string;
  polandCityId?: string;
  polandCityName?: string;
  postalCode?: string;
  stateOrProvince?: string;
}

export interface IConsultantSalesAgent {
  firstName: string;
  middleName?: string;
  lastName: string;
  imageUrl?: string;
  federatedIssuerAssignedId?: string;
  systemUserId?: string;
}

export interface IConsultantShortlistedSalesRequest {
  id: string;
}

export interface IConsultantTierEvaluatedRules {
  evaluationResult: boolean;
  expected: string;
  id: number;
  input?: string;
  title: string;
}

export interface IConsultantTierStatus {
  contractStatus: string;
  dateCalculated?: number;
  description: string;
}

export interface IConsultantTier {
  contracts?: string;
  evaluatedRules?: IConsultantTierEvaluatedRules;
  recruitedUpdated?: string;
  recruitmentSequence?: string;
  status: IConsultantTierStatus;
  tierStatus: string;
}

export interface IConsultantWorkLocation {
  name: string;
  states: string[];
}

export interface IConsultantTimelineItem {
  document?: string;
  owner?: string;
  type: string;
  createdOn: string;
  createdBy: DataModifier;
  modifiedOn?: string;
  modifiedBy?: DataModifier;
  content: string;
  businessUnit?: string;
  object: number;
  subject?: string;
  scheduledStart?: string;
  filename?: string;
}

export interface IConsultant {
  applicableForC2ROrC2C?: boolean;
  availability: string;
  crmLink: string;
  crmStatus: string;
  cvFile?: IConsultantCvFile;
  email: string;
  expectedRate: IConsultantExpectedRate;
  experience: IConsultantExperience;
  firstName: string;
  id: string;
  imageUrl?: string;
  lastName: string;
  lastRole: string;
  linkedIn?: string;
  location: IConsultantLocation;
  matchingType: ConsultantMatchingType;
  middleName?: string;
  my7NLink: string;
  phoneNumber?: string;
  previousRoles: string[];
  remarks: boolean;
  salesAgent?: IConsultantSalesAgent;
  shortlistedSalesRequests: IConsultantShortlistedSalesRequest[];
  tier: IConsultantTier;
  type: string;
  workLocations: IConsultantWorkLocation[];
}

export interface IConsultantDetails extends IConsultant {
  keySkills: string[];
  summary: string;
  timeline?: IConsultantTimelineItem[];
  requests?: any[]; // TODO: update type when Requests are refactored
}

export interface IConsultantsResponse {
  elements: IConsultant[];
  pagination: Pagination;
}

export interface IConsultantsResultsSummary {
  matchingType: ConsultantMatchingType;
  displayName: string;
  icon: string;
  iterationId: number;
  amount: number;
}

export interface ISearchConsultantsItem extends IConsultant {
  added: boolean;
}
