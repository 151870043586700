import { Injectable, Inject } from '@angular/core';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
} from '@azure/msal-angular';
import {
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import {
  appAccessScopes,
  authScopes,
  b2cPolicies,
} from '../../app/auth-config';
import { ContextService } from '../platform/context.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _destroying$ = new Subject<void>();
  isLogin = false;
  loginDisplay = new Subject<boolean>();
  initializeDone = new Subject<boolean>();
  accessTokens: { [key: string]: string };
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private contextService: ContextService,
    private router: Router
  ) {}

  // Initializes authentication and sets up event listeners
  initialize() {
    this.setLoginDisplay();

    // Enables account storage events for multi-tab or multi-window login/logout events
    this.authService.instance.enableAccountStorageEvents();

    // Listens for ACCOUNT_ADDED and ACCOUNT_REMOVED events
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    // Listens for the completion of interaction events
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    // Subscribes to login display changes and acquires token silently if logged in
    this.loginDisplay.subscribe(async (val) => {
      if (val) {
        this.accessTokens = await this.acquireTokenSilent();
        this.initializeDone.next(true);
      }
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        )
      )
      .subscribe((result) => {
        if (result.error instanceof InteractionRequiredAuthError) {
          const accessTokenRequest = {
            scopes: [appAccessScopes.scope],
            account: this.authService.instance.getAllAccounts()[0],
          };

          this.authService.acquireTokenRedirect(accessTokenRequest);
        }
      });
  }

  // Acquires access tokens silently
  async acquireTokenSilent() {
    const accessTokens: { [key: string]: string } = {};

    const accessTokenRequest = {
      scopes: [appAccessScopes.scope],
      account: this.authService.instance.getAllAccounts()[0],
    };

    try {
      await new Promise((resolve) => {
        this.authService.acquireTokenSilent(accessTokenRequest).subscribe({
          next: (response) => {
            accessTokens[appAccessScopes.api] = response.accessToken;
            resolve(true);
          },
          error: (e) => {
            console.error(e);
            this.signout();
          },
        });
      });
    } catch (error) {
      console.log(error);
      if (error instanceof InteractionRequiredAuthError) {
        this.authService.acquireTokenRedirect(accessTokenRequest);
      }
    }

    const account = this.authService.instance.getAllAccounts()[0];
    if (account.idTokenClaims) {
      this.contextService.userInfo = account.idTokenClaims;
    }

    return accessTokens;
  }

  // Checks and sets the active account if not already set
  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  // Sets the login display state based on the presence of accounts
  setLoginDisplay() {
    this.changeLoginDisplay(
      this.authService.instance.getAllAccounts().length > 0
    );
  }

  // Cleans up resources when the component is destroyed
  OnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // Changes the login display state and updates the isLogin flag
  changeLoginDisplay(val: boolean) {
    this.loginDisplay.next(val);
    this.isLogin = val;
  }

  // Initiates the sign-in process
  signin() {
    const signinFlowRequest = {
      scopes: authScopes,
      authority: b2cPolicies.authorities.signin.authority,
    };

    this.userRequest(signinFlowRequest);
  }

  // Initiates the sign-out process
  signout() {
    this.authService.instance
      .handleRedirectPromise()
      .then(() => {
        sessionStorage.setItem('redirect', this.router.url);
        this.authService.logout();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Initiates a user request (sign-in or sign-up) based on the provided user flow request
  userRequest(userFlowRequest?: RedirectRequest | PopupRequest) {
    this.authService.instance.handleRedirectPromise().then();

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest,
      } as RedirectRequest);
    } else {
      this.authService.instance
        .handleRedirectPromise()
        .then(() => {
          this.authService.loginRedirect(userFlowRequest);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
