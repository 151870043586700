import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { option } from '../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnChanges {
  @Input() clearFromParent: boolean;
  @Input() placeholder = 'Choose location';
  @Input() disabled: boolean;
  @Input() countries: { displayName: string; value: string }[];
  options: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  selectOption(option: option) {
    if (option.id === '-1') {
      this.selected = [];
      this.selectedOption.emit(this.selected);
      return;
    }
    const findex = this.selected.findIndex((x) => x.id === option.id);
    if (findex === -1) {
      this.selected.push(option);
      this.selectedOption.emit(this.selected);
      return;
    }
    this.selected.splice(findex, 1);
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges() {
    if (this.countries) {
      this.options = [];
      this.countries.forEach((country, i) => {
        const findex = this.selected.findIndex((x) => x.name === country.value);
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: country.displayName,
          name: country.value,
        });
      });
    }
  }
}
