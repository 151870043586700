import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tier } from '../../../../interface/shared.interface';

@Component({
  selector: 'app-tier-statuses',
  templateUrl: './tier-statuses.component.html',
  styleUrls: ['./tier-statuses.component.scss'],
})
export class TierStatusesComponent {
  @Input() tier: tier;
  @Input() trailingIcon: { type: string; icon: string } | undefined;
  @Output() callbackFunction = new EventEmitter();

  isRecruited(status: string) {
    if (!status) {
      return false;
    }
    const allowedStatus = [
      'candidate',
      'verification',
      'prescreening',
      'interview',
      'evaluation',
      'completed',
    ];
    return allowedStatus.includes(status.toLowerCase());
  }
  getRecruitedStatus() {
    if (this.tier.status) {
      const result = {
        label: this.tier.status.description,
        class: '',
      };
      if (['ApproachingDeadline'].includes(this.tier.status.contractStatus)) {
        result.class = 'warning--Lighter warningText--Dark';
        return result;
      }
      if (['FinalCountdown'].includes(this.tier.status.contractStatus)) {
        result.class = 'urgent--Lighter urgentText--Dark';
        return result;
      }
      if (['OutOfWork'].includes(this.tier.status.contractStatus)) {
        result.class = 'danger--Lighter dangerText--Dark';
        return result;
      }
      if (['OnContract'].includes(this.tier.status.contractStatus)) {
        result.class = 'info--Lighter infoText--Dark';
        return result;
      }
      if (['ReadyToWork'].includes(this.tier.status.contractStatus)) {
        result.class = 'success--Lighter  successText--Dark';
        return result;
      }
      if (['Candidate'].includes(this.tier.status.contractStatus)) {
        result.class = 'success--Lighter';
        return result;
      }
    }

    return { label: '', class: '' };
  }

  getIcon() {
    let result = { type: 'icon', icon: '' };
    if (this.tier.status) {
      result = { type: 'icon', icon: 'info-circle' };
      if (['FinalCountdown', 'ApproachingDeadline'].includes(this.tier.status.contractStatus)) {
        result.icon = 'alert-triangle';
        return result;
      }
      if (['OnContract'].includes(this.tier.status.contractStatus)) {
        result.icon = 'loader';
        return result;
      }
      if (['ReadyToWork'].includes(this.tier.status.contractStatus)) {
        result.icon = 'circle-check';
        return result;
      }
    }

    return result;
  }
}
