<div
  class="bundle-tiers-avatar"
  [class]="{ tiers: tiers, small: class === 'small' }"
>
  <div class="avatar" [class]="class">
    <ng-container *ngIf="consultant">
      <img
        *ngIf="getInput(consultant).imageUrl"
        [src]="getInput(consultant).imageUrl"
      />
      <i-tabler
        class="user"
        *ngIf="!getInput(consultant).imageUrl"
        name="user"
      ></i-tabler>
    </ng-container>
    <ng-container *ngIf="customer">
      <i-tabler class="large" name="file-text"></i-tabler>
    </ng-container>
    <ng-container *ngIf="by">
      <img *ngIf="by.imageUrl" [src]="by.imageUrl" />
      <p *ngIf="!by.imageUrl && by.firstName">
        {{ by.firstName[0] }}
      </p>
    </ng-container>
    <ng-container *ngIf="shortListed">
      <img *ngIf="shortListed.imageUrl" [src]="shortListed.imageUrl" />
      <p *ngIf="!shortListed.imageUrl">
        {{ shortListed.fullName[0] }}
      </p>
    </ng-container>
  </div>
  <div *ngIf="tiers" class="tier">
    <app-tier
      [id]="getId()"
      [class]="class"
      *ngIf="tier"
      [tier]="tier"
    ></app-tier>
  </div>
</div>
