import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';

import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';
import { ActionTypes, ContextService } from '../../../../../../services/platform/context.service';
import { Subscription } from 'rxjs';
import { 
  ClaimingDeclarationOfInterestMessageResponse,
  ClaimingStatus,
  IClaimingDeclarationOfInterestMessage
} from '../../../../../shared/interfaces/claiming';

const CLAIMING_DECLARATION_OF_INTEREST_MESSAGE_RESPONSE_TEXT = {
  [ClaimingDeclarationOfInterestMessageResponse.GOOD_MATCH_AVAILABLE]: 'Yes, it’s a good match & I’m available',
  [ClaimingDeclarationOfInterestMessageResponse.GOOD_MATCH_NOT_AVAILABLE]: 'Yes, it’s a good match but I’m not available',
  [ClaimingDeclarationOfInterestMessageResponse.NOT_GOOD_MATCH_AVAILABLE]: 'I am available, but it’s not a good match',
  [ClaimingDeclarationOfInterestMessageResponse.NOT_GOOD_MATCH_NOT_AVAILABLE]: 'I am not available & it’s not a good match'
};

@Component({
  selector: 'app-reply-message-content',
  templateUrl: './reply-message-content.component.html',
  styleUrls: ['./reply-message-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplyMessageContentComponent implements OnInit, OnDestroy {
  private contextService = inject(ContextService);
  private helperFunction = inject(HelperFunctionsService);

  reply = signal<IClaimingDeclarationOfInterestMessage>(null);
  loading = signal(true);

  public readonly claimingStatus = ClaimingStatus;

  private subscriptions$ = new Subscription();

  ngOnInit() {
    // TODO: get rid of this actions solution
    this.subscriptions$.add(
        this.contextService.actions.subscribe((val) => {
        if (val.action === ActionTypes.ReplyMessage) {
          this.reply.set(this.contextService.declarationOfInterestReply);
          this.loading.set(false);
        }
      })
    );
  }

  statusIcon = computed(() => {
    const status = this.reply()?.status;
    const icon = { type: 'icon', icon: '' };
    switch (status) {
      case ClaimingStatus.PENDING_REPLY:
        icon.icon = 'loader';
        break;
      case ClaimingStatus.INTERESTED:
        icon.icon = 'check';
        break;
      case ClaimingStatus.NOT_INTERESTED:
      case ClaimingStatus.NO_REPLY:
        icon.icon = 'X';
        break;
    }
    return icon;
  });

  color = computed(() => {
    const status = this.reply()?.status;

    switch (status) {
      case ClaimingStatus.PENDING_REPLY:
        return 'warning--Light';
      case ClaimingStatus.INTERESTED:
        return 'success--Light';
      case ClaimingStatus.NOT_INTERESTED:
      case ClaimingStatus.NO_REPLY:
        return 'danger--Light';
      default:
        return '';
    }
  });

  match = computed(() => {
    const response = this.reply()?.response;
    return CLAIMING_DECLARATION_OF_INTEREST_MESSAGE_RESPONSE_TEXT[response];
  });

  getDate(date: number | string, iso?: string): string {
    if (iso) {
      date = Math.floor(new Date(date).getTime() / 1000);
    }
    return this.helperFunction.getDate(date as number, iso ? false : true);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
