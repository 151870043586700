import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option } from '../../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnChanges {
  @Input() team?: { name: string; displayName: string }[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  options: option[] = [];
  @Input() disabled: boolean;

  selectOption(option: option) {
    const findex = this.selected.findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected.splice(findex, 1);
    } else {
      this.selected.push(option);
    }
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.team && changes['selected']) {
      this.options = [];
      this.team.forEach((team: { name: string; displayName: string }) => {
        const findex = this.selected.findIndex(
          (x) => x.displayName === team.name
        );
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: team.name,
          name: team.displayName,
          type: 'team',
        });
      });
    }
  }
}
