import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import {
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { withCountriesDictionary } from './countries-dictionary.store';
import { computed, inject } from '@angular/core';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  pipe,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  EMPTY,
} from 'rxjs';
import { DictionariesService } from '../../services/dictionaries.service';
import { withBuAndOfficeLocationsDictionary } from './bu-and-office-locations-dictionary.store';
import { withRelationStatusesDictionary } from './relation-statuses-dictionary.store';
import { withCrmStatusesDictionary } from './crm-statuses-dictionary.store';

export type DictionariesState = {
  loading: boolean;
  lastUpdateTimestamp: number;
};

export const initialDictionariesState: DictionariesState = {
  loading: false,
  lastUpdateTimestamp: null,
};

const CACHE_LIMIT = 60 * 5 * 1000; // 5 minutes

export const DictionariesStore = signalStore(
  { providedIn: 'root' },
  withDevtools('dictionaries'),
  withState(initialDictionariesState),
  withCountriesDictionary(),
  withBuAndOfficeLocationsDictionary(),
  withRelationStatusesDictionary(),
  withCrmStatusesDictionary(),
  withComputed(({ lastUpdateTimestamp }) => ({
    _hasCacheTimeLimitPassed: computed(() => {
      const now = Date.now();
      if (lastUpdateTimestamp() === null) {
        return true;
      } else {
        return now - lastUpdateTimestamp() > CACHE_LIMIT;
      }
    }),
  })),
  withMethods(
    (
      store,
      dictionariesApi = inject(DictionariesService),
      snackBarService = inject(SnackBarService)
    ) => ({
      queryDictionaries: rxMethod<{
        forceReload: boolean;
      }>(
        pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap((params) => {
            if (
              params.forceReload ||
              store.countriesDictionary.values === null || // add more connected feature stores if needed
              store._hasCacheTimeLimitPassed()
            ) {
              updateState(
                store,
                '[DictionariesStore] query dictionaries - update loading',
                {
                  loading: true,
                }
              );
            }
          }),
          switchMap((params) => {
            if (
              params.forceReload ||
              store.countriesDictionary.values === null || // add more connected feature stores if needed
              store._hasCacheTimeLimitPassed()
            ) {
              return dictionariesApi.getDictionaries().pipe(
                tapResponse({
                  next: (response) =>
                    updateState(
                      store,
                      '[DictionariesStore] query dictionaries - update state after success',
                      {
                        _countriesDictionary: {
                          ...store._countriesDictionary(),
                          _countries: response?.filters?.crmCountries ?? null,
                        },
                        _buAndOfficeLocationsDictionary: {
                          ...store._buAndOfficeLocationsDictionary(),
                          _locations:
                            response?.filters?.businessUnitAndOfficeLocations ??
                            null,
                        },
                        _relationStatusesDictionary: {
                          ...store._relationStatusesDictionary(),
                          _relationStatuses:
                            response?.filters?.relationStatuses ?? null,
                        },
                        _crmStatusesDictionary: {
                          ...store._crmStatusesDictionary(),
                          _crmStatuses: response?.filters?.statuses ?? null,
                        },
                        loading: false,
                        lastUpdateTimestamp: Date.now(),
                      }
                    ),
                  error: () => {
                    updateState(
                      store,
                      '[DictionariesStore] query dictionaries - update state after error',
                      {
                        loading: false,
                      }
                    );
                    snackBarService.open({
                      message: 'Error while loading countries dictionary',
                      type: SnackBarTypes.ErrorAlt,
                    });
                  },
                })
              );
            } else {
              // do nothing, data from cache is still valid
              return EMPTY;
            }
          })
        )
      ),
    })
  )
);
