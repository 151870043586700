import {Injectable} from '@angular/core';
import {INavigationItem, NavigationMenuItem} from '../../interfaces/navigation';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest, distinctUntilChanged, filter, first, Observable, of, shareReplay, startWith} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly _navigation: Array<INavigationItem> = [
    {
      id: NavigationMenuItem.REQUESTS,
      allowed: true,
      state: false,
      link: '/requests',
      displayName: 'Requests',
      icon: 'briefcase',
      size: '1.6rem', //default 1rem
      count: '',
      default: true
    },
    {
      id: NavigationMenuItem.CONSULTANTS,
      allowed: false,
      state: false,
      link: '/consultants',
      displayName: 'consultants',
      icon: 'address-book',
      size: '1.6rem', //default 1rem
      count: '',
    },
    {
      id: NavigationMenuItem.MATCHING,
      allowed: true,
      state: false,
      link: '/matching',
      displayName: 'Matching',
      icon: 'exchange',
      size: '1.6rem', //default 1rem
      count: '',
    },
    {
      id: NavigationMenuItem.CANDIDATES,
      state: false,
      link: '/candidates/inbox',
      displayName: 'Candidates',
      icon: 'Inbox',
      size: '1.4rem', //default 1rem
      count: '0',
      submenu: [
        {
          id: NavigationMenuItem.CANDIDATES_INBOX,
          state: false,
          link: '/candidates/unsubmitted',
          displayName: 'Registered',
          count: '0',
        },
        {
          id: NavigationMenuItem.CANDIDATES_NEW_LEADS,
          state: false,
          link: '/candidates/new-leads',
          displayName: 'New Leads',
          count: '0',
        },
        {
          id: NavigationMenuItem.CANDIDATES_PENDING_INTERVIEW,
          state: false,
          link: '/candidates/pending-interview',
          displayName: 'Pending interview',
          count: '0',
        },
        {
          id: NavigationMenuItem.CANDIDATES_REJECTED,
          state: false,
          link: '/candidates/rejected',
          displayName: 'Rejected',
          count: '0',
          activeForRoutePrefix: ['/candidates/rejected']
        },
      ],
    },
    {
      id: NavigationMenuItem.CANDIDATES_ACCEPTED,
      state: false,
      link: '/candidates/accepted',
      displayName: 'Accepted',
      icon: 'address-book',
      size: '1.6rem', //default 1rem
      count: '0',
    }
  ];

  navigationReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  navigation$: Observable<INavigationItem[]> = of(this._navigation);
  activeNavigationItem$: Observable<INavigationItem>;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), first())
      .subscribe(() => this.handleActiveItem());
  }

  get navigation(): Array<INavigationItem> {
    return this._navigation;
  }

  handleActiveItem() {
    const initialUrl = this.router.routerState.snapshot.url;

    this.activeNavigationItem$ = combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(new NavigationEnd(0, this.router.routerState.snapshot.url, this.router.routerState.snapshot.url))
      ),
      this.navigation$
    ]).pipe(
      map(([event, navItemsList]) => {
          const navigationEndEvent: NavigationEnd = (event as NavigationEnd);
          const startsWithFn = String.prototype.startsWith.bind(navigationEndEvent.urlAfterRedirects);

          // First find the root navigation index if it exists in array
          const rootPathIndex: number = navItemsList.findIndex((item) => {
            return item.default === true;
          });

          const rootPathItem: INavigationItem = rootPathIndex > -1 ? navItemsList[rootPathIndex] : null;

          // First we check if after redirects the url is pointing to root
          // saves some checks later on
          if (navigationEndEvent.urlAfterRedirects === '/' && rootPathIndex > -1) {
            return navItemsList[rootPathIndex];
          }

          const matchedMenuElement = {
            mainIndex: -1,
            subItemIndex: -1
          };

          for (let i = 0; i < navItemsList.length; i += 1) {
            // Check if the current url starts with the link from navigation...
            if (navigationEndEvent.urlAfterRedirects.startsWith(navItemsList[i].link)
              // otherwise checks if it's among prefixes
              || (navItemsList[i].activeForRoutePrefix && navItemsList[i].activeForRoutePrefix.some(startsWithFn))) {
              // found index of active item in level 1
              matchedMenuElement.mainIndex = i;
              break;
            } else if (navItemsList[i].submenu) {
              for (let j = 0; j < navItemsList[i].submenu.length; j+=1) {
                if (navigationEndEvent.urlAfterRedirects.startsWith(navItemsList[i].submenu[j].link)
                  // otherwise checks if it's among prefixes
                  || (navItemsList[i].submenu[j].activeForRoutePrefix && navItemsList[i].submenu[j].activeForRoutePrefix.some(startsWithFn))) {
                  // found index of active item in level 2
                  matchedMenuElement.mainIndex = i;
                  matchedMenuElement.subItemIndex = j;
                  break;
                }
              }
            }
          }

          // If we found a match return it, otherwise check the root if it's matching
          if (matchedMenuElement.mainIndex > -1) {
            if (matchedMenuElement.subItemIndex > -1) {
              return navItemsList[matchedMenuElement.mainIndex].submenu[matchedMenuElement.subItemIndex];
            } else {
              return navItemsList[matchedMenuElement.mainIndex]
            }
          }

          // If nothing was found we are going back to root element to check if something matches there
          return !rootPathItem ? null : (
            // If current route is among active prefixes for root navigation item
            rootPathItem.activeForRoutePrefix && rootPathItem.activeForRoutePrefix.some(startsWithFn) ? rootPathItem : null
          );
        }
      ),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  /**
   * Gets a specific menu item.
   * @param id
   */
  getMenuItem(id: NavigationMenuItem): INavigationItem {
    return this._navigation.find((item) => item.id === id);
  }

  /**
   * Allow access to a specific menu item.
   * @param item
   */
  allowAccess(item: NavigationMenuItem) {
    const menuItem = this.getMenuItem(item);
    if (menuItem) {
      menuItem.allowed = true;
    } else {
      console.error(`[NavigationService] Item not found`, item);
    }
  }

  /**
   * Allow access to the Consultants section
   */
  allowConsultants() {
    this.allowAccess(NavigationMenuItem.CONSULTANTS);
  }

  /**
   * Allow access to the Requests section
   */
  allowCandidates() {
    this.allowAccess(NavigationMenuItem.CANDIDATES);
  }

  /**
   * Allow access to the Accepted Candidates section
   */
  allowAccepted() {
    this.allowAccess(NavigationMenuItem.CANDIDATES_ACCEPTED);
  }

  /**
   * Sets the navigation as ready
   */
  setNavigationReady() {
    this.navigationReady$.next(true);
  }
}
