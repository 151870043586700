import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ISmartTip } from '../../interfaces/matching/smart-tip';
import { ApiService } from '../api.service';
import { ServiceNames } from '../../interfaces/services';
import { IClaimingFreelancerStatus } from '../../interfaces/claiming';

@Injectable({
  providedIn: 'root',
})
export class MatchingService {
  API_MATCHING: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.API_MATCHING = this.apiService.serviceUrl(ServiceNames.Core, 'v1');
  }

  getSmartTip(entityId: number, query?: string): Observable<ISmartTip> {
    return this.http.get<ISmartTip>(
      this.API_MATCHING + '/smarttip/' + entityId + (query ? '?' + query : '')
    );
  }

  /**
   * Get consultant statuses used for claiming
   */
  getConsultantStatuses(): Observable<Array<IClaimingFreelancerStatus>> {
    return this.http
      .get<Array<IClaimingFreelancerStatus>>(this.API_MATCHING + '/matching/freelancer/status')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `[MatchingService] Failed to get consultant requests statuses`,
            error
          );
          return throwError(() => error);
        })
      );
  }
}
