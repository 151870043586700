import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  faChevronRight,
  faSpinner,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { MatomoTracker } from 'ngx-matomo';
import { PotentialRequests } from 'src/interface/shared.interface';
import { tableList, list, status } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() list: list[];
  @Input() potentialRequests: PotentialRequests[];
  @Input() listLimit = -1;
  @Input() type = '';
  @Input() selected: string[] = [];
  @Input() loading = true;
  @Input() tableList: tableList[] = [];
  @Input() clickRow = false;
  @Output() callbackFunction = new EventEmitter<string[]>();
  @Output() view = new EventEmitter<string[]>();
  faChevronRight = faChevronRight;
  faSpinner = faSpinner;
  faLinkSimple = faArrowUpRightFromSquare;
  first = false;
  constructor(
    private contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private helperfunctions: HelperFunctionsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list']) {
      if (this.list.length > 0) {
        this.loading = false;
      }
    }
  }

  href(link: string | unknown) {
    if (typeof link === 'string') {
      this.matomoTracker.trackEvent('Matching', 'Click - (Old list), CRM Link');
      window.open(link, '_blank');
    }
  }
  select(id: string) {
    if (id === '-1') {
      if (
        this.selected.length > 0 &&
        this.selected.length === this.list.length
      ) {
        this.selected = [];
        return;
      }
      this.list.forEach((x: list) => {
        const findex = this.selected.findIndex((z) => z === x.id);
        if (findex === -1) {
          this.selected.push(x.id);
          return;
        }
      });

      return;
    }
    const findex = this.selected.findIndex((x) => x === id);
    if (findex === -1) {
      this.selected.push(id);
      return;
    }
    this.selected.splice(findex, 1);
  }

  firstTd() {
    this.first = true;
  }
  getStatus(value: string) {
    const status = {
      Registered: 'Registered',
      NewLead: 'New lead',
      PendingInterview: 'Pending interview',
      Rejected: 'Rejected',
      Approved: 'Approved',
    };
    return status[value as keyof status];
  }

  getJobFamily(id: string) {
    const findex = this.contextService.jobFamily.findIndex((x) => x.id === id);
    return this.contextService.jobFamily[findex].displayName;
  }
  getYearsOfExperience(shortYearExp: string) {
    const findex = this.contextService.levelOfExperience.findIndex(
      (x) => x === shortYearExp
    );
    return this.contextService.yearExperience[findex]?.displayName ?? '-';
  }
  openList(id: string) {
    if (!this.first && this.type === 'consultant') {
      this.callbackFunction.emit(['openCandidate', id]);
    }
    if (this.type === 'match') {
      this.view.emit(['view', id]);
    }
    this.first = false;
  }
  convertToProcent(value: any) {
    if (typeof value === 'number') {
      value = JSON.stringify(value);
    }
    return value && !value.includes('-') ? value.split('.')[1].slice(0, 2) : '';
  }

  getTypeOf(value: unknown) {
    return typeof value;
  }
  getRequestValue(request: PotentialRequests, typeOf: string) {
    return (request[typeOf as keyof PotentialRequests] ?? '-') + ' ';
  }
  getValue(candiate: list, typeOf: string) {
    if (typeOf === 'countryCode' || typeOf === 'country') {
      const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
      const countryCode = candiate[typeOf as keyof list] as string;
      if (!countryCode) {
        return '';
      }
      return regionNames.of(countryCode.toUpperCase());
    }
    if (typeOf === 'levelOfExperience') {
      return (
        this.getYearsOfExperience(candiate[typeOf as keyof list] as string) +
        ' '
      );
    }
    if (typeOf === 'labels') {
      const labels = candiate[typeOf as keyof list] as string[];

      return labels.includes('7NConsultant') ? 'Yes' : 'No';
    }
    if (typeOf === 'status') {
      return this.getStatus(candiate[typeOf as keyof list] as string) + ' ';
    }
    if (typeOf === 'submissionTime' || typeOf === 'createdAtTime') {
      return candiate[typeOf as keyof list]
        ? this.helperfunctions.getDate(
            candiate[typeOf as keyof list] as number
          ) + ' '
        : '-';
    }
    if (typeOf === 'category') {
      const category = candiate[typeOf as keyof list] as string;
      if (!category) {
        return '';
      }
      return category.match(/[A-Z][a-z]+/g)?.join(' ');
    }
    if (typeOf === 'workLocations') {
      return candiate[typeOf as keyof list];
    }
    if (typeOf === 'previouslyWorkedAsConsultant') {
      return candiate[typeOf as keyof list] ? 'Yes' : 'No';
    }

    return (candiate[typeOf as keyof list] ?? '-') + ' ';
  }
}
