<app-notification></app-notification>
<app-request
  *ngIf="requestId"
  [requestId]="requestId"
  [loading]="loading"
  [rerender]="rerender"
  (callbackFunction)="loading = $event"
></app-request>

<div class="surface--2">
  <app-loader *ngIf="loading" [loading]="loading"></app-loader>
</div>

<div *ngIf="!loading && !requestId" class="requests">
  <div class="top surface--2">
    <app-page-title label="Requests"></app-page-title>

    <div class="search">
      <app-input
        (inputs)="search($event)"
        [leadingIcon]="'search'"
        placeholder="Search for request title or client name"
        [inputType]="'search'"
      ></app-input>
    </div>
  </div>
  <div class="content">
    <div class="filters surface--2">
      <app-filters [disable]="loading" [filterType]="'request'"></app-filters>
    </div>
    <div class="requestList surface--2">
      <div class="bundle-headline-switch">
        <div>
          <h3>All requests</h3>
          <p class="body-small textSecondary">
            {{ requestsList.length }} active requests
          </p>
        </div>
        <div class="my-request">
          <app-switch [isToggled]="onlyMyRequest" (callbackFunction)="myRequests($event)" label="Only show my requests"></app-switch>
        </div>
      </div>
      <div *ngIf="selectedFilters" class="selectedFilters">
        <ng-container *ngFor="let filter of selectedFilters; let i = index">
          <app-badge
            [label]="filter.displayName"
            [trailingIcon]="{ type: 'icon', icon: 'X' }"
            color="neutral--Grey-300"
            (callbackFunction)="removeFilter(filter)"
          ></app-badge>
        </ng-container>
        <p
          *ngIf="selectedFilters.length > 0"
          class="textLink clear-all body-small"
          (click)="clearAll(true)"
        >
          Clear all
        </p>
      </div>
      <div class="max-width">
        <div class="sortedBy">
          <p class="body-small textSecondary">
            Sort by: <span class="bold">Created date</span>
          </p>
        </div>
        <app-request-level1
          [level0]="false"
          *ngFor="let request of requestsList"
          [selectedRequest]="request"
        ></app-request-level1>
      </div>
    </div>
  </div>
</div>
