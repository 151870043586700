import {
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  Signal,
} from '@angular/core';
import { IOption } from './option';
import { IConsultantTier } from '../../consultants/interfaces/consultant';

export interface IBasicFilterComponent {
  values?: InputSignal<IOption[]>;
  disabled: InputSignal<boolean>;
  selected: ModelSignal<IOption | IOption[]>;
  showTitle: InputSignal<boolean>;
  showCounter: InputSignal<boolean>;

  selectedOptionsUpdated: OutputEmitterRef<IOption | IOption[]>;

  selectedOptionsCounter: Signal<number>;

  readonly TITLE?: string;

  clear: () => void;
}

export enum FilterTypes {
  KEYWORDS = 'keywords',
  LOCATION_COUNTRY = 'location-country',
  LOCATION_RANGE = 'location-range',
  AVAILABILITY = 'availability',
  WORKED_WITH_7N = 'worked-with-7n',
  CRM_STATUS = 'crm-status',
  RELATION_STATUS = 'relation-status',
  TEAM_LOCATION_COUNTRY = 'team-location-country',
  TEAM_LOCATION_OFFICE = 'team-location-office',
  TEAM_NAME = 'team-name',
}

export enum AvalibilityFilterOptionIds {
  NOW = 'now',
  NEXT_30_DAYS = 'next30days',
  NEXT_60_DAYS = 'next60days',
  CUSTOM_RANGE = 'range',
}

export enum AvailabilityFilterOptionNames {
  NOW = 'Now',
  NEXT_30_DAYS = 'Next 30 days',
  NEXT_60_DAYS = 'Next 60 days',
  CUSTOM_RANGE = 'Custom range',
}

export const AvailabilityFilterValues = {
  [AvalibilityFilterOptionIds.NOW]: {
    id: AvalibilityFilterOptionIds.NOW,
    name: AvailabilityFilterOptionNames.NOW,
  },
  [AvalibilityFilterOptionIds.NEXT_30_DAYS]: {
    id: AvalibilityFilterOptionIds.NEXT_30_DAYS,
    name: AvailabilityFilterOptionNames.NEXT_30_DAYS,
  },
  [AvalibilityFilterOptionIds.NEXT_60_DAYS]: {
    id: AvalibilityFilterOptionIds.NEXT_60_DAYS,
    name: AvailabilityFilterOptionNames.NEXT_60_DAYS,
  },
  [AvalibilityFilterOptionIds.CUSTOM_RANGE]: {
    id: AvalibilityFilterOptionIds.CUSTOM_RANGE,
    name: AvailabilityFilterOptionNames.CUSTOM_RANGE,
  },
};

export const AvailabilityFilterOptions: IOption[] = [
  {
    id: AvalibilityFilterOptionIds.NOW,
    name: AvailabilityFilterOptionNames.NOW,
  },
  {
    id: AvalibilityFilterOptionIds.NEXT_30_DAYS,
    name: AvailabilityFilterOptionNames.NEXT_30_DAYS,
  },
  {
    id: AvalibilityFilterOptionIds.NEXT_60_DAYS,
    name: AvailabilityFilterOptionNames.NEXT_60_DAYS,
  },
  {
    id: AvalibilityFilterOptionIds.CUSTOM_RANGE,
    name: AvailabilityFilterOptionNames.CUSTOM_RANGE,
  },
];

export const ConsultantCrmStatusFilterOptions: IOption[] = [
  {
    id: 'Lead',
    name: 'Lead',
  },
  {
    id: '7N Pending',
    name: '7N Pending',
  },
  {
    id: '7N Consultant',
    name: '7N Consultant',
  },
];

// TODO: maybe move this to store or should be fetched from the backend
export const ConsultantTierStasuses: IConsultantTier[] = [
  {
    tierStatus: '',
    recruitmentSequence: 'On contract',
    status: {
      description: 'On Contract',
      contractStatus: 'OnContract',
    },
  },
  {
    tierStatus: '',
    recruitmentSequence: 'Running out',
    status: {
      description: 'Running out',
      contractStatus: 'ApproachingDeadline',
    },
  },
  {
    tierStatus: '',
    recruitmentSequence: 'Running out soon',
    status: {
      description: 'Running out soon',
      contractStatus: 'FinalCountdown',
    },
  },
  // TODO: someone commented this out, not sure if it should be removed
  // {
  //   tierStatus: '',
  //   recruitmentSequence: 'Out of work',
  //   status: {
  //     description: 'Out of work',
  //     contractStatus: 'OutOfWork',
  //   },
  // },
  {
    tierStatus: '',
    recruitmentSequence: 'Ready to work',
    status: {
      description: 'Ready to work',
      contractStatus: 'ReadyToWork',
    },
  },
  {
    tierStatus: 'Tier1',
    recruitmentSequence: 'Verification',
    status: {
      description: '1 Verification',
      contractStatus: 'verification',
    },
  },

  {
    tierStatus: 'Tier1',
    recruitmentSequence: 'Prescreening',
    status: {
      description: '2 Prescreening',
      contractStatus: 'prescreening',
    },
  },
  {
    tierStatus: 'Tier1',
    recruitmentSequence: 'Interview',
    status: {
      description: '3 Interview',
      contractStatus: 'interview',
    },
  },
  {
    tierStatus: 'Tier1',
    recruitmentSequence: 'Evaluation',
    status: {
      description: '4 Evaluation',
      contractStatus: 'evaluation',
    },
  },
  {
    tierStatus: 'Tier1',
    recruitmentSequence: 'Completed',
    status: {
      description: '5 Completed',
      contractStatus: 'completed',
    },
  },
];

export const ConsultantRelationStasusFilterOptions: IOption[] = [
  ...ConsultantTierStasuses.map((tier) => ({
    id: tier.status.contractStatus,
    name: tier.status.description,
  })),
];
