<div class="add-request">
  <app-search-request
    [selectedSearchType]="selectedSearchType()"
    [selectedRequest]="selectedRequest()"
    [requestsList]="requestsList()"
    [requestsListLoading]="requestsListLoading()"
    [requestsListError]="requestsListError()"
    (selectrequest)="selectRequest.emit($event)"
    (manualRequest)="manualRequest.emit($event)"
  ></app-search-request>
</div>
