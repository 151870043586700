<div class="matching-keywords-container">
  <div class="matching-keywords-content flex flex-col flex-start-start gap-6">
    <div class="matching-keywords-header flex flex-row flex-start-center gap-6">
      <p class="matching-keywords-title bold">Matching keywords</p>
      <app-badge class="matching-keywords-title-counter" color="amount neutral--Grey-300" [label]="keywordsLabel()"></app-badge>
    </div>
    <p class="matching-keywords-subtitle body-small">Keywords that were found both in Sales Request and Candidate's Profile</p>
    <div class="keywords flex flex-row flex-start-start gap-6">
      <div #keywordsContainer class="matching-keywords-list w-full" [ngClass]="{ 'show-all-keywords': showAllKeywords() }">
        @for(keyword of keywordsSorted() | slice : 0 : 20; track keyword[0]){
          <app-badge class="matching-keyword" color="highlight--Lighter" [label]="keyword[0]" [amount]="keyword[1]"></app-badge>
        }
      </div>
  
      @if(showReadMoreBtn()) {
        <app-button style="min-width: unset" 
                    [class]="['xsmall']" 
                    [label]="showAllKeywords() ? 'Less -' : 'More +'"
                    (click)="showAllKeywords.set(!showAllKeywords())"></app-button>
      }
    </div>
  </div>
</div>