import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  requestLevels2,
  requestV3,
  routeParams
} from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';

@Component({
  selector: 'app-request-level1',
  templateUrl: './request-level1.component.html',
  styleUrls: ['./request-level1.component.scss'],
})
export class RequestLevel1Component {
  @Input() level0: boolean;
  @Input() level3: boolean;
  @Input() selectedSalesRequest: requestV3;
  @Input() selectedRequest: requestV3;
  requestLevel2: requestLevels2;
  openDropdown: boolean;
  freelanceAddedToRequest: boolean;
  expand: boolean;
  params: routeParams;

  constructor(
    private helperFunctions: HelperFunctionsService,
    private contextService: ContextService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((val) => {
      this.params = val as routeParams;
    });
  }
  getDisplayName(value: string) {
    const findex = this.contextService.filterData.countries.findIndex(
      (x) => x.value === value
    );

    if (findex === -1) {
      return '-';
    }
    return this.contextService.filterData.countries[findex].displayName;
  }
  calcDay(createdAt: number | null) {
    if (createdAt) {
      const day = this.helperFunctions.daysBetweenTimestamp(createdAt);
      return 'Created ' + (day > 1 ? +day + ' days' : day + ' day') + ' ago';
    }
    return '-';
  }

  ifEmptyReturnDash(value: string | null) {
    if (value) {
      return this.getDate(value);
    }
    return '-';
  }

  openMatchModal(event: Event, another?: boolean) {
    event.stopPropagation();
    this.contextService.openMatchModal = another
      ? 'addAnotherconsultant'
      : 'addconsultant';
    this.contextService.selectedRequest = this.selectedRequest;
  }
  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown = !this.openDropdown;
  }
  openInternal(event: Event, match?: boolean) {
    event.stopPropagation();
    if (this.selectedRequest) {
      if (match) {
        if (this.selectedRequest.isDescription) {
          this.helperFunctions.route([
            '/matching/rtc/' + this.selectedRequest.id,
          ]);
        }
        return;
      }
      window.open('requests/' + this.selectedRequest.id, '_blank');
    }
  }
  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();
    if (this.selectedRequest) {
      window.open(link, '_blank');
    }
  }
  getDate(value: string) {
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }
  getColorType(priority: string) {
    if (priority.toLowerCase() === 'medium') {
      return 'brand--Light textLink';
    }
    if (priority.toLowerCase() === 'low') {
      return 'brand--Lighter textLink';
    }
    return 'brand--Medium';
  }
  getSaleStatus(salesStatus: { salesStatus: string }) {
    return this.helperFunctions.getSaleStatus(salesStatus);
  }

  onClickOutside() {
    if (this.openDropdown) {
      this.openDropdown = !this.openDropdown;
    }
  }
}
