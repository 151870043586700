<div
  *ngIf="tier"
  class="tier"
  [class]="{
    expand: contextService.expandableTier === id || !expandable,
    notExpandable: !expandable,
    small: class === 'small'
  }"
>

  <div
    [class]="
      getStatus().toLocaleLowerCase() + (expandable ? ' expandable' : '')
    "
  >
    <div (click)="expandTier($event)" [class]="'badge'">
      <div class="bundle-name-icon" [class]="{ dangerAny: isAnyRedDot() }">
        <i-tabler [name]="getIcon()"></i-tabler>
        <p class="tiny bold">{{ getStatus() }}</p>
        <div class="red-dot"></div>
      </div>
      <i-tabler
        *ngIf="expandable"
        [name]="
          'chevron-' + (contextService.expandableTier === id ? 'up' : 'down')
        "
      ></i-tabler>
    </div>
    <div class="info tiny">
      <div>
        <p>7N contracts</p>
        <p class="bold">{{ tier.contracts }}</p>
      </div>
      <div>
        <p>Worked for 7N</p>
        <p class="bold">{{ getCompletedDate("", getRule(20).input) ?? "-" }}</p>
      </div>
      <div
        [class]="{
          danger: !getRule(40).evaluationResult
        }"
      >
        <p>Profile updated</p>
        <div>
          <p
            *ngIf="getCompletedDate('', getRule(40).input); else dash"
            class="bold"
          >
            {{ getCompletedDate("", getRule(40).input) }} ago
          </p>
          <ng-template #dash><p>-</p></ng-template>
          <div class="red-dot"></div>
        </div>
      </div>
      <div [class]="{ danger: !getRule(30).evaluationResult }">
        <p>Profile strength</p>
        <div>
          <p class="bold">{{ getRule(30).input ?? "0" }}%</p>
          <div class="red-dot"></div>
        </div>
      </div>
      <div
        class="recruited"
        [class]="{
          danger: !getRule(80).evaluationResult
        }"
      >
        <p>Recruited</p>
        <p class="bold">
          <ng-container *ngIf="!getRule(80).evaluationResult; else content">
            <span class="step">
              <span [class]="{ active: biggerThanStep(0) }"></span>
              <span [class]="{ active: biggerThanStep(1) }"></span>
              <span [class]="{ active: biggerThanStep(2) }"></span>
              <span [class]="{ active: biggerThanStep(3) }"></span>
              <span [class]="{ active: biggerThanStep(4) }"></span>
            </span>
            {{ tier.recruitmentSequence }}
            <div class="red-dot"></div>
          </ng-container>
          <ng-template #content>
            <p
              *ngIf="getCompletedDate(tier.recruitedUpdated); else dash"
              class="bold"
            >
              {{ getCompletedDate(tier.recruitedUpdated) }} ago
            </p>
            <ng-template #dash><p>-</p></ng-template>
          </ng-template>
        </p>
      </div>
    </div>
  </div>
</div>
