<div class="country">

  <app-dropdown-v2
    trailingIcon="search"
    [selected]="selected"
    [options]="options"
    [type]="'country'"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [disabled]="disabled"
    [placeholder]="placeholder"
  ></app-dropdown-v2>
</div>
