import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { option } from '../../../../interface/shared.interface';

@Component({
  selector: 'app-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss'],
})
export class DropdownTreeComponent {
  @Input() selected: option[] = [];
  @Input() options: option[];
  @Input() multiselect: boolean;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() searchable = true;
  @Output() selectOption = new EventEmitter<option>();
  dropdown: boolean;
  rawOptions: option[];
  @ViewChild('searchInput') searchInput: ElementRef<HTMLElement>;
  search(event: Event) {
    const value = (
      event.currentTarget as HTMLInputElement
    ).innerText.toLocaleLowerCase();

    if (!this.rawOptions) {
      this.rawOptions = this.options;
    }
    this.options = JSON.parse(JSON.stringify(this.rawOptions));

    this.options = this.options.filter((x) => {
      if (x.displayName.toLowerCase().includes(value) || !x.subNames) {
        return true;
      }
      x.subNames = x.subNames.filter((z) =>
        z.displayName.toLowerCase().includes(value)
      );
      if (x.subNames.length > 0) {
        return true;
      }
      return false;
    });
  }
  isSlected(option: option) {
    return this.selected.some((x) => x.id === option.id);
  }
  selectFunc(select: option, topLevel?: boolean, option?: option) {
    this.searchInput.nativeElement.innerHTML = '';
    if (option) {
      const findex = this.selected.findIndex((x) => x.id === option.id);
      if (findex !== -1) {
        this.selectOption.emit(option);
      }
    }
    if (topLevel) {
      const findex = this.options.findIndex((x) => x.id === select.id);
      if (findex !== -1) {
        this.options[findex].subNames?.forEach((x) => {
          if (this.selected.some((z) => z.id === x.id)) {
            this.selectOption.emit(x);
          }
        });
      }
    }
    this.selectOption.emit(select);
  }
  resetInput() {
    if (this.rawOptions) {
      this.options = this.rawOptions;
    }
    return '';
  }
}
