<div [class]="'surface--3 heading ' + (openSidePanel ? 'open' : '')">
  <app-logo [back]="false"></app-logo>
  <div
    (click)="toggleSide()"
    [class]="'toggle-btn' + (openSidePanel ? ' ' : ' turn')"
    data-cy="navigation-toggle-btn"
  >
    <div class="wrap-icon">
      <i-tabler class="chevron" name="chevron-left"></i-tabler>
      <i-tabler class="close" name="X"></i-tabler>
    </div>
  </div>
</div>
