import { Component, Input } from '@angular/core';
import { matchConsultants ,by, tier } from '../../../interface/shared.interface';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() consultant?: matchConsultants;
  @Input() customer?: {
    name: string;
    imageUrl?: string;
  };
  @Input() shortListed?: {
    fullName: string;
    imageUrl?: string;
  };
  @Input() tier: tier;
  @Input() by: by;
  @Input() tiers: boolean;
  @Input() salesAgent: boolean;
  @Input() class: string;
  isExpandTier: boolean;

  getId() {
    const profile = [this.consultant, this.by];
    const findex = profile.findIndex((x) => x);
    return profile[findex]?.id ?? '1';
  }

  getInput(consultant: matchConsultants) {
    if (this.customer) {
      return this.customer;
    }
    if (this.salesAgent) {
      return consultant.salesAgent;
    }
    return consultant;
  }
}
