import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {INavigationItem} from '../../../shared/interfaces/navigation';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationItemComponent {
  @Input() navItem: INavigationItem;
  @Input() active = false;
}
