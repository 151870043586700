import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ICrmCountry } from '../../../../shared/interfaces/dictionaries';
import { DictionariesFacadeService } from '../../../../shared/services/dictionaries-facade.service';
import { option } from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import { filter, forkJoin, from, take } from 'rxjs';
import { PlatformService } from '../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnChanges, OnInit {
  @Input() selectedCvType: number;
  @Input() disable: boolean;
  @Input() filterLevel: boolean;
  @Input() matchId: string | undefined;
  @Input() iterateId: number | null;
  @Input() selectedSearchType: number;
  @Input() selectedFilters: option[] = [];
  @Input() selectedNav: string;
  @Input() filterType: string;
  filterLength = 0;
  openFilter: boolean;
  filterDataReady: boolean;
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService
  ) {}

  private dictionariesFacade = inject(DictionariesFacadeService);
  ngOnInit() {
    forkJoin({
      consultantStatus: from(this.platformService.getConsultantStatus()),
      countries: this.dictionariesFacade.countriesDict$.pipe(
        filter((countries) => !!countries?.length),
        take(1)
      )
    }).subscribe(({consultantStatus, countries}) => {
      this.contextService.filterData.salesstatus = consultantStatus;
      this.contextService.filterData.locationCountries = this.getCountriesOptions(countries);
      this.filterDataReady = true;
    });
  }

  openModal() {
    if (this.matchId) {
      this.contextService.iterateId = this.iterateId;
      this.contextService.matchId = this.matchId;
      this.contextService.openMatchModal = this.filterLevel
        ? 'filters'
        : 'match-filters';
    }
    if (this.filterType === 'request') {
      this.contextService.openMatchModal = 'request-filters';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCvType']) {
      this.getLength(this.selectedFilters);
    }
    if (changes['selectedFilters']) {
      this.getLength(this.selectedFilters);
    }
  }
  getLength(selectedFilters: option[]) {
    if (this.selectedCvType === 1) {
      this.filterLength = selectedFilters.filter(
        (x) => x.type !== 'keywords'
      ).length;
      return;
    }
    this.filterLength = selectedFilters.length;
  }

  private getCountriesOptions(countries: Array<ICrmCountry>): Array<option> {
    return countries.map((country) => ({
      name: country.countryName,
      displayName: country.countryName,
      id: country.countryId,
    }));
  }
}
