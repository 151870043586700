import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  requestResult,
  routeParams,
  searchContext,
  filterOption,
  countryCode,
  option
} from '../../../../../interface/shared.interface';

import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { Platform } from '@angular/cdk/platform';
import { MatomoTracker } from 'ngx-matomo-client';
import countries from '../../../../../assets/countries';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatchSalesRequestConsultant,
  MatchingConsultantRequest,
  MatchingRequestConsultant,
  cvType,
  matchConsultants,
  matchType,
  matches,
  requestV3
} from '../../../../../interface/shared.interface';
import { FeatureFlagsService } from '../../../../../services/feature-flags/feature-flags.service';
import { combineLatest, distinctUntilChanged, Subscription } from 'rxjs';
import { filteringList, removeFilterFunc } from '../shared-functions/filtering';
import {
  searchCTR,
  searchRTC,
  searchManual,
  searchRequest,
} from '../shared-functions/search';
import { matomoMatching } from '../shared-functions/matomo-tracking';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss'],
})
export class MatchComponent implements OnInit {
  @Input() isOverlay: boolean;
  @Input() params: routeParams;

  potentialResultsCv: matchConsultants[];
  potentialResultsFile: matchConsultants[];
  selectedCvType = 0;
  potentialRequestResults: requestV3[] = [];
  potentialListResults: requestV3[];
  closed = false;
  countryCode: countryCode[] = countries.list;
  dialog = false;
  eachLoading = -1;
  iterateId: number | null;
  faSpinner = faSpinner;
  filterList: filterOption[] = [];
  inputActive = '';
  moreLoading = false;
  loading = false;
  loadingSearchType = false;
  loadLength = 30;
  matchId: string;
  noResults = false;
  potentialResults: matchConsultants[] = [];
  rawPotentialResults?: matchConsultants[];
  allResults: matchConsultants[] | requestV3[];
  searchContext = this.contextService.advancedSearch;
  pagination = {
    limit: 200,
    offset: 100,
    id: '',
    loading: false,
  };
  response: boolean;
  allconsultants: matchConsultants[] = this.contextService.allconsultants ?? [];
  requestData: requestResult = { description: '', extrnalLink: '' };
  requestId: string;
  requestIdFiles: string;
  searchArray: matchConsultants[] = [];
  searchArrayCountry: countryCode[] = [];
  searchInput = '';
  searchType: string[] = ['rtc', 'ctr', 'ctc'];
  selectedConsultant?: matchConsultants;
  selectedRequest: requestV3 | undefined;
  manualRequest: matches;
  isManual: boolean;
  matchType: matchType[] = [
    {
      displayName: 'Request to Consultant',
      icon: 'file-text',
      modal: 'request',
      add: 'Add request',
      emptyStatment: {
        title: 'Add request to start your search.',
        description:
          'Please select a request to start matching. All matching consultants will appear here.',
      },
    },
    {
      displayName: 'Consultant to Request',
      icon: 'user',
      modal: 'ctr',
      add: 'Add consultant',
      emptyStatment: {
        title: 'Add consultant to start your search.',
        description:
          'Please select a consultant to start matching. All matching requests will appear here.',
      },
    },
    {
      displayName: 'Consultant to Consultant',
      icon: 'users',
      modal: 'ctc',
      add: 'Add consultant',
      emptyStatment: {
        title: 'Add consultant to start your search.',
        description:
          'Please select a consultant to start matching. All matching consultants will appear here.',
      },
    },
  ];
  selectedSearchType = 0;
  selectedFilters: option[];
  matchingType: cvType[] = [];
  c = 0;
  getLoading: boolean;
  isContentReady = false;
  queryParams: any;
  filters: string[] = [];
  subs = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private platformService: PlatformService,
    public platform: Platform,
    private helperFunctions: HelperFunctionsService,
    public featureFlags: FeatureFlagsService
  ) {
    if (
      this.helperFunctions.getCETTime() >= 23 ||
      this.helperFunctions.getCETTime() < 7
    ) {
      this.closed = true;
    }
    if (this.contextService.referrer) {
      this.matomoTracker.trackEvent(
        'Referrer',
        'Matching - ' + this.contextService.referrer
      );
      this.contextService.referrer = '';
    } else {
      this.matomoTracker.trackEvent('Matching', 'Selected in navigation');
    }
    this.contextService.search.subscribe(async (value: string) => {
      if (value === 'search' && this.response) {
        this.search();
      }
    });
  }
  getTier(option: option) {
    return this.contextService.filterData.tiers.find(
      (x: any) => x.status.contractStatus === option.displayName
    );
  }
  // Removes a filter from the list of selected filters and updates the query parameters
  removeFilter(filter: option) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = { ...params };
    });
    const { selectedFilters, queryParams } = removeFilterFunc(
      filter,
      this.selectedFilters,
      this.queryParams
    );
    this.selectedFilters = selectedFilters;

    // Navigates to the current route with updated query parameters
    this.router.navigate([], { queryParams });
  }

  // Clears all selected filters and navigates to the current route with no query parameters

  clearAll(newSearch?: boolean) {
    this.selectedFilters = [];
    this.contextService.globalFilterM.next([]);
    if (newSearch) {
      this.router.navigate([], {});
    }
  }

  // Returns the request if defined, otherwise returns an empty request object
  getRequest(request: undefined | requestV3) {
    return request as requestV3;
  }

  // Determines the status of the list and the number of items being shown
  listStatus() {
    let searchType = 'requests';
    let length = this.loadLength;
    if (this.potentialListResults) {
      if (this.loadLength >= this.potentialListResults.length) {
        length = this.potentialListResults.length;
      }
    }
    if (this.selectedSearchType !== 1) {
      if (this.loadLength >= this.potentialResults.length) {
        length = this.potentialResults.length;
      }
      searchType = 'candidates of ';
      if (this.selectedRequest) {
        searchType += this.selectedRequest.title;
      }
    }

    return 'Showing ' + length + ' ' + searchType;
  }

  // Compares two matchConsultants objects based on their first names
  compare(a: matchConsultants, b: matchConsultants) {
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  }

  // Sets up route parameters and handles deep linking and navigation
  routes() {
    this.route.params.subscribe(async (params) => {
      this.response = false;
      params = params as routeParams;

      if (params['deeplink']) {
        this.selectedSearchType = this.searchType.findIndex(
          (x) => x === params['deeplink']
        );
      }

      if (params['id'] && ['ctr', 'ctc'].includes(params['deeplink'])) {
        await this.platformService
          .consultant(params['id'])
          .then((respoonse) => {
            this.selectConsultant(respoonse[0]);
            if (this.route.snapshot.queryParamMap.get('search') !== 'false') {
              this.search();
            }
          });
      }
      if (params['id'] && params['deeplink'] === 'rtc') {
        this.potentialResults = [];
        this.filterList = [];
        this.isManual = false;

        if (params['id'].includes('manual')) {
          this.isManual = true;
          this.selectedRequest = undefined;
          this.contextService.selectedRequest = this.selectedRequest;
          this.manualRequest = this.contextService.manualRequest;
          if (this.manualRequest) {
            this.search();
          }
          return;
        }
      }

      if (params['id'] && params['deeplink'] === 'rtc') {
        this.potentialResults = [];
        this.filterList = [];
        this.isManual = false;

        if (params['id'].includes('manual')) {
          this.isManual = true;
          this.selectedRequest = undefined;
          this.contextService.selectedRequest = this.selectedRequest;
          this.manualRequest = this.contextService.manualRequest;
          return;
        }

        this.platformService.getRequests(params['id']).then((response) => {
          response.type = 'search';
          this.selectedRequest = response.data[0];
          this.contextService.selectedRequest = this.selectedRequest;
          if (this.route.snapshot.queryParamMap.get('search') !== 'false') {
            this.search();
          }
        });
      }

      if (!params['id']) {
        this.contextService.selectedRequest = undefined;
      }
    });
  }

  // Handles the loading and display of CV files based on the selected tab
  cvFile(tab: number) {
    this.loadLength = 30;
    this.getLoading = true;
    this.selectedCvType = tab;
    if (tab === 0) {
      this.potentialResults = this.potentialResultsCv;
    }
    if (tab === 1) {
      this.potentialResults = this.potentialResultsFile;
    }
    this.rawPotentialResults = undefined;
    this.queryParams['cvtype'] = tab;
    this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }

  // Loads more results when the "Load more" button is clicked
  loadMore() {
    this.matomoTracker.trackEvent('Matching', 'Clicked - Load more');
    this.moreLoading = true;
    setTimeout(async () => {
      if (this.loadLength + 20 >= this.potentialResults.length) {
        const findex = this.filters.findIndex((x) => x.includes('offset'));

        if (findex !== -1) {
          this.filters.splice(findex, 1);
        }
        const { potentialResults, potentialResultsCv, potentialResultsFile } =
          await searchRequest(
            this.filters,
            this.platformService,
            this.requestIdFiles ?? this.requestId,
            this.matchingType,
            this.selectedCvType,
            this.contextService,
            true
          );
        this.potentialResults = potentialResults;
        this.potentialResultsCv = potentialResultsCv;
        this.potentialResultsFile = potentialResultsFile;
      }
      this.loadLength = this.loadLength + 20;
      this.moreLoading = false;
    }, 1000);
  }

  // Selects the match type and navigates to the corresponding route
  chooseMatchType(type: number) {
    this.matomoTracker.trackEvent(
      'Matching',
      'Selected - ' + this.searchType[type]
    );
    this.helperFunctions.route(['matching', this.searchType[type]]);
    this.loadingSearchType = true;
    this.iterateId = null;
    setTimeout(() => {
      this.loadingSearchType = false;
    }, 300);
    this.selectedSearchType = type;
    this.searchContext = {
      request: '',
      crmId: '',
      qualification: '',
      qualificationImportance: 'Minimal',
      countryCode: '',
      matchingType: 'CV',
    };

    if (localStorage.getItem('countryCode')) {
      this.searchContext.countryCode =
        localStorage.getItem('countryCode') ?? '';
    }
    this.contextService.advancedSearch = this.searchContext;
    this.allResults = [];
    this.filterList = [];
    this.potentialResults = [];
    this.potentialListResults = [];
    this.searchArray = [];
    this.searchArrayCountry = [];
    this.contextService.globalFilterM.next([]);
    this.selectedConsultant = undefined;
    if (this.contextService.manualRequest) {
      this.contextService.manualRequest.description = '';
    }
    if (this.contextService?.selectedConsultant) {
      this.contextService.selectedConsultant.id = '-1';
      this.contextService.selectedConsultant.crmId = '-1';
    }
  }

  // Selects a consultant and updates the search context
  selectConsultant(consultant: matchConsultants) {
    this.selectedConsultant = consultant;
    this.searchContext.crmId = consultant.id;
    this.inputActive = '';
  }

  // Handles focus events and updates the search context based on the input type
  focus(e: Event, type: keyof searchContext) {
    this.input(e, type);
    if (type === 'crmId') {
      if (this.searchInput.length === 0) {
        this.searchArray = this.allconsultants;
      }
    }
  }

  // Handles blur events and resets the input active state if necessary
  blur(input: string) {
    if (input !== 'crmId' && input !== 'countryCode') {
      this.inputActive = '';
    }
  }

  // Opens the match modal
  openMatchModal() {
    this.contextService.openMatchModal = 'request';
  }

  // Returns the title of the selected request
  getSelectedRequest() {
    if (this.contextService.selectedRequest) {
      return this.contextService.selectedRequest.title;
    }
    return '';
  }

  // Handles different actions such as cancel and search
  findAction(type: string) {
    if (type === 'cancel') {
      this.searchContext = {
        request: '',
        crmId: '',
        qualification: '',
        qualificationImportance: 'Minimal',
        countryCode: '',
        matchingType: 'CV',
      };
    }
    if (type === 'search') {
      this.search();
    }
  }

  // Custom input handler for updating the search context
  customInput(event: { event: Event; type: string }) {
    const type = event.type as keyof searchContext;
    this.inputActive = type;
    if (type === 'qualification') {
      this.searchInput = (event.event.target as HTMLInputElement).value;
    } else {
      this.searchInput =
        (event.event.target as HTMLInputElement).getAttribute('value') ?? '';
    }
    this.searchContext[type] = this.searchInput;
  }

  // Input handler for updating the search context and filtering results
  input(e: Event, type: keyof searchContext) {
    this.inputActive = type;
    this.searchInput = (e.target as HTMLInputElement).innerText;
    if (type === 'countryCode') {
      this.searchArrayCountry = this.countryCode.filter((x) => {
        if (x.displayName) {
          x.displayName
            .toLowerCase()
            .startsWith(this.searchInput.toLowerCase());
        }
      });
      if (this.searchInput.length === 0) {
        this.searchArrayCountry = this.countryCode;
      }
    }
    if (type === 'crmId') {
      this.searchArray = this.allconsultants
        .filter((x: matchConsultants) => {
          const troncate = x.firstName.toLowerCase().split(' ');
          const string = this.searchInput.toLowerCase().split(' ');
          const searchName = string.every((x) =>
            troncate.some((z) => z.startsWith(x))
          );
          if (searchName) {
            return true;
          }
          return x.firstName
            .toLowerCase()
            .startsWith(this.searchInput.toLowerCase());
        })
        .sort((a: matchConsultants, b: matchConsultants) => {
          const nameA = a.firstName.toLowerCase();
          const nameB = b.firstName.toLowerCase();
          const insertedText = this.searchInput.toLowerCase();
          if (nameA.startsWith(insertedText)) {
            return -1;
          }
          if (!nameB.startsWith(insertedText)) {
            return 1;
          }
          return 0;
        });
      if (this.searchInput.length === 0) {
        this.searchArray = this.allconsultants;
      }
      return;
    }

    this.searchContext[type] = this.searchInput;
  }

  // Generates a random integer between min and max
  getRandomInt(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  // Content readiness handler for fetching results based on the iteration ID
  contentReady = async (iterateId: number) => {
    const searchType = this.searchType[this.selectedSearchType];
    this.response = false;
    this.rawPotentialResults = undefined;
    this.matchId = '';
    this.filterList = [];
    this.pagination.offset = 100;

    this.loading = true;
    this.potentialResults = [];
    this.c++;
    if (this.c > 60) {
      this.loading = false;
      return;
    }

    if (this.isContentReady && searchType === 'rtc') {
      this.isContentReady = false;
      this.potentialResults = [];

      const CV = new Promise((resolve) => {
        this.platformService
          .getCachedRequests(
            this.selectedRequest!.id,
            this.matchingType[0].iterationId
          )
          .then((resp: MatchSalesRequestConsultant) => {
            resolve(resp);
          });
      });
      const file = new Promise((resolve) => {
        this.platformService
          .getCachedRequests(
            this.selectedRequest!.id,
            this.matchingType[1].iterationId
          )
          .then((resp: MatchSalesRequestConsultant) => {
            resolve(resp);
          });
      });

      Promise.all([CV, file]).then(async (values: any[]) => {
        const mergedArray = [
          ...values[0].potentialFreelancers,
          ...values[1].potentialFreelancers,
        ];
        this.iterateId = values[0].iterationId;
        values[0].potentialFreelancers = mergedArray;
        values[0].paginationCv = values[0].pagination ?? {};
        values[0].paginationFile = values[1].pagination ?? {};
        this.matchingType = this.matchingType.map((x) => {
          if (x.iterationId === values[0].iterationId) {
            x.amount = values[0].pagination.total;
          }
          if (x.iterationId === values[1].iterationId) {
            x.amount = values[1].pagination.total;
          }
          return x;
        });
        await this.setresults(values[0], 'srtc', this.selectedRequest!.id);
        this.response = true;
        if (this.selectedFilters.length > 0) {
          this.contextService.globalFilterM.next(this.selectedFilters);
        }
      });
      return;
    }

    // Recursive function to periodically check if all matching types are ready by fetching cached requests.
    // If any matching type is not ready, it waits for 1 second and checks again.
    // Once all matching types are ready, it sets the contentReady flag to true and calls the contentReady method.
    const head = (iterateId: number) => {
      if (!this.matchingType.every((x) => x.contentReady)) {
        this.platformService
          .getCachedRequests(this.selectedRequest!.id, iterateId, true)
          .then((resp: { status: number }) => {
            const findex = this.matchingType.findIndex(
              (x) => x.iterationId === iterateId
            );
            if (resp.status === 200) {
              this.matchingType[findex].contentReady = true;
            } else if (
              resp.status === 204 &&
              !this.matchingType[findex].contentReady
            ) {
              setTimeout(() => {
                head(iterateId);
              }, 1000);
            } else {
              // quit the loop silently
              this.loading = false;
              this.isContentReady = false;
              Sentry.captureException(
                '[MatchComponent][contentReady] Error in fetching cached requests: ' +
                JSON.stringify(resp)
              );
              return;
            }


            if (this.matchingType.every((x) => x.contentReady)) {
              this.isContentReady = true;
              this.contentReady(iterateId);
            }
          });
      }
    };
    head(iterateId);
  };

  // Main search function that triggers the search based on the selected search type and context
  async search() {
    this.getLoading = false;
    this.response = false;
    this.rawPotentialResults = undefined;
    this.matchId = '';
    this.iterateId = null;
    this.filterList = [];
    this.loadLength = 30;
    this.pagination.offset = 100;
    this.loading = true;
    this.potentialResults = [];

    const searchType = this.searchType[this.selectedSearchType];

    matomoMatching(this.matomoTracker, searchType, this.searchContext);
    try {
      if (searchType === 'ctc') {
        this.platformService
          .matchconsultanttoconsultants(this.searchContext, 'v2')
          .then(async (response) => {
            if (response.message) {
              return;
            }
            this.iterateId = new Date().valueOf();
            await this.setresults(response, searchType);

            const { potentialResults, rawPotentialResults } =
              await filteringList(
                this.selectedFilters,
                this.contextService,
                this.potentialResults,
                this.rawPotentialResults
              );
            this.potentialResults = potentialResults;
            this.rawPotentialResults = rawPotentialResults;
            this.response = true;
          });
      }
      if (searchType === 'ctr') {
        this.requestId = '';

        const { response } = await searchCTR(
          this.platformService,
          this.searchContext
        );
        this.setresults(response, searchType);
        this.response = true;
      }
      if (searchType === 'rtc') {
        if (this.isManual) {
          this.searchContext.request = this.manualRequest.description;

          const { results, matchingType } = await searchManual(
            [],
            this.searchContext,
            this.platformService
          );
          this.matchingType = matchingType;
          this.requestId = results[0].id;
          this.requestIdFiles = results[1].id;
          this.iterateId = results[0].iterationId;

          this.setresults(results[0], 'rtc');
          this.response = true;
          return;
        }
        const { matchingType } = await searchRTC(
          this.platformService,
          this.searchContext,
          this.selectedRequest,
          this.contextService,
          this.matchingType
        );
        this.matchingType = matchingType;
        this.matchingType.forEach((value) => {
          this.contentReady(value.iterationId);
        });
      }
    } catch {
      this.loading = false;
    }
  }

  // Sets the results of the search and updates the relevant state variables
  async setresults(
    response:
      | MatchingConsultantRequest
      | MatchingRequestConsultant
      | MatchSalesRequestConsultant,
    searchType: string,
    id?: string
  ) {
    let allResults: matchConsultants[] = [];
    const allRequestResults: requestV3[] = [];
    let potentialRequestResults: requestV3[] = [];
    if (Object.keys(response).length > 0) {
      this.requestId = response.matchId ?? response.id;
      if (searchType === 'ctr') {
        response = response as MatchingConsultantRequest;
        const potentialRequests =
          response.potentialRequests as requestV3[];
        potentialRequestResults = potentialRequests ?? [];
        this.contextService.resultsLength.next(potentialRequestResults.length);
      }
      if (searchType === 'srtc') {
        response = response as MatchSalesRequestConsultant;
        this.requestId = response.matchId;

        const potentialCandidates =
          response.potentialFreelancers as matchConsultants[];
        allResults = potentialCandidates;
      }
      if (searchType === 'rtc') {
        response = response as MatchingRequestConsultant;
        const potentialCandidates =
          response.potentialCandidates as matchConsultants[];
        allResults = potentialCandidates.map(
          (elm: matchConsultants, index: number) => {
            return elm;
          }
        );
      }
      if (searchType === 'ctc') {
        response = response as MatchingRequestConsultant;
        const potentialCandidates =
          response.potentialCandidates as matchConsultants[];
        allResults = potentialCandidates.filter((x) => x);
        this.contextService.resultsLength.next(allResults.length);
      }
    } else {
      this.loading = false;
      return;
    }

    this.pagination.id = response.id;

    let potentialResults: matchConsultants[];
    if (allResults.length > 0) {
      potentialResults = allResults.splice(0, this.loadLength);
    } else {
      this.potentialRequestResults = potentialRequestResults ?? [];
      this.potentialListResults = allRequestResults.splice(0, this.loadLength);

      this.loading = false;
      if (this.potentialRequestResults.length > 0) {
        return;
      }
      this.allResults = this.potentialListResults;
      this.noResults = this.potentialListResults.length === 0 ? true : false;
      return;
    }
    this.potentialResults = [...potentialResults, ...allResults];

    if (searchType === 'srtc' || searchType === 'rtc') {
      this.potentialResultsCv = this.potentialResults.filter(
        (x) => x.matchingType === 'CV'
      );
      this.potentialResultsFile = this.potentialResults.filter(
        (x) => x.matchingType === 'Files'
      );

      this.potentialResults = this.potentialResultsCv;
    }

    this.loading = false;
    this.eachLoading = -1;
    this.allResults = this.potentialResults;
    this.noResults = this.potentialResults.length === 0 ? true : false;
    this.matchId = id ?? response.id;
  }

  // Initializes the component and subscribes to global filter changes
  ngOnInit(): void {
    this.routes();
    if (localStorage.getItem('countryCode')) {
      this.searchContext.countryCode =
        localStorage.getItem('countryCode') ?? '';
    }
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        this.queryParams = { ...params };
      });
    }
    this.subs.add(
      combineLatest([this.contextService.globalFilterM, this.route.queryParams])
        .pipe(distinctUntilChanged())
        .subscribe(async ([selectedFilters, queryParams]) => {
          this.queryParams = { ...queryParams };
          this.selectedFilters = selectedFilters;
          if (this.requestId && this.iterateId && this.response) {
            this.filters = [];
            for (const key in this.queryParams) {
              if (key === 'cvtype') {
                continue;
              }
              if (key === 'keywords') {
                this.filters.push(key + '=' + this.queryParams[key]);
                continue;
              }
              if (key === 'range') {
                this.filters.push('availability=' + this.queryParams[key]);
                continue;
              }

              // if the key is mapLocation it can be an array, so we need to iterate over it and add each element to the filters array
              if (
                key === 'mapLocation' &&
                Array.isArray(this.queryParams[key]) &&
                this.queryParams[key].length > 0
              ) {
                this.queryParams[key].forEach((element: string) => {
                  this.filters.push('mapLocation=' + element);
                });
                continue;
              }

              this.filters.push(key + '=' + this.queryParams[key]);
            }
            if (this.searchType[this.selectedSearchType] === 'ctc') {
              const { potentialResults, rawPotentialResults, filterOptions } =
                await filteringList(
                  this.selectedFilters,
                  this.contextService,
                  this.potentialResults,
                  this.rawPotentialResults
                );
              this.potentialResults = potentialResults;
              this.rawPotentialResults = rawPotentialResults;
              this.selectedFilters = filterOptions;
            }

            if (this.searchType[this.selectedSearchType] === 'rtc') {
              this.getLoading = true;
              this.loading = true;
              this.response = false;
              this.rawPotentialResults = undefined;
              this.potentialResults = [];
              const {
                potentialResults,
                potentialResultsCv,
                potentialResultsFile,
              } = await searchRequest(
                this.filters,
                this.platformService,
                this.requestIdFiles ?? this.requestId,
                this.matchingType,
                this.selectedCvType,
                this.contextService
              );
              this.potentialResults = potentialResults;
              this.potentialResultsCv = potentialResultsCv;
              this.potentialResultsFile = potentialResultsFile;

              this.response = true;
              this.loading = false;
              for (const key in this.queryParams) {
                if (key === 'cvtype') {
                  if (this.queryParams['cvtype']) {
                    let type = this.queryParams['cvtype'];
                    if (typeof type === 'string') {
                      type = JSON.parse(this.queryParams['cvtype']);
                    }
                    this.cvFile(type);
                    return;
                  }
                }
              }
              this.cvFile(0);
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
