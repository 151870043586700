import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  signalStore,
  withState,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import { IAppSettings } from '../../interfaces/app-settings';
import { GlobalService } from '../../services/global.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  pipe,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  EMPTY,
} from 'rxjs';

export type GlobalState = {
  appVersion: string;
  appSettings: IAppSettings;
  loading: {
    appSettings: boolean;
  };
  lastUpdateTimestamp: {
    appSettings: number;
  };
};

export const initialGlobalState: GlobalState = {
  appVersion: null,
  appSettings: null,
  loading: {
    appSettings: false,
  },
  lastUpdateTimestamp: {
    appSettings: null,
  },
};

const CACHE_LIMIT = 60 * 60 * 1000; // 60 minutes

export const GlobalStore = signalStore(
  { providedIn: 'root' },
  withDevtools('global'),
  withState(initialGlobalState),
  withComputed(({ appVersion, appSettings, lastUpdateTimestamp, loading }) => ({
    getAppVersion: computed(() => appVersion()),
    getAzureMapsKey: computed(() => appSettings()?.mapsSubscriptionKey),
    isAppSettingsReady: computed(
      () => appSettings() !== null && loading().appSettings === false
    ),
    hasCacheTimeLimitPassedForAppSettingsData: computed(() => {
      const now = Date.now();
      if (lastUpdateTimestamp().appSettings === null) {
        return true;
      } else {
        return now - lastUpdateTimestamp().appSettings > CACHE_LIMIT;
      }
    }),
  })),
  withMethods(
    (
      store,
      globalService = inject(GlobalService),
      snackBarService = inject(SnackBarService)
    ) => ({
      queryAppVersion() {
        updateState(store, '[GlobalStore][queryAppVersion] update state', {
          appVersion: globalService.getAppVersion(),
        });
      },
      queryAppSettings: rxMethod<{
        forceReload: boolean;
      }>(
        pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap((params) => {
            if (
              params.forceReload ||
              store.appSettings() === null ||
              store.hasCacheTimeLimitPassedForAppSettingsData()
            ) {
              updateState(
                store,
                '[GlobalStore][queryAppSettings] update loading',

                {
                  loading: {
                    ...store.loading(),
                    appSettings: true,
                  },
                }
              );
            }
          }),
          switchMap((params) => {
            if (
              params.forceReload ||
              store.appSettings() === null ||
              store.hasCacheTimeLimitPassedForAppSettingsData()
            ) {
              return globalService.getAppSettings().pipe(
                tapResponse({
                  next: (response) =>
                    updateState(
                      store,
                      '[GlobalStore][queryAppSettings] update state with response',
                      {
                        appSettings: response,
                        loading: {
                          ...store.loading(),
                          appSettings: false,
                        },
                        lastUpdateTimestamp: {
                          ...store.lastUpdateTimestamp(),
                          appSettings: Date.now(),
                        },
                      }
                    ),
                  error: () => {
                    updateState(
                      store,
                      '[GlobalStore][queryAppSettings] update state after error',
                      {
                        appSettings: null,
                        loading: {
                          ...store.loading(),
                          appSettings: false,
                        },
                        lastUpdateTimestamp: {
                          ...store.lastUpdateTimestamp(),
                          appSettings: null,
                        },
                      }
                    );
                    snackBarService.open({
                      message: 'Error while loading app settings',
                      type: SnackBarTypes.ErrorAlt,
                    });
                  },
                })
              );
            } else {
              // do nothing, data from cache is still valid
              return EMPTY;
            }
          })
        )
      ),
    })
  )
);
