import {shortlistedProfiles} from '../../../interface/shared.interface';

export interface ProfileSelectionChange {
  selected: boolean;
  consultant: shortlistedProfiles;
}

export enum WorkType {
  FULL_REMOTE = 'FullRemote',
  HYBRID = 'Hybrid',
  ON_SITE = 'Onsite'
}
