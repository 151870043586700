@if(tierContentData()) {
  <div class="tier">
    <div [class]="tierStatus()?.toLocaleLowerCase()">
      <div class="badge">
        <div class="bundle-name-icon" [ngClass]="{ dangerAny: isAnyRedDot() }">
          <i-tabler [name]="icon()"></i-tabler>
          <p class="tiny bold">{{ tierStatus() }}</p>
          <div class="red-dot"></div>
        </div>
        @if(popoverRef){
          <i-tabler class="close-popover" name="X" (click)="close()"></i-tabler>
        }
      </div>
      <div class="info tiny">
        <div>
          <p>7N contracts</p>
          <p class="bold">{{ tierContentData().contracts }}</p>
        </div>
        <div>
          <p>Worked for 7N</p>
          <p class="bold">{{ getCompletedDate('', getRule(20).input) ?? '-' }}</p>
        </div>
        <div [ngClass]="{ danger: !getRule(40).evaluationResult }">
          <p>Profile updated</p>
          <div>
            @if(getCompletedDate('', getRule(40).input)) {
              <p class="bold">
                {{ getCompletedDate('', getRule(40).input) }} ago
              </p>
            }
            @else {
              <p>-</p>
            }
            <div class="red-dot"></div>
          </div>
        </div>
        <div [ngClass]="{ danger: !getRule(30).evaluationResult }">
          <p>Profile strength</p>
          <div>
            <p class="bold">{{ getRule(30).input ?? '0' }}%</p>
            <div class="red-dot"></div>
          </div>
        </div>
        <div class="recruited"
            [ngClass]="{ danger: !getRule(80).evaluationResult }">
          <p>Recruited</p>
          <p class="bold">
            @if(!getRule(80).evaluationResult) {
              <span class="step">
                <span [ngClass]="{ active: biggerThanStep(0) }"></span>
                <span [ngClass]="{ active: biggerThanStep(1) }"></span>
                <span [ngClass]="{ active: biggerThanStep(2) }"></span>
                <span [ngClass]="{ active: biggerThanStep(3) }"></span>
                <span [ngClass]="{ active: biggerThanStep(4) }"></span>
              </span>
              {{ tierContentData().recruitmentSequence }}
              <div class="red-dot"></div>
            }
            @else {
              @if(getCompletedDate(tierContentData().recruitedUpdated)) {
                <p class="bold">
                  {{ getCompletedDate(tierContentData().recruitedUpdated) }} ago
                </p>
              }
              @else {
                <p>-</p>
              }
            }
          </p>
        </div>
      </div>
    </div>
  </div>
}
