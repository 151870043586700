import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { declarationOfInterest } from '../../../../../../interface/shared.interface';
import { dropdown } from '../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-messaging-content',
  templateUrl: './messaging-content.component.html',
  styleUrls: ['./messaging-content.component.scss'],
})
export class MessagingContentComponent {
  steps: string[] = ['Send to', 'Request', 'Message'];
  claiming: declarationOfInterest;
  smsNoti = false;
  notification = [
    {
      description:
        'Any changes made to the phone numbers will currently not be synced to CRM',
      type: 'stayAlive',
      show1: true,
      icon: 'info-circle-filled',
    },
    {
      description:
        'The link to the request description will expire after 48 hours',
      type: 'stayAlive',
      show1: true,
      icon: 'info-circle-filled',
    },
  ];
  selected: dropdown = {
    value: 'ENG',
    displayName: 'English',
  };
  language: dropdown[] = [
    {
      value: 'ENG',
      displayName: 'English',
    },
    {
      value: 'DAN',
      displayName: 'Danish',
    },
    {
      value: 'POL',
      displayName: 'Polish',
    },
  ];
  loading = true;
  changeValue = new Date();
  messageTemplate = '';
  clamingDescription = '';
  @Output() validateButton = new EventEmitter<string>();
  @Input() selectedStep = 0;

  constructor(
    public contextService: ContextService,
    private platformService: PlatformService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedStep']) {
      if (this.claiming) {
        this.messageTemplate = this.claiming.messageTemplate ?? '';
        this.clamingDescription = this.claiming.description ?? '';
      }
    }
  }
  ngOnInit() {
    this.contextService.reRender.subscribe((val) => {
      if (val === 'messaging') {
        this.claiming = this.contextService.claiming;
        this.loading = false;
        this.messageTemplate = this.claiming.messageTemplate ?? '';
        this.clamingDescription = this.claiming.description ?? '';
        this.changeValue = new Date();
      }
    });
  }
  selectOption(option: any) {
    this.selected = option;
    const idList: string[] = [];
    this.claiming.freelancers.forEach((x) => {
      idList.push(x.freelancerId);
    });
    this.platformService
      .declarationOfInterest(
        this.contextService.selectedRequest!.id,
        'post',
        undefined,
        { freelancers: idList, languageCode: option.value }
      )
      .then((response: declarationOfInterest) => {
        this.contextService.claiming.messageTemplate = response.messageTemplate;

        this.contextService.reRender.next('messaging');
      });
  }
  title(value: string) {
    this.claiming.title = value;
  }
  description(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    if (this.claiming) {
      this.contextService.claiming.description = target.innerText;
      this.claiming.description = target.innerText;
    }
    this.validateButton.emit('description');
  }
  message(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    this.claiming.messageTemplate = target.innerHTML;
  }

  phoneNumber(value: string, id: string) {
    const find = this.claiming.freelancers.find((x) => x.freelancerId === id);
    if (find) {
      find.mobilephone = value;
    }
  }
  reset() {
    this.changeValue = new Date();
  }
  beautifyNumber(number: string) {
    return number.replace(/\s/g, '');
  }
}
