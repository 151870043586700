import {
  Component,
  OnInit,
  computed,
  inject,
  input,
  signal,
  ChangeDetectionStrategy,
  output,
  model,
  OnDestroy,
} from '@angular/core';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import {
  ContextService,
  RerenderTypes,
} from '../../../../../services/platform/context.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { modalType } from '../../../../../interface/shared.interface';
import { FeatureFlagsService } from '../../../../../services/feature-flags/feature-flags.service';
import { Subscription, take } from 'rxjs';
import { ISmartTip } from '../../../../shared/interfaces/matching/smart-tip';
import { MatchingService } from '../../../../shared/services/matching/matching.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { IConsultant } from '../../../../consultants/interfaces/consultant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-level1',
  templateUrl: './level1.component.html',
  styleUrls: ['./level1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Level1Component implements OnInit, OnDestroy {
  private helperFunctions = inject(HelperFunctionsService);
  private contextService = inject(ContextService);
  private matomoTracker = inject(MatomoTracker);
  public featureFlags = inject(FeatureFlagsService);
  private matchingService = inject(MatchingService);
  private snackBarService = inject(SnackBarService);
  private router = inject(Router);

  consultant = model.required<IConsultant>();
  isSmartTip = input(true);
  selected = input<boolean>(false);
  isManual = input<boolean>(false);
  selectedSearchType = input.required<number>();
  selectedCvType = input.required<number>();
  iterateId = input<number>();
  selectedNav = input<string>();
  entityId = input<any>(); // TODO: type

  closeLevel = output<boolean>();

  isLevel2Expanded = signal<boolean>(false);
  openDropdown = signal<string>('');
  smartTip = signal<ISmartTip>(null);
  smartTipLoading = signal<boolean>(false);

  subs = new Subscription();

  isSmartTipBtnDisabled = computed(() => {
    return this.smartTipLoading() || !!this.smartTip();
  });

  fullName = computed(
    () =>
      this.consultant()?.firstName +
      ' ' +
      this.consultant()?.middleName +
      ' ' +
      this.consultant()?.lastName
  );

  freelanceAddedToRequest = computed<boolean>(() => {
    return this.consultant()?.shortlistedSalesRequests.some(
      (x) => x.id === this.contextService.selectedRequest?.id
    );
  });

  isTruncateName = computed(() => {
    const lastRoleLength = this.consultant().lastRole?.length || 0;
    const fullNameLength = this.fullName().length;
    return !this.consultant().lastRole || lastRoleLength < fullNameLength;
  });

  isTruncateLastRole = computed(() => {
    const lastRoleLength = this.consultant().lastRole?.length || 0;
    const fullNameLength = this.fullName().length;
    return lastRoleLength > fullNameLength;
  });

  consultantExpectedRate = computed(() => {
    const expectedRate = this.consultant().expectedRate;
    if (expectedRate.currency && expectedRate.ratePerHour) {
      return expectedRate.ratePerHour + ' ' + expectedRate.currency;
    } else {
      return '-';
    }
  });

  consultantAvailabilityDate = computed(() => {
    const availability = this.consultant().availability;
    if (!availability) {
      return '-';
    } else {
      const timestamp = Math.round(new Date(availability).getTime() / 1000);
      return this.helperFunctions.getDate(timestamp, false);
    }
  });

  consultantCountry = computed(() => {
    const countryCode = this.consultant()?.location?.countryCode;
    if (countryCode) {
      return this.helperFunctions.getCountry(countryCode.toLocaleUpperCase());
    } else {
      return '';
    }
  });

  cvTypeIcon = computed(() => {
    const cvFile = this.consultant().cvFile;
    if (cvFile) {
      return {
        type: 'icon',
        icon: 'file-cv',
      };
    } else if (this.selectedCvType() === 1) {
      return {
        type: 'icon',
        icon: 'info-circle',
      };
    } else {
      return {
        type: 'icon',
        icon: 'discount-check',
      };
    }
  });

  cvTypeColor = computed(() => {
    const cvFile = this.consultant().cvFile;
    const cvType = this.selectedCvType();
    if (cvType === 0 || cvFile) {
      return 'brand--Light textLink';
    } else {
      return 'neutral--Grey-300';
    }
  });

  cvTypeLabel = computed(() => {
    const cvFile = this.consultant().cvFile;
    const cvType = this.selectedCvType();

    if (cvFile) {
      const split = cvFile.filename.split('.');
      if (split[split.length - 1] !== 'pdf') {
        return 'Word CV';
      }
      return 'PDF CV';
    } else if (cvType === 1) {
      return 'No CV';
    } else {
      return 'My7N CVs';
    }
  });

  isSmartTipVisible = computed(() => {
    return (
      this.featureFlags.isFeatureEnabled('explainAbility') &&
      (!!this.smartTip() || this.smartTipLoading()) &&
      this.selectedCvType() === 0 &&
      this.isSmartTip()
    );
  });

  isSmartTipBtnVisible = computed(() => {
    return (
      this.featureFlags.isFeatureEnabled('explainAbility') &&
      !this.isManual() &&
      this.selectedSearchType() !== 2 &&
      this.selectedCvType() === 0 &&
      this.isSmartTip()
    );
  });

  isMatchingKeywordsSectionVisible = computed(() => {
    return this.isSmartTip() && this.selectedSearchType() !== 2;
  });

  ngOnInit() {
    if (this.selectedNav() === 'consultants') {
      this.contextService.selectedRequest = undefined;
    }
    this.subs.add(
      this.contextService.reRender.subscribe((val) => {
        if (val.name === RerenderTypes.LongShortList) {
          if (
            this.contextService.selectedConsultant?.id ===
              this.consultant().id &&
            this.contextService.selectedRequest
          ) {
            this.consultant.update((val) => {
              val.shortlistedSalesRequests.push({
                id: this.contextService.selectedRequest.id,
              });
              return { ...val };
            });
          }
        }
      })
    );
  }

  loadSmartTip(event: Event) {
    event.stopPropagation();

    if (
      !this.smartTip() &&
      this.featureFlags.isFeatureEnabled('explainAbility') &&
      !this.smartTipLoading()
    ) {
      this.smartTipLoading.set(true);
      this.isLevel2Expanded.set(true);

      let iterationQuery = '';
      if (this.iterateId() !== 1) {
        iterationQuery = '&iterationId=' + this.iterateId();
      }

      let consultantId = '';

      if (this.selectedSearchType() === 2) {
        consultantId = 'otherConsultantId=' + this.consultant().id;
        iterationQuery = '';
      } else {
        consultantId = 'consultantId=' + this.consultant().id;
      }

      this.matchingService
        .getSmartTip(this.entityId(), consultantId + iterationQuery)
        .pipe(take(1))
        .subscribe({
          next: (val) => {
            this.smartTip.set(val);
          },
          error: (error: any) => {
            this.snackBarService.open({
              message: 'An error occured while loading the smart tip',
              type: SnackBarTypes.ErrorAlt,
            });
            console.error(
              '[Level1Component] An error occured while loading the smart tip: ',
              error
            );
            this.smartTipLoading.set(false);
          }
        });
    }
  }

  expand() {
    this.isLevel2Expanded.set(!this.isLevel2Expanded());
  }

  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown.set(id);
  }

  openProfile(event: Event, id: string) {
    event.stopPropagation();
    this.router.navigateByUrl(
      '/' + (this.selectedNav() ?? 'matching') + '/consultant/' + id
    );
  }

  openMatchModal(event: Event, modal: modalType = 'longlist') {
    event.stopPropagation();
    if (this.selectedNav() === 'consultants') {
      this.contextService.selectedRequest = undefined;
    }

    if (modal === 'longlist' || modal === 'anotherLonglist') {
      this.contextService.selectedLonglistConsultant = { ...this.consultant() };
    } else {
      this.contextService.selectedConsultant = { ...this.consultant() };
    }

    this.contextService.matchModalMode.next(modal);
  }

  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();

    if (link) {
      this.matomoTracker.trackEvent('Matching', 'External Link - ' + text);
      window.open(link, '_blank');
    }
  }

  openExplainerInfo() {
    this.contextService.matchModalMode.next('explainerInfo');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
