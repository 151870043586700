<app-notification></app-notification>
<div class="consultants">
  <section class="top">
    <app-page-title class="title" [label]="selectedNav"></app-page-title>
    <app-input
      [value]="queryParams?.search || ''"
      (inputs)="search($event)"
      [leadingIcon]="'search'"
      placeholder="Search for consultants"
      [inputType]="'search'"
    ></app-input>
  </section>
  <section class="surface--2 results">
    <div class="max-width">
      <app-filters
        [filterType]="'consultant'"
        [selectedFilters]="selectedFilters"
        [disable]="loading"
        [selectedCvType]="selectedCvType"
        [matchId]="'1'"
        [selectedNav]="selectedNav"
      ></app-filters>

      <div class="cards-list">
        <div class="the-list">
          <div class="cards">
            <div class="title-tab">
              <h3>All consultants</h3>
              <app-tab-buttons
                *ngIf="featureFlags.isFeatureEnabled('filebasedmatching')"
                classList="body-small small"
                class="cvFile"
                [disable]="loading"
                [selected]="selectedCvType"
                [tabButtons]="matchingType"
                (callbackFunction)="cvFile($event)"
              ></app-tab-buttons>
            </div>

            <div *ngIf="selectedFilters.length > 0" class="selectedFilters">
              <ng-container
                *ngFor="let filter of selectedFilters; let i = index"
              >
                <ng-container
                  *ngIf="filter.type === 'relationstatus'; else displayName"
                >
                  <app-tier-statuses
                    (callbackFunction)="removeFilter(filter)"
                    [tier]="getTier(filter)!"
                    [trailingIcon]="{ type: 'icon', icon: 'X' }"
                  ></app-tier-statuses>
                </ng-container>
                <ng-template #displayName>
                  <app-badge
                    [label]="filter.displayName"
                    [trailingIcon]="{ type: 'icon', icon: 'X' }"
                    color="neutral--Grey-300"
                    (callbackFunction)="removeFilter(filter)"
                  ></app-badge>
                </ng-template>
              </ng-container>
              <p
                *ngIf="selectedFilters.length > 0"
                class="textLink clear-all body-small"
                (click)="clearAll(true)"
              >
                Clear all
              </p>
            </div>
            <ng-container *ngIf="!loading">
              <app-level1
                [isSmartTip]="false"
                [selectedNav]="selectedNav"
                [selectedCvType]="selectedCvType"
                *ngFor="let result of potentialResults | slice : 0 : loadLength"
                [consultant]="result"
                [selectedSearchType]="2"
              ></app-level1>
              <div
                *ngIf="loadLength < potentialResults.length"
                class="load-more"
              >
                <app-button
                  label="Load more"
                  (click)="loadMore()"
                  *ngIf="!moreLoading"
                ></app-button>
                <app-loader
                  [class]="['small']"
                  *ngIf="moreLoading"
                  [loading]="moreLoading"
                ></app-loader>
              </div>
            </ng-container>
          </div>
        </div>

        <app-loader *ngIf="loading" [loading]="loading"></app-loader>
        <div
          class="empty-results"
          *ngIf="potentialResults.length === 0 && response"
        >
          <div class="textSecondary">
            <img src="/assets/images/no-info.png" />
            <p class="s1">No results</p>
            <p class="body-large">Try changing your filters"</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
