import { inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { appAccessScopes } from '../../app/auth-config';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedInterceptorService implements HttpInterceptor {
  private msalService = inject(MsalService);
  readonly API_URL = environment.apiUrl;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && request.url.startsWith(this.API_URL)) {
          console.error('Unauthorized request:', {
            url: request.url,
            timestamp: new Date().toISOString(),
            error: error.message,
          });

          // Attempt to acquire a new token
          const accessTokenRequest = {
            scopes: [appAccessScopes.scope],
            account: this.msalService.instance.getAllAccounts()[0],
          };
          this.msalService.acquireTokenRedirect(accessTokenRequest);
        }
        return throwError(() => error);
      })
    );
  }
}
