import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { HelperFunctionsService } from '../../../services/helperFunctions/helper-functions.service';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { SelectedPro } from '../../../interface/shared.interface';

@Component({
  selector: 'app-month-year-select',
  templateUrl: './month-year-select.component.html',
  styleUrls: ['./month-year-select.component.scss'],
})
export class MonthYearSelectComponent implements OnChanges {
  @Input() value: string;
  @Input() styles: string;
  @Input() disabled: boolean;
  @Input() trailingIcon: string;
  @Input() label: string;
  @Input() type = '';
  @Input() index = -1;
  @Input() placeholder = '-1';
  @Input() selectedMonthRange: string;
  @Output() callbackFunction = new EventEmitter<{
    value: string;
    type: keyof SelectedPro | undefined;
  }>();
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  months: string[] = this.helperFunctions.listOfOptions('stringMonth');
  year = new Date().getFullYear();
  selectedDate = new Date();
  selectedPicker = {
    type: '',
    index: -1,
    rangeId: '1',
  };
  monthYear = '';
  openCustomMonth: boolean;
  monthRange = [
    {
      id: '1',
      displayName: 'Available now',
    },
    {
      id: '2',
      displayName: '+ 30 days',
    },
    {
      id: '3',
      displayName: '+ 45 days',
    },
    {
      id: '4',
      displayName: '+ 60 days',
    },
    {
      id: '5',
      date: '',
      displayName: '+ Custom month',
    },
  ];

  constructor(private helperFunctions: HelperFunctionsService) {}

  ngOnChanges(): void {
    this.setSelectedDate();
  }
  setMonthRange(event: { id: string }) {
    if (event.id === '5') {
      this.openCustomMonth = !this.openCustomMonth;
    } else {
      this.openCustomMonth = false;
    }
    if (this.selectedMonthRange === event.id && event.id !== '5') {
      this.selectedMonthRange = '';
      event.id = '0';
    } else {
      this.selectedMonthRange = event.id;
    }

    this.callbackFunction.emit({ value: event.id, type: 'monthRange' });
  }
  changeYear(value: number) {
    this.year = this.year + value;
    const monthIndex = this.selectedDate.getMonth() + 1;
    this.value =
      this.year +
      '-' +
      (monthIndex < 10 ? '0' + monthIndex : monthIndex) +
      '-' +
      '01';
    this.setSelectedDate(true);
  }
  lastday(y: number, m: number) {
    return new Date(y, m, 0).getDate();
  }
  selectMonth(monthIndex = this.selectedDate.getMonth()) {
    monthIndex = monthIndex + 1;
    this.value =
      this.year +
      '-' +
      (monthIndex < 10 ? '0' + monthIndex : monthIndex) +
      '-' +
      (this.type === 'end' ? this.lastday(this.year, monthIndex) : '01');
    this.setSelectedDate(true);
  }

  setSelectedDate(change?: boolean, event?: Event) {
    if (event) {
      const element = event.currentTarget as HTMLInputElement;
      if (element.value !== this.monthYear) {
        this.value = '';
      }
    }
    if (this.value) {
      this.selectedDate = new Date(this.value ?? '');
      this.monthYear =
        this.months[this.selectedDate.getMonth()] +
        ', ' +
        this.selectedDate.getFullYear();
    }
    if (!this.value) {
      this.monthYear = '';
      this.selectedMonthRange = '0';
      this.openCustomMonth = false;
    }
    if (change) {
      this.callbackFunction.emit({ value: this.value, type: 'monthRange' });
    }
  }

  openPicker() {
    this.selectedPicker = {
      type: this.type,
      index: this.index,
      rangeId: '1',
    };
  }

  closePicker() {
    this.selectedPicker = {
      type: '',
      index: -1,
      rangeId: '1',
    };
  }
}
