import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
export interface ruleLength {
  [key: string]: number;
}

export interface matchingType {
  matchingType: string;
  displayName: string;
  icon: string;
  iterationId: 1;
  amount: number;
}
export interface select {
  value: string;
  label: string;
  id: string;
  subValue?: boolean;
  subId?: string;
}

export interface shortlistedProfiles {
  salesStatus: string;
  salesNotes: string;
  addedBy: string;
  modifiedOn: number;
  createdOn: number;
  declarationInformation: declarationInformation;
  type: string;
  cvFile: {
    filename: string;
    annotationId: string;
  };
  experience: experience;
  salesAgent: {
    firstName: string;
    middleName: string;
    lastName: string;
    imageUrl: string;
    federatedIssuerAssignedId: string;
    systemUserId: string;
  };
  email: string;
  phoneNumber: string;
  workLocations: {
    name: string;
    states: string[];
  }[];
  previousRoles: string[];
  matchingType: string;
  tier: {
    tierStatus: string;
    contracts: string;
    recruitmentSequence: string;
    recruitedUpdated: string;
    evaluatedRules: {
      id: number;
      title: string;
      expected: string;
      input: string;
      evaluationResult: boolean;
    }[];
    status: {
      description: string;
      contractStatus: string;
      dateCalculated: number;
    };
  };
  remarks: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  location: {
    locationId: string;
    country: string;
    countryCode: string;
    stateOrProvince: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postalCode: string;
    locationMapsLink?: string;
  };
  lastRole: string;
  crmStatus: string;
  availability: string;
  expectedRate: {
    ratePerHour: number;
    currency: string;
  };
  applicableForC2ROrC2C: boolean;
  imageUrl: string;
  crmLink: string;
  my7NLink: string;
  linkedIn: string;
  shortlistedSalesRequests: {
    id: string;
  }[];
  id: string;
}

export interface ConsultantInfo extends by {
  id?: string;
  added?: boolean;
  crmStatus: string;
  countryCode: string;
  country: string;
  color: string;
  externalLink: string;
  linkedIn?: string;
  customerName: string;
  availability: string;
  score: number;
  canWorkRemote: boolean;
  workLocations: FilterWorkLocation[];
  mainConsultancyCategory: string;
  email: string;
  ownedBy: string;
  ratePerHour: number;
  currency: string;
  my7N: string;
  itRole: string;
  crmId: string;
  fullName: string;
  jobTitle: string;
  city: string;
  declarationInformation: declarationInformation | undefined;
  phoneNumber: string;
  addedBy: string;
  salesStatus: string;
  salesNotes: string | undefined;
  description?: string;
  imageUrl: string;
}

export interface PotentialRequests {
  title: string;
  customer: string;
  creationDate: string;
  startDate: string;
  endDate: string;
  createdBy: string;
  score: number;
  externalLink: string;
  description: string;
  id: string;
  matchId: string;
}
export interface MatchingConsultantRequest {
  object: string;
  id: string;
  matchId?: string;
  requisitionedBy: string;
  potentialRequests: requestV3[]; // You can replace 'any' with the actual type if you have one
  candidate: {
    crmId: string;
    fullName: string;
    jobTitle: string;
    city: string;
    countryCode: string;
  };
  qualification: string;
  qualificationImportance: string;
}
export interface MatchingRequestConsultant {
  object: string;
  id: string;
  matchId?: string;
  iterationId: number;
  requisitionedBy: string;
  potentialCandidates: matchConsultants[]; // You can replace 'any' with the actual type if you have one
  candidate: {
    crmId: string;
    fullName: string;
    jobTitle: string;
    city: string;
    countryCode: string;
  };
  qualification: string;
  qualificationImportance: string;
  pagination: pagination;
  paginationCv?: pagination;
  paginationFile?: pagination;
}

export interface declarationInformation {
  status: string;
  consultantId: string;
  freelancerDeclarationOfInterestId: string;
  statusChangedAt: number;
  createdAt: string;
}
export interface declarationOfInterest {
  createdAt?: number;
  id?: string;
  messageTemplate: string;
  title: string;
  description: string;
  freelancers: [
    {
      freelancerId: string;
      mobilephone: string;
      email?: string;
      fullName?: string;
    }
  ];
  notifyThroughSms: boolean;
}
export interface declarationOfInterestReply {
  id: string;
  createdAt: number;
  status: string;
  response: string;
  responseText: string;
  responseTime: number;
  declaredAvailability: string;
  consultantId: string;
  email: string;
  mobilephone: string;
  fullName: string;
  emailMessage: string;
  title: string;
  description: string;
  notifyThroughSms: boolean;
  token: string;
}

export interface shortLongList {
  salesStatus: string;
  addedBy: string;
  modifiedOn: number;
  createdOn: number;
  consultantId: string;
  crmId: string;
}

export interface ListViewModelResponse {
  object: string;
  data: requestMatchesLevels1[];
}
export interface pagination {
  total: number;
  count: number;
  hasNextPage: boolean;
}

export interface cvType {
  id?: string;
  matchingType: string;
  displayName: string;
  icon: string;
  iterationId: number;
  amount: number;
  contentReady?: boolean;
}
export interface MatchSalesRequestConsultant {
  paginationCv?: pagination;
  paginationFile?: pagination;
  companyLogo: string;
  createdByName: string;
  createdOn: number;
  customer: string;
  externalLink: string;
  id: string;
  matchId: string;
  iterationId: string;
  itRole: string;
  latestIteration?: {
    qualification: string;
    qualificationImportance: string;
    potentialFreelancers: matchConsultants[];
  };
  potentialFreelancers: matchConsultants[];
  longlist: shortLongList[];
  mainConsultancyCategory: string;
  maxCostPerHourCurrency: string;
  modifiedByName: string;
  modifiedOn: number;
  mostRecent: boolean;
  object: string;
  owner: string;
  owningBusinessUnit: string;
  priority: string;
  shortlist: shortLongList[];
  status: string;
  summary: string;
  title: string;
}

export interface matches {
  id: string;
  object: string;
  customer: string;
  customerName: string;
  title: string;
  companyLogo: string;
  description?: string;
  longlist?: shortLongList[];
  shortlist?: shortLongList[];
  type: string;
  alreadyLonglist?: boolean;
  externalLink?: string;
  displayName?: string;
  value?: string;
}

export interface requestV3Level2 {
  firstName: string;
  middleName: string;
  lastName: string;
  imageUrl: string;
  federatedIssuerAssignedId: string;
  systemUserId: string;
}
export interface requestV3 {
  object: string;
  maxCostPerHour: {
    amount: number;
    currencyIsoCode: string;
  };
  contractEndDate: string;
  hoursPerWeek?: number;
  typeOfWork: string;
  ownedBy: {
    country: string;
    office: string;
    team: string;
    firstName: string;
    middleName: string;
    lastName: string;
    imageUrl: string;
    federatedIssuerAssignedId: string;
    systemUserId: string;
  };
  recruitmentAgents: {
    firstName: string;
    middleName: string;
    lastName: string;
    imageUrl: string;
    federatedIssuerAssignedId: string;
    systemUserId: string;
  }[];
  keySkills: string[];
  summary: string;
  description: string;
  country: string;
  crmLink: string;
  priority: string;
  deadline: string;
  highestSalesStatus: { salesStatus: string };
  totalNumberOfEnlistedProfiles: number;
  createdAt: number;
  isDescription: boolean;
  title: string;
  customer: {
    id: string;
    name: string;
  };
  contractStartDate: string;
  id: string;
  etag: string;
  enlistedProfiles: by[];
}
export interface requestV3Level3 extends requestV3 {
  enlistedProfiles: shortlistedProfiles[];
}

export interface requestMatchesLevels1 {
  object: string;
  crmLink: string;
  isDescription: boolean;
  country: string;
  customer: {
    id: string;
    name: string;
    imageUrl?: string;
  };
  id: string;
  priority: string;
  highestSalesStatus: {
    salesStatus: string;
    displayName: string | null;
  };
  enlistedProfiles: ConsultantInfo[];
  title: string;
  contractStartDate: string | null;
  createdAt: number | null;
  deadline: string | null;
  externalLink?: string;
}

export interface requestLevels2 extends requestMatchesLevels1 {
  ownedBy: salesAgent;
  recruitmentAgents: salesAgent[];
  candidates: salesAgent[];
  keySkills: string[];
  summary: string;
  description: string;
}

export interface requestMatches {
  customerName: string;
  companyLogo: string;
  alreadyLonglist?: boolean;
  longlist: any[];
  shortlist: any[];
  type: string;
  createdAt?: number;
  object: string;
  id: string;
  createdByName: string;
  createdOn?: number;
  modifiedByName: string;
  modifiedOn: number;
  owner: string;
  title: string;
  description: string;
  mostRecent: boolean;
  summary: string;
  status: string;
  itRole: string;
  mainConsultancyCategory: string;
  customer: string;
  owningBusinessUnit: string;
  priority: string;
  maxCostPerHour: string;
  maxCostPerHourCurrency: string;
  startDate: string;
  externalLink: string;
}
export interface matchConsultants {
  totalAmountCv?: number;
  totalAmountFile?: number;
  cvFile: {
    filename: string;
    annotationId: string;
  };
  crmLink: string;
  crmStatus: string;
  matchingType: string;
  my7NLink: string;
  linkedIn?: string;
  expectedRate: { currency: string; ratePerHour: number };
  firstName: string;
  lastName: string;
  middleName?: string;
  lastRole: string;
  location: {
    city: string;
    countryCode: string;
    stateOrProvince?: string;
    locationMapsLink?: string;
  };
  id: string;
  crmId?: string;
  salesStatus?: string;
  addedBy?: string;
  salesNotes?: string;
  applicableForC2ROrC2C?: boolean;
  availability: string;
  imageUrl: string;
  added?: boolean;
  email: string;
  remarks: boolean;
  phoneNumber: string;
  tier: tier;
  salesAgent: salesAgent;
  workLocations: {
    name: string;
    states: string[];
  }[];
  shortlistedSalesRequests: { id: string }[];
  previousRoles: string[];
  experience: experience;
  keywords?: { [key: string]: number };
}
export interface matchType {
  displayName: string;
  icon: string;
  modal: modalType;
  add: string;
  emptyStatment?: {
    title: string;
    description: string;
  };
}

export type response = {
  GoodMatchAndAvailable: string;
  GoodMatchButNotAvailable: string;
  NotGoodMatchAndAvailable: string;
  NotGoodMatchAndNotAvailable: string;
};

export interface tabButtons {
  displayName: string;
  icon: string;
  amount?: number;
  mobile?: boolean;
}
export interface defaultDropdown {
  displayName: string;
  value: string;
}
export interface countryCode {
  displayName?: string;
  value?: string;
}
export interface jobHistory {
  role: string;
  company: string;
  industryId: string | number;
  current: boolean;
  start: string;
  end?: string;
  error?: string[];
}

export interface nav {
  allowed?: boolean;
  state: boolean;
  displayName: string;
  slug: string;
  icon?: string;
  submenu?: nav[];
  size?: string;
  count: string;
}

export interface buttonsSelect {
  id: string;
  type: keyof type;
  single?: boolean;
}
export interface ProProfile {
  id: string;
  displayName: string;
  description?: string;
  value?: string;
  family?: string;
  year?: string;
  empty?: boolean;
}
export interface Scores {
  challenges: string[];
  fitTasks: string[];
  label: string[];
  noFitTasks: string[];
  score: number;
  strengths: string[];
  trait: string;
  traitDisplayName: string;
  leftHandScoreLabel: string;
  leftHandScoreExcerpt: string;
  rightHandScoreLabel: string;
  rightHandScoreExcerpt: string;
  traitExcerpt: string;
  scoreExcerpt: string;
}
export interface gameInstance {
  gameCode: string;
  scores: Scores[];
}
export interface requestResult {
  description: string;
  extrnalLink: string;
}
export interface FilterWorkLocation {
  name: string;
  states: string[];
}

export interface FilterData {
  agents: salesAgent[];
  salesstatus?: freeStatus[];
  priority?: string[];
  label?: string[];
  displayName?: string;
  id?: string | number;
  team?: { name: string; displayName: string }[];
  location?: { displayName: string; subNames: string[] }[];
  statuses?: string[];
  tiers: tier[];
  customers?: string[];
  parentId?: number | string;
  subValue?: boolean;
  childLength?: number;
  workLocations?: FilterWorkLocation[];
  countries: Array<{ value: string; displayName: string }>;
  locationCountries: Array<{ id: string; displayName: string }>;
}

export interface SelectedFilter {
  id: string;
  type: string;
}
export interface filterOption {
  data: FilterData[] | ProProfile[] | select[];
  raw: any[];
  title: string;
  icon: IconDefinition;
  type: string;
  selected: string[];
  input: string;
  multiSelect?: boolean;
  subValue?: boolean;
  combineSelected?: string[];
  disabled?: boolean;
  default?: boolean;
  placeHolder?: string;
  group?: string;
  monthRange?: string;
}
export interface status {
  Registered: string;
  NewLead: string;
  PendingInterview: string;
  Rejected: string;
  Approved: string;
}
export interface statusCount {
  unsubmitted: string;
  newLead: string;
  pendingInterview: string;
  rejected: string;
  approved: string;
}

export interface tableList {
  title: string;
  typeOf: string[];
  disable: boolean;
}

export interface searchContext {
  request?: string;
  qualification: string;
  qualificationImportance: string;
  countryCode: string;
  crmId?: string;
  matchingType: string;
}
// export interface pagination {
//   limit: number;
//   offset: number;
//   id: string;
//   loading: boolean;
// }

export interface consultant {
  salesStatus: string;
  addedBy: string;
  modifiedOn: number;
  createdOn: number;
  crmStatus: string;
  countryCode: string;
  country: string;
  color: string;
  externalLink: string;
  availability: string;
  score: number;
  canWorkRemote: false;
  workLocations: FilterWorkLocation[];
  mainConsultancyCategory: string;
  email: string;
  phoneNumber: string;
  ownedBy: string;
  ratePerHour: number;
  currency: string;
  my7N: string;
  itRole: string;
  crmId: string;
  fullName: string;
  jobTitle: string;
  city: string;
}
export interface buttons {
  title: string;
  type: string;
  class?: classes;
  disable?: boolean | undefined;
  step?: number;
  trailingIcon?: string;
  leadingIcon?: string;
}

export type classes = string;

export interface dropdown {
  countryCode?: string;
  name?: string;
  displayName?: string;
  value?: string;
  trailingInfo?: string;
  id?: number;
  icon?: string;
  leadingIcon?: string;
  imageIcon?: string;
  link?: string;
  isAllowed?: boolean;
  disable?: boolean;
}

export type filter = {
  title?: string;
  name: string;
  option: option[];
  type: string;
  hide?: boolean;
  disable: boolean;
  cvOrFiles?: number;
  showCounter?: boolean;
};
export type option = {
  id: string;
  displayName: string;
  name?: string;
  type?: string;
  cvOrFiles?: number;
  range?: { startDate: Date; endDate: Date };
  subNames?: { id: string; displayName: string }[];
  value?: unknown;
};
export interface freeStatus {
  name: string;
  disable: boolean;
  isAllowed: boolean;
  displayName: string;
  leadingIcon: string;
  trailingInfo: string;
  order: number;
  progress: number;
}

export type modalType =
  | 'add-request'
  | 'close'
  | 'filters'
  | 'match-filters'
  | 'request-filters'
  | 'request'
  | 'manual'
  | 'ctr'
  | 'ctc'
  | 'advancedSearch'
  | 'longlist'
  | 'anotherLonglist'
  | 'addconsultant'
  | 'addAnotherconsultant'
  | 'addNotes'
  | 'close'
  | 'replyMessage'
  | 'customInput'
  | 'explainerInfo'
  | 'messaging';

export interface GenomeItem {
  object: string;
  id: string;
  category: string;
  genome: {
    tracking:
      | {
      campaign: string | undefined;
      source: string | undefined;
    }
      | undefined;
    secretCode: {
      socialCapacity: number;
      empathy: number;
      diplomaticAuthority: number;
      holisticProjectUnderstanding: number;
      highPerformanceUnderStress: number;
      insightsIntoOwnStrengthsAndWeaknesses: number;
    };
    object: string;
    id: string;
    genome: {
      object: string;
      id: string;
      levelOfExperience: string;
      location: {
        city: string;
        state: string;
        country: string;
      };
      previouslyWorkedAsConsultant: boolean;
      workingSweetspot: workingSweetspot;
      expectedIncome: {
        currency: string;
        isHourly: boolean;
        value: number;
        hourlyRate: number | undefined;
        hourlyRateCalculated: boolean;
        quotable: string | undefined;
        market: string | undefined;
      };
      workingLanguages: string[];
      workingPreferences: {
        remote: boolean;
        onSite: boolean;
        hybrid: boolean;
      };
      professionalProfiles: professionalProfiles;
      availabilityDate: string;
      status: number;
      labels: string[];
    };
    submissionTime?: number;
  };
  metadata: {
    emailAddress: string;
    phoneNumber: string;
  };
  industryProfile: {
    object: string;
    id: string;
    levelOfExperience: string;
    selectedIndustries: { id: string; levelOfExperience: string }[];
    jobHistory: {
      role: string;
      company: string;
      industryId: string;
      current: boolean;
      start: string;
      industryDisplayName: string;
      end: string;
    }[];
  };
  skillsProfile: {
    object: string;
    id: string;
    jobFamily: {
      object: string;
      id: string;
      displayName: string;
    };
    selectedSkills: {
      id: string;
      displayName: string;
    }[];
    topSelectedSkills: {
      id: string;
      displayName: string;
    }[];
    technologySkills: {
      family: string;
      id: string;
      displayName: string;
    }[];
    topTechnologySkills: {
      family: string;
      id: string;
      displayName: string;
    }[];
  };
  assessmentProfile: {
    object: string;
    id: string;
    assignedAssessment: {
      object: string;
      status: string;
    };
    assessmentResults: {
      object: string;
      id: string;
      gameInstanceResults: gameInstance[];
    };
  };
}

export interface professionalProfiles {
  linkedin: string;
  github: string;
  dribbble: string;
  website: string;
  other: string;
}
export interface workingSweetspot {
  dimension1: number;
  dimension2: number;
  dimension3: number;
  dimension4: number;
  dimension5: number;
  dimension6: number;
}
export interface list {
  previouslyWorkedAsConsultant: boolean;
  added: boolean;
  workLocations: FilterWorkLocation[];
  submissionTime: number;
  industryIds: string[];
  skillIds: string[];
  technologyIds: string[];
  availabilityDate: string;
  createdAtTime: string;
  expectedSalary: number;
  expectedSalaryCurrency: string;
  expectedSalaryType: string;
  givenName: string;
  id: string;
  jobFamily: string;
  levelOfExperience: string;
  levelOfExpertise: string;
  object: string;
  problemSolvingAbility: string;
  roleEstimate: string;
  surname: string;
  // systemScore: number
  status: string;
  countryCode: string;
  campaign: string;
  labels: string[];
  sortDate: string | number;
}

export interface ProProfileSlider {
  value: number;
  legend?: string;
}

export interface routeParams {
  id: string;
  slug: string;
  deeplink: string;
}
export interface remFilters {
  selected: string[];
  type: string;
}
export interface SelectedPro {
  jobFamily: number[];
  yearExperience: number[];
  asConsultant: number[];
  levelWork: number[];
  skills: number[];
  topSkills: number[];
  sweetSpot: number[];
  industries: number[];
  technologies: number[];
  languages: number[];
  workFrom: number[];
  rate: number[];
  available: string[];
  registered: string[];
  countryCode: string[];
  campaign: string[];
  labels: string[];
  monthRange: string[];
}

export interface salesAgent {
  firstName: string;
  middleName: string;
  lastName: string;
  imageUrl: string;
  federatedIssuerAssignedId: string;
}
export interface type {
  monthRange: string;
  jobFamily: string;
  yearExperience: string;
  asConsultant: string;
  levelWork: string;
  skills: string;
  topSkills: string;
  sweetSpot: string;
  industries?: string;
  technologies: string;
  workFrom: string;
  rate: string;
  jobHistory: string;
  workTime: string;
}

export interface profile3 extends by {
  summary: string;
  keySkills: string[];
  remarks: boolean;
  cvFile: {
    filename: string;
    url: string;
    annotationId: string;
  };
  matchingType: string;
  shortlistedSalesRequests: requestV3[];
  salesAgent: salesAgent;
  email: string;
  phoneNumber: string;
  workLocations: {
    name: string;
    states: string[];
  }[];
  previousRoles: string[];

  location: {
    countryCode: string;
    city: string;
    stateOrProvince?: string;
    locationMapsLink?: string;
  };
  lastRole: string;
  crmStatus: string;
  availability: string;
  expectedRate: {
    ratePerHour: number;
    currency: string;
  };
  tier: tier;
  experience: experience;
  applicableForC2ROrC2C: boolean;
  crmLink: string;
  my7NLink: string;
  linkedIn?: string;
  id: string;
  imageUrl: string;
}

export interface tier {
  recruitmentSequence: string;
  tierStatus: string;
  contracts?: string;
  evaluatedRules?: any;
  recruitedUpdated?: string;
  status: any;
}
export interface tierStatus {
  description: string;
  contractStatus: string;
}

export interface shortlistedSalesRequests {
  customerName: string;
  title: string;
  salesStatus: string;
  id: string;
}
export interface by {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  imageUrl?: string;
}
export interface experiences {
  customerName: string;
  contractName: string;
  experienceType: string;
  description?: string;
  jobTitle?: string;
  keySkills: string[];
  startDate: string;
  endDate: string;
  contractWith7N: boolean;
}
export interface experience {
  yearsOfExperience: number;
  experienceCount: number;
  experiences: experiences[];
}

export interface timeline {
  type: string;
  date: string;
  agent: {
    firstName: string;
    middleName: string;
    lastName: string;
    imageUrl?: string;
  };
  description: string;
}

export interface IGetConsultantsResponse {
  elements: Array<matchConsultants>;
  pagination: pagination;
}
