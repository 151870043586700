import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';
import {
  matchConsultants,
  matches,
  requestMatches,
  requestV3
} from '../../../../../interface/shared.interface';
import { buttons } from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-match-modal',
  templateUrl: './match-modal.component.html',
  styleUrls: ['./match-modal.component.scss']
})
export class MatchModalComponent implements OnInit {
  selectedRequest?: requestV3 = this.contextService.selectedRequest;

  manualRequest: matches;
  salesRequests: requestMatches[];
  rawOptions: requestMatches[];
  tap: 'search' | 'manual' = 'search';
  selectedRequestDescription = '';
  manualDescription = '';
  type = this.contextService.openMatchModal;
  selectedConsultant: matchConsultants = this.contextService.selectedConsultant;
  toggleRequest = false;
  someSelected: boolean;
  note = '';
  addedNote = '';
  selectedStep = 0;
  loading: boolean;
  addNotes: boolean;
  buttons: buttons[] = [
    {
      title: 'Cancel',
      disable: false,
      type: 'cancel',
      class: 'ghost',
    },
    {
      title: 'Add to request',
      disable: true,
      type: 'addToRequest',
      class: 'primary',
    },
  ];
  content = {
    icon: { name: '', class: '' },
    headline: 'Add consultant to a request',
    description: '',
  };
  sending: boolean;

  constructor(
    private platformService: PlatformService,
    public contextService: ContextService,
    private helperFunctions: HelperFunctionsService,
    private matomoTracker: MatomoTracker,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getSearchRequests();
    if (this.type === 'explainerInfo') {
      this.content.icon = { name: 'sparkles', class: 'textHighlightMedium' };
      this.content.headline = 'Matching results';
      this.content.description =
        'The “Smart tips”-section includes two elements: “Text explanation” and “Keywords”. The text message is generated by OpenAI, by asking it to read the selected request and the consultant CV, and then explain why the consultant is a good fit for the request. It will also mention if the consultant has previously worked for the client of the request. \n The keywords are the overlapping keywords in the request and the consultant CV, as identified by matching. These keywords are ranked based on the number of times they appear in the consultant CV. There will be a maximum of 20 keywords shown.';
      this.buttons = [];
    }
    if (this.type === 'advancedSearch') {
      this.content.headline = 'Advanced search';
      this.buttons = [
        {
          title: '',
          disable: false,
          type: 'advancedSearch',
          class: 'none',
        },
        {
          title: 'Done',
          disable: false,
          type: 'advancedSearch',
          class: 'primary',
        },
      ];
    }
    if (['filters', 'match-filters', 'request-filters'].includes(this.type)) {
      this.content.headline = 'Filters';
      this.buttons = [
        {
          title: 'Reset',
          disable: false,
          type: 'filters-reset',
          class: 'ghost',
        },
        {
          title: 'Show',
          disable: false,
          type: 'filters',
          class: 'primary',
        },
      ];
      this.contextService.resultsLength.subscribe((value: number) => {
        this.buttons[1].title = 'Show ' + value + ' results';
      });
    }
    if (['ctr', 'ctc'].includes(this.type)) {
      this.content.headline = 'Choose consultant';
      this.buttons = [
        {
          title: 'Cancel',
          disable: false,
          type: this.type,
          class: 'ghost',
        },
        {
          title: 'Confirm selection',
          disable: !this.contextService.selectedConsultant,
          type: this.type,
          class: 'primary',
        },
      ];
    }
    if (this.type === 'messaging') {
      this.content.headline = 'Send message';
      this.buttons = [
        {
          title: 'Previous',
          disable: true,
          type: 'back',
          class: 'none',
          step: 0,
        },
        {
          title: 'Next',
          disable: false,
          type: 'messaging',
          class: 'primary',
          step: 2,
          trailingIcon: 'chevron-right',
        },
      ];
    }
    if (this.type === 'replyMessage') {
      this.content.headline = 'Message response';
      this.buttons = [

      ];
    }
    if (this.type === 'addNotes') {
      this.content.headline = 'Sales notes';
      this.buttons[0] = {
        title: '',
        disable: false,
        type: '',
        class: 'none',
      };

      this.buttons[1] = {
        title: 'Add note',
        disable: false,
        type: 'addNotes',
        class: 'primary',
        trailingIcon: 'plus',
      };
    }
    if (this.type === 'request') {
      this.content.headline = 'Choose request';
      this.buttons[1] = {
        title: 'Choose request',
        disable: true,
        type: 'requests',
        class: 'primary',
      };
    }
    if (this.contextService.selectedConsultant) {
      this.note = this.contextService.selectedConsultant.salesNotes ?? '';
    }
    if (this.type === 'longlist') {
      this.buttons[1].disable =
        !this.contextService.selectedConsultant ||
        this.contextService.openMatchModal === 'addAnotherconsultant';
    }
  }

  menu(value: 'search' | 'manual') {
    this.tap = value;
  }
  validateButtons(event: string) {

    this.buttons[1].disable =
      this.contextService.claiming.description.length < 2;
  }
  action(button: buttons) {
    if (button.disable) {
      return;
    }
    if (['filters', 'filters-reset'].includes(button.type)) {
      if (button.type === 'filters-reset') {
        this.router.navigate([], {});
      }
      this.closeModal();
    }
    if (['ctr', 'ctc'].includes(button.type)) {
      if (button.title !== 'Cancel') {
        this.helperFunctions.route([
          'matching',
          button.type,
          this.selectedConsultant?.id ?? '',
        ]);
      }
      this.closeModal();
    }
    if (['advancedSearch'].includes(button.type)) {
      this.contextService.search.next('search');
      this.closeModal();
    }
    if (['cancel'].includes(button.type)) {
      this.closeModal();
    }
    if (button.type === 'addToRequest') {
      this.matomoTracker.trackEvent(
        this.type === 'addconsultant' ? 'Request' : 'Matching',
        'Added to - Longlist'
      );
      this.addToRequest();
    }
    if (button.type === 'requests') {
      this.continue();
    }
    if (button.type === 'backNotes') {
      this.addNotes = false;
    }
    if (button.type === 'addNotes') {
      this.addNotes = !this.addNotes;
      this.content.headline = this.addNotes ? 'Add sales note' : 'Sales notes';
      this.buttons[0] = {
        title: this.addNotes ? 'Previous' : '',
        disable: false,
        type: this.addNotes ? 'addNotes' : '',
        class: this.addNotes ? 'ghost' : 'none',
        leadingIcon: this.addNotes ? 'chevron-left' : '',
      };
      this.buttons[1] = {
        title: this.addNotes ? 'Save' : 'Add note',
        trailingIcon: this.addNotes ? '' : 'plus',
        disable: this.addNotes,
        type: this.addNotes ? 'saveNotes' : 'addNotes',
        class: 'primary',
      };
    }
    if (button.type === 'saveNotes') {
      this.saveNote();
    }
    if (button.type === 'sendMessage') {
      this.sendMessage();
    }
    if (['messagingBack', 'messaging'].includes(button.type)) {
      if (!button.step) {
        this.selectedStep--;
        this.buttons[1].title = 'next';
        this.buttons[1].type = 'messaging';
        this.buttons[1].trailingIcon = 'chevron-right';
      }

      if (button.type === 'messaging') {
        this.selectedStep++;
        this.buttons[1].disable = false;
        if (this.selectedStep === 2) {
          this.buttons[1].title = 'Send message';
          this.buttons[1].type = 'sendMessage';
          this.buttons[1].trailingIcon = 'send';
        }
      }
      this.buttons[0] = {
        title: 'Previous',
        disable: false,
        type: 'messagingBack',
        class: this.selectedStep > 0 ? 'ghost' : 'none',
        leadingIcon: 'chevron-left',
      };
      if (this.selectedStep === 1) {
        if (!this.contextService.claiming.description) {
          this.buttons[1].disable = true;
        }
      } else {
        this.buttons[1].disable = false;
      }
    }
  }

  sendMessage() {
    if (!this.contextService.claiming.id) {
      return;
    }
    this.sending = true;
    this.platformService
      .declarationOfInterest(
        this.contextService.claiming.id,
        'put',
        this.contextService.claiming
      )
      .then((response) => {
        if (response.status === 200) {
          this.contextService.reRender.next('sentMessage');
          this.closeModal();
        }
      });
  }

  saveNote() {
    if (
      !this.contextService.selectedRequest ||
      !this.contextService.selectedConsultant.id
    ) {
      return;
    }
    this.buttons[1].disable = true;
    this.platformService
      .postconsultantsalesStatus(
        this.contextService.selectedRequest.id,
        this.contextService.selectedConsultant.id,
        this.addedNote
      )
      .then((response) => {
        if (response.status === 202 || response.status === 200) {
          this.contextService.notification.next({
            description:
              this.selectedConsultant.firstName +
              ' sales notes have been updated',
            type: 'success',
            show: true,
            icon: 'sparkles',
          });
          this.contextService.reRender.next('saveNote');
        } else {
          this.contextService.notification.next({
            description: 'Oops, something went wrong. Try again',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
        this.closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  continue() {
    if (this.selectedRequest) {
      this.helperFunctions.route(['matching', 'rtc', this.selectedRequest!.id]);
      this.contextService.selectedRequest = this.selectedRequest;
      if (this.contextService.manualRequest) {
        this.contextService.manualRequest.description = '';
      }
    }
    if (this.manualRequest) {
      this.helperFunctions.route([
        'matching',
        'rtc',
        'manual' + new Date().valueOf(),
      ]);
      this.contextService.selectedRequest = undefined;
      this.contextService.manualRequest = this.manualRequest as matches;
    }
    this.contextService.openMatchModal = 'close';
  }

  closeModal() {
    this.contextService.reRender.next('close');
    this.contextService.openMatchModal = 'close';
  }

  getDescription(id: string) {
    this.platformService.description(id, 'v2').then((response) => {
      this.selectedRequestDescription = response.description;
    });
  }

  search(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.value.length === 0) {
      this.salesRequests = [];
    }
    if (!this.rawOptions) {
      this.rawOptions = this.salesRequests;
    }

    if (target.value.length === 0) {
      this.salesRequests = this.rawOptions;
      return;
    }

    this.salesRequests = this.rawOptions.filter((x) => {
      const findbyTitle = x.title
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      const findbyCustomerName = x.customerName
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      if (!findbyTitle) {
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }

  getSearchRequests() {
    this.platformService.getRequestsList().then((response) => {
      this.salesRequests = response.elements;
      this.salesRequests.map((x) => {
        if (this.selectedConsultant) {
          x.alreadyLonglist =
            x.longlist?.some(
              (z) => z.freelancerId === this.selectedConsultant.id
            ) ||
            x.shortlist?.some(
              (z) => z.freelancerId === this.selectedConsultant.id
            );
        }
        return x;
      });
    });
  }
  selected() {
    this.buttons[1].disable = !this.selectedConsultant;
  }
  selectRequest(request: requestV3) {
    this.selectedRequest = request;
    this.buttons[1].disable = !this.selectedRequest;
  }

  addToRequest() {
    this.loading = true;
    const freelance = {
      freelancerId: this.selectedConsultant.id,
      salesNotes: '',
    };

    this.platformService
      .longList(this.selectedRequest!.id, freelance)
      .then((response) => {
        if (response.status === 202 || response.status === 200) {
          this.contextService.notification.next({
            description:
              this.selectedConsultant.firstName +
              ' is added to the longlist of ' +
              this.selectedRequest!.title,
            type: 'success',
            show: true,
            icon: 'sparkles',
          });
          if (
            this.selectedRequest?.id === this.contextService.selectedRequest?.id
          ) {
            this.contextService.reRender.next('longShortList');
          }
        } else {
          this.contextService.notification.next({
            description: 'Oops, something went wrong. Try again',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
        this.closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  blur() {
    if (this.manualRequest) {
      this.manualDescription = this.manualRequest?.description ?? '';
    }
  }

  manualInput(event: string) {
    if (event.length === 0) {
      this.buttons[1].disable = true;
      return;
    }
    const manualRequest = {
      id: 'manual',
      object: '',
      customer: '',
      customerName: '',
      title: event,
      companyLogo: '',
      description: event,
      type: 'manual',
    };
    this.selectedRequest = undefined;
    this.manualRequest = manualRequest;
    this.buttons[1].disable = false;
  }
  input(event: Event) {
    const target = event.currentTarget as HTMLInputElement;

    if (this.type === 'addNotes') {
      this.addedNote = target.innerText;
      this.selected();
      return;
    }
  }
  setSelectedRequest(event: requestV3) {
    this.selectedRequest = event;
    this.buttons[1].disable = true;
    this.selectedRequestDescription = '';
    if (event) {
      this.buttons[1].disable = false;
      this.getDescription(this.selectedRequest.id);
    }
  }
}

