import { Component, EventEmitter, Output } from '@angular/core';
import {
  matchConsultants,
  profile3,
  requestV3,
  shortlistedProfiles
} from '../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-longlist-content',
  templateUrl: './longlist-content.component.html',
  styleUrls: ['./longlist-content.component.scss']
})
export class LonglistContentComponent {
  selectedConsultant: matchConsultants | profile3 | shortlistedProfiles = this.contextService.selectedLonglistConsultant;
  toggleRequest: boolean;
  salesRequests: requestV3[];
  selectedRequest?: requestV3;
  rawOptions: requestV3[];
  toggleDropdown: boolean;
  loading: boolean;
  @Output() selected = new EventEmitter<requestV3>();
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService
  ) {
    if (this.contextService.openMatchModal === 'longlist') {
      this.selectedRequest = this.contextService.selectedRequest;
    }
  }

  search(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 0) {
      this.salesRequests = [];
    }
    if (!this.rawOptions) {
      this.rawOptions = this.salesRequests;
    }

    if (target.value.length === 0) {
      this.salesRequests = this.rawOptions;
      return;
    }
    this.salesRequests = this.rawOptions.filter((x) => {
      const findbyTitle = x.title
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      const findbyCustomerName = x.customer.name
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      if (!findbyTitle) {
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }

  selectRequest(value: requestV3) {
    this.selectedRequest = value;
    this.selected.emit(value);
  }
  getSearchRequests() {
    if (this.salesRequests) {
      return;
    }
    this.loading = true;
    this.platformService.getRequests().then((response) => {
      this.salesRequests = response.data;
      this.loading = false;
    });
  }
}

