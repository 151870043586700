
export enum ClaimingStatus {
  PENDING_REPLY = 'PendingReply',
  INTERESTED = 'Interested',
  NOT_INTERESTED = 'NotInterested',
  NO_REPLY = 'NoReply',
}

export enum FreelancerStatus {
  ADDED = 'Added',
  CONTACTED = 'Contacted',
  CLAIMED = 'Claimed',
  CV_SENT = 'CVSent',
  INTERVIEW_SCHEDULED = 'InterviewScheduled',
  INTERVIEWED_BY_CUSTOMER = 'InterviewedByCustomer',
  CUSTOMER_APPROVED = 'CustomerApproved',
  SOLD = 'Sold',
  NOT_SOLD = 'NotSold',
}
