import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { option } from '../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-priority-select',
  templateUrl: './priority-select.component.html',
  styleUrls: ['./priority-select.component.scss']
})
export class PrioritySelectComponent implements OnChanges{
  @Input() disabled: boolean;
  @Input() priority?: string[];
  options: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();

  ngOnChanges(changes: SimpleChanges): void {


    if (this.priority) {
      this.options = [];
      this.priority.forEach((status: string) => {
        const findex = this.selected.findIndex((x) => x.displayName === status);
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: status,
          type: 'priority',
        });
      });
    }
  }

  selectOption(option: option) {
    if (option.id === '-1') {
      this.selected = [];
      this.selectedOption.emit(this.selected);
      return;
    }
    const findex = this.selected.findIndex((x) => x.id === option.id);
    if (findex === -1) {
      this.selected.push(option);
      this.selectedOption.emit(this.selected);
      return;
    }
    this.selected.splice(findex, 1);
    this.selectedOption.emit(this.selected);
  }
}
