import { Component, ViewEncapsulation } from '@angular/core';
import { declarationOfInterestReply, response } from '../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../../services/platform/context.service';

@Component({
  selector: 'app-reply-message-content',
  templateUrl: './reply-message-content.component.html',
  styleUrls: ['./reply-message-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReplyMessageContentComponent {
  reply: declarationOfInterestReply;
  loading = true;
  response: response = {
    GoodMatchAndAvailable: 'Yes, it’s a good match & I’m available',
    GoodMatchButNotAvailable: 'Yes, it’s a good match but I’m not available',
    NotGoodMatchAndAvailable: 'I am available, but it’s not a good match',
    NotGoodMatchAndNotAvailable: 'I am not available & it’s not a good match',
  };
  constructor(
    private contextService: ContextService,

    private helperFunction: HelperFunctionsService
  ) {}
  ngOnInit() {
    this.contextService.reRender.subscribe((val) => {
      if (val === 'replyMessage') {
        this.reply = this.contextService.declarationOfInterestReply;
        this.loading = false;
      }
    });
  }

  getStatus(value: string) {
    const icon = { type: 'icon', icon: '' };
    if (value === 'PendingReply') {
      icon.icon = 'loader';
    }
    if (value === 'Interested') {
      icon.icon = 'check';
    }
    if (value === 'NotInterested') {
      icon.icon = 'X';
    }
    if (value === 'NoReply') {
      icon.icon = 'X';
    }
    return icon;
  }
  getColor(value: string) {
    if (value === 'PendingReply') {
      return 'warning--Light';
    }
    if (value === 'Interested') {
      return 'success--Light';
    }
    if (value === 'NotInterested') {
      return 'danger--Light';
    }
    if (value === 'NoReply') {
      return 'danger--Light';
    }
    return '';
  }
  match(value: string) {
    const key = value as keyof response;
    return this.response[key];
  }
  getDate(date: number | string, iso?: string) {
    if (iso) {
      date = Math.floor(new Date(date).getTime() / 1000);
    }
    return this.helperFunction.getDate(date as number, iso ? false : true);
  }
}
