
export enum NavigationMenuItem {
  REQUESTS = 'requests',
  CONSULTANTS = 'consultants',
  MATCHING = 'matching',
  CANDIDATES = 'candidates',
  CANDIDATES_ACCEPTED = 'candidates-accepted',
  CANDIDATES_INBOX = 'candidates-inbox',
  CANDIDATES_NEW_LEADS = 'candidates-new-leads',
  CANDIDATES_PENDING_INTERVIEW = 'candidates-pending-interview',
  CANDIDATES_REJECTED = 'candidates-rejected',
}



export interface INavigationItem {
  id: NavigationMenuItem;
  allowed?: boolean;
  state: boolean;
  displayName: string;
  link: string;
  icon?: string;
  submenu?: INavigationItem[];
  size?: string;
  count: string;
  /**
   * List of prefixes for which the navigation item should be active
   */
  activeForRoutePrefix?: Array<string>;
  default?: boolean;
}
