import {
  MatchSalesRequestConsultant,
  MatchingConsultantRequest,
  MatchingRequestConsultant,
  cvType,
  matchConsultants,
  matches,
  matchingType,
  requestV3,
  searchContext
} from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';

// Searches for consultants based on the provided filters and request ID
export async function searchRequest(
  filters: string[],
  platformService: PlatformService,
  requestId: string, // this.requestIdFiles ?? this.requestId
  matchingType: cvType[],
  selectedCvType: number,
  contextService: ContextService,
  pagenation?: boolean
) {
  const query = filters.map((x) => x.replace(/&/g, '%26')).join('&');
  // Fetches cached filter results for CV and file types
  const CV = new Promise((resolve) => {
    platformService
      .getCachedFilter(
        matchingType[0].id ?? requestId,
        matchingType[0].iterationId,
        query
      )
      .then((resp: MatchSalesRequestConsultant) => {
        resolve(resp);
      });
  });
  const file = new Promise((resolve) => {
    platformService
      .getCachedFilter(
        matchingType[1].id ?? requestId,
        matchingType[1].iterationId,
        query
      )
      .then((resp: MatchSalesRequestConsultant) => {
        resolve(resp);
      });
  });

  let potentialResults: matchConsultants[] = [];
  let potentialResultsCv: matchConsultants[] = [];
  let potentialResultsFile: matchConsultants[] = [];

  // Waits for both CV and file results to be fetched
  await Promise.all([CV, file]).then((values: any[]) => {
    if (pagenation) {
      potentialResultsCv = [
        ...potentialResultsCv,
        ...values[0].potentialFreelancers,
      ];
      if (values[1]) {
        potentialResultsFile = [
          ...potentialResultsFile,
          ...values[1].potentialFreelancers,
        ];
      }
    } else {
      potentialResultsCv = values[0].potentialFreelancers;
      if (values[1]) {
        potentialResultsFile = values[1].potentialFreelancers;
      }
    }

    // Updates the matching type amounts based on the results
    let total = 0;
    total = values[1].pagination.total;
    matchingType[1].amount = total;
    total = values[0].pagination.total;
    matchingType[0].amount = total;

    // Sets the potential results based on the selected CV type
    if (selectedCvType === 1) {
      potentialResults = potentialResultsFile;
      contextService.resultsLength.next(values[1].pagination.total);
    } else {
      contextService.resultsLength.next(values[0].pagination.total);
      potentialResults = potentialResultsCv;
    }
  });

  return { potentialResults, potentialResultsCv, potentialResultsFile };
}

// Searches manually for consultants based on the provided filters and search context
export async function searchManual(
  filters: string[],
  searchContext: searchContext,
  platformService: PlatformService
) {
  // Fetches matching consultants for CV and file types
  const CV = await new Promise((resolve) => {
    platformService
      .matchrequesttoconsultants(searchContext, 'CV', filters.join('&'))
      .then(async (response: MatchingRequestConsultant) => {
        resolve(response);
      });
  });
  const file = await new Promise((resolve, rejected) => {
    platformService
      .matchrequesttoconsultants(searchContext, 'Files', filters.join('&'))
      .then(async (response?: MatchingRequestConsultant) => {
        resolve(response);
      })
      .catch(() => rejected([]));
  });

  const matchingType: cvType[] = [];
  let results: MatchingRequestConsultant[] = [];

  // Waits for both CV and file results to be fetched
  await Promise.all([CV, file]).then((values: any[]) => {
    results = values;

    // Merges the results and updates pagination information
    const mergedArray = [
      ...results[0].potentialCandidates,
      ...results[1].potentialCandidates,
    ];
    results[0].paginationCv = results[0].pagination;
    results[0].paginationFile = results[1].pagination;
    results[0].potentialCandidates = mergedArray;

    // Updates the matching type information
    matchingType.push(
      {
        matchingType: 'CV',
        displayName: 'My7N CVs',
        icon: 'discount-check',
        id: results[0].id,
        iterationId: results[0].iterationId,
        amount: results[0].pagination.total,
      },
      {
        matchingType: 'Files',
        displayName: 'Other CVs',
        icon: 'file-cv',
        id: results[1].id,
        iterationId: results[1].iterationId,
        amount: results[1].pagination.total,
      }
    );
  });

  return {
    results,
    matchingType,
  };
}

// Searches for consultants to match requests based on the provided search context
export async function searchCTR(
  platformService: PlatformService,
  searchContext: searchContext
) {
  return await platformService
    .matchconsultanttorequests(searchContext, 'v2')
    .then(async (response) => {
      return { response };
    });
}

// Searches for requests to match consultants based on the provided search context
export async function searchRTC(
  platformService: PlatformService,
  searchContext: searchContext,
  selectedRequest: requestV3 | undefined,
  contextService: ContextService,
  matchingType: cvType[]
) {
  if (selectedRequest) {
    matchingType = [];

    // Iterates over matching results and updates the matching type information
    await platformService
      .iterateMatch(selectedRequest.id, searchContext)
      .then((resp: any) => {
        if (resp.status === 202 && selectedRequest) {
          const iterations = resp.data.iterations.sort((a: any, b: any) =>
            a.matchingType.localeCompare(b.matchingType)
          );

          iterations.forEach((value: any) => {
            if (value.matchingType === 'CV') {
              matchingType.push({
                matchingType: 'CV',
                displayName: 'My7N CVs',
                icon: 'discount-check',
                iterationId: value.iterationId,
                contentReady: false,
                amount: 0,
                id: selectedRequest.id,
              });
            }
            if (value.matchingType === 'Files') {
              matchingType.push({
                matchingType: 'Files',
                displayName: 'Other CVs',
                icon: 'file-cv',
                iterationId: value.iterationId,
                contentReady: false,
                amount: 0,
                id: selectedRequest.id,
              });
            }
            matchingType.map((x) => (x.contentReady = false));
          });
        } else {
          // Displays a notification if the matching process fails
          contextService.notification.next({
            description: 'Oops, something went wrong. Try again',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
      });
  }

  return { matchingType };
}
