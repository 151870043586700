<div class="each-consultant">
  <div
    class="bundle surface--1"
    [class]="{ shortListed: shortListed, disable: disable }"
  >
    <div>
      <div class="image caption">
        <img *ngIf="consultant.imageUrl" [src]="consultant.imageUrl" />
        <p *ngIf="!consultant.imageUrl">{{ consultant.firstName[0] }}</p>
      </div>
      <div class="text">
        <div class="bundle-shortlist">
          <div class="name-title">
            <h3>
              {{ consultant.firstName }} {{ consultant.middleName }}
              {{ consultant.lastName }}
            </h3>
            <p class="body-large">{{ consultant.lastRole }}</p>
          </div>
          <div>
            <app-cta
              *ngIf="shortListed"
              [imgIcon]="'/assets/svg/list-check.svg'"
              classes="success--Lighter disable small"
            >
            </app-cta>
          </div>
        </div>
        <div class="info">
          <div class="country">
            <i-tabler width="16px" height="16px" name="map-pin"></i-tabler>
            <p class="body-small">
              <span> {{ getCity(consultant.location.city) }}</span>
              {{ getCountry(consultant.location.countryCode) }}
            </p>
          </div>
          <div class="li"></div>
          <p class="body-small">
            <span>Available from</span> {{ getDate(consultant.availability) }}
          </p>
          <div class="li"></div>
          <p class="body-small">
            {{ validateRate(consultant.expectedRate) }} / <span>hour</span
            ><span class="mobile">h</span>
          </p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="disable">
      <div class="nocv">
        <app-badge label="No CV data" color="neutral--Grey-300"></app-badge>
      </div>
    </ng-container>
    <ng-container *ngIf="ctas">
      <div class="bundle-cta">
        <div class="cta" *ngIf="ctas.user">
          <app-cta
            classes="brand--Lightest textLink"
            [icon]="'user'"
            (click)="openProfile($event, consultant.id)"
          >
          </app-cta>
        </div>
        <div class="cta" *ngIf="ctas.selected">
          <app-cta
            classes="brand--Lightest textLink"
            [icon]="'X'"
            (click)="cta.emit('close')"
          >
          </app-cta>
        </div>
        <div class="cta" *ngIf="ctas.edit">
          <app-cta
            classes="brand--Lightest textLink"
            [icon]="'pencil'"
            (click)="cta.emit('edit')"
          >
          </app-cta>
        </div>
      </div>
    </ng-container>
  </div>
</div>
