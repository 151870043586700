import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tabButtons } from '../../../../interface/shared.interface';

@Component({
  selector: 'app-tab-buttons',
  templateUrl: './tab-buttons.component.html',
  styleUrls: ['./tab-buttons.component.scss'],
})
export class TabButtonsComponent {
  @Input() selected = 0;
  @Input() disable: boolean;
  @Input() style: string;
  @Input() type: string;
  @Input() classList: string;
  @Input() tabButtons: tabButtons[];
  @Output() callbackFunction = new EventEmitter<number>();
  getClass(i: number) {
    const classes: string[] = [];
    if (this.type === 'neutral') {
      if (this.selected === i) {
        classes.push('neutral neutral--Grey-0 textLink');
      } else {
        classes.push('neutral neutral--Grey-100 textSecondary');
      }
    } else {
      classes.push('textLink');
      if (this.selected === i) {
        classes.push('brand--Light');
      } else {
        classes.push('brand--Lightest');
      }
    }
    if (this.tabButtons[i].mobile) {
      classes.push('mobile');
    }
    return classes.join(' ');
  }
}
