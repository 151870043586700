import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import {
  matchConsultants,
  requestV3,
  shortlistedProfiles,
} from '../../../../interface/shared.interface';
import { modalType, profile3 } from '../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../services/platform/context.service';
import { FeatureFlagsService } from '../../../../services/feature-flags/feature-flags.service';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleDropdownComponent {
  consultant = input<matchConsultants>();
  shortlistedProfiles = input<shortlistedProfiles>();
  request = input<requestV3>();
  profile3 = input<profile3>();
  isManual = input<boolean>();
  selectedSearchType = input<number>();

  private contextService = inject(ContextService);
  private matomoTracker = inject(MatomoTracker);
  private helperFunctions = inject(HelperFunctionsService);
  public featureFlags = inject(FeatureFlagsService);
  
  openMatchModal(event: Event, modal: modalType = 'longlist') {
    event.stopPropagation();
    const profileTypes = [
      this.consultant(),
      this.profile3(),
      this.shortlistedProfiles(),
    ];
    const findex = profileTypes.findIndex((x) => x);
    if (modal === 'longlist' || modal === 'anotherLonglist') {
      this.contextService.selectedLonglistConsultant = profileTypes[findex];
    } else {
      this.contextService.selectedConsultant = profileTypes[findex];
    }

    this.contextService.openMatchModal = modal;
  }

  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();
    if (link) {
      this.matomoTracker.trackEvent('Matching', 'External Link - ' + text);
      window.open(link, '_blank');
    }
  }

  openRtcMatch(event: Event) {
    event.stopPropagation();
    this.helperFunctions.route(['matching/rtc/' + this.request().id]);
  }
}
