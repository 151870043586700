import { createReducer, on } from '@ngrx/store';
import * as fromRoot from './../index';
import * as DictionariesActions from './dictionaries.actions';
import { ICrmCountry } from '../../interfaces/dictionaries';

export const featuresDictionariesKey = 'dictionaries';

export interface DictionariesState extends fromRoot.State {
  dictionaries: State;
}

export interface State {
  countries: Array<ICrmCountry>;
  loading: boolean;
}

export const initialState: State = {
  countries: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    DictionariesActions.queryCountries,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    DictionariesActions.queryCountriesSuccess,
    (state, { countries }): State => ({
      ...state,
      countries: countries,
      loading: false,
    })
  ),
  on(
    DictionariesActions.queryCountriesError,
    (state): State => ({
      ...state,
      loading: false,
    })
  )
);

export const getCountriesDict = (state: State) => {
  return state.countries;
};

export const getLoading = (state: State) => {
  return state.loading;
};
