import { Component, inject, OnInit } from '@angular/core';
import { HelperFunctionsService } from '../../services/helperFunctions/helper-functions.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { ContextService } from 'src/services/platform/context.service';
import { list, routeParams } from 'src/interface/shared.interface';
import { Location } from '@angular/common';
import { PlatformService } from 'src/services/platform/platform-service.service';
import { DictionariesFacadeService } from '../shared/services/dictionaries-facade.service';
import { GlobalFacadeService } from '../shared/services/global-facade.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  rightContentType = '';
  openSidePanel: boolean;
  breadCrumbs!: routeParams;
  selectedNav: string;
  navs = this.contextService.navs.dashboard;
  page: string;
  loading = false;
  faSpinner = faSpinner;
  count = 0;
  isOverlay: boolean;
  params: routeParams;
  id: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public contextService: ContextService,
    private helperFunctions: HelperFunctionsService,
    private location: Location,
    private platformService: PlatformService
  ) {
    this.contextService.openSidePanel.subscribe((val: boolean) => {
      this.openSidePanel = val;
    });
  }

  private dictionariesService = inject(DictionariesFacadeService);
  private globalService = inject(GlobalFacadeService);

  async ngOnInit() {
    this.globalService.queryAppVersion();
    this.globalService.queryAppSettings();
    this.dictionariesService.queryCountries();
    
    await this.platformService
      .getFilters('', 0, 'filters')
      .then((respond: any) => {
        const allTerritories: { name: string; displayName: string }[] = [];
        respond.filters.businessUnitAndOfficeLocations.forEach((x: any) => {
          const location = {
            displayName: x.businessUnit,
            subNames: x.officeAndTerritories
              .filter((x: any) => !x.hidden)
              .map((x: any) => x.officeLocation),
          };

          this.contextService.filterData?.location?.push(location);

          let territories: { name: string; displayName: string }[] = [];

          x.officeAndTerritories.forEach((element: any) => {
            territories = [...territories, ...element.territories];
          });

          allTerritories.push(...territories);
        });

        this.contextService.filterData.team = allTerritories
          .filter(
            (item, index, self) =>
              index === self.findIndex((i) => i.name === item.name)
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      });

    this.selectedNav =
      this.activatedRoute.snapshot.url[
        this.activatedRoute.snapshot.url.length - 1
      ]?.path;
    this.page = this.activatedRoute.snapshot.data['page'];
    this.activatedRoute.params.subscribe((val) => {
      this.params = val as routeParams;
      this.isOverlay = false;
      this.location.onUrlChange((url) => {
        const split = url.split('/');
        if (
          ['matching', 'consultant'].every((x) => split.includes(x)) ||
          ['consultants', 'consultant'].every((x) => split.includes(x))
        ) {
          this.isOverlay = true;
          this.rightContentType = 'consultant';
          this.id = split[split.length - 1];
          return;
        }
        this.slugHandle();
      });
      this.slugHandle();
      this.breadCrumbs = this.params;
    });

    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        this.navs = this.contextService.navs.dashboard;

        this.loading = true;
      }
    });
  }
  getNavName(slug: string) {
    let find = this.navs.find((x) => x.slug === slug);
    if (find) {
      return find.displayName;
    }
    for (let i = 0; i < this.navs.length; i++) {
      if (!find) {
        find = this.navs[i].submenu?.find((z) => z.slug === slug);
      }
    }
    if (find) {
      return find.displayName;
    }
    return '';
  }
  slugHandle() {
    this.selectedNav = this.params['slug'];
    if (
      !['matching', 'community', 'requests', 'consultants'].some(
        (x) => x === this.selectedNav
      )
    ) {
      this.rightContentType = 'accepted';
      this.selectedNav += this.params['id'] ? '/' + this.params['id'] : '';
    } else {
      if (this.params['deeplink'] === 'consultant' && this.params['id']) {
        this.id = this.params['id'];
        this.rightContentType = this.params['deeplink'];
        return;
      }
      this.rightContentType = this.selectedNav;
    }
  }

  toggleSide() {
    this.openSidePanel = !this.openSidePanel;
  }

  getListType(array: string[]) {
    return array.some((x) => this.rightContentType === x);
  }

  step(value: string) {
    this.helperFunctions.route(['/' + value]);
  }
  getSlug(slug: string) {
    if (!this.contextService.profileData) {
      return '';
    }
    const find: list | undefined = this.contextService.profileData.find(
      (x) => x.id === slug
    );

    if (!find) {
      return '';
    }
    return find ? find.givenName + ' ' + find.surname : '';
  }
  getCandidate(slug: string) {
    const findex = this.contextService.profileData.findIndex(
      (x) => x.id === slug
    );
    if (findex !== -1) {
      const find: list = this.contextService.profileData[findex];
      return find.givenName + ' ' + find?.surname;
    }
    return '';
  }
  signout() {
    this.authenticationService.signout();
  }
}
