import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ISmartTip } from '../../interfaces/matching/smart-tip';

@Injectable({
  providedIn: 'root',
})
export class MatchingService {
  private http = inject(HttpClient);

  private readonly API_URL = environment.apiUrl;
  private readonly SMART_TIP_API_URL = `${this.API_URL}/agentportal/v1/smarttip/`;

  getSmartTip(entityId: number, query?: string): Observable<ISmartTip> {
    return this.http.get<any>(
      this.SMART_TIP_API_URL + entityId + (query ? '?' + query : '')
    );
  }
}
