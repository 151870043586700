import { ICrmCountry } from '../../interfaces/dictionaries';
import {
  deepComputed,
  signalStoreFeature,
  withComputed,
  withState,
} from '@ngrx/signals';
import { computed } from '@angular/core';
import { IOption } from '../../interfaces/option';
import { FilterTypes } from '../../interfaces/filters';

export type CountriesDictionaryState = {
  _countries: Array<ICrmCountry>;
};

export const initialCountriesDictionaryState: CountriesDictionaryState = {
  _countries: null,
};

export function withCountriesDictionary() {
  return signalStoreFeature(
    withState({ _countriesDictionary: initialCountriesDictionaryState }),
    withComputed(({ _countriesDictionary }) => ({
      countriesDictionary: deepComputed(() => ({
        values: computed(() => _countriesDictionary._countries()),
        valuesSortedAsc: computed(() => {
          if (_countriesDictionary._countries() === null) {
            return [];
          } else {
            return _countriesDictionary
              ._countries()
              .sort((a, b) => a.countryName.localeCompare(b.countryName));
          }
        }),
        valuesAsOptions: computed<IOption[]>(() => {
          if (_countriesDictionary._countries() === null) {
            return [];
          } else {
            return _countriesDictionary._countries().map((country) => ({
              id: country.countryId,
              name: country.countryName,
              type: FilterTypes.TEAM_LOCATION_COUNTRY,
            }));
          }
        }),
      })),
    }))
  );
}
