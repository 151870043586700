import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { routeParams } from '../../interface/shared.interface';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ContextService } from '../../services/platform/context.service';
import { PlatformService } from '../../services/platform/platform-service.service';
import { DictionariesStore } from '../shared/store/dictionaries/dicitionaries.store';
import { GlobalStore } from '../shared/store/global/global.store';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  openSidePanel: boolean;
  loading = false;
  params: routeParams;
  matchModalMode = signal(this.contextService.matchModalMode.value);

  private dictionariesStore = inject(DictionariesStore);
  private globalStore = inject(GlobalStore);

  constructor(
    private authenticationService: AuthenticationService,
    public contextService: ContextService,
    private platformService: PlatformService
  ) {
    this.contextService.openSidePanel.subscribe((val: boolean) => {
      this.openSidePanel = val;
    });

    this.contextService.matchModalMode$.subscribe((modalMode) => {
      this.matchModalMode.set(modalMode);
    });
  }

  async ngOnInit() {
    this.globalStore.queryAppVersion();
    this.globalStore.queryAppSettings({ forceReload: true });
    this.dictionariesStore.queryDictionaries({ forceReload: true });

    await this.platformService
      .getFilters('', 0, 'filters')
      .then((respond: any) => {
        // TODO: moved to DictionariesStore, can be removed after all usages are removed
        const allTerritories: { name: string; displayName: string }[] = [];
        this.contextService.filterData.location = [];
        respond.filters.businessUnitAndOfficeLocations.forEach((x: any) => {
          const location = {
            displayName: x.businessUnit,
            subNames: x.officeAndTerritories
              .filter((x: any) => !x.hidden)
              .map((x: any) => x.officeLocation),
          };

          this.contextService.filterData.location.push(location);

          let territories: { name: string; displayName: string }[] = [];

          x.officeAndTerritories.forEach((element: any) => {
            territories = [...territories, ...element.territories];
          });

          allTerritories.push(...territories);
        });

        this.contextService.filterData.team = allTerritories
          .filter(
            (item, index, self) =>
              index === self.findIndex((i) => i.name === item.name)
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      });

    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        this.loading = true;
      }
    });
  }

  toggleSide() {
    this.openSidePanel = !this.openSidePanel;
  }

  signout() {
    this.authenticationService.signout();
  }
}
