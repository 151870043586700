import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDictionaries from './dictionaries.reducer';

export const selectDictionariesState =
  createFeatureSelector<fromDictionaries.State>(
    fromDictionaries.featuresDictionariesKey
  );

export const selectCountriesDict = createSelector(
  selectDictionariesState,
  fromDictionaries.getCountriesDict
);

export const selectLoading = createSelector(
  selectDictionariesState,
  fromDictionaries.getLoading
);
