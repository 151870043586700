import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as DictionariesActions from './dictionaries.actions';
import { Action } from '@ngrx/store';
import { DictionariesService } from '../../services/dictionaries.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Injectable({
  providedIn: 'root',
})
export class DictionariesEffects {
  private actions$ = inject(Actions);
  private dictionariesService = inject(DictionariesService);
  private snackBarService = inject(SnackBarService);

  queryCountries$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DictionariesActions.queryCountries),
      switchMap(() => {
        return this.dictionariesService.getCountriesDict().pipe(
          switchMap((countries) => {
            countries.sort((a, b) =>
              a.countryName.localeCompare(b.countryName)
            );
            return of(DictionariesActions.queryCountriesSuccess({ countries }));
          }),
          catchError((error: Error) => {
            console.error(
              `[DictionariesEffects] Error while loading countries dictionary: ${error}`
            );

            this.snackBarService.open({
              message: 'Error while loading countries dictionary',
              type: SnackBarTypes.ErrorAlt
            });
            
            return of(DictionariesActions.queryCountriesError({ error }));
          })
        );
      })
    );
  });
}
