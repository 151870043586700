import {
  Component,
  ElementRef,
  input,
  output,
  viewChild,
  model,
  signal,
  ChangeDetectionStrategy,
  inject,
} from '@angular/core';
import { option, tier } from '../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-dropdown-v2',
  templateUrl: './dropdown-v2.component.html',
  styleUrls: ['./dropdown-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownV2Component {
  trailingIcon = input<string>();
  tiers = input<tier[]>([]);
  selected = input<option[]>([]);
  options = model.required<option[]>();
  multiselect = input(false);
  placeholder = input<string>();
  type = input<string>();
  disabled = input(false);
  searchable = input(true);

  selectOption = output<option>();

  searchInput = viewChild<ElementRef>('searchInput');

  dropdown = signal(false);
  searchText = signal('');

  rawOptions: option[];

  helperFunctionsService = inject(HelperFunctionsService);

  getTier(option: option): tier {
    return this.tiers().find(
      (x) => x.status.contractStatus === option.displayName
    );
  }

  search(event: Event) {
    this.searchText.set((event.currentTarget as HTMLInputElement).innerText);

    const value = this.helperFunctionsService.trimAndClearSpaces(this.searchText()).toLocaleLowerCase();

    if (!this.rawOptions) {
      this.rawOptions = this.options();
    }

    this.options.set(
      this.rawOptions.filter((x) => {
        let find = x.displayName.toLocaleLowerCase().includes(value);
        if (!find) {
          find = x.name ? x.name.toLocaleLowerCase().includes(value) : find;
        }
        return find;
      })
    );
  }

  isSelected(option: option): boolean {
    return this.selected().some((x) => x.id === option.id);
  }

  selectFunc(select: option) {
    if (this.disabled()) {
      return;
    }
    this.searchInput().nativeElement.innerHTML = '';
    this.selectOption.emit(select);
  }

  resetInput() {
    if (this.rawOptions) {
      this.options.set(this.rawOptions);
    }
    return '';
  }
}
