@if (openDropdown()) {
  <ng-container (clickOutside)="openDropdown.set(false)"
                [myDirective]="openDropdown()"></ng-container>
}

<div class="request-level1" [ngClass]="{'non-expandable': !expandable() }">
  <div class="bundle-levels surface--1">
    <div class="level0">
      <app-avatar [customer]="selectedRequest().customer"
                  class="small"
                  [tiers]="true"></app-avatar>

      @if (selectedSalesRequest()) {
        <app-sale-request style="width: 100%"
                          [selectedRequest]="selectedSalesRequest()"></app-sale-request>
      }

      @if (selectedRequest()) {
        <div class="info"
             [class]="{level0}"
             (click)="expandPanel()">
          <div>
            <div class="title">
              <div class="bundle-title-create">
                <h3>{{ selectedRequest().title }}</h3>
                <p class="tiny textSecondary createdAt">
                  {{ calcDay(selectedRequest().createdAt) }}
                </p>
              </div>
              @if (!level0()) {
                <p>{{ selectedRequest().customer.name }}</p>
              }
            </div>
            <div class="cta">
              @if (!(params().slug === 'requests' && params().deeplink)) {
                @if (freelanceAddedToRequest()) {
                  <app-cta imgIcon="/assets/svg/list-check.svg"
                           classes="success--Lighter"></app-cta>
                }
                <app-cta classes="brand--Lightest textLink"
                         icon="file-text"
                         (click)="openInternal($event)"></app-cta>
              }

              <app-cta classes="textLink"
                       [ngClass]="{ 'md:hidden': usedOnDetails()}"
                       icon="dots"
                       (click)="toggleDropdown($event)"></app-cta>

              <div class="bundle-dropdown">
                @if (openDropdown()) {
                  <app-simple-dropdown (click)="toggleDropdown($event)"
                                       [request]="selectedRequest()"></app-simple-dropdown>
                }
              </div>
            </div>
          </div>
          <div class="bundle-detail-expand">
            <ul class="details body-small">
              @if (level0()) {
                <li>{{ selectedRequest().customer.name }}</li>
              } @else {
                <li>{{ getDisplayName(selectedRequest().country) }}</li>
              }

              <li>Start {{ getDate(selectedRequest().contractStartDate) || '-' }}</li>
              <li>
                <app-badge class="only-first-letter"
                           [label]="selectedRequest().priority"
                           [color]="getColorType(selectedRequest().priority)"></app-badge>
              </li>

              @if (!level0()) {
                <li>Deadline {{ getDate(selectedRequest().deadline) || '-' }}</li>
                <li>
                  @if (getSaleStatus(selectedRequest().highestSalesStatus)) {
                    <app-badge [label]="getSaleStatus(selectedRequest().highestSalesStatus).displayName"
                               color="neutral--Grey-300 textPrimary"
                               [icon]="getSaleStatus(selectedRequest().highestSalesStatus).icon"
                               class="sales-status"></app-badge>
                  }
                </li>
                <li class="body-small">
                  <span>
                  {{
                    selectedRequest().enlistedProfiles
                      ? selectedRequest().enlistedProfiles.length
                      : 0
                  }}
                  </span>
                  <span>{{ selectedRequest().enlistedProfiles.length === 1 ? 'candidate' : 'candidates' }}</span>
                </li>
              }

              @if (!selectedRequest().isDescription) {
                <li>
                  <app-badge label="No description"
                             color="neutral--Grey-300"></app-badge>
                </li>
              }
            </ul>
            @if (expandable()) {
              <i-tabler [name]="'chevron-' + (expand() ? 'up' : 'down')"></i-tabler>
            }
          </div>
        </div>
      }
    </div>
    @if (expand()) {
      <app-request-level2 [request]="selectedRequest()"
                          [requestLevel3]="level3()"></app-request-level2>
    }
  </div>
</div>
