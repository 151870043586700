<div class="priority-select">
  <app-dropdown-v2
    trailingIcon="chevron-down"
    [selected]="selected"
    [options]="options"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [type]="'priority-select'"
    [placeholder]="'Find priority'"
    [disabled]="disabled"
    [searchable]="false"
  ></app-dropdown-v2>
</div>
