import { Component, EventEmitter, Input, Output } from '@angular/core';
import { profile3 } from '../../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent {
  @Input() consultant: profile3;
  @Output() downloadUrl = new EventEmitter();
  expandState: number[] = [];
  constructor(private helperFunctions: HelperFunctionsService) {}

  expandStates(index: number) {
    const findex = this.expandState.findIndex((x) => x === index);
    if (findex === -1) {
      this.expandState.push(index);
    } else {
      this.expandState.splice(findex, 1);
    }
  }
  getDate(value: string) {
    if (!value) {
      return '-';
    }
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }
  getCity(value: string) {
    if (value) {
      return value + ', ';
    }
    return '';
  }
  getCountryState(value: string | undefined) {
    if (value) {
      return value + ', ';
    }
    return '';
  }
  getCountry(value: string) {
    if (value) {
      return this.helperFunctions.getCountry(value.toLocaleUpperCase());
    }
    return '';
  }
  gotTo(link: string) {
    window.open(link, '_blank');
  }
}
