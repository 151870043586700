import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import saveAs from 'file-saver';
import {
  matchConsultants,
  shortlistedProfiles,
} from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Component({
  selector: 'app-level2',
  templateUrl: './level2.component.html',
  styleUrls: ['./level2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Level2Component {
  consultant = input.required<matchConsultants | shortlistedProfiles>();
  selectedCvType = input(0);

  private helperFunctions = inject(HelperFunctionsService);
  private platformService = inject(PlatformService);
  private contextService = inject(ContextService);
  private snackBarService = inject(SnackBarService);

  experience = computed(() =>
    this.consultant()?.experience ? this.consultant().experience.experiences : []
  );
  email = computed(() => this.consultant().email ?? '-');
  phoneNumber = computed(() => this.consultant().phoneNumber ?? '-');
  salesAgentFullName = computed(() => {
    if (this.consultant()?.salesAgent) {
      return (
        this.consultant().salesAgent.firstName +
        ' ' +
        this.consultant().salesAgent.lastName
      );
    } else {
      return '-';
    }
  });

  getMonthYear(startDate: string, endDate: string | null): string {
    const d = Math.round(new Date().getTime() / 1000);
    const startStamp = this.helperFunctions
      .getDate(Math.round(new Date(startDate).getTime() / 1000), false)
      .split(' ');
    const endStamp = endDate
      ? this.helperFunctions
          .getDate(Math.round(new Date(endDate).getTime() / 1000), false)
          .split(' ')
      : ['Now'];
    startStamp.shift();
    endStamp.shift();
    let handleEndStamp = endStamp.join(' ');
    if (!endDate || d < Math.round(new Date(endDate).getTime() / 1000)) {
      handleEndStamp = 'Now';
    }
    return startStamp.join(' ') + ' - ' + handleEndStamp;
  }

  openExternal(event: Event, link?: string) {
    event.stopPropagation();

    if (link) {
      window.open(link, '_blank');
    }
  }

  downloadUrl(event: Event) {
    event.stopPropagation();
    if (!this.consultant().cvFile || !this.consultant().cvFile.annotationId) {
      return;
    }

    this.platformService
      .getDownloadUrl(this.consultant().cvFile.annotationId)
      .then((response) => {
        if (response) {
          saveAs(response.data, this.consultant().cvFile.filename);
        } else {
          // @TODO check if needed
          console.error(`[Level2] Empty response for ${this.consultant().cvFile.annotationId}`);
          throw new Error(`[Level2] Empty response for ${this.consultant().cvFile.annotationId}`);
        }
      }).catch(() => {
        this.snackBarService.open({
          message: `Oops, unable downloading file`,
          type: SnackBarTypes.ErrorAlt
        });
      });
  }
}
