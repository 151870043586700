<div class="wrap-search">
  <app-input
    (inputs)="searchConsultant($event)"
    trailingIcon="chevron-down"
    placeholder="e.g. John Smith"
    type="field"
    (focus)="toggleDropdown = true"
  ></app-input>
  <div *ngIf="toggleDropdown" class="consultants surface--2">
    <ng-container *ngIf="allconsultants.length === 0 && !loading">
      <div class="placeholder" *ngIf="isEmptyInput">
        <div class="image-box">
          <img width="160" src="assets/images/lets_start.png" />
        </div>
        <div class="s1">Start typing to search.</div>
        <div class="body-large">Search for freelancers by name.</div>
      </div>

      <div class="placeholder" *ngIf="!isEmptyInput">
        <div class="image-box">
          <img width="160" src="assets/images/empty_folder.png" />
        </div>
        <div class="s1">Person not found.</div>
        <div class="body-large">Please try another name.</div>
      </div>
    </ng-container>

    <div class="loading" *ngIf="loading">
      <app-loader [loading]="loading"></app-loader>
    </div>
    <div *ngIf="!loading">
      <ng-container *ngFor="let consultant of allconsultants">
        <app-level0
          [shortListed]="consultant.added"
          *ngIf="dataValidator(consultant)"
          (click)="select(consultant)"
          [consultant]="consultant"
          [disable]="!consultant.applicableForC2ROrC2C && !addable"
        ></app-level0>
      </ng-container>
    </div>
  </div>
</div>
