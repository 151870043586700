import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinct, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as GlobalActions from './global.actions';
import { Action } from '@ngrx/store';
import { GlobalService } from '../../services/global.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalEffects {
  private actions$ = inject(Actions);
  private globalService = inject(GlobalService);

  queryAppVersion$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalActions.queryAppVersion),
      map(() => {
        const appVersion = this.globalService.getAppVersion();
        return GlobalActions.queryAppVersionSuccess({ appVersion: appVersion });
      }),
      catchError((error: Error) => {
        console.error(
          `[GlobalEffects] Error while loading app version: ${error}`
        );
        return of(GlobalActions.queryAppVersionError());
      })
    );
  });

  queryAppSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalActions.queryAppSettings),
      distinctUntilChanged(),
      switchMap(() => {
        return this.globalService.getAppSettings().pipe(
          map((appSettings) => {
            return GlobalActions.queryAppSettingsSuccess({ appSettings });
          }),
          catchError((error: Error) => {
            console.error(
              `[GlobalEffects] Error while loading app settings: ${error}`
            );
            return of(GlobalActions.queryAppSettingsError());
          })
        );
      }),
    );
  });
}
