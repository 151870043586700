import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  declarationInformation,
  declarationOfInterest,
  declarationOfInterestReply,
  dropdown,
  freeStatus,
  matchConsultants,
  matches,
  requestV3,
  shortlistedProfiles,
  by
} from '../../../../../interface/shared.interface';
import { Location } from '@angular/common';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { PlatformService } from '../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-shortlisted-profile',
  templateUrl: './shortlisted-profile.component.html',
  styleUrls: ['./shortlisted-profile.component.scss'],
})
export class ShortlistedProfileComponent {
  @Input() bulkSelect: shortlistedProfiles[];
  @Input() selectedRequest: requestV3;
  @Input() consultant: shortlistedProfiles;
  @Input() consultantstatus: dropdown[];
  @Output() updateResults = new EventEmitter();
  @Output() loadMore = new EventEmitter<number>();
  expandLevel2: boolean;
  externalLinks: dropdown[] = [];
  count = 0;
  hideSelectNotification = false;
  openDropdown: string;
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService,
    private matomoTracker: MatomoTracker,
    private helperFunctions: HelperFunctionsService,
    private location: Location
  ) {}

  getCountry(value: string) {
    if (value) {
      return this.helperFunctions.getCountry(value.toLocaleUpperCase());
    }
    return '';
  }

  openProfile(event: Event, id: string) {
    event.stopPropagation();
    this.location.go('/consultants/consultant/' + id);
  }

  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown = id;
  }

  checked(result: shortlistedProfiles) {
    if (result.declarationInformation) {
      return;
    }
    const findex = this.bulkSelect.findIndex((x) => x === result);
    if (findex === -1) {
      this.bulkSelect.push(result);
    } else {
      this.bulkSelect.splice(findex, 1);
    }
  }
  openNote(event: Event, result: shortlistedProfiles) {
    event.stopPropagation();
    this.contextService.selectedConsultant = result;
    this.contextService.openMatchModal = 'addNotes';
    this.matomoTracker.trackEvent('Request', 'Open modal - Add to notes');
  }
  selectedSaleNotes(result: shortlistedProfiles, event: dropdown | matches) {
    event = event as dropdown;
    result.salesStatus = event.name!;

    this.selected(result);
  }
  getFullName(value: by) {
    return (
      value.firstName + ' ' + (value.middleName ?? '') + ' ' + value.lastName
    );
  }
  selected(value: shortlistedProfiles) {
    this.matomoTracker.trackEvent(
      'Request',
      'Changed sale status to - ' + value.salesStatus
    );
    this.platformService
      .putConsultantStatus(this.selectedRequest.id, value)
      .then((response) => {
        if (response.status === 202) {
          const findex = this.consultantstatus.findIndex(
            (x) => x.name === value.salesStatus
          );
          if (findex < 2) {
            this.contextService.notification.next({
              description:
                'Updated sales status for ' + this.getFullName(value),
              type: 'success',
              show1: true,
              icon: 'sparkles',
            });
          } else {
            this.contextService.notification.next({
              description:
                this.getFullName(value) + ' is now moved to the shortlist',
              type: 'success',
              show1: true,
              icon: 'sparkles',
            });
          }
          this.updateResults.emit();
        }
      });
  }

  validateRate(expectedRate: { currency: string; ratePerHour: number }) {
    if (expectedRate.currency && expectedRate.ratePerHour) {
      return expectedRate.ratePerHour + ' ' + expectedRate.currency;
    }
    return '-';
  }
  cleanText(text: string) {
    const parts = text
      .split('\n')
      .map((part) => part.trim())
      .filter((part) => part.length > 0);

    return parts.length > 0 ? parts[parts.length - 1] : '';
  }

  getLengthOfNotes(text: string) {
    if (!text) {
      return 0;
    }
    const parts = text
      .split('\n')
      .map((part) => part.trim())
      .filter((part) => part.length > 0);
    return parts.length;
  }
  getDate(value: string) {
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }

  dateDifference(timestamp: number) {
    const d = new Date(0);
    d.setUTCSeconds(timestamp);
    return this.helperFunctions.calculateDateDifference(d);
  }

  getDateTimestamp(timestamp: number, hourMinutes = false) {
    return this.helperFunctions.getDate(timestamp, hourMinutes);
  }
  getProcessLengt(name: string) {
    const findex = this.consultantstatus.findIndex((x) => x.name === name);
    if (findex === -1) {
      return '0';
    }
    return (findex + 1) * 15;
  }
  getStatus(value: declarationInformation) {
    const icon = { type: 'icon', icon: '' };
    if (value.status === 'PendingReply') {
      icon.icon = 'loader';
    }
    if (value.status === 'Interested') {
      icon.icon = 'check';
    }
    if (value.status === 'NotInterested') {
      icon.icon = 'X';
    }
    if (value.status === 'NoReply') {
      icon.icon = 'X';
    }
    return icon;
  }
  getColor(value: declarationInformation) {
    if (value.status === 'PendingReply') {
      return 'warning--Light';
    }
    if (value.status === 'Interested') {
      return 'success--Light';
    }
    if (value.status === 'NotInterested') {
      return 'danger--Light';
    }
    if (value.status === 'NoReply') {
      return 'danger--Light';
    }
    return '';
  }
  beautifyScore(score: number) {
    return score.toFixed(2).substring(2);
  }
  returnString(value: number | string) {
    if (!value || value === '') {
      return '-';
    }
    return value;
  }
  getSelected(value: string) {
    let find: dropdown;
    if (this.consultantstatus) {
      find = this.consultantstatus.find((x) => x.name === value);
    }
    return find;
  }

  openMessaging(event: Event, result: shortlistedProfiles) {
    event.stopPropagation();
    console.log(result.declarationInformation);
    if (result.declarationInformation) {
      this.contextService.openMatchModal = 'replyMessage';
      this.platformService
        .declarationOfInterest(
          result.declarationInformation.freelancerDeclarationOfInterestId,
          'getFree'
        )
        .then((response: declarationOfInterestReply) => {
          this.contextService.declarationOfInterestReply = response;
          this.contextService.reRender.next('replyMessage');
        });
    }
    if (!result.declarationInformation) {
      this.contextService.openMatchModal = 'messaging';
      this.platformService
        .declarationOfInterest(this.selectedRequest.id, 'post', undefined, {
          freelancers: [result.id],
          languageCode: 'ENG',
        })
        .then((response: declarationOfInterest) => {
          this.contextService.claiming = response;
          this.contextService.reRender.next('messaging');
        });
    }
  }

  openModal() {
    this.contextService.openMatchModal = 'longlist';
    this.matomoTracker.trackEvent('Matching', 'Open modal - Add to longlist');
  }
  externalLink(value: dropdown | matches) {
    value = value as dropdown;
    if (value.link) {
      this.matomoTracker.trackEvent(
        'Request',
        'External Link - ' + value.displayName
      );
      window.open(value.link);
    }
  }
  getExternalLinks(result: shortlistedProfiles) {
    this.externalLinks = [];

    this.externalLinks.push({
      displayName: 'Open in CRM',
      imageIcon: 'assets/svg/table.svg',
      link: result.crmLink,
      disable: !result.crmLink,
    });

    this.externalLinks.push({
      displayName: 'Open in LinkedIn',
      icon: 'brand-linkedin',
      link: result.linkedIn,
      disable: !result.linkedIn,
    });

    this.externalLinks.push({
      displayName: 'Open in My7N',
      imageIcon: 'assets/svg/7n.svg',
      link: result?.my7NLink,
      disable: !result.my7NLink,
    });

    return this.externalLinks;
  }
}
