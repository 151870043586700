import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ILocationRangeForm } from './location-range-input/location-range-input.component';
import {
  ILocationRangeFilterResultValue,
  ILocationRangeInputAddressOption,
  ILocationRangeInputResult,
} from '../../../../../shared/interfaces/location';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Subscription,
} from 'rxjs';
import { option } from '../../../../../../interface/shared.interface';

@Component({
  selector: 'app-location-range-filter',
  templateUrl: './location-range-filter.component.html',
  styleUrls: ['./location-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRangeFilterComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Output() filterChanged = new EventEmitter<
    Array<ILocationRangeFilterResultValue>
  >();

  @Input() disabled = false;
  @Input() value: Array<option> | null = [];

  private fb = inject(FormBuilder);

  locationFilterFormGroup: FormGroup = new FormGroup<{
    locationRanges: FormArray;
  }>({
    locationRanges: this.fb.array([]),
  });

  filterValues = new BehaviorSubject<Array<ILocationRangeFilterResultValue>>(
    []
  );

  subs = new Subscription();

  DEFAULT_RANGE_VALUE = 10;

  get locationRanges(): FormArray {
    return this.locationFilterFormGroup.get('locationRanges') as FormArray;
  }

  get filters(): Array<FormGroup> {
    return this.locationRanges.controls as Array<FormGroup>;
  }

  ngOnInit() {
    // set new value to the filterValues subject when the form changes (take only valid values)
    this.subs.add(
      this.locationRanges.valueChanges
        .pipe(debounceTime(500))
        .subscribe((formArrayValues) => {
          const results = this.buildResultValuesFromFormArray(formArrayValues);
          this.filterValues.next(results);
        })
    );

    // emit the filter values only when the values are different
    this.subs.add(
      this.filterValues
        .pipe(
          distinctUntilChanged((prev, current) => {
            return this.compareFilters(prev, current);
          })
        )
        .subscribe((value) => {
          // emit only when the form is valid and has been used
          if (this.locationRanges.valid && this.locationRanges.touched) {
            this.filterChanged.emit(value);
          }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (this.disabled) {
        this.locationFilterFormGroup.disable();
      } else {
        this.locationFilterFormGroup.enable();
      }
    }

    if (changes['value']) {
      this.locationRanges.clear();
      if (
        changes['value'].currentValue &&
        changes['value'].currentValue.length > 0
      ) {
        // set the initial value
        this.initFilterValues(changes['value'].currentValue as Array<option>);
      } else {
        // add empty form group
        this.addLocationRangeFormGroup();
      }
    }

    this.locationFilterFormGroup.updateValueAndValidity();
  }

  addLocationRange() {
    if (!this.disabled) {
      // TODO: should be implemented at the button component level
      this.addLocationRangeFormGroup();
    }
  }

  removeLocationRange(index: number) {
    if (!this.disabled) {
      this.locationRanges.removeAt(index);
      this.filterChanged.next(
        this.buildResultValuesFromFormArray(this.locationRanges.value)
      );
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  showAddLocationRangeButton(): boolean {
    return (
      this.locationRanges.length === 0 || // no filters, button should be visible
      (this.locationRanges.length === 1 &&
        this.locationRanges.controls[0].value?.search &&
        this.locationRanges.controls[0].value?.search !== '' &&
        this.locationRanges.controls[0].status === 'VALID') || // one filter, button should be visible only if the filter is valid
      this.locationRanges.length > 1 // more than one filter, button should be visible
    );
  }

  showRemoveLocationRangeButton(): boolean {
    return this.locationRanges.length > 1;
  }

  private initFilterValues(initialValues: Array<option>) {
    initialValues.forEach((value) => {
      const convertedLocationRangeValue =
        this.getLocationRangeFilterValueFromString(value.displayName);

      const locationRangeForm = this.fb.group<ILocationRangeForm>({
        search: new FormControl<ILocationRangeInputAddressOption>(
          {
            name: convertedLocationRangeValue?.search ?? '',
            labelOption: {
              flagImgUrl: '',
              countryCode: '',
              countryName: '',
              address: convertedLocationRangeValue?.search ?? '',
            },
            value: {
              lat: convertedLocationRangeValue?.coordinates?.lat,
              lon: convertedLocationRangeValue?.coordinates?.lon,
            },
          },
          [LocationRangeValidator]
        ),
        range: new FormControl<number>(
          convertedLocationRangeValue?.range ?? this.DEFAULT_RANGE_VALUE,
          [Validators.required]
        ),
      });
      this.locationRanges.push(locationRangeForm);
    });
  }

  private getLocationRangeFilterValueFromString(
    displayName: string
  ): ILocationRangeFilterResultValue | null {
    if (displayName) {
      return {
        coordinates: {
          lat: parseFloat(displayName.split(',')[0]),
          lon: parseFloat(displayName.split(',')[1]),
        },
        range: parseFloat(displayName.split(',')[2]),
        search: displayName.split(',')[3],
      };
    }

    return null;
  }

  private compareFilters(
    prev: Array<ILocationRangeFilterResultValue>,
    current: Array<ILocationRangeFilterResultValue>
  ): boolean {
    if (prev.length !== current.length) {
      return false;
    }
    for (let i = 0; i < prev.length; i++) {
      if (
        prev[i].coordinates.lat !== current[i].coordinates.lat ||
        prev[i].coordinates.lon !== current[i].coordinates.lon ||
        prev[i].range !== current[i].range
      ) {
        return false;
      }
    }
    return true;
  }

  private addLocationRangeFormGroup() {
    const locationRangeForm = this.fb.group<ILocationRangeForm>({
      search: new FormControl<string>('', [LocationRangeValidator]),
      range: new FormControl<number>(this.DEFAULT_RANGE_VALUE, [
        Validators.required,
      ]),
    });
    this.locationRanges.push(locationRangeForm);
  }

  private buildResultValuesFromFormArray(
    formArrayValues: Array<ILocationRangeInputResult>
  ): Array<ILocationRangeFilterResultValue> {
    const results: Array<ILocationRangeFilterResultValue> = [];
    formArrayValues.forEach((inputResult: ILocationRangeInputResult) => {
      if (
        inputResult.search?.value &&
        inputResult.range !== null &&
        inputResult.range !== undefined
      ) {
        const result: ILocationRangeFilterResultValue = {
          search: inputResult.search.name,
          coordinates: { ...inputResult.search?.value },
          range: inputResult.range,
        };
        results.push(result);
      }
    });

    return results;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

export const LocationRangeValidator = (control: AbstractControl) => {
  const value = control.value;
  if (value === null || value === undefined || value === '') {
    return null;
  }
  if (!(typeof value === 'object' && 'labelOption' in value)) {
    return { invalidLocationRange: 'Invalid location' };
  }
  return null;
};
