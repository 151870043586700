import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import saveAs from 'file-saver';
import { HelperFunctionsService } from '../../../../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: ['./timeline-card.component.scss'],
})
export class TimelineCardComponent {
  @Input() timeline: any;
  expand: boolean;
  scheduledStart: Date;
  @ViewChild('info') info: ElementRef<HTMLElement>;
  expandable: boolean;
  constructor(
    private helperFunction: HelperFunctionsService,
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}

  // Lifecycle hook called after the component's view has been initialized
  ngAfterViewInit() {
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  // Handles the resizing of the component and adjusts the expandable state
  onResize() {
    if (this.info) {
      let maxHeight = 56;

      // Check if the first element child has the class 'appointment' and adjust maxHeight
      if (
        this.info.nativeElement.firstElementChild?.getAttribute('class') ===
        'appointment'
      ) {
        maxHeight = 68;
      }

      // Determine if the element's height exceeds the maxHeight and set expandable state
      if (this.info.nativeElement.offsetHeight > maxHeight) {
        this.expandable = true;
      } else {
        this.expandable = false;
      }
    }
  }

  // Downloads a file from a given annotation ID
  downloadUrl(event: Event, annotationId: string) {
    event.stopPropagation();
    this.platformService.getDownloadUrl(annotationId).then((response) => {
      if (response) {
        saveAs(response.data, this.timeline.filename);
      } else {
        this.contextService.notification.next({
          description: 'Oops, unable to download file',
          type: 'error',
          show: true,
          icon: 'info-circle-filled',
        });
      }
    });
  }

  // Returns the full name of the agent who created or modified the timeline
  getFullname() {
    const by = this.getAgent();
    if (!by) {
      return '';
    }
    return by.firstName + ' ' + by.middleName + ' ' + by.lastName;
  }

  // Returns the appropriate icon based on the type of timeline event
  getIcon(type: string) {
    if (type === 'TimelineEmail') {
      return 'mail';
    }
    if (type === 'TimelineAppointment') {
      return 'calendar';
    }
    return 'file';
  }

  // Converts a date string to a timestamp and returns a formatted date
  getDate(date: string) {
    const timestamp = Math.round(new Date(date).getTime() / 1000);
    return this.helperFunction.getDate(timestamp);
  }

  // Returns the month name of the scheduled start date of the timeline event
  getMonth() {
    this.scheduledStart = new Date(this.timeline.scheduledStart);
    return (
      this.scheduledStart.toLocaleString('default', { month: 'long' }) ?? ''
    );
  }

  // Returns the day of the month of the scheduled start date of the timeline event
  getDay() {
    this.scheduledStart = new Date(this.timeline.scheduledStart);
    return this.scheduledStart.getDate();
  }

  // Returns the time of the scheduled start date of the timeline event, optionally adjusted by a length
  getTime(length?: number) {
    this.scheduledStart = new Date(this.timeline.scheduledStart);
    if (length) {
      this.scheduledStart.setMinutes(this.scheduledStart.getMinutes() + length);
    }
    return (
      this.scheduledStart.getHours() +
      ':' +
      (this.scheduledStart.getMinutes() >= 10
        ? this.scheduledStart.getMinutes()
        : '0' + this.scheduledStart.getMinutes())
    );
  }

  // Determines the type of agent (created by or modified by) for the timeline event
  checkTypeAgent() {
    if (this.timeline?.modifiedBy) {
      return 'modified by';
    }
    if (this.timeline?.createdBy) {
      return 'created by';
    }
    return '';
  }

  // Returns the agent who created or modified the timeline event
  getAgent() {
    if (this.checkTypeAgent() === 'created by') {
      return this.timeline.createdBy;
    }
    if (this.checkTypeAgent() === 'modified by') {
      return this.timeline.modifiedBy;
    }
    return null;
  }
}
