<div class="filter-list" [class]="{ 'open-filter': modal, disable }">
  <ng-container *ngFor="let filter of filters; let i = index">
    <ng-container *ngIf="filter.hide !== true">
      <div class="filter-container">
        <div *ngIf="isFilterAvailable(filter.type)" class="filter body-large">
          <div class="filter-header">
            <div class="filter-title">
              <p class="bold">
                {{ filter.name }}
              </p>
              <app-badge *ngIf="filter.option.length > 0 && filter.showCounter" [label]="filter.option.length + ''"
                         color="neutral--Grey-900 textContrast amount"></app-badge>
            </div>
            <p *ngIf="filter.option.length > 0" (click)="clearFilter(i)" class="body-small textLink clear">
              Clear
            </p>
          </div>
        </div>
        <app-location-country-filter [disabled]="!matchId || disable"
                                     [selected]="filter.option"
                                     (selectedOption)="selectedFiltersFunc($event, 'addressCountry')"
                                     [countries]="filterData ? filterData.locationCountries : []"
                                     *ngIf="filter.type === 'addressCountry' && isFilterAvailable(filter.type) && filterData?.locationCountries?.length > 0">
        </app-location-country-filter>

        <app-location-range-filter *ngIf="filter.type === 'mapLocation' && isFilterAvailable(filter.type)"
                                   [value]="filter.option"
                                   [disabled]="!matchId || disable || filter.disable"
                                   (filterChanged)="locationFilterUpdated($event)">
        </app-location-range-filter>

        <app-country [selected]="filter?.option ?? []"
                     [disabled]="!matchId || disable || filter.disable"
                     (selectedOption)="selectedFiltersFunc($event, 'location')"
                     *ngIf="filter.type === 'location'">
        </app-country>

        <app-keywords [disabled]="!matchId || filter.disable || disable"
                      [selected]="filter.option"
                      (selectedOption)="selectedFiltersFunc($event, 'keywords')"
                      *ngIf="filter.type === 'keywords'">
        </app-keywords>

        <app-availability [selected]="availability"
                          [disabled]="disable || filter.disable"
                          (selectedOption)="setAvailability($event)"
                          *ngIf="filter.type === 'availability'">
        </app-availability>

        <mat-slide-toggle [disabled]="!matchId || disable || filter.disable"
                          [checked]="isChecked"
                          (change)="setWorkAs7N($event.checked)"
                          label="yes"
                          *ngIf="filter.type === 'workedwith7n'">
          <p class="body-small">Yes</p>
        </mat-slide-toggle>

        <app-statuses [disabled]="!matchId || disable"
                      [selected]="filter.option"
                      (selectedOption)="selectedFiltersFunc($event, 'status')"
                      [statuses]="filterData ? filterData.statuses : []"
                      *ngIf="filter.type === 'status'">
        </app-statuses>

        <app-tier-select [disabled]="!matchId || disable"
                         [selected]="filter.option"
                         (selectedOption)="selectedFiltersFunc($event, 'relationstatus')"
                         [tiers]="filterData ? filterData.tiers : []"
                         *ngIf="filter.type === 'relationstatus' && isFilterAvailable(filter.type)">
        </app-tier-select>

        <app-team-filter [disabled]="!matchId || disable"
                         [filter]="filter"
                         (selectedOptionTeam)="selectedFiltersFunc($event, 'team')"
                         (selectedOptionLocation)="selectedFiltersFunc($event, 'location')"
                         *ngIf="filter.type === 'teamfilter' && isFilterAvailable(filter.type)">
        </app-team-filter>
      </div>
    </ng-container>
  </ng-container>
</div>
