import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  matchType,
  matches,
  requestV3
} from '../../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-search-request',
  templateUrl: './search-request.component.html',
  styleUrls: ['./search-request.component.scss']
})
export class SearchRequestComponent {
  allRequests: requestV3[];
  rawOptions: requestV3[];
  timeout: NodeJS.Timeout;
  loading: boolean;
  manualRequestDescription =
    this.contextService.manualRequest?.description ?? '';
  @Input() type = 'request';
  @Input() selectedSearchType = 0;
  @Input() matchType: matchType[] = [
    {
      displayName: 'Request to Consultant',
      icon: 'file-text',
      modal: 'request',
      add: 'Find request',
    },
    {
      displayName: 'Manual Input',
      icon: 'user',
      modal: 'manual',
      add: 'Manual request',
    },
  ];
  @Input() selectedRequest: matches | requestV3 | undefined;
  @Input() toggleDropdown: boolean;
  @Output() selectrequest = new EventEmitter<requestV3>();
  @Output() manualRequest = new EventEmitter<string>();
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}

  input(event: Event) {
    const target = event.target as HTMLInputElement;
    this.manualRequest.emit(target.value);
  }

  select(selectRequest: requestV3 | undefined) {
    if (selectRequest && this.contextService.selectedConsultant) {
      const selected = selectRequest.enlistedProfiles.some(
        (x) => x.id === this.contextService.selectedConsultant.id
      );
      if (selected) {
        return;
      }
    }
    if (
      selectRequest?.isDescription ||
      this.type === 'longlist' ||
      selectRequest === undefined
    ) {
      this.selectrequest.emit(selectRequest);
      this.toggleDropdown = false;
    }
  }

  geSelectedRequest() {
    return this.selectedRequest as requestV3;
  }
  getRequests() {
    if (this.allRequests) {
      return;
    }

    this.loading = true;
    this.platformService.getRequests().then((response) => {
      this.allRequests = response.data;
      this.loading = false;
    });
  }
  chooseRequestType(event: number) {
    this.selectedSearchType = event;
  }
  searchRequest(value: string) {
    if (this.loading) {
      return;
    }

    if (value.length === 0) {
      this.allRequests = [];
    }
    if (!this.rawOptions) {
      this.rawOptions = this.allRequests;
    }

    if (value.length === 0) {
      this.allRequests = this.rawOptions;
      return;
    }
    this.allRequests = this.rawOptions.filter((x) => {
      let findbyTitle = false;
      if (x.title) {
        findbyTitle = x.title
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(value.toLowerCase().replace(/\s/g, ''));
      }
      const findbyCustomerName = x.customer.name
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(value.toLowerCase().replace(/\s/g, ''));
      if (!findbyTitle) {
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }
}

