import { Component, Input } from '@angular/core';
import { notification } from '../../../interface/shared.interface';
import { PlatformService } from '../../../services/platform/platform-service.service';
import { ContextService } from '../../../services/platform/context.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Input() message: notification;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {
    this.contextService.notification.subscribe((value: notification) => {
      this.message = value;
      if (value.show && value.type !== 'stayAlive') {
        setTimeout(() => {
          value.show = false;
          this.contextService.notification.next(value);
        }, 5000);
      }
      if (value.show1 && value.type !== 'stayAlive') {
        setTimeout(() => {
          value.show1 = false;
          this.contextService.notification.next(value);
        }, 5000);
      }
    });
  }
}
