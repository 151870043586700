import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '../../core-components/icons/icons.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ButtonComponent } from '../../core-components/buttons/button/button.component';
import { CtaComponent } from '../../core-components/buttons/cta/cta.component';
import { TabButtonsComponent } from '../../core-components/buttons/tab-buttons/tab-buttons.component';
import { BadgeComponent } from '../../core-components/badge/badge.component';
import { AvatarComponent } from '../../core-components/avatar/avatar.component';
import { InputComponent } from '../../core-components/input/input.component';
import { LoaderComponent } from '../../core-components/loader/loader.component';
import { CheckboxSelectComponent } from '../../core-components/checkboxSelect/checkboxSelect.component';
import { DialogComponent } from '../../core-components/dialog/dialog.component';
import { DropdownComponent } from '../../core-components/dropdowns/dropdown/dropdown.component';
import { DropdownSearchComponent } from '../../core-components/dropdowns/dropdown-search/dropdown-search.component';
import { DropdownTreeComponent } from '../../core-components/dropdowns/dropdown-tree/dropdown-tree.component';
import { DropdownV2Component } from '../../core-components/dropdowns/dropdown-v2/dropdown-v2.component';
import { SimpleDropdownComponent } from '../../core-components/dropdowns/simple-dropdown/simple-dropdown.component';
import { LogoComponent } from '../../core-components/logo/logo.component';
import { MobileSupportComponent } from '../../core-components/mobile-support/mobile-support.component';
import { MonthYearSelectComponent } from '../../core-components/month-year-select/month-year-select.component';
import { NavbarComponent } from '../../core-components/navbar/navbar.component';
import { ProgressBarsComponent } from '../../core-components/progress-bars/progress-bars.component';
import { SelectItemsComponent } from '../../core-components/select-items/select-items.component';
import { SliderComponent } from '../../core-components/slider/slider.component';
import { StepsComponent } from '../../core-components/steps/steps.component';
import { TierComponent } from '../../core-components/tier/tier.component';
import { RecruitedStepsComponent } from '../../core-components/tier/recruited-steps/recruited-steps.component';
import { TierContentComponent } from '../../core-components/tier/tier-content/tier-content.component';
import { TierStatusesComponent } from '../../core-components/tier/tier-statuses/tier-statuses.component';
import { WidgetsComponent } from '../../core-components/widgets/widgets.component';
import { ClickOutsideDirective} from '../../click-outside.directive';
import { RouterModule} from '@angular/router';
import { RequestLevel0Component } from '../../dashboard/right-content/components/request-level0/request-level0.component';
import { FiltersComponent } from '../../dashboard/right-content/components/filters/filters.component';
import { RequestFiltersComponent } from '../../dashboard/right-content/components/filters/request-filters/request-filters.component';
import { CountryComponent } from '../../dashboard/right-content/components/filters/request-filters/country/country.component';
import { AgentSelectComponent } from '../../dashboard/right-content/components/filters/request-filters/agent-select/agent-select.component';
import { TeamFilterComponent } from '../../dashboard/right-content/components/filters/shared-filter/team-filter/team-filter.component';
import { LocationComponent } from '../../dashboard/right-content/components/filters/shared-filter/team-filter/location/location.component';
import { TeamComponent } from '../../dashboard/right-content/components/filters/shared-filter/team-filter/team/team.component';
import { PrioritySelectComponent } from '../../dashboard/right-content/components/filters/request-filters/priority-select/priority-select.component';
import { SalesStatusSelectComponent } from '../../dashboard/right-content/components/filters/request-filters/sales-status-select/sales-status-select.component';
import { SwitchComponent } from '../../dashboard/right-content/components/switch/switch.component';
import { PageTitleComponent } from '../../dashboard/right-content/components/page-title/page-title.component';
import { Level1Component } from '../../dashboard/right-content/components/level1/level1.component';
import { ConsultantFiltersComponent } from '../../dashboard/right-content/components/filters/consultant-filters/consultant-filters.component';
import {
  LocationRangeInputComponent
} from '../../dashboard/right-content/components/filters/location-range-filter/location-range-input/location-range-input.component';
import { LocationRangeFilterComponent } from '../../dashboard/right-content/components/filters/location-range-filter/location-range-filter.component';
import { LocationCountryFilterComponent } from '../../dashboard/right-content/components/filters/location-country-filter/location-country-filter.component';
import { KeywordsComponent } from '../../dashboard/right-content/components/filters/consultant-filters/keywords/keywords.component';
import { AvailabilityComponent } from '../../dashboard/right-content/components/filters/consultant-filters/availability/availability.component';
import { StatusesComponent } from '../../dashboard/right-content/components/filters/consultant-filters/statuses/statuses.component';
import { TierSelectComponent } from '../../dashboard/right-content/components/filters/consultant-filters/tier-select/tier-select.component';
import { Level2Component } from '../../dashboard/right-content/components/level2/level2.component';
import { LonglistContentComponent } from '../../dashboard/right-content/components/match-modal/longlist-content/longlist-content.component';
import { Level0Component } from '../../dashboard/right-content/components/level0/level0.component';
import { SearchRequestComponent } from '../../dashboard/right-content/components/match-modal/component/search-request/search-request.component';
import { FindConsultantComponent } from '../../dashboard/right-content/components/match-modal/find-consultant/find-consultant.component';
import { MatchModalComponent } from '../../dashboard/right-content/components/match-modal/match-modal.component';
import { MessagingContentComponent } from '../../dashboard/right-content/components/match-modal/messaging-content/messaging-content.component';
import { AddNotesContentComponent } from '../../dashboard/right-content/components/match-modal/add-notes-content/add-notes-content.component';
import { consultantContentComponent } from '../../dashboard/right-content/components/match-modal/consultant-content/consultant-content.component';
import { ReplyMessageContentComponent } from '../../dashboard/right-content/components/match-modal/reply-message-content/reply-message-content.component';
import { AdvancedSearchComponent } from '../../dashboard/right-content/components/match-modal/advanced-search/advanced-search.component';
import { SearchConsultantComponent } from '../../dashboard/right-content/components/match-modal/component/search-consultant/search-consultant.component';
import { AddRequestComponent } from '../../dashboard/right-content/components/match-modal/add-request/add-request.component';
import { MatchingSmartTipComponent } from '../../dashboard/right-content/components/matching-smart-tip/matching-smart-tip.component';
import { MatchingKeywordsComponent } from '../../dashboard/right-content/components/matching-keywords/matching-keywords.component';
import { RequestLevel1Component } from '../../dashboard/right-content/components/request-level1/request-level1.component';
import { RequestLevel2Component } from '../../dashboard/right-content/components/request-level2/request-level2.component';



@NgModule({
  declarations: [
    AddNotesContentComponent,
    AddRequestComponent,
    AdvancedSearchComponent,
    AgentSelectComponent,
    AvailabilityComponent,
    AvatarComponent,
    BadgeComponent,
    ButtonComponent,
    CheckboxSelectComponent,
    consultantContentComponent,
    ConsultantFiltersComponent,
    CountryComponent,
    CtaComponent,
    DialogComponent,
    DropdownComponent,
    DropdownSearchComponent,
    DropdownTreeComponent,
    DropdownV2Component,
    FiltersComponent,
    FindConsultantComponent,
    InputComponent,
    KeywordsComponent,
    Level0Component,
    Level1Component,
    Level2Component,
    LoaderComponent,
    LocationComponent,
    LocationRangeInputComponent,
    LocationRangeFilterComponent,
    LocationCountryFilterComponent,
    LogoComponent,
    LonglistContentComponent,
    MatchModalComponent,
    MatchingKeywordsComponent,
    MatchingSmartTipComponent,
    MessagingContentComponent,
    MobileSupportComponent,
    MonthYearSelectComponent,
    NavbarComponent,
    PageTitleComponent,
    PrioritySelectComponent,
    ProgressBarsComponent,
    RecruitedStepsComponent,
    ReplyMessageContentComponent,
    RequestFiltersComponent,
    RequestLevel0Component,
    RequestLevel1Component,
    RequestLevel2Component,
    SalesStatusSelectComponent,
    SearchConsultantComponent,
    SearchRequestComponent,
    SelectItemsComponent,
    SimpleDropdownComponent,
    SliderComponent,
    StatusesComponent,
    StepsComponent,
    SwitchComponent,
    TabButtonsComponent,
    TeamComponent,
    TeamFilterComponent,
    TierComponent,
    TierContentComponent,
    TierSelectComponent,
    TierStatusesComponent,
    WidgetsComponent,

    //directives
    ClickOutsideDirective
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    FormsModule,
    IconsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    // modules
    ClipboardModule,
    FormsModule,
    IconsModule,
    MaterialModule,
    ReactiveFormsModule,

    // components
    AddNotesContentComponent,
    AddRequestComponent,
    AdvancedSearchComponent,
    AgentSelectComponent,
    AvailabilityComponent,
    AvatarComponent,
    BadgeComponent,
    ButtonComponent,
    CheckboxSelectComponent,
    consultantContentComponent,
    ConsultantFiltersComponent,
    CountryComponent,
    CtaComponent,
    DialogComponent,
    DropdownComponent,
    DropdownSearchComponent,
    DropdownTreeComponent,
    DropdownV2Component,
    FiltersComponent,
    FindConsultantComponent,
    InputComponent,
    KeywordsComponent,
    Level0Component,
    Level1Component,
    Level2Component,
    LoaderComponent,
    LocationComponent,
    LocationRangeInputComponent,
    LocationRangeFilterComponent,
    LocationCountryFilterComponent,
    LogoComponent,
    LonglistContentComponent,
    MatchModalComponent,
    MatchingKeywordsComponent,
    MatchingSmartTipComponent,
    MessagingContentComponent,
    MobileSupportComponent,
    MonthYearSelectComponent,
    NavbarComponent,
    PageTitleComponent,
    PrioritySelectComponent,
    ProgressBarsComponent,
    RecruitedStepsComponent,
    ReplyMessageContentComponent,
    RequestLevel0Component,
    RequestLevel1Component,
    RequestLevel2Component,
    RequestFiltersComponent,
    SalesStatusSelectComponent,
    SearchConsultantComponent,
    SearchRequestComponent,
    SelectItemsComponent,
    SimpleDropdownComponent,
    SliderComponent,
    StatusesComponent,
    StepsComponent,
    SwitchComponent,
    TabButtonsComponent,
    TeamComponent,
    TeamFilterComponent,
    TierComponent,
    TierContentComponent,
    TierSelectComponent,
    TierStatusesComponent,
    WidgetsComponent,

    //directives
    ClickOutsideDirective
  ]
})
export class SharedModule { }
