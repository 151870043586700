import {
  deepComputed,
  signalStoreFeature,
  withComputed,
  withState,
} from '@ngrx/signals';
import { computed } from '@angular/core';
import { IOption } from '../../interfaces/option';
import {
  IBusinessUnitAndOfficeLocation,
  IOfficeAndTerritory,
} from '../../interfaces/dictionaries';
import { FilterTypes } from '../../interfaces/filters';

export type BuAndOfficeLocationsDictionaryState = {
  _locations: IBusinessUnitAndOfficeLocation[];
};

export const initialBuAndOfficeLocationsDictionaryState: BuAndOfficeLocationsDictionaryState =
  {
    _locations: null,
  };

export function withBuAndOfficeLocationsDictionary() {
  return signalStoreFeature(
    withState({
      _buAndOfficeLocationsDictionary:
        initialBuAndOfficeLocationsDictionaryState,
    }),
    withComputed(
      ({
        _buAndOfficeLocationsDictionary: _buAndOfficeLocationsDictionary,
      }) => ({
        buAndOfficeLocationsDictionary: deepComputed(() => ({
          values: computed(() => {
            return _buAndOfficeLocationsDictionary._locations();
          }),
          valuesAsOptions: computed<IOption[]>(() => {
            if (_buAndOfficeLocationsDictionary._locations() === null) {
              return [];
            } else {
              const options: IOption[] = [];
              _buAndOfficeLocationsDictionary._locations().forEach((item) => {
                const option: IOption = {
                  id: item.businessUnit,
                  name: item.businessUnit,
                  type: FilterTypes.TEAM_LOCATION_OFFICE,
                  value: item.officeAndTerritories
                    .filter((x: any) => !x.hidden)
                    .map((x: any) => x.officeLocation),
                };
                options.push(option);
              });

              return options;
            }
          }),
          territoriesAsOptions: computed<IOption[]>(() => {
            if (_buAndOfficeLocationsDictionary._locations() === null) {
              return [];
            } else {
              const territories: IOption[] = [];
              _buAndOfficeLocationsDictionary._locations().forEach((item) => {
                item.officeAndTerritories.forEach(
                  (element: IOfficeAndTerritory) => {
                    const options: IOption[] = element.territories.map(
                      (x: any) => {
                        return {
                          id: x.name,
                          name: x.displayName,
                          type: FilterTypes.TEAM_NAME,
                        } as IOption;
                      }
                    );
                    territories.push(...options);
                  }
                );
              });

              // territories has duplicated records, we need to make them unique
              const uniqueTerritories = territories.filter(
                (v, i, a) => a.findIndex((t) => t.id === v.id) === i
              );

              return uniqueTerritories;
            }
          }),
        })),
      })
    )
  );
}
