import { Observable } from 'rxjs';

export interface ILocationRangeInputRangeOption {
  label: string;
  value: number;
}

export interface ICoordinate {
  lat: number;
  lon: number;
}

export interface ILocationRangeInputAddressOptionLabel {
  flagImgUrl: string;
  countryCode: string;
  countryName: string;
  address: string;
}

export interface ILocationRangeInputAddressOption {
  name: string;
  labelOption: ILocationRangeInputAddressOptionLabel;
  value: ICoordinate | null;
}

export interface ILocationRangeInputResult {
  search: ILocationRangeInputAddressOption;
  range: number;
}

export interface ILocationRangeFilterResultValue {
  search: string;
  coordinates: ICoordinate;
  range: number;
}

export interface ILocationService {
  getAddressSearchResults(
    address: string
  ): Observable<Array<ILocationRangeInputAddressOption>>;

  getAddressCoordinate(address: unknown): ICoordinate | null;
}
