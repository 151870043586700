<ng-container
  *ngIf="openDropdown"
  (clickOutside)="openDropdown = false"
  [myDirective]="openDropdown"
>
</ng-container>
<div class="request-level1">
  <div class="bundle-levels surface--1">
    <div class="level0">
      <app-avatar
        [customer]="selectedRequest.customer"
        class="small"
        [tiers]="true"
      ></app-avatar>
      <app-sale-request
        style="width: 100%"
        *ngIf="selectedSalesRequest"
        [selectedRequest]="selectedSalesRequest"
      ></app-sale-request>
      <div
        *ngIf="selectedRequest"
        class="info"
        [class]="{level0}"
        (click)="expand = !expand"
      >
        <div>
          <div class="title">
            <div class="bundle-title-create">
              <h3>{{ selectedRequest.title }}</h3>
              <p class="tiny textSecondary createdAt">
                {{ calcDay(selectedRequest.createdAt) }}
              </p>
            </div>
            <p *ngIf="!level0">{{ selectedRequest.customer.name }}</p>
          </div>
          <div class="cta">
            <ng-container
              *ngIf="params['slug'] === 'requests' && params['id']; else match"
            >
              <app-cta
                [class]="{ disable: !selectedRequest.isDescription }"
                [classes]="
                  'brand--Lightest textLink ' +
                  (!selectedRequest.isDescription ? 'disable' : '')
                "
                [icon]="'refresh'"
                (click)="openInternal($event, true)"
              >
              </app-cta>
            </ng-container>
            <ng-template #match>
              <app-cta
                *ngIf="freelanceAddedToRequest"
                [imgIcon]="'/assets/svg/list-check.svg'"
                classes="success--Lighter"
              >
              </app-cta>
              <app-cta
                classes="brand--Lightest textLink"
                [icon]="'file-text'"
                (click)="openInternal($event)"
              >
              </app-cta>
            </ng-template>
            <app-cta
              [classes]="' textLink'"
              [icon]="'dots'"
              (click)="toggleDropdown($event, selectedRequest.id)"
            >
            </app-cta>
            <div class="bundle-dropdown">
              <app-simple-dropdown
                *ngIf="openDropdown"
                (click)="toggleDropdown($event, '')"
                [request]="selectedRequest"
              ></app-simple-dropdown>
            </div>
          </div>
        </div>
        <div class="bundle-detail-expand">
          <div class="details body-small">
            <p *ngIf="level0">{{ selectedRequest.customer.name }}</p>
            <p *ngIf="!level0" class="">
              {{ getDisplayName(selectedRequest.country) }}
            </p>
            <li></li>
            <p class="">
              <span>Starting on </span>
              {{ ifEmptyReturnDash(selectedRequest.contractStartDate) }}
            </p>
            <li></li>
            <app-badge
              class="only-first-letter"
              [label]="selectedRequest.priority"
              [color]="getColorType(selectedRequest.priority)"
            ></app-badge>
            <ng-container *ngIf="!level0">
              <li></li>
              <p class="">
                <span>Deadline </span>
                {{ ifEmptyReturnDash(selectedRequest.deadline) }}
              </p>
              <li></li>

              <app-badge
                *ngIf="getSaleStatus(selectedRequest.highestSalesStatus)"
                [label]="
                  getSaleStatus(selectedRequest.highestSalesStatus).displayName
                "
                [color]="'neutral--Grey-300 textPrimary'"
                [icon]="getSaleStatus(selectedRequest.highestSalesStatus).icon"
              ></app-badge>
              <p class="body-large">
                {{
                  selectedRequest.enlistedProfiles
                    ? selectedRequest.enlistedProfiles.length
                    : 0
                }}
                <span>candidates</span>
              </p>
            </ng-container>
          </div>
          <i-tabler [name]="'chevron-' + (expand ? 'up' : 'down')"></i-tabler>
        </div>
      </div>
    </div>
    <app-request-level2
      [request]="selectedRequest"
      [requestLevel3]="level3"
      *ngIf="expand"
    ></app-request-level2>
  </div>
</div>
