import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
  faArrowRightFromBracket = faArrowRightFromBracket as IconProp
  @Input() openSidePanel = false
  @Output() callbackFunction = new EventEmitter()

  toggleSide() {
    this.callbackFunction.emit()
  }
}
