<div
  *ngIf="(type === 'field' || type === 'content-field') && !combined"
  class="field"
  [class]="{ disabled: disabled, 'content-field': type === 'content-field' }"
>
  <label *ngIf="label" class="label2">{{ label }}</label>
  <div
    *ngIf="type === 'content-field' && valueType === 'innerText'"
    [style]="styles"
    [class]="type"
  >
    <div
      class="body1"
      [innerText]="value"
      [attr.placeholder]="placeholder"
      [attr.contenteditable]="!disabled"
      (input)="selectedCallback.emit($event)"
    ></div>
  </div>
  <div
    *ngIf="type === 'content-field' && valueType === 'innerHtml'"
    [style]="styles"
    [class]="type"
  >
    <div
      class="body1"
      #myContent
      [innerHtml]="value"
      [attr.contenteditable]="!disabled"
      (input)="selectedCallback.emit($event)"
    ></div>
  </div>
  <div [class]="{ leadingIcon: leadingIcon }" *ngIf="type === 'field'">
    <i-tabler *ngIf="leadingIcon" [name]="leadingIcon"></i-tabler>
    <input
      (keypress)="onlyNumberKey($event.key)"
      [type]="inputType"
      (mousedown)="class === 'button' ? $event.preventDefault() : ''"
      (blur)="blur.emit($event)"
      (input)="inputValue($event)"
      (focus)="focus.emit($event)"
      [placeholder]="placeholder"
      [class]="{ trailingIcon: trailingIcon, button: class === 'button' }"
      class="body1"
      [value]="value"
      [disabled]="disabled"
      #myInput
      (paste)="paste($event)"
    />

    <i-tabler
      class="body-small clear"
      *ngIf="value && inputType === 'search'"
      [name]="'xbox-x'"
      (click)="clearInput()"
    ></i-tabler>
    <i-tabler
      *ngIf="trailingIcon && inputType !== 'search'"
      [name]="trailingIcon"
    ></i-tabler>
  </div>
</div>
<div *ngIf="inputBox.length > 0" class="box">
  <div *ngFor="let box of inputBox; let i = index">
    <input
      [type]="inputType"
      (blur)="blur.emit($event)"
      (focus)="focus.emit($event)"
      #box
      [name]="i"
      (keydown)="validate($event)"
      (input)="boxInput($event)"
      (paste)="paste($event)"
      class="title1"
      [placeholder]="placeholder"
      [value]="box > 0 ? box : ''"
      [disabled]="disabled"
    />
  </div>
</div>
<div *ngIf="combined && options" class="field combined" [class]="combined">
  <label [class]="{ disabled: disabled }" class="label2">{{ label }}</label>
  <div>
    <app-dropdown
      trailingIcon="chevron-down"
      *ngIf="combined === 'leading'"
      [selected]="option"
      [options]="options"
    ></app-dropdown>
    <input
      [type]="inputType"
      (blur)="blur.emit($event)"
      (focus)="focus.emit($event)"
      [placeholder]="placeholder"
      class="body1"
      [value]="value"
      [disabled]="disabled"
    />
    <app-dropdown
      (selectedCallback)="selectedCallback.emit($event)"
      trailingIcon="chevron-down"
      *ngIf="combined === 'trailing'"
      [selected]="option"
      [options]="options"
    ></app-dropdown>
  </div>
</div>
<div *ngIf="combined && !options" class="field combined" [class]="combined">
  <label [class]="{ disabled: disabled }" *ngIf="label" class="label2">{{
      label
    }}</label>
  <div>
    <i-tabler *ngIf="combined === 'leading'" [name]="icon"></i-tabler>
    <input
      [type]="inputType"
      (blur)="blur.emit($event)"
      (focus)="focus.emit($event)"
      [placeholder]="placeholder"
      class="body1"
      [value]="value"
      [disabled]="disabled"
    />
    <i-tabler *ngIf="combined === 'trailing'" [name]="icon"></i-tabler>
  </div>
</div>
