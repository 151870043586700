import { IConsultant, IConsultantSalesAgent } from '../../consultants/interfaces/consultant';
import { ClaimingStatus, FreelancerStatus } from './claiming';

export interface IRequestBase {
  contractEndDate: string;
  contractStartDate: string;
  country: string;
  createdAt: number;
  crmLink: string;
  customer: IRequestCustomer;
  deadline: string;
  description: string;
  etag: string;
  highestSalesStatus: IRequestHighestSalesStatus;
  hoursPerWeek: number;
  id: string;
  isDescription: boolean;
  keySkills: Array<string>;
  maxCostPerHour: IRequestMaxCostPerHour;
  object: string; // @TODO [techdebt] probably needs mapping
  ownedBy: IRequestOwner;
  priority: string;
  recruitmentAgents: Array<IConsultantSalesAgent>;
  summary: string;
  title: string;
  totalNumberOfEnlistedProfiles: number;
  typeOfWork: WorkType;
}


export interface IRequest extends IRequestBase {
  enlistedProfiles: Array<IRequestEnlistedProfileBase>;
}

export interface IRequestDetails extends IRequestBase {
  enlistedProfiles: Array<IRequestEnlistedProfile>;
}

export interface IRequestEnlistedProfileBase {
  firstName: string;
  id: string;
}

export interface IRequestEnlistedProfile extends IConsultant {
  addedBy: string;
  createdOn: number;
  declarationInformation: IRequestDeclarationInformation;
  modifiedOn: number;
  salesNotes: string;
  salesStatus: FreelancerStatus;
}

export interface IRequestCustomer {
  id: string;
  name: string;
}



export interface IRequestDeclarationInformation { // @TODO maybe in matching?
  status: ClaimingStatus;
  consultantId: string; // @TODO check for deletion
  freelancerDeclarationOfInterestId: string;
  statusChangedAt: number;
  createdAt: string; // @TODO check for deletion
}

export interface IRequestHighestSalesStatus {
  salesStatus: FreelancerStatus;
}

export interface IRequestMaxCostPerHour {
  amount: number;
  currencyIsoCode: string;
}

export interface IRequestOwner extends IConsultantSalesAgent {
  country: string;
  office: string;
  team: string;
}

export interface IRequestsPagination {
  count: number;
  hasNextPage: boolean;
  total: number;
}

export interface IRequestsResponse {
  elements: Array<IRequest>;
  pagination: IRequestsPagination;
}

export interface ProfileSelectionChange {
  selected: boolean;
  consultant: IRequestEnlistedProfile;
}

export enum WorkType {
  FULL_REMOTE = 'FullRemote',
  HYBRID = 'Hybrid',
  ON_SITE = 'Onsite',
}

export interface DataModifier {
  firstName: string;
  middleName?: string;
  lastName: string;
  imageUrl?: string;
  federatedIssuerAssignedId?: string;
  systemUserId?: string;
}
