import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option } from '../../../../../../interface/shared.interface';

@Component({
  selector: 'app-location-country-filter',
  templateUrl: './location-country-filter.component.html'
})
export class LocationCountryFilterComponent implements OnChanges {
  @Input() countries: Array<option> = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  options: option[] = [];
  @Input() disabled: boolean;

  selectOption(option: option) {
    const findex = this.selected.findIndex(
      (x) => x.id === option.id
    );
    if (findex !== -1) {
      this.selected.splice(findex, 1);
    } else {
      this.selected.push(option);
    }
    this.selectedOption.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.countries && changes['selected']) {
      this.options = [];
      this.countries.forEach((country: option) => {
        this.options.push({
          id: country.id,
          displayName: country.displayName,
          name: country.displayName,
          type: 'addressCountry',
        });
      });
    }
  }
}
