<div class="request-level2" *ngIf="request">
  <div class="left">
    <div class="bundle-for-mobile">
      <div class="general">
        <p class="body-small textSecondary bold">General</p>
        <div class="bundle-info body-small">
          <div>
            <i-tabler name="cash"></i-tabler>
            <p class="body-small">
              {{ request.maxCostPerHour.amount }}
              {{ request.maxCostPerHour.currencyIsoCode }}
            </p>
          </div>
          <div>
            <i-tabler name="hourglass-empty"></i-tabler>
            <p class="body-small">{{ getDate(request.contractEndDate) }}</p>
          </div>
          <div>
            <i-tabler name="clock-hour-3"></i-tabler>
            <p class="body-small">{{ request.hoursPerWeek ?? "-" }} hours</p>
          </div>
          <div>
            <i-tabler name="briefcase"></i-tabler>
            <p class="body-small">{{ request.typeOfWork }}</p>
          </div>
        </div>
      </div>

      <div class="agent">
        <p class="body-small textSecondary bold">Owner</p>
        <div class="bundle-info body-small">
          <div>
            <app-avatar [by]="request.ownedBy" class="xs"></app-avatar>
            <p class="body-small">
              {{ getFullname(request.ownedBy) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bundle-for-mobile">
      <div class="recruitment">
        <p class="body-small textSecondary bold">Recruitment agents</p>
        <div class="bundle-info body-small">
          <div *ngFor="let agent of request.recruitmentAgents">
            <app-avatar [by]="agent" class="xs"></app-avatar>
            <p class="body-small">
              {{ getFullname(agent) }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="request.enlistedProfiles" class="candidates">
        <p class="body-small textSecondary bold">Candidates</p>
        <div class="bundle-info body-small">
          <div>
            <div
              class="avatar"
              *ngFor="let candidate of request.enlistedProfiles | slice : 0 : 3"
            >
              <app-avatar [by]="candidate" class="xs"></app-avatar>
            </div>
            <p class="body-small">
              {{ request.enlistedProfiles.length }} candidates
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="bundle-for-mobile">
      <div class="keyRoles">
        <p class="body-small textSecondary bold">Key roles</p>
        <div class="bundle-info body-small">
          <div *ngFor="let skill of request.keySkills; let isLast = last">
            <p class="bold">{{ skill }}</p>
            <li *ngIf="!isLast"></li>
          </div>
        </div>
        <div class="summary">
          <p class="body-small textSecondary bold">Summary</p>
          <div class="bundle-info body-small">
            <p class="body-small truncated">{{ request.summary }}</p>
          </div>
        </div>
      </div>
      <div class="description">
        <p class="body-small textSecondary bold">Description</p>
        <div class="bundle-info body-small">
          <p
            #descriptionText
            class="body-small"
            [class]="{ truncated: !readMore }"
          >
            {{ request.description }}
          </p>

          <div class="readMore" *ngIf="truncated && requestLevel3">
            <app-button
              [label]="readMore ? 'Read Less' : 'Read More'"
              [trailingIcon]="'chevron-' + (readMore ? 'up' : 'down')"
              [class]="['link', 'body-small']"
              (click)="clickReadMore()"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
