export enum ClaimingLanguageCode {
  ENGLISH = 'ENG',
  DANISH = 'DAN',
  POLISH = 'POL'
}

export enum ClaimingStatus {
  PENDING_REPLY = 'PendingReply',
  INTERESTED = 'Interested',
  NOT_INTERESTED = 'NotInterested',
  NO_REPLY = 'NoReply',
}

export enum FreelancerStatus {
  ADDED = 'Added',
  CONTACTED = 'Contacted',
  CLAIMED = 'Claimed',
  CV_SENT = 'CVSent',
  INTERVIEW_SCHEDULED = 'InterviewScheduled',
  INTERVIEWED_BY_CUSTOMER = 'InterviewedByCustomer',
  CUSTOMER_APPROVED = 'CustomerApproved',
  SOLD = 'Sold',
  NOT_SOLD = 'NotSold',
}

export const FREELANCER_STATUS_ICONS = {
  [FreelancerStatus.ADDED]: 'checklist',
  [FreelancerStatus.CONTACTED]: 'send',
  [FreelancerStatus.CLAIMED]: 'check',
  [FreelancerStatus.CV_SENT]: 'clipboard-text',
  [FreelancerStatus.INTERVIEW_SCHEDULED]: 'calendar-clock',
  [FreelancerStatus.INTERVIEWED_BY_CUSTOMER]: 'calendar-user',
  [FreelancerStatus.CUSTOMER_APPROVED]: 'user-check',
  [FreelancerStatus.SOLD]: 'discount-check',
  [FreelancerStatus.NOT_SOLD]: 'square-rounded-x',
};

export interface IClaimingFreelancerStatus {
  value: FreelancerStatus;
  name: string;
  description: string;
  isAllowed: boolean;
  order: number;
  progress: number;
}

export interface IClaimingFreelancerStatusExpanded
  extends IClaimingFreelancerStatus {
  disable: boolean;
  displayName: string;
  leadingIcon: string;
  trailingInfo: string;
}

export interface IClaimingDeclarationInformation {
  status: ClaimingStatus;
  consultantId: string;
  freelancerDeclarationOfInterestId: string;
  statusChangedAt: number;
  createdAt: string;
}

export interface IClaimingDeclarationOfInterest {
  createdAt: number;
  description?: string;
  freelancers: IClaimingDeclarationOfInterestFreelancerInfo[];
  id: string;
  messageTemplate: string;
  notifyThroughSms: boolean;
  title: string;
}

export interface IClaimingDeclarationOfInterestMessage {
  consultantId?: string;
  createdAt: number;
  declaredAvailability: string;
  description: string;
  email: string;
  emailMessage: string;
  freelancerId?: string;
  fullName: string;
  id: string;
  mobilephone: string;
  notifyThroughSms: boolean;
  response: ClaimingDeclarationOfInterestMessageResponse;
  responseText: string;
  responseTime: number;
  status: ClaimingStatus;
  title: string;
  token: string;
}

export enum ClaimingDeclarationOfInterestMessageResponse {
  GOOD_MATCH_AVAILABLE = 'GoodMatchAndAvailable',
  GOOD_MATCH_NOT_AVAILABLE = 'GoodMatchButNotAvailable',
  NOT_GOOD_MATCH_AVAILABLE = 'NotGoodMatchAndAvailable',
  NOT_GOOD_MATCH_NOT_AVAILABLE = 'NotGoodMatchAndNotAvailable'
}

export interface IClaimingDeclarationOfInterestLanguagePayload {
  freelancers: string[];
  languageCode: ClaimingLanguageCode;
}

export interface IClaimingDeclarationOfInterestFreelancerInfo {
  freelancerId: string;
  mobilephone: string;
  email: string;
  fullName: string;
}
