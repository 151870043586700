<div class="messaging-content">
  <app-loader [loading]="loading()"></app-loader>
  @if(claiming()) {
  <div class="message-steps">
    @for(step of steps; track step; let i = $index; let isLast = $last) {
    <div>
      @if(selectedStep() <= i) {
        <app-badge [amount]="i + 1"
                   [color]="'amount tiny ' + (selectedStep() === i ? 'brand--Dark textContrast' : 'brand--Light textSecondary')"></app-badge>
        }
        @else {
          <app-badge [icon]="{ type: 'icon', icon: 'check' }"
                    [color]="'amount tiny brand--Dark textContrast'"></app-badge>
        }
        @if(!isLast) {
          <span [ngClass]="{ done: selectedStep() > i }"></span>
        }
    </div>
    }
  </div>

  @switch(selectedStep()) {
    @case(0) {
      <div class="header">
        <div>
          <h3>Send to</h3>
          <app-badge [amount]="claiming().freelancers.length" [color]="'amount tiny textSecondary neutral--Grey-300'"></app-badge>
        </div>
        <div class="sms-notificaton">
          <app-select-items label="Also send an SMS notification"
                            (checked)="setSmsNotification(!$event)"
                            type="checkbox"
                            [isChecked]="claiming().notifyThroughSms"></app-select-items>
        </div>
      </div>
      <div class="content step0">
        <div class="consultants">
          @for(consultant of claiming().freelancers; track consultant.freelancerId) {
            <div class="consultant-item">
              <div class="profile">
                <div class="image label1">{{ consultant.fullName[0] }}</div>
                <div class="label1 fullName">{{ consultant.fullName }}</div>
              </div>
              <div class="contact">
                <app-input [disabled]="true"
                           [value]="consultant.email"
                           class="contact-email"
                           placeholder="Email"></app-input>
                <app-input (inputs)="setPhoneNumber($event, consultant.freelancerId)"
                           inputType="tel"
                           [value]="beautifyNumber(consultant.mobilephone)"
                           class="contact-phone"
                           placeholder="Phone number"></app-input>
              </div>
            </div>
          }
        </div>
      </div>
    }
    @case(1) {
      <div class="content step1">
        <h3>Request</h3>
        <app-input (inputs)="setTitle($event)"
                   label="Title"
                   [value]="claiming().title"
                   class="title"></app-input>
        <app-input (selectedCallback)="setDescription($event)"
                   label="Description"
                   [value]="claimingDescription()"
                   type="content-field"
                   valueType="innerText"
                   class="description"></app-input>
      </div>
    }
    @case(2) {
      <div class="content step2">
        <div class="bundle-reset-lang">
          <div>
            <h3>Message</h3>
            <p class="tiny textSecondary">
              To change the language of your message, select it from the dropdown.
            </p>
          </div>
          <div>
            <app-dropdown trailingIcon="chevron-down"
                          [selected]="selected()"
                          [options]="language"
                          (selectedCallback)="selectOption($event)"
                          [styles]="'width: fit-content'"></app-dropdown>
          </div>
        </div>
        <app-input [changeValue]="changeValue()"
                   (selectedCallback)="setMessage($event)"
                   styles="height: 380px"
                   valueType="innerHtml"
                   [value]="messageTemplate()"
                   type="content-field"
                   class="message">
        </app-input>
      </div>
    }
  }
}
</div>
