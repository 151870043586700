import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  delay,
  filter,
  iif,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { LocationFacadeService } from '../../../../../../shared/services/location/location-facade.service';
import {
  ILocationRangeInputAddressOption,
  ILocationRangeInputRangeOption,
} from '../../../../../../shared/interfaces/location';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface ILocationRangeForm {
  search: FormControl<string | ILocationRangeInputAddressOption | null>;
  range: FormControl<number | null>;
}

export const LocationRangeOptions: Array<ILocationRangeInputRangeOption> = [
  {
    label: '+10 km',
    value: 10,
  },
  {
    label: '+25 km',
    value: 25,
  },
  {
    label: '+50 km',
    value: 50,
  },
  {
    label: '+100 km',
    value: 100,
  },
];

@Component({
  selector: 'app-location-range-input',
  templateUrl: './location-range-input.component.html',
  styleUrls: ['./location-range-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRangeInputComponent
  implements OnInit, OnChanges, OnDestroy
{
  locationOptions$: Observable<Array<ILocationRangeInputAddressOption>>;
  loading$ = new BehaviorSubject<boolean>(false);
  mode$ = new BehaviorSubject<'search' | 'range' | null>(null);

  @Input() form: FormGroup<ILocationRangeForm>;
  @Input() disabled = false;

  private locationFacade = inject(LocationFacadeService);

  @ViewChild(MatAutocompleteTrigger)
  autoComplete: MatAutocompleteTrigger;

  @ViewChild('inputRangeSearch') inputRangeSearch: ElementRef;
  @ViewChild('inputSearch') inputSearch: ElementRef;

  LocationRangeOptions = LocationRangeOptions;

  scrollEvent = () => {
    if (this.autoComplete.panelOpen) {
      this.autoComplete.updatePosition();
    }
  };

  subs = new Subscription();

  ngOnInit(): void {
    this.setScrollListener(); // TODO: something prevents HostListener from firing on scroll, probably global styles, needs to be investigated

    // listen for search value changes and fetch location options, set loading state along the way
    this.locationOptions$ = this.form.controls.search.valueChanges.pipe(
      debounceTime(500),
      filter((value): value is string => typeof value === 'string'),
      tap(() => this.loading$.next(true)),
      map((value) => (value as string)?.trim()?.replace(/\s+/g, ' ')), // Clean value from multiple spaces and trim
      switchMap((value) =>
        iif(
          () => !!value && value.length > 2,
          this.locationFacade.getAddressSearchResults(value ?? '').pipe(
            delay(500), // loading spinner should be visible for at least 500ms to avoid flickering
            tap(() => this.loading$.next(false))
          ),
          of([]).pipe(tap(() => this.loading$.next(false)))
        )
      )
    );

    // set mode to range if search value is already set and valid
    this.subs.add(
      this.mode$.pipe(take(1)).subscribe((mode) => {
        if (
          mode === null &&
          this.form.value.search !== null &&
          this.form.value.search !== ''
        ) {
          this.mode$.next('range');
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (this.disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
    this.form.updateValueAndValidity();
  }

  displayLabel(option: ILocationRangeInputAddressOption): string {
    return option?.name;
  }

  setMode(mode: 'search' | 'range') {
    if (
      mode === 'range' &&
      this.form.valid === true &&
      this.form.controls.search.value
    ) {
      this.mode$.next('range');
    } else if (
      mode === null &&
      this.form.value.search !== null &&
      this.form.value.search !== ''
    ) {
      this.mode$.next('range');
    } else {
      this.mode$.next('search');
    }
  }

  getSearchValue(): ILocationRangeInputAddressOption | null {
    return this.form.controls.search.value as ILocationRangeInputAddressOption;
  }

  private setScrollListener() {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    window.removeEventListener('scroll', this.scrollEvent, true);
  }
}
