<div class="replyMessage">
  <app-loader [loading]="loading"></app-loader>
  <ng-container *ngIf="reply">
    <div class="repond">
      <div class="left-content">
        <div class="profile">
          <div class="image">
            <app-badge
              [color]="getColor(reply.status) + ' medium '"
              [icon]="getStatus(reply.status)"
            ></app-badge>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right-content">
        <div *ngIf="['PendingReply'].includes(reply.status)" class="top">
          <p class="label2">Waiting for reply</p>
          <p class="body2 date"></p>
        </div>
        <div *ngIf="['NoReply'].includes(reply.status)" class="top">
          <p class="label2">No reply from {{ reply.fullName }}</p>
          <p class="body2 date"></p>
        </div>
        <div *ngIf="['Interested', 'NotInterested'].includes(reply.status)">
          <div class="message">
            <div class="sentInfo">
              <p class="tiny">{{ getDate(reply.responseTime) }}</p>
              <p class="tiny">Sent by {{ reply.fullName }}</p>
            </div>
            <div>
              <p class="label2">Match:</p>
              <p class="body2">{{ match(reply.response) }}</p>
            </div>
            <div *ngIf="reply.declaredAvailability">
              <p class="label2">When would you be available:</p>
              <p class="body2">
                {{ getDate(reply.declaredAvailability, "iso") }}
              </p>
            </div>
            <div *ngIf="reply.responseText">
              <p class="label2">Why is the match not good:</p>
              <p class="body2">{{ reply.responseText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agent-message">
      <div class="left-content">
        <div class="profile">
          <app-badge
            [icon]="{ type: 'icon', icon: 'mail' }"
            [color]="'textLink brand--Light medium'"
          ></app-badge>
        </div>
      </div>
      <div class="right-content">
        <div class="message">
          <p class="tiny">Sent: {{ getDate(reply.createdAt) }}</p>
          <div
            class="body2"
            *ngIf="reply"
            [innerHTML]="reply.emailMessage"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
