import {
  deepComputed,
  signalStoreFeature,
  withComputed,
  withState,
} from '@ngrx/signals';
import { computed } from '@angular/core';
import { IOption } from '../../interfaces/option';

export type CrmStatusesDictionaryState = {
  _crmStatuses: string[];
};

export const initialCrmStatusesDictionaryState: CrmStatusesDictionaryState =
  {
    _crmStatuses: null,
  };

export function withCrmStatusesDictionary() {
  return signalStoreFeature(
    withState({
      _crmStatusesDictionary: initialCrmStatusesDictionaryState,
    }),
    withComputed(({ _crmStatusesDictionary }) => ({
      crmStatusesDictionary: deepComputed(() => ({
        values: computed(() => _crmStatusesDictionary._crmStatuses()),
        valuesSortedAsc: computed(() => {
          if (_crmStatusesDictionary._crmStatuses() === null) {
            return [];
          } else {
            return _crmStatusesDictionary
              ._crmStatuses()
              .sort((a, b) => a.localeCompare(b));
          }
        }),
        valuesAsOptions: computed<IOption[]>(() => {
          if (_crmStatusesDictionary._crmStatuses() === null) {
            return [];
          } else {
            return _crmStatusesDictionary
              ._crmStatuses()
              .map((crmStatus) => ({
                id: crmStatus,
                name: crmStatus,
              }));
          }
        }),
      })),
    }))
  );
}
