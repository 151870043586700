import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  OnInit,
  output, signal
} from '@angular/core';
import {MatomoTracker} from 'ngx-matomo-client';
import {
  declarationOfInterest,
  freeStatus,
  requestV3Level3,
  shortlistedProfiles
} from '../../../../../../interface/shared.interface';
import {HelperFunctionsService} from '../../../../../../services/helperFunctions/helper-functions.service';
import {ActionTypes, ContextService, RerenderTypes} from '../../../../../../services/platform/context.service';
import {PlatformService} from '../../../../../../services/platform/platform-service.service';
import {Subscription} from 'rxjs';
import {ProfileSelectionChange} from '../../../../../shared/interfaces/requests';
import {SnackBarService, SnackBarTypes} from '@my7n/ui';
import {FreelancerStatus} from '../../../../../shared/interfaces/claiming.interface';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestComponent implements OnInit, OnDestroy {
  requestId = input.required<string>();
  loading = input<boolean>( true);
  loadingStateChange = output<boolean>();

  bulkSelect = signal<shortlistedProfiles[]>([]);
  selectedRequest = signal<requestV3Level3>(null);
  firstTimeLoading = signal<boolean>(true);

  consultantStatus = signal<freeStatus[]>([]);
  enlistedProfiles = signal<shortlistedProfiles[]>([]);
  showNotSold = signal<boolean>(true);
  hideSelectNotification = signal<boolean>(false);

  private subscriptions$ = new Subscription();
  constructor(
    private platformService: PlatformService,
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.subscriptions$.add(
      this.contextService.reRender.subscribe((val) => {
        if (val.name === RerenderTypes.LongShortList) {
          this.getRequest(val.data.affectedId);
        }
      })
    );

    this.subscriptions$.add(
      this.contextService.actions.subscribe((val) => {

        switch (val.action) {
          case ActionTypes.ClaimingSentMessage: {
            this.bulkSelect.set([]);

            // single & multiple claiming - update consultant status to Contacted
            if (val.data.affectedConsultants?.length) {
              for (const consultant of val.data.affectedConsultants) {
                const profileData = this.getProfileById(consultant.freelancerId);

                if (profileData.salesStatus === FreelancerStatus.ADDED) {
                  this.platformService.updateConsultantStatus(this.requestId(), consultant.freelancerId, FreelancerStatus.CONTACTED)
                    .then(() => {
                      console.log(`Consultant status updated ${consultant.freelancerId}`);
                    });
                }
              }
            }

            this.contextService.reRender.next({
              name: RerenderTypes.LongShortList,
              data: {affectedId: val.data.affectedId}
            });
            break;
          }
          case ActionTypes.CloseMatchModal:
            this.hideSelectNotification.set(false);
            break;
          case ActionTypes.ReplyMessage: {
            this.contextService.openMatchModal = 'replyMessage';
            break;
          }
        }
      })
    );

    this.getRequest();
  }

  openBulkMessaging() {
    this.contextService.openMatchModal = 'messaging';
    const freelancers: string[] = [];
    this.bulkSelect().forEach((val: shortlistedProfiles) => {
      freelancers.push(val.id);
    });
    this.platformService
      .declarationOfInterest(this.selectedRequest().id, 'post', undefined, {
        freelancers,
        languageCode: '',
      })
      .then((response: declarationOfInterest) => {
        this.hideSelectNotification.set(true);
        this.contextService.claiming = response;
        this.contextService.actions.next({ action: ActionTypes.Messaging });
      })
      .catch(() => {
        this.snackBarService.open({
          message: 'Failed to get declaration of interest',
          type: SnackBarTypes.ErrorAlt
        });
      });
  }

  handleNotSold(value: boolean) {
    this.showNotSold.set(value);

    if (this.showNotSold()) {
      // show all profiles
      this.enlistedProfiles.set(this.selectedRequest().enlistedProfiles);
    } else {
      // hide not sold profiles
      this.enlistedProfiles.update(() => {
        return this.selectedRequest().enlistedProfiles.filter(
          (x) => x.salesStatus !== 'NotSold'
        );
      });
    }
  }

  getRequest(scrollId?: string) {
    if (this.loading() === false) {
      this.loadingStateChange.emit(true);
    }
    this.platformService
      .requestId(this.requestId(), 'v3')
      .then(async (response: any) => {
        if (!response) {

          // @TODO: is it needed?
          this.snackBarService.open({
            message: 'Failed to get request',
            type: SnackBarTypes.ErrorAlt,
          });
        }
        this.selectedRequest.set(response);

        // sort enlisted profiles by modified date
        this.selectedRequest().enlistedProfiles.sort(
          (a: any, b: any) => b.modifiedOn - a.modifiedOn
        );

        this.enlistedProfiles.set(this.selectedRequest().enlistedProfiles);

        // must be called in case if request list is refreshed and not sold profiles are hidden
        this.handleNotSold(this.showNotSold());

        this.contextService.selectedRequest = this.selectedRequest();

        await this.platformService.getConsultantStatus().then((response: any) => {
          this.consultantStatus.set(response);
          this.consultantStatus().map((x) => {
            switch (x.name) {
              case FreelancerStatus.ADDED:
                x.leadingIcon = 'checklist';
                break;
              case FreelancerStatus.CONTACTED:
                x.leadingIcon = 'send';
                break;
              case FreelancerStatus.CLAIMED:
                x.leadingIcon = 'check';
                break;
              case FreelancerStatus.CV_SENT:
                x.leadingIcon = 'clipboard-text';
                break;
              case FreelancerStatus.INTERVIEW_SCHEDULED:
                x.leadingIcon = 'calendar-clock';
                break;
              case FreelancerStatus.INTERVIEWED_BY_CUSTOMER:
                x.leadingIcon = 'calendar-user';
                break;
              case FreelancerStatus.CUSTOMER_APPROVED:
                x.leadingIcon = 'user-check';
                break;
              case FreelancerStatus.SOLD:
                x.leadingIcon = 'discount-check';
                break;
              case FreelancerStatus.NOT_SOLD:
                x.leadingIcon = 'square-rounded-x';
                x.disable = true;
                break;
            }

            // Setting display name
            if (x.name === FreelancerStatus.CV_SENT) {
              x.displayName = 'CV Sent';
            } else {
              // Formatting display name - Split by capital letters and join with space
              x.displayName = x.name.split(/(?=[A-Z])/).join(' ');
            }

            return x;
          });
        })
        .catch(() => {
          this.snackBarService.open({
            message: 'Failed to get request statuses',
            type: SnackBarTypes.ErrorAlt,
          });
        });

        this.loadingStateChange.emit(false);
        this.firstTimeLoading.set(false);

        if (scrollId) {
          setTimeout(() => {
            const elementPositionY =
              document.getElementById(scrollId)?.offsetTop;
            if (elementPositionY !== undefined) {
              document.getElementById('request_view').scroll({
                top: elementPositionY,
                behavior: 'smooth',
              });
            }
          }, 0);
        }
      })
      .catch(() => {
        this.snackBarService.open({
          message: 'Failed to get request',
          type: SnackBarTypes.ErrorAlt,
        });
      });
  }

  addConsultant() {
    this.matomoTracker.trackEvent('Request', 'Open modal - Add consultant');
    this.contextService.selectedConsultant = <any>undefined;
    this.contextService.openMatchModal = 'addconsultant';
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  onSelectionChange(data: ProfileSelectionChange) {
    if (data.consultant?.declarationInformation) {
      return;
    }

    const findex = this.bulkSelect().findIndex((x) => x === data.consultant);

    if (data.selected && findex === -1) {
      this.bulkSelect.update((selected) => {
        return [...selected, data.consultant];
      });
    } else if (!data.selected && findex !== -1) {
      this.bulkSelect.update((selected) => {
        return selected.filter((x) => x !== data.consultant);
      });
    }
  }

  openInCrm(event: Event) {
    event.stopPropagation();
    const link = this.selectedRequest().crmLink;

    if(!link) {
      console.warn('[RequestComponent] No CRM link found');
      return;
    }

    this.matomoTracker.trackEvent('Matching', 'External Link - View in CRM');
    window.open(link, '_blank');
  }

  openRtcMatch(event: Event) {
    event.stopPropagation();
    this.helperFunctions.route(['matching/rtc/' + this.selectedRequest().id]);
  }

  /**
   * Get details of enlisted profile by id
   * @param contactId
   */
  getProfileById(contactId: string): shortlistedProfiles {
    return this.selectedRequest().enlistedProfiles.find((x) => x.id === contactId);
  }
}

