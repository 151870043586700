import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  gameInstance,
  jobHistory,
  ProProfileSlider,
  routeParams,
  Scores,
  GenomeItem,
  workingSweetspot,
  professionalProfiles,
  list
} from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import {
  faArrowLeft,
  faCircleQuestion,
  faGlobe,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { faGlobe as faGlobeOther } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import languages from '../../../../../assets/languages';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { ApiService } from '../../../../../services/api/api.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
})
export class CandidateComponent implements OnInit, OnDestroy {
  selectedConsultant: GenomeItem;
  navs = this.contextService.navs.dashboard;
  breadCrumbs!: routeParams;
  faCircleQuestion = faCircleQuestion;
  loading = true;
  faArrowLeft = faArrowLeft;
  faSpinner = faSpinner;
  faLinkedin = faLinkedin;
  faGithub = faGithub;
  faGlobe = faGlobe;
  faGlobeOther = faGlobeOther;
  languages = languages.list;

  levelOfExperience = [
    {
      title: 'Experienced',
      acitve: false,
      description:
        'Experienced is when you have some experience within your field/role',
    },
    {
      title: 'Senior',
      acitve: false,
      description:
        'Senior is when you are very experienced within your field/role',
    },
    {
      title: 'Lead',
      acitve: false,
      description:
        'Lead is when you are very experience within you field and can set directions for projects',
    },
    {
      title: 'Head',
      acitve: false,
      description:
        'Head is when you are very experienced and can manage teams and define the project strategy',
    },
  ];
  dialogType = '';
  result: gameInstance = { scores: [], gameCode: '' };
  dialog = false;
  mobile = window.innerWidth < 900;
  sliderOptions: ProProfileSlider[] = [
    { value: -2, legend: 'Not important' },
    { value: -1 },
    { value: 0 },
    { value: 1 },
    { value: 2, legend: 'Very important' },
  ];
  // Radar
  radarChartOptions: ChartConfiguration['options'] = {
    borderColor: '#0007F5',
    color: 'white',
    scales: {
      r: {
        beginAtZero: true,
        max: 3,
        min: 0,

        ticks: {
          font: {
            size: 10,
          },
          precision: 0,
          callback: function (label, index) {
            // when the floored value is the same as the value we have a whole number
            if (index === 1) {
              return 'Difficulty';
            }
            if (index === 2) {
              return 'Avragely';
            }
            if (index === 3) {
              return 'Successfully';
            }
            return '';
          },
        },
        pointLabels: {
          font: {
            size: 16,
          },
          color: '#222222',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  radarChartOptionsMobile: ChartConfiguration['options'] = {
    borderColor: '#0007F5',
    color: 'white',
    scales: {
      r: {
        beginAtZero: true,
        max: 3,
        min: 0,

        ticks: {
          font: {
            size: 10,
          },
          precision: 0,
          callback: function (label, index) {
            // when the floored value is the same as the value we have a whole number
            if (index === 1) {
              return 'Difficulty';
            }
            if (index === 2) {
              return 'Avragely';
            }
            if (index === 3) {
              return 'Successfully';
            }
            return '';
          }, // display: false,
        },
        pointLabels: {
          font: {
            size: 10,
          },
          color: '#222222',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  radarChartLabels: string[] = [];

  radarChartData: ChartData<'radar'> = {
    labels: this.radarChartLabels,

    datasets: [
      {
        data: [],
        borderWidth: 2,
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointBorderColor: ' #0007F5',
        radius: window.innerWidth < 900 ? 4 : 6,
        hoverRadius: window.innerWidth < 900 ? 4 : 6,
        // fill: false,
      },
    ],
  };
  star = '';
  radarChartType: ChartType = 'radar';
  months: string[] = this.helperFunctions.listOfOptions('stringMonth');

  private subscriptions$ = new Subscription();

  // Constructor to initialize services and set up route parameters and data subscriptions
  constructor(
    public contextService: ContextService,
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private apiService: ApiService,
    private router: Router,
    private matomoTracker: MatomoTracker,
    public helperFunctions: HelperFunctionsService
  ) {
    // Swap function to reorder elements in an array
    const swap = (array: [string, number][], x: number, y: number) => {
      const b = array[x];
      array[x] = array[y];
      array[y] = b;
      return array;
    };

    // Subscribe to route parameters and fetch data
    this.subscriptions$.add(
      this.activatedRoute.params.subscribe((val) => {
        this.breadCrumbs = val as routeParams;
        this.platformService.getData(val['slug'], val['id'])
        .catch((e) => {
          console.error('[CandidateComponent] Failed to get data', e);

          //@TODO enable when fixed endpoints on candidates
            // this.snackBarService.open({
            //   message: `Failed to get data`,
            //   type: SnackBarTypes.ErrorAlt
            // });
        });
      })
    );

    // Subscribe to data loaded event and process consultant data
    this.subscriptions$.add(
      this.contextService.dataLoaded.subscribe((state) => {
        if (state === 'success') {
          this.selectedConsultant = this.contextService.consultantData;
          if (this.selectedConsultant.genome?.genome.expectedIncome) {
            this.star = this.selectedConsultant.genome.genome.expectedIncome
              .hourlyRateCalculated
              ? '*'
              : '';
            const askingPrice =
              this.selectedConsultant.genome.genome.expectedIncome.hourlyRate;
            const marketPrice = (askingPrice as number) * 2;
            const quotable = marketPrice - (marketPrice as number) * 0.33;
            this.selectedConsultant.genome.genome.expectedIncome.quotable =
              quotable.toFixed(0);
            this.selectedConsultant.genome.genome.expectedIncome.market =
              marketPrice.toFixed(0);
          }

          // Update consultant status based on conditions
          if (
            [0].includes(this.selectedConsultant.genome?.genome.status) &&
            this.selectedConsultant.genome?.genome?.labels.includes(
              '7NConsultant'
            )
          ) {
            this.selectedConsultant.genome.genome.status = 1;
          }

          // Process and map consultant secret code data to radar chart labels and data
          if (this.selectedConsultant.genome?.secretCode) {
            type StringArray = [string, number] extends (infer T)[] ? T[] : never;
            let sec = Object.entries(this.selectedConsultant.genome.secretCode);

            const findex = sec.findIndex((x: StringArray) =>
              x.some((z: string | number) => z === 'highPerformanceUnderStress')
            );
            sec = swap(sec, 0, findex);
            const findex1 = sec.findIndex((x: StringArray) =>
              x.some((z) => z === 'diplomaticAuthority')
            );
            sec = swap(sec, 3, findex1);

            for (const [key, value] of sec) {
              if (`${key}` === 'insightsIntoOwnStrengthsAndWeaknesses') {
                this.radarChartLabels.push('Insights');
              }
              if (`${key}` === 'highPerformanceUnderStress') {
                this.radarChartLabels.push('Performance');
              }
              if (`${key}` === 'holisticProjectUnderstanding') {
                this.radarChartLabels.push('Holistic');
              }
              if (`${key}` === 'diplomaticAuthority') {
                this.radarChartLabels.push('Diplomatic');
              }
              if (`${key}` === 'empathy') {
                this.radarChartLabels.push('Empathy');
              }
              if (`${key}` === 'socialCapacity') {
                this.radarChartLabels.push('Social');
              }

              this.radarChartData.datasets[0].data.push(JSON.parse(`${value}`));
            }
          }
          this.loading = false;
        }
      })
    );
  }

  // Toggles the dialog visibility
  toogleDialog() {
    this.dialog = false;
  }

  // Formats a category value by adding spaces between capitalized words
  getCategory(value: string) {
    if (!value) {
      return '-';
    }
    return value.match(/[A-Z][a-z]+/g)?.join(' ');
  }

  // Returns the full country name based on the country code
  getCountry(value: string) {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return value ? regionNames.of(value) : value;
  }

  // Toggles the dialog visibility, sets the dialog type, and assigns the result and score
  readMore(type: string, result: gameInstance, score: Scores) {
    this.dialog = !this.dialog;
    this.dialogType = type;

    result.scores = [score];
    this.result = result;
  }
  // Returns the sweet spot value for a given dimension index
  getSweetSpot(index: number) {
    const value = 'dimension' + index;
    const key = value as keyof workingSweetspot;
    return this.selectedConsultant.genome.genome.workingSweetspot[key] ?? 0;
  }

  // Formats a date string to show the month and year
  getMonthYear(date: string) {
    const d = new Date(date);
    return this.months[d.getMonth()] + ', ' + d.getFullYear();
  }

  // Retrieves the value from the job history object based on the provided key
  getValue(value: string, object: jobHistory) {
    const key = value as keyof jobHistory;
    return object[key];
  }

  // Formats a date to a readable string
  getDate(date: number) {
    return date ? this.helperFunctions.getDate(date) : '-';
  }

  // Converts a string to lowercase
  toLowerCase(value: unknown | string) {
    const newValue = value as string;
    return newValue.toLowerCase();
  }

  // Sets the status of the selected consultant based on the provided value
  async setSatus(value: string) {
    if (
      this.selectedConsultant.genome.genome.labels.includes('7NConsultant') &&
      value === 'approve'
    ) {
      for (let i = 0; i < 2; i++) {
        if (i === 0) {
          value = 'schedule';
        } else {
          value = 'approve';
        }
        await this.apiService
          .setStatus(this.selectedConsultant.id + '/action/' + value)
          .then((response) => {
            if (response) {
              this.selectedConsultant.genome.genome.status = -1;
            }
          });
      }
      return;
    }
    this.apiService
      .setStatus(this.selectedConsultant.id + '/action/' + value)
      .then((response) => {
        if (response) {
          if (value === 'schedule') {
            this.selectedConsultant.genome.genome.status = 1;
          }
          if (value === 'reject') {
            this.selectedConsultant.genome.genome.status = 2;
          }
          if (value === 'approve') {
            this.selectedConsultant.genome.genome.status = -1;
          }
        }
      });
  }

  // Formats a date string to a readable format if the year is greater than or equal to 2022
  beautifyDate(date: string) {
    const d = new Date(date);
    return d.getFullYear() >= 2022
      ? d.getDate() + '/' + (d.getMonth() + 1) + ' ' + d.getFullYear()
      : '-';
  }

  // Returns a formatted label for the given characteristic behavior value
  getCharBehav(value: string) {
    if (value === 'speed_accuracy_tradeoff') {
      return 'Speed-accuracy tradeoff';
    }
    if (value === 'problem_solving_style') {
      return 'Problem solving style';
    }
    if (value === 'problem_solving_ability') {
      return 'Problem solving ability';
    }
    if (value === 'collaboration') {
      return 'Collaboration';
    }
    if (value === 'flexibility') {
      return 'Cognitive flexibility';
    }
    return '';
  }

  // Checks if there is any profile data available
  anyProfile(object: professionalProfiles) {
    let anyProfile = false;
    if (object) {
      for (const [, value] of Object.entries(object)) {
        if ((value as string).length > 0) {
          anyProfile = true;
        }
      }
    }
    return anyProfile;
  }

  // Returns the job family display name based on the provided ID
  getJobFamily(id: string) {
    const findex = this.contextService.familiesData.findIndex(
      (x) => x.id === id
    );
    return this.contextService.familiesData[findex]?.displayName;
  }

  // Returns the skill display name based on the provided ID
  getSkills(id: string) {
    const findex = this.contextService.skillsData.findIndex((x) => x.id === id);
    return this.contextService.skillsData[findex].displayName;
  }

  // Returns the language label based on the provided country code
  getLanguage(countryCode: string) {
    return this.languages.find((x: any) => x.value === countryCode)?.label;
  }

  // Returns the industry display name based on the provided ID
  getIndustry(id: string) {
    const findex = this.contextService.industriesData.findIndex(
      (x) => x.id === id
    );
    return this.contextService.industriesData[findex].displayName;
  }

  // Returns the years of experience display name based on the provided short year experience
  getYearsOfExperience(shortYearExp: string) {
    const findex = this.contextService.levelOfExperience.findIndex(
      (x) => x === shortYearExp
    );
    return this.contextService.yearExperience[findex]?.displayName;
  }

  // Returns the candidate's full name based on the provided slug
  getCandidate(slug: string) {
    const find: list | undefined = this.contextService.profileData.find(
      (x) => x.id === slug
    );

    if (!find) {
      return '';
    }
    return find ? find?.givenName + ' ' + find?.surname : '';
  }

  // Lifecycle hook called after the component's view has been initialized
  ngOnInit(): void {
    window.onresize = () => {
      if (window.innerWidth < 900) {
        this.radarChartData.datasets[0].radius = 4;
        this.radarChartData.datasets[0].hoverRadius = 4;
        this.mobile = true;
      }
      if (window.innerWidth > 900) {
        this.mobile = false;
        this.radarChartData.datasets[0].radius = 8;
        this.radarChartData.datasets[0].hoverRadius = 8;
      }
    };
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
