import { Component, Input } from '@angular/core';
import saveAs from 'file-saver';
import {
  matchConsultants,
  shortlistedProfiles
} from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-level2',
  templateUrl: './level2.component.html',
  styleUrls: ['./level2.component.scss']
})
export class Level2Component {
  @Input() consultant: matchConsultants | shortlistedProfiles;
  @Input() selectedCvType = 0;
  constructor(
    private helperFunctions: HelperFunctionsService,
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}
  getExperience(consultant: matchConsultants | shortlistedProfiles) {
    return consultant.experience ? consultant.experience.experiences : [];
  }
  dashIfEmpty(value: string) {
    return value ?? '-';
  }
  getFullname(
    consultant: matchConsultants | shortlistedProfiles,
    salesAgent = false
  ) {
    if (!consultant) {
      return '-';
    }
    let naming: any = consultant;
    if (salesAgent) {
      naming = consultant.salesAgent ?? '-';
    }
    return naming.firstName + ' ' + naming.lastName;
  }
  getMonthYear(startDate: string, endDate: string | null) {
    const d = Math.round(new Date().getTime() / 1000);
    const startStamp = this.helperFunctions
      .getDate(Math.round(new Date(startDate).getTime() / 1000), false)
      .split(' ');
    const endStamp = endDate
      ? this.helperFunctions
        .getDate(Math.round(new Date(endDate).getTime() / 1000), false)
        .split(' ')
      : ['Now'];
    startStamp.shift();
    endStamp.shift();
    let handleEndStamp = endStamp.join(' ');
    if (!endDate || d < Math.round(new Date(endDate).getTime() / 1000)) {
      handleEndStamp = 'Now';
    }
    return startStamp.join(' ') + ' - ' + handleEndStamp;
  }
  openExternal(event: Event, link?: string) {
    event.stopPropagation();

    if (link) {
      window.open(link, '_blank');
    }
  }
  downloadUrl(event: Event) {
    event.stopPropagation();
    if (!this.consultant.cvFile) {
      return;
    }
    this.platformService
      .getDownloadUrl(this.consultant.cvFile.annotationId)
      .then((response) => {
        if (response) {
          saveAs(response.data, this.consultant.cvFile.filename);
        } else {
          this.contextService.notification.next({
            description: 'Oops, unable downloading file',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
      });
  }
}
