import { createAction, props } from '@ngrx/store';
import { ICrmCountry } from '../../interfaces/dictionaries';

export const queryCountries = createAction('[Dictionaries] Query countries');

export const queryCountriesSuccess = createAction(
  '[Dictionaries] Query countries success',
  props<{ countries: Array<ICrmCountry> }>()
);

export const queryCountriesError = createAction(
  '[Dictionaries] Query countries failure',
  props<{ error: Error }>()
);
