import { Injectable } from '@angular/core';
import axios from 'axios';
import { MsalService } from '@azure/msal-angular';
import { appAccessScopes } from '../../app/auth-config';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class HttpAxiosInterceptorService {
  readonly API_URL = environment.apiUrl;

  constructor(
    private msalService: MsalService,
    private authService: AuthenticationService
  ) {
    // Set up Axios interceptor
    axios.interceptors.request.use(
      async (config) => {
        const accessToken = await this.authService.acquireTokenSilent();
        config.headers.Authorization = 'Bearer ' + accessToken['genome'];
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        // Pass through successful responses
        return response;
      },
      (error) => {
        // Check if the error is a 401
        if (
          error.response &&
          error.response.config.url.startsWith(this.API_URL) &&
          error.response.status === 401
        ) {
          const accessTokenRequest = {
            scopes: [appAccessScopes.scope],
            account: this.msalService.instance.getAllAccounts()[0],
          };
          this.msalService.acquireTokenRedirect(accessTokenRequest);

          return Promise.reject(error);
        }
        // Pass through other errors
        return Promise.reject(error);
      }
    );
  }
}
