<div
  [myDirective]="dropdown"
  (clickOutside)="dropdown ? closeDropdown() : ''"
  class="dropdownInput"
  [class]="{ small: class.includes('small'), tiny: class.includes('small'), disabled: selected?.disable}"
>
  <h3 *ngIf="label">{{ label }}</h3>
  <div>
    <div *ngIf="!iconOnly" class="search-input body1">
      <div
        tabindex="0"
        [class]="{ active: dropdown, iconOnly: iconOnly }"
        class="input"
        #input
        (click)="toggleDropdown(true)"
      >
        <i-tabler *ngIf="leadingIcon" [name]="leadingIcon"></i-tabler>
        <i-tabler
          *ngIf="selected?.leadingIcon"
          [name]="selected?.leadingIcon ?? ''"
        ></i-tabler>
        <div
          [attr.placeholder]="placeholder"
          [innerText]="getSelected()"
          (input)="search($event)"
          class="innerText"
          [class]="{ trailingIcon: trailingIcon, leadingIcon: leadingIcon }"
        ></div>
        <i-tabler
          *ngIf="(trailingIcon && !getSelected()) || !searchable"
          [name]="trailingIcon"
        ></i-tabler>
        <i-tabler
          *ngIf="getSelected() && searchable"
          (click)="clearSelected()"
          class="body-small clear"
          name="X"
        ></i-tabler>
      </div>
    </div>
    <div *ngIf="iconOnly">
      <app-button
        (click)="toggleDropdown(true, true)"
        [class]="['ghost', 'small']"
        [trailingIcon]="trailingIcon"
      ></app-button>
    </div>
    <div
      *ngIf="dropdown"
      #dropdownList
      class="dropdown label2"
      [style]="styles"
    >
      <app-loader [loading]="!options || !matches"> </app-loader>
      <ng-container *ngIf="options">
        <div
          *ngFor="let option of options"
          (click)="selectDropdown(option)"
          [attr.name]="option.displayName"
          [class]="{ disabledOption: option.disable }"
        >
          <i-tabler *ngIf="option.icon" [name]="option.icon"></i-tabler>
          <img *ngIf="option.imageIcon" [src]="option.imageIcon" />
          {{ option.displayName
          }}<span>{{ option.trailingInfo ? option.trailingInfo : "" }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="matches">
        <div
          *ngFor="let match of matches"
          (click)="selectDropdown(match)"
          [attr.name]="match.title"
        >
          <div class="match">
            <i-tabler name="file-text"></i-tabler>
            <div>
              <p class="label2">{{ match.title }}</p>
              <p class="body2">{{ match.customerName }}</p>
            </div>
          </div>
        </div>
        <ng-container *ngIf="rawOptionsMatches">
          <div
            class="noSearch"
            *ngIf="matches.length === 0 && rawOptionsMatches.length > 0"
          >
            No requests match your search
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="salesStatus">
        <div
          class="salesNotes"
          *ngFor="let option of salesStatus; let i = index"
          (click)="i < 3 || !selected?.isAllowed ? selectDropdown(option) : ''"
          [attr.name]="option.displayName"
          [class]="{
            disabled: i > 2 || !selected?.isAllowed,
            acitve: option.name === selected?.name
          }"
        >
          <div>
            <i-tabler
              *ngIf="option.leadingIcon"
              [name]="option.leadingIcon"
            ></i-tabler>
            <p class="label2">{{ option.displayName }}</p>
          </div>
          <span (click)="i > 2 ? openExternalLink() : ''" class="caption">
            <i-tabler *ngIf="i > 2" [name]="'external-link'"></i-tabler
            ></span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
