import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { matches, requestV3 } from '../../../../../../interface/shared.interface';

@Component({
  selector: 'app-add-request',
  templateUrl: './add-request.component.html',
  styleUrls: ['./add-request.component.scss']
})
export class AddRequestComponent {
  @Input() selectedRequest: matches | requestV3 | undefined;
  @Output() selectRequest = new EventEmitter<requestV3>();
  @Output() manualRequest = new EventEmitter<string>();
  selectedSearchType = 0;
  constructor(private activeRoute: ActivatedRoute) {
    this.activeRoute.params.subscribe(async (params) => {
      if (params['id']) {
        if (params['id'].includes('manual')) {
          this.selectedSearchType = 1;
        }
      }
    });
  }
}
