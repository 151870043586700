import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { IKeywords } from '../../../../shared/interfaces/keywords';
import { delay } from 'rxjs';

@Component({
  selector: 'app-matching-keywords',
  templateUrl: './matching-keywords.component.html',
  styleUrls: ['./matching-keywords.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchingKeywordsComponent {
  keywords = input.required<IKeywords>();

  showAllKeywords = signal(false);

  keywordsContainer = viewChild<ElementRef>('keywordsContainer');

  keywordsSorted = computed(() => {
    const keywordsObj = this.keywords() || {};
    return Object.entries(keywordsObj).sort(([, a], [, b]) => b - a);
  });

  keywordsLabel = computed(() => {
    const length = Object.keys(this.keywords() || {}).length;
    if (!length) {
      return 0;
    } else if (length >= 20) {
      return 20;
    } else {
      return length;
    }
  });

  showReadMoreBtn = toSignal(
    toObservable(
      computed(() => {
        this.keywords(); // to trigger the computed on smartTip change
        const container = this.keywordsContainer()?.nativeElement;

        return container
          ? container.scrollHeight > container.clientHeight
          : false;
      })
    ).pipe(delay(0))
  );
}
