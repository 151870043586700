import { Component, Input } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import {
  matchConsultants,
  requestV3,
  shortlistedProfiles
} from '../../../../interface/shared.interface';
import { modalType, profile3 } from '../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../services/platform/context.service';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
})
export class SimpleDropdownComponent {
  @Input() consultant: matchConsultants;
  @Input() shortlistedProfiles: shortlistedProfiles;
  @Input() request: requestV3;
  @Input() profile3: profile3;
  @Input() isManual: boolean;
  @Input() selectedSearchType: number;
  consultantId = '';
  constructor(
    private contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private helperFunctions: HelperFunctionsService
  ) {}
  openMatchModal(event: Event, modal: modalType = 'longlist') {
    event.stopPropagation();
    const profileTypes = [
      this.consultant,
      this.profile3,
      this.shortlistedProfiles,
    ];
    const findex = profileTypes.findIndex((x) => x);
    this.contextService.selectedLonglistConsultant = profileTypes[findex];
    this.contextService.openMatchModal = modal;
  }
  openExternal(link: string, text: string, event: Event) {
    event.stopPropagation();

    if (link) {
      this.matomoTracker.trackEvent('Matching', 'External Link - ' + text);
      window.open(link, '_blank');
    }
  }
  openRtcMatch(id: string, event: Event) {
    event.stopPropagation();
    this.helperFunctions.route(['matching/rtc/' + id]);
  }
}
