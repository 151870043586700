<div class="add-notes">
  <div *ngIf="!addNotes" class="crm-content">
    <app-input
      [disabled]="true"
      class="crm-content"
      [value]="cleanText(note)"
      type="content-field"
    ></app-input>
  </div>
  <div class="crm-content" *ngIf="addNotes">
    <app-input
      [placeholder]="'Write a note'"
      (selectedCallback)="callbackFunction.emit($event)"
      type="content-field"
    ></app-input>
  </div>
</div>
