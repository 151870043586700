import { inject, Injectable } from '@angular/core';
import { AzureMapsService } from './azure-maps.service';
import { ILocationRangeInputAddressOption } from '../../interfaces/location';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationFacadeService {
  private mapsService = inject(AzureMapsService);

  getAddressSearchResults(
    address: string
  ): Observable<Array<ILocationRangeInputAddressOption>> {
    if (address) {
      return this.mapsService.getAddressSearchResults(address);
    } else {
      return of([]);
    }
  }
}
