import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { filter, option } from '../../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-team-filter',
  templateUrl: './team-filter.component.html',
  styleUrls: ['./team-filter.component.scss'],
})
export class TeamFilterComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() filter: filter;
  @Input() selected: option[] = [];
  selectedTeam: option[];
  selectedLocation: option[] = [];
  @Output() selectedOptionTeam = new EventEmitter<option[]>();
  @Output() selectedOptionLocation = new EventEmitter<option[]>();
  filterData = this.contextService.filterData;
  allUniqueTerritories: string[] = [];

  constructor(
    private contextService: ContextService,
    private platformService: PlatformService
  ) {}
  selectedOption(option: option[], type: string) {
    if (type === 'location') {
      this.selectedOptionLocation.emit(option);
    }
    if (type === 'team') {
      this.selectedOptionTeam.emit(option);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTeam = this.selected.filter((x: any) => x.type === 'team');

    this.selectedLocation = this.selected.filter(
      (x: any) => x.type === 'country' || x.type === 'office'
    );
  }
}
