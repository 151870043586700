import { createAction, props } from '@ngrx/store';
import { IAppSettings } from '../../interfaces/app-settings';

export const queryAppVersion = createAction('[Global] Query App Version');

export const queryAppVersionSuccess = createAction(
  '[Global] App Version Loaded',
  props<{ appVersion: string }>()
);

export const queryAppVersionError = createAction(
  '[Global] App Version Load Failure'
);

export const queryAppSettings = createAction(
  '[Dictionaries] Query AppSettings'
);

export const queryAppSettingsSuccess = createAction(
  '[Dictionaries] Query AppSettings success',
  props<{ appSettings: IAppSettings }>()
);

export const queryAppSettingsError = createAction(
  '[Dictionaries] Query AppSettings failure'
);
