import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
  @Input() isToggled = false;
  @Input() label: string;
  @Output() callbackFunction = new EventEmitter<boolean>();

  toggle() {
    this.isToggled = !this.isToggled;
    this.callbackFunction.emit(this.isToggled);
  }
}
