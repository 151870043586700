import { EventHint, ErrorEvent } from '@sentry/angular';
import { AxiosError, isAxiosError } from 'axios';

// based on https://github.com/getsentry/sentry-javascript/issues/4377#issuecomment-2017757609

function handleSentryBeforeSend(event: ErrorEvent, hint: EventHint) {
  handleAxiosErrors(event, hint);
  return event;
}

function handleAxiosErrors(event: ErrorEvent, hint: EventHint) {
  addAxiosContextRecursive(event, hint?.originalException);
  changeTitleOfAxiosEvent(event, hint);
}

function addAxiosContextRecursive(event: ErrorEvent, error: unknown) {
  if (isAxiosError(error)) {
    addAxiosContext(event, error);
  } else if (error instanceof Error && error.cause) {
    addAxiosContextRecursive(event, error.cause);
  }
}

function addAxiosContext(event: ErrorEvent, error: AxiosError) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts['Axios'] = { Response: error.response };
    event.contexts = contexts;
  }
}

function changeTitleOfAxiosEvent(event: ErrorEvent, hint: EventHint) {
  event.exception.values[0].type = `${event.exception.values[0].type} - ${
    (hint?.originalException as AxiosError)?.config?.url
  }`;
}

export { handleSentryBeforeSend };
