import {AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, input, signal, viewChild} from '@angular/core';
import {
  salesAgent
} from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { IRequest, IRequestDetails, WorkType } from '../../../../shared/interfaces/requests';

@Component({
  selector: 'app-request-level2',
  templateUrl: './request-level2.component.html',
  styleUrls: ['./request-level2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestLevel2Component implements AfterViewInit {
  constructor(private helperFunctions: HelperFunctionsService) {}
  request = input<IRequest | IRequestDetails>();
  requestLevel3 = input<boolean>();
  readMore = signal<boolean>(false);
  truncated = signal<boolean>(false);

  descriptionText = viewChild<ElementRef>('descriptionText');

  getWorkIcon = computed((): string => {
    let iconName = '';

    switch (this.request().typeOfWork) {
      case WorkType.FULL_REMOTE:
        iconName = 'armchair-2';
        break;
      case WorkType.ON_SITE:
        iconName = 'building';
        break;
      case WorkType.HYBRID:
        iconName = 'device-laptop';
        break;
    }
    return iconName;
  })

  contractLength = computed(() => {
    if (this.request().contractStartDate && this.request().contractEndDate) {
      const startDate = new Date(this.request().contractStartDate);
      const endDate = new Date(this.request().contractEndDate);
      return this.helperFunctions.calculateDateDifference(startDate, endDate);
    } else {
      return '-';
    }
  });

  getFullname(salesAgent: salesAgent): string {
    return salesAgent.firstName + ' ' + salesAgent.lastName;
  }

  observer = new ResizeObserver(() => {
    this.isTruncated();
  });

  ngAfterViewInit() {
    if (this.descriptionText()) {
      this.observer.observe(this.descriptionText().nativeElement);
    }
  }

  toggleReadMore() {
    this.readMore.update((val) => !val);
  }

  isTruncated() {
    if (this.descriptionText()) {
      const element = this.descriptionText().nativeElement;
      if (!this.readMore()) {
        this.truncated.set(element.offsetHeight < element.scrollHeight);
      }
    }
  }
}
