import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { ICrmCountry } from '../../../../shared/interfaces/dictionaries';
import { DictionariesFacadeService } from '../../../../shared/services/dictionaries-facade.service';
import { option } from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import { filter, forkJoin, from, take } from 'rxjs';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit {
  selectedCvType = input<number>();
  disable = input<boolean>();
  filterLevel = input<boolean>();
  matchId = input<string>();
  iterateId = input<number>();
  selectedSearchType = input<number>();
  selectedFilters = input<option[]>([]);
  selectedNav = input<string>();
  filterType = input<string>();

  openFilter = signal(false);
  filterDataReady = signal(false);

  private contextService = inject(ContextService);
  private platformService = inject(PlatformService);
  private dictionariesFacade = inject(DictionariesFacadeService);
  private snackBarService = inject(SnackBarService);

  ngOnInit() {
    forkJoin({
      consultantStatus: from(this.platformService.getConsultantStatus()),
      countries: this.dictionariesFacade.countriesDict$.pipe(
        filter((countries) => !!countries?.length),
        take(1)
      )
    }).subscribe({
      next: ({consultantStatus, countries}) => {
        this.contextService.filterData.salesstatus = consultantStatus;
        this.contextService.filterData.locationCountries = this.getCountriesOptions(countries);
        this.filterDataReady.set(true);
      },
      error: () => {
        this.snackBarService.open({
          message: 'Failed to get filters',
          type: SnackBarTypes.ErrorAlt
        });
      }
    });
  }

  openModal() {
    if (this.matchId) {
      this.contextService.iterateId = this.iterateId();
      this.contextService.matchId = this.matchId();
      this.contextService.openMatchModal = this.filterLevel
        ? 'filters'
        : 'match-filters';
    }
    if (this.filterType() === 'request') {
      this.contextService.openMatchModal = 'request-filters';
    }
  }

  filterLength = computed(() => {
    const selectedCvType = this.selectedCvType();
    const selectedFilters = this.selectedFilters();
    let filterLength = 0;

    if (selectedCvType === 1) {
      filterLength = selectedFilters.filter(
        (x) => x.type !== 'keywords'
      ).length;
    } else {
      filterLength = selectedFilters.length;
    }
    return filterLength;
  });

  private getCountriesOptions(countries: Array<ICrmCountry>): Array<option> {
    return countries.map((country) => ({
      name: country.countryName,
      displayName: country.countryName,
      id: country.countryId,
    }));
  }
}
