<div class="smart-tip body-small highlight--Lighter textHighlightDark">
  <div class="head">
    <div>
      <i-tabler name="sparkles" class="smart-tip-icon"></i-tabler>
      <p class="bold">Why could this be a good match?</p>
    </div>
    <div class="explainerInfo" (click)="openExplainerInfo.emit()">
      <i-tabler name="info-circle"></i-tabler>
    </div>
  </div>
  <div class="explainer">
    @if(smartTip()) {
      <div class="explainer-content" #smartTipContainer [ngClass]="{ 'show-all': showAll() }">
        <p [ngStyle]="{'white-space': 'pre-wrap'}">{{ smartTip() }}</p>
      </div>
      @if(showReadMoreBtn()) {
        <app-button style="min-width: unset" 
                    [class]="['xsmall']" 
                    [label]="showAll() ? 'Read less -' : 'Read more +'"
                    (click)="showAll.set(!showAll())"></app-button>
      }

    }
    @else {
      <app-loader [loading]="loading()" [class]="['highlight', 'small']"></app-loader>
    }
  </div>
</div>