<div class="agent-select">
  <app-dropdown-v2
    trailingIcon="search"
    [selected]="selected"
    [options]="options"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [type]="'agent-select'"
    [placeholder]="'Find agent'"
    [disabled]="disabled"
    [searchable]="true"
  ></app-dropdown-v2>
</div>
