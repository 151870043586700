<div [formGroup]="form" class="location-filter-form">
  <div
    *ngIf="{ mode: mode$ | async } as componentState"
    class="location-input-container"
  >
    <mat-form-field
      [ngStyle]="{
        display: componentState.mode !== 'range' ? 'flex' : 'none'
      }"
      appearance="fill"
      [ngClass]="{
        empty: inputSearch.value.length === 0,
        'mode-search': true,
        disabled: disabled,
        'location-mat-form-field-mode-search': true
      }"
    >
      <input
        type="text"
        matInput
        #inputSearch
        placeholder="Add location"
        formControlName="search"
        [matAutocomplete]="mapSearch"
        (mouseout)="setMode('range')"
        class="location-input-mode-search"
      />

      <mat-autocomplete
        #mapSearch="matAutocomplete"
        [autoActiveFirstOption]="true"
        [displayWith]="displayLabel"
        (closed)="setMode('range')"
        (opened)="setMode('search')"
      >
        <mat-option
          *ngFor="let option of locationOptions$ | async"
          [value]="option"
          class="location-option"
        >
          <span
            class="fi fi-{{
              option.labelOption.countryCode.toLocaleLowerCase()
            }}"
          ></span>
          <span class="address">{{ option.labelOption.address }}</span>
          <span class="country-name">{{ option.labelOption.countryName }}</span>
        </mat-option>
      </mat-autocomplete>
      <i-tabler
        matIconSuffix
        class="input-suffix-search"
        name="search"
      ></i-tabler>
      <div class="loader" *ngIf="loading$ | async as loading">
        <mat-spinner [diameter]="16"></mat-spinner>
      </div>
    </mat-form-field>

    <!-- range filter mode, input is valid and not focused -->
    <mat-form-field
      [ngStyle]="{ display: componentState.mode === 'range' ? 'flex' : 'none' }"
      [ngClass]="{
        empty: inputSearch.value.length === 0,
        'mode-range': componentState.mode === 'range',
        disabled: disabled,
        'location-mat-form-field-mode-range': true
      }"
    >
      <input
        type="text"
        matInput
        #inputRangeSearch
        placeholder="Add location"
        [value]="getSearchValue()?.name"
        [readonly]="true"
        [disabled]="disabled"
        class="location-input-mode-range"
        (mouseover)="setMode('search')"
      />

      <mat-select
        [formControl]="form.controls.range"
        disableOptionCentering
        panelClass="range-filter-options-panel"
        class="location-range-select"
      >
        <mat-option
          *ngFor="let range of LocationRangeOptions"
          [value]="range.value"
        >
          {{ range.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
