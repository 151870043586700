<div [ngClass]="'badge ' + color()">
  @if(icon()?.type === 'svg') {
    <img *ngIf="icon().type === 'svg'" [src]="icon().icon" />
  }
  @else if(icon()?.type === 'icon') {
    <i-tabler *ngIf="icon()?.type === 'icon'" [name]="icon().icon"></i-tabler>
  }

  @if(label() !== undefined && label() !== '') {
    <p class="tiny" [attr.title]="label()">{{ label() }}</p>
  }

  @if(trailingIcon()) {
    <div class="icon" (click)="callbackFunction.emit()">
      @if(trailingIcon().type === 'svg') {
        <img [src]="trailingIcon().icon" />
      }
      @else if(trailingIcon()?.type === 'icon') {
        <i-tabler [name]="trailingIcon().icon"></i-tabler>
      }
    </div>
  }

  @if(amount() !== undefined) {
    <p class="amount tiny">{{amount()}}</p>
  }
</div>