import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  matchType,
  matches,
  requestV3,
} from '../../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';
import { SnackBarService } from '@my7n/ui';

@Component({
  selector: 'app-search-request',
  templateUrl: './search-request.component.html',
  styleUrls: ['./search-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRequestComponent {
  private platformService = inject(PlatformService);
  private contextService = inject(ContextService);
  private snackBarService = inject(SnackBarService);

  type = input('request');
  selectedSearchType = model.required<number>();
  matchType = input<matchType[]>([
    {
      displayName: 'Request to Consultant',
      icon: 'file-text',
      modal: 'request',
      add: 'Find request',
    },
    {
      displayName: 'Manual Input',
      icon: 'user',
      modal: 'manual',
      add: 'Manual request',
    },
  ]);
  selectedRequest = input.required<matches | requestV3>();
  requestsList = input.required<requestV3[]>();
  requestsListLoading = input.required<boolean>();
  requestsListError = input.required<boolean>();
  toggleDropdown = model<boolean>();

  selectrequest = output<requestV3>();
  manualRequest = output<string>();

  requestsListErrorSignal = computed(() => {
    const error = this.requestsListError();
    return error;
  });

  searchText = signal<string>('');
  filteredRequests = computed<requestV3[]>(() => {
    const search = this.searchText();
    const list = this.requestsList();

    return list.filter((x) => {
      let findByTitle = false;
      if (x.title) {
        findByTitle = x.title
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(search.toLowerCase().replace(/\s/g, ''));
      }
      const findbyCustomerName = x.customer.name
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(search.toLowerCase().replace(/\s/g, ''));
      if (!findByTitle) {
        return findbyCustomerName;
      }
      return findByTitle;
    });
  });
  manualRequestDescription =
    this.contextService.manualRequest?.description ?? '';

  input(event: Event) {
    const target = event.target as HTMLInputElement;
    this.manualRequest.emit(target.value);
  }

  select(selectRequest: requestV3) {
    if (selectRequest && this.contextService.selectedConsultant) {
      const selected = selectRequest.enlistedProfiles.some(
        (x) => x.id === this.contextService.selectedConsultant.id
      );
      if (selected) {
        return;
      }
    }
    if (
      selectRequest?.isDescription ||
      this.type() === 'longlist' ||
      selectRequest === undefined
    ) {
      this.selectrequest.emit(selectRequest);
      this.toggleDropdown.set(false);
    }
  }

  chooseRequestType(event: number) {
    this.selectedSearchType.set(event);
  }

  searchRequest(searchText: string) {
    if (this.requestsListLoading() || !this.requestsList()) {
      return;
    }

    this.searchText.set(searchText);
  }
}
