import { HttpClient } from '@angular/common/http';
import * as _packageInfo from 'package.json';
import { inject, Injectable } from '@angular/core';
import { IAppSettings } from '../interfaces/app-settings';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const packageInfo = _packageInfo;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private http = inject(HttpClient);

  readonly API_URL = environment.apiUrl;
  readonly SETTINGS_API_URL = `${this.API_URL}/agentportal/v1/portal/settings`;

  getAppVersion(): string {
    return packageInfo.version;
  }

  getAppSettings(): Observable<IAppSettings> {
    return this.http.get<IAppSettings>(this.SETTINGS_API_URL);
  }
}
