<div class="each-consultant">
  <app-select-items
    (click)="checked(consultant)"
    [isChecked]="bulkSelect.includes(consultant)"
    [disabled]="consultant.declarationInformation ? true : false"
    type="checkbox"
  ></app-select-items>
  <div class="bundle surface--1">
    <div
      class="status-bar"
      [class]="{ disabled: getSelected(consultant.salesStatus)?.disable }"
    >
      <div
        class="process"
        [class]="{
          fullWidth: getProcessLengt(consultant.salesStatus) >= '100'
        }"
        [style]="'width:' + getProcessLengt(consultant.salesStatus) + '%'"
      ></div>
      <div class="bundle-left">
        <app-dropdown
          [class]="'small'"
          trailingIcon="chevron-down"
          type="salesNotes"
          (selectedCallback)="selectedSaleNotes(consultant, $event)"
          [selected]="getSelected(consultant.salesStatus)"
          [salesStatus]="consultantstatus"
        ></app-dropdown>

        <li></li>
        <p class="tiny">
          Added
          {{ getDateTimestamp(consultant.createdOn) }}
          by
          {{ consultant.addedBy }}
        </p>
      </div>
      <div class="bundle-right">
        <p class="tiny">
          <span class="bold changeTitle"> Sales status change </span>
          {{ dateDifference(consultant.modifiedOn) }}
          ago
        </p>
      </div>
    </div>
    <div
      class="card-info"
      (click)="expandLevel2 = !expandLevel2"
      [class]="{ expandLevel2 }"
    >
      <div class="chevron">
        <i-tabler
          [name]="'chevron-' + (expandLevel2 ? 'up' : 'down')"
        ></i-tabler>
      </div>
      <app-avatar
        [by]="consultant"
        [tier]="consultant.tier"
        class="small"
        [tiers]="true"
      ></app-avatar>

      <div class="text">
        <div class="bundle-cta">
          <div *ngIf="consultant.tier">
            <div
              class="name-title"
              [class]="{ reverse: consultant.tier.status }"
            >
              <h3 class="bold">
                {{ getFullName(consultant) }}
                <div
                  *ngIf="consultant.remarks"
                  class="remarks warning--Medium"
                ></div>
              </h3>
              <p class="body-large last-role" *ngIf="consultant.lastRole">
                {{ consultant.lastRole }}
              </p>
            </div>
            <div *ngIf="consultant.tier.status">
              <app-tier-statuses [tier]="consultant.tier"></app-tier-statuses>
            </div>
          </div>
          <div class="cta">
            <div class="salesNotes" *ngIf="consultant.salesNotes">
              <p class="tiny surface--3">
                “{{ cleanText(consultant.salesNotes) }}“
              </p>
              <div class="arrow"></div>
            </div>
            <div class="bundle-cta-badge">
              <app-cta
                classes="brand--Lightest textLink"
                [icon]="'note'"
                (click)="openNote($event, consultant)"
              >
              </app-cta>
              <app-badge
                *ngIf="getLengthOfNotes(consultant.salesNotes)"
                [amount]="getLengthOfNotes(consultant.salesNotes)"
                [color]="'amount tiny brand--Dark textContrast'"
              ></app-badge>
            </div>
            <div class="bundle-cta-badge">
              <app-cta
                classes="brand--Lightest textLink"
                [icon]="'send'"
                (click)="openMessaging($event, consultant)"
              >
              </app-cta>
              <app-badge
                [color]="getColor(consultant.declarationInformation) + ' tiny '"
                *ngIf="consultant.declarationInformation"
                [icon]="getStatus(consultant.declarationInformation)"
              ></app-badge>
            </div>
            <app-cta
              classes="brand--Lightest textLink"
              [icon]="'user'"
              (click)="openProfile($event, consultant.id)"
            >
            </app-cta>
            <div
              (clickOutside)="toggleDropdown($event, '')"
              class="bundle-dropdown"
            >
              <app-cta
                [classes]="' textLink'"
                [icon]="'dots'"
                (click)="toggleDropdown($event, consultant.id)"
              >
              </app-cta>
              <app-simple-dropdown
                *ngIf="openDropdown === consultant.id"
                (click)="toggleDropdown($event, '')"
                [shortlistedProfiles]="consultant"
              ></app-simple-dropdown>
            </div>
          </div>
        </div>
        <div class="title">
          <p class="body-large">{{ consultant.lastRole }}</p>
        </div>
        <div class="info">
          <div class="country body-large">
            <i-tabler width="16px" height="16px" name="map-pin"></i-tabler>
            <p class="body-small">
              <span *ngIf="consultant.location.city" class="hide-on-mobile">{{
                consultant.location.city
              }}</span>
              <span
                *ngIf="consultant.location.stateOrProvince"
                class="hide-on-mobile"
                >{{
                  consultant.location.stateOrProvince.toLocaleUpperCase()
                }}</span
              >
              <span *ngIf="consultant.location.countryCode">
                {{ getCountry(consultant.location.countryCode) }}
              </span>
            </p>
          </div>
          <div class="li"></div>
          <p class="body-small">
            <span>Available</span>
            {{ getDate(consultant.availability) }}
          </p>
          <div class="li"></div>
          <p class="body-small">
            {{ validateRate(consultant.expectedRate) }} / h
          </p>
        </div>
      </div>
    </div>
    <app-level2 [consultant]="consultant" *ngIf="expandLevel2"></app-level2>
  </div>
</div>
