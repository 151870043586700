import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PotentialRequests } from 'src/interface/shared.interface';
import { list, pagination, tableList } from 'src/interface/shared.interface';

@Component({
  selector: 'app-the-list',
  templateUrl: './the-list.component.html',
  styleUrls: ['./the-list.component.scss'],
})
export class TheListComponent {
  faSpinner = faSpinner;
  @Input() tableList: tableList[] = [];
  @Input() loading = true;
  @Input() clickRow = false;
  @Input() listLimit = 0;
  @Input() list: list[] = [];
  @Input() potentialRequests: PotentialRequests[] = [];
  @Input() type = '';
  @Input() selected: string[] = [];
  @Input() loadMoreLength = 10;
  @Input() pagination = {
    limit: 200,
    offset: 100,
    id: '',
    loading: false,
  };
  @Output() openCandidate = new EventEmitter<string[]>();
  @Output() loadMore = new EventEmitter();
  @Output() view = new EventEmitter<string[]>();
}
