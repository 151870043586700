import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  requestResult,
  routeParams,
  searchContext,
  filterOption,
  countryCode,
  option,
} from '../../../../../interface/shared.interface';

import {
  ContextService,
  RerenderTypes,
} from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { Platform } from '@angular/cdk/platform';
import { MatomoTracker } from 'ngx-matomo-client';
import countries from '../../../../../assets/countries';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatchSalesRequestConsultant,
  MatchingConsultantRequest,
  MatchingRequestConsultant,
  cvType,
  matchConsultants,
  matchType,
  matches,
  requestV3,
} from '../../../../../interface/shared.interface';
import { FeatureFlagsService } from '../../../../../services/feature-flags/feature-flags.service';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  Subscription,
} from 'rxjs';
import { filteringList, removeFilterFunc } from '../shared-functions/filtering';
import {
  searchCTR,
  searchRTC,
  searchManual,
  searchRequest,
} from '../shared-functions/search';
import { matomoMatching } from '../shared-functions/matomo-tracking';
import * as Sentry from '@sentry/angular';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { MatchingType, MatchingTypeName } from '../../../../shared/interfaces/matching/matching-type';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  public contextService = inject(ContextService);
  private matomoTracker = inject(MatomoTracker);
  private platformService = inject(PlatformService);
  public platform = inject(Platform);
  private helperFunctions = inject(HelperFunctionsService);
  public featureFlags = inject(FeatureFlagsService);
  private snackBarService = inject(SnackBarService);

  faSpinner = faSpinner;

  params = input<routeParams>();

  selectedCvType = signal(0);
  potentialRequestResults = signal<requestV3[]>([]);
  potentialListResults = signal<requestV3[]>(null);
  closed = signal(false);
  dialog = signal(false);
  iterateId = signal<number>(null);
  inputActive = signal('');
  moreLoading = signal(false);
  loading = signal(false);
  loadingSearchType = signal(false);
  loadLength = signal(30);
  potentialResults = signal<matchConsultants[]>([]);
  searchContext = signal<searchContext>(
    this.contextService.advancedSearch.value
  );
  response = signal(false);
  requestData = signal<requestResult>({ description: '', extrnalLink: '' });
  requestId = signal<string>(null);
  selectedConsultant = signal<matchConsultants>(null);
  selectedRequest = signal<requestV3>(null);
  manualRequest = signal<matches>(null);
  isManual = signal(false);
  selectedSearchType = signal(0);
  selectedFilters = signal<option[]>(null);
  getLoading = signal(false);

  potentialResultsCv: matchConsultants[];
  potentialResultsFile: matchConsultants[];
  countryCode: countryCode[] = countries.list;
  eachLoading = -1;
  filterList: filterOption[] = [];
  matchId: string;
  noResults = false;
  rawPotentialResults?: matchConsultants[];
  allResults: matchConsultants[] | requestV3[];
  pagination = {
    limit: 200,
    offset: 100,
    id: '',
    loading: false,
  };
  allconsultants: matchConsultants[] = this.contextService.allconsultants ?? [];
  requestIdFiles: string;
  searchArray: matchConsultants[] = [];
  searchArrayCountry: countryCode[] = [];
  searchInput = '';
  searchType: MatchingTypeName[] = [
    MatchingTypeName.RequestToConsultant,
    MatchingTypeName.ConsultantToRequest,
    MatchingTypeName.ConsultantToConsultant
  ];
  matchingType: cvType[] = [];
  matchType: matchType[] = [
    {
      displayName: 'Request to Consultant',
      icon: 'file-text',
      modal: 'request',
      add: 'Add request',
      emptyStatment: {
        title: 'Add request to start your search.',
        description:
          'Please select a request to start matching. All matching consultants will appear here.',
      },
    },
    {
      displayName: 'Consultant to Request',
      icon: 'user',
      modal: 'ctr',
      add: 'Add consultant',
      emptyStatment: {
        title: 'Add consultant to start your search.',
        description:
          'Please select a consultant to start matching. All matching requests will appear here.',
      },
    },
    {
      displayName: 'Consultant to Consultant',
      icon: 'users',
      modal: 'ctc',
      add: 'Add consultant',
      emptyStatment: {
        title: 'Add consultant to start your search.',
        description:
          'Please select a consultant to start matching. All matching consultants will appear here.',
      },
    },
  ];
  isContentReady = false;
  queryParams: any;
  filters: string[] = [];
  c = 0;
  subs = new Subscription();

  constructor() {
    if (this.contextService.referrer) {
      this.matomoTracker.trackEvent(
        'Referrer',
        'Matching - ' + this.contextService.referrer
      );
      this.contextService.referrer = '';
    } else {
      this.matomoTracker.trackEvent('Matching', 'Selected in navigation');
    }
  }

  // Initializes the component and subscribes to global filter changes
  ngOnInit(): void {
    this.subs.add(
      this.contextService.reRender
        .pipe(
          filter(
            (val) =>
              val?.name === RerenderTypes.LongShortList && val?.data?.affectedId
          )
        )
        .subscribe({
          next: (val) => {
            this.updateFreelancerShortlistedElement(
              val.data.affectedId,
              val.data.requestId || this.requestId()
            );
          },
        })
    );

    if (
      this.helperFunctions.getCETTime() >= 23 ||
      this.helperFunctions.getCETTime() < 7
    ) {
      this.closed.set(true);
    }

    this.contextService.search.subscribe((value: string) => {
      if (value === 'search' && this.response()) {
        this.search();
      }
    });

    this.routes();

    const countryCodeFromLocalStorage = localStorage.getItem('countryCode');
    if (countryCodeFromLocalStorage) {
      this.contextService.advancedSearch.next(this.searchContext());
    }

    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        this.queryParams = { ...params };
      });
    }

    this.subs.add(
      this.contextService.advancedSearch.asObservable().subscribe((val) => {
        // update changes related to contextService.advancedSearch
        // keep values that are managed by the match component (like crmId)
        this.searchContext.update((prev) => ({
          ...prev,
          countryCode: val.countryCode,
          matchingType: val.matchingType,
          qualification: val.qualification,
          qualificationImportance: val.qualificationImportance,
        }));
      })
    );

    this.subs.add(
      combineLatest([this.contextService.globalFilterM, this.route.queryParams])
        .pipe(distinctUntilChanged())
        .subscribe(async ([selectedFilters, queryParams]) => {
          this.queryParams = { ...queryParams };
          this.selectedFilters.set(selectedFilters);
          if (this.requestId() && this.iterateId() && this.response()) {
            this.filters = [];
            for (const key in this.queryParams) {
              if (key === 'cvtype') {
                continue;
              }
              if (key === 'keywords') {
                this.filters.push(key + '=' + this.queryParams[key]);
                continue;
              }
              if (key === 'range') {
                this.filters.push('availability=' + this.queryParams[key]);
                continue;
              }

              // if the key is mapLocation it can be an array, so we need to iterate over it and add each element to the filters array
              if (
                key === 'mapLocation' &&
                Array.isArray(this.queryParams[key]) &&
                this.queryParams[key].length > 0
              ) {
                this.queryParams[key].forEach((element: string) => {
                  this.filters.push('mapLocation=' + element);
                });
                continue;
              }

              this.filters.push(key + '=' + this.queryParams[key]);
            }
            if (this.selectedSearchType() === MatchingType.ConsultantToConsultant) {
              const { potentialResults, rawPotentialResults, filterOptions } =
                await filteringList(
                  this.selectedFilters(),
                  this.contextService,
                  this.potentialResults(),
                  this.rawPotentialResults
                );
              this.potentialResults.set(potentialResults);
              this.rawPotentialResults = rawPotentialResults;
              this.selectedFilters.set(filterOptions);
            }

            if (this.selectedSearchType() === MatchingType.RequestToConsultant) {
              this.getLoading.set(true);
              this.loading.set(true);
              this.response.set(false);
              this.rawPotentialResults = undefined;
              this.potentialResults.set([]);
              const {
                potentialResults,
                potentialResultsCv,
                potentialResultsFile,
              } = await searchRequest(
                this.filters,
                this.platformService,
                this.snackBarService,
                this.requestIdFiles ?? this.requestId(),
                this.matchingType,
                this.selectedCvType(),
                this.contextService
              );
              this.potentialResults.set(potentialResults);
              this.potentialResultsCv = potentialResultsCv;
              this.potentialResultsFile = potentialResultsFile;

              this.response.set(true);
              this.loading.set(false);
              for (const key in this.queryParams) {
                if (key === 'cvtype') {
                  if (this.queryParams['cvtype']) {
                    let type = this.queryParams['cvtype'];
                    if (typeof type === 'string') {
                      type = JSON.parse(this.queryParams['cvtype']);
                    }
                    this.cvFile(type);
                    return;
                  }
                }
              }
              this.cvFile(0);
            }
          }
        })
    );
  }

  getTier(option: option) {
    return this.contextService.filterData.tiers.find(
      (x: any) => x.status.contractStatus === option.displayName
    );
  }

  // Removes a filter from the list of selected filters and updates the query parameters
  removeFilter(filter: option) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = { ...params };
    });
    const { selectedFilters, queryParams } = removeFilterFunc(
      filter,
      [...this.selectedFilters()],
      this.queryParams
    );
    this.selectedFilters.set(selectedFilters);

    // Navigates to the current route with updated query parameters
    this.router.navigate([], { queryParams });
  }

  // Clears all selected filters and navigates to the current route with no query parameters

  clearAll(newSearch?: boolean) {
    this.selectedFilters.set([]);
    this.contextService.globalFilterM.next([]);
    if (newSearch) {
      this.router.navigate([], {});
    }
  }

  // Determines the status of the list and the number of items being shown
  listStatus = computed(() => {
    let searchType = 'requests';
    let length = this.loadLength();
    const potentialListResults = this.potentialListResults();
    const selectedSearchType = this.selectedSearchType();
    const potentialResults = this.potentialResults();
    const selectedRequest = this.selectedRequest();

    if (potentialListResults && length >= potentialListResults.length) {
      length = potentialListResults.length;
    }

    if (selectedSearchType !== MatchingType.ConsultantToRequest) {
      if (length >= potentialResults?.length) {
        length = potentialResults?.length;
      }

      searchType = 'candidates of ';

      if (selectedRequest) {
        searchType += selectedRequest.title;
      }
    }

    return 'Showing ' + length + ' ' + searchType;
  });

  areFiltersDisabled = computed(() => {
    const loading = this.loading() || this.getLoading();
    const selectedSearchType = this.selectedSearchType();
    const selectedRequest = this.selectedRequest();
    const selectedConsultant = this.selectedConsultant();
    const isManual = this.isManual();

    const emptyRequestForRequestToConsultant =
      selectedSearchType === MatchingType.RequestToConsultant &&
      !selectedRequest;
    const emptyManualRequestForRequestToConsultant =
      selectedSearchType === MatchingType.RequestToConsultant && !isManual;
    const emptyConsultantForConsultantToConsultant =
      selectedSearchType === MatchingType.ConsultantToConsultant &&
      !selectedConsultant;

    return (
      loading ||
      (emptyRequestForRequestToConsultant &&
        emptyManualRequestForRequestToConsultant) ||
      emptyConsultantForConsultantToConsultant
    );
  });

  // Compares two matchConsultants objects based on their first names
  compare(a: matchConsultants, b: matchConsultants): number {
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  }

  // Sets up route parameters and handles deep linking and navigation
  routes() {
    this.subs.add(
      this.route.params.subscribe(async (params) => {
        this.response.set(false);
        params = params as routeParams;

        if (params['deeplink']) {
          this.selectedSearchType.set(
            this.searchType.findIndex((x) => x === params['deeplink'])
          );
        }

        if (params['id'] && [MatchingTypeName.ConsultantToRequest, MatchingTypeName.ConsultantToConsultant].includes(params['deeplink'])) {
          await this.platformService
            .consultant(params['id'])
            .then((respoonse) => {
              this.selectConsultant(respoonse[0]);
              if (this.route.snapshot.queryParamMap.get('search') !== 'false') {
                this.search();
              }
            })
            .catch(() => {
              this.snackBarService.open({
                message: 'Failed to get consultant data',
                type: SnackBarTypes.ErrorAlt
              });
            });
        }

        if (params['id'] && params['deeplink'] === MatchingTypeName.RequestToConsultant) {
          this.potentialResults.set([]);
          this.filterList = [];
          this.isManual.set(false);

          if (params['id'].includes('manual')) {
            this.isManual.set(true);
            this.selectedRequest.set(undefined);
            this.contextService.selectedRequest = this.selectedRequest();
            this.manualRequest.set(this.contextService.manualRequest);
            if (this.manualRequest()) {
              this.search();
            }
            return;
          }
        }

        if (params['id'] && params['deeplink'] === MatchingTypeName.RequestToConsultant) {
          this.potentialResults.set([]);
          this.filterList = [];
          this.isManual.set(false);

          if (params['id'].includes('manual')) {
            this.isManual.set(true);
            this.selectedRequest.set(undefined);
            this.contextService.selectedRequest = this.selectedRequest();
            this.manualRequest.set(this.contextService.manualRequest);
            return;
          }

          this.platformService
            .getRequests(params['id'])
            .then((response) => {
              response.type = 'search';
              this.selectedRequest.set(response.data[0]);
              this.contextService.selectedRequest = this.selectedRequest();
              if (this.route.snapshot.queryParamMap.get('search') !== 'false') {
                this.search();
              }
            })
            .catch(() => {
              this.snackBarService.open({
                message: 'Failed to get requests data',
                type: SnackBarTypes.ErrorAlt
              });
            });
        }

        if (!params['id']) {
          this.contextService.selectedRequest = undefined;
        }
      })
    );
  }

  // Handles the loading and display of CV files based on the selected tab
  cvFile(tab: number) {
    this.loadLength.set(30);
    this.getLoading.set(true);
    this.selectedCvType.set(tab);
    if (tab === 0) {
      this.potentialResults.set(this.potentialResultsCv);
    }
    if (tab === 1) {
      this.potentialResults.set(this.potentialResultsFile);
    }
    this.rawPotentialResults = undefined;
    this.queryParams['cvtype'] = tab;
    this.router.navigate([], {
      queryParams: this.queryParams,
    });
    this.getLoading.set(false);
  }

  // Loads more results when the "Load more" button is clicked
  loadMore() {
    this.matomoTracker.trackEvent('Matching', 'Clicked - Load more');
    this.moreLoading.set(true);
    setTimeout(async () => {
      if (this.loadLength() + 20 >= this.potentialResults()?.length) {
        const findex = this.filters.findIndex((x) => x.includes('offset'));

        if (findex !== -1) {
          this.filters.splice(findex, 1);
        }

        const { potentialResults, potentialResultsCv, potentialResultsFile } =
          await searchRequest(
            this.filters,
            this.platformService,
            this.snackBarService,
            this.requestIdFiles ?? this.requestId(),
            this.matchingType,
            this.selectedCvType(),
            this.contextService,
            true
          );
        this.potentialResults.set(potentialResults);
        this.potentialResultsCv = potentialResultsCv;
        this.potentialResultsFile = potentialResultsFile;
      }
      this.loadLength.set(this.loadLength() + 20);
      this.moreLoading.set(false);
    }, 1000);
  }

  // Selects the match type and navigates to the corresponding route
  chooseMatchType(type: number) {
    this.matomoTracker.trackEvent(
      'Matching',
      'Selected - ' + this.searchType[type]
    );
    this.helperFunctions.route(['matching', this.searchType[type]]);
    this.loadingSearchType.set(true);
    this.iterateId.set(null);

    setTimeout(() => {
      this.loadingSearchType.set(false);
    }, 300);

    this.selectedSearchType.set(type);
    this.searchContext.set({
      request: '',
      crmId: '',
      qualification: '',
      qualificationImportance: 'Minimal',
      countryCode: '',
      matchingType: 'CV',
    });

    if (localStorage.getItem('countryCode')) {
      this.contextService.advancedSearch.next({
        ...this.contextService.advancedSearch.value,
        countryCode: localStorage.getItem('countryCode') ?? '',
      });
    }

    this.allResults = [];
    this.filterList = [];
    this.potentialResults.set([]);
    this.potentialListResults.set([]);
    this.searchArray = [];
    this.searchArrayCountry = [];
    this.contextService.globalFilterM.next([]);
    this.selectedConsultant.set(undefined);

    if (this.contextService.manualRequest) {
      this.contextService.manualRequest.description = '';
    }
    if (this.contextService?.selectedConsultant) {
      this.contextService.selectedConsultant.id = '-1';
      this.contextService.selectedConsultant.crmId = '-1';
    }
  }

  // Selects a consultant and updates the search context
  selectConsultant(consultant: matchConsultants) {
    this.selectedConsultant.set(consultant);
    this.searchContext.update((val) => ({ ...val, crmId: consultant.id }));
    this.inputActive.set('');
  }

  // Handles focus events and updates the search context based on the input type
  focus(e: Event, type: keyof searchContext) {
    this.input(e, type);
    if (type === 'crmId') {
      if (this.searchInput.length === 0) {
        this.searchArray = this.allconsultants;
      }
    }
  }

  // Handles blur events and resets the input active state if necessary
  blur(input: string) {
    if (input !== 'crmId' && input !== 'countryCode') {
      this.inputActive.set('');
    }
  }

  // Opens the match modal
  openMatchModal() {
    this.contextService.openMatchModal = 'request';
  }

  // Returns the title of the selected request
  getSelectedRequest(): string {
    if (this.contextService.selectedRequest) {
      return this.contextService.selectedRequest.title;
    }
    return '';
  }

  // Handles different actions such as cancel and search
  findAction(type: string) {
    if (type === 'cancel') {
      this.searchContext.set({
        request: '',
        crmId: '',
        qualification: '',
        qualificationImportance: 'Minimal',
        countryCode: '',
        matchingType: 'CV',
      });
    }
    if (type === 'search') {
      this.search();
    }
  }

  // Custom input handler for updating the search context
  customInput(event: { event: Event; type: string }) {
    const type = event.type as keyof searchContext;
    this.inputActive.set(type);
    if (type === 'qualification') {
      this.searchInput = (event.event.target as HTMLInputElement).value;
    } else {
      this.searchInput =
        (event.event.target as HTMLInputElement).getAttribute('value') ?? '';
    }
    this.searchContext.update((val) => ({ ...val, [type]: this.searchInput }));
  }

  // Input handler for updating the search context and filtering results
  input(e: Event, type: keyof searchContext) {
    this.inputActive.set(type);
    this.searchInput = (e.target as HTMLInputElement).innerText;
    if (type === 'countryCode') {
      this.searchArrayCountry = this.countryCode.filter((x) => {
        if (x.displayName) {
          x.displayName
            .toLowerCase()
            .startsWith(this.searchInput.toLowerCase());
        }
      });
      if (this.searchInput.length === 0) {
        this.searchArrayCountry = this.countryCode;
      }
    }
    if (type === 'crmId') {
      this.searchArray = this.allconsultants
        .filter((x: matchConsultants) => {
          const troncate = x.firstName.toLowerCase().split(' ');
          const string = this.searchInput.toLowerCase().split(' ');
          const searchName = string.every((x) =>
            troncate.some((z) => z.startsWith(x))
          );
          if (searchName) {
            return true;
          }
          return x.firstName
            .toLowerCase()
            .startsWith(this.searchInput.toLowerCase());
        })
        .sort((a: matchConsultants, b: matchConsultants) => {
          const nameA = a.firstName.toLowerCase();
          const nameB = b.firstName.toLowerCase();
          const insertedText = this.searchInput.toLowerCase();
          if (nameA.startsWith(insertedText)) {
            return -1;
          }
          if (!nameB.startsWith(insertedText)) {
            return 1;
          }
          return 0;
        });
      if (this.searchInput.length === 0) {
        this.searchArray = this.allconsultants;
      }
      return;
    }
    this.searchContext.update((val) => ({ ...val, [type]: this.searchInput }));
  }

  // Generates a random integer between min and max
  getRandomInt(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  // Content readiness handler for fetching results based on the iteration ID
  contentReady = async (iterateId: number) => {
    const searchType = this.searchType[this.selectedSearchType()];
    this.response.set(false);
    this.rawPotentialResults = undefined;
    this.matchId = '';
    this.filterList = [];
    this.pagination.offset = 100;

    this.loading.set(true);
    this.potentialResults.set([]);
    this.c++;
    if (this.c > 60) {
      this.loading.set(false);
      return;
    }

    if (this.isContentReady && searchType === MatchingTypeName.RequestToConsultant) {
      this.isContentReady = false;
      this.potentialResults.set([]);

      const CV = this.platformService.getCachedRequests(
        this.selectedRequest().id,
        this.matchingType[0].iterationId
      ) as Promise<MatchSalesRequestConsultant>;

      const file = this.platformService.getCachedRequests(
        this.selectedRequest().id,
        this.matchingType[1].iterationId
      ) as Promise<MatchSalesRequestConsultant>;

      Promise.all([CV, file])
        .then(async (values: any[]) => {
          const mergedArray = [
            ...values[0].potentialFreelancers,
            ...values[1].potentialFreelancers,
          ];
          this.iterateId.set(values[0].iterationId);
          values[0].potentialFreelancers = mergedArray;
          values[0].paginationCv = values[0].pagination ?? {};
          values[0].paginationFile = values[1].pagination ?? {};
          this.matchingType.map((x) => {
            if (x.iterationId === values[0].iterationId) {
              x.amount = values[0].pagination.total;
            }
            if (x.iterationId === values[1].iterationId) {
              x.amount = values[1].pagination.total;
            }
            return x;
          });
          await this.setResults(values[0], 'srtc', this.selectedRequest().id);
          this.response.set(true);
          if (this.selectedFilters()?.length > 0) {
            this.contextService.globalFilterM.next(this.selectedFilters());
          }
        })
        .catch(() => {
          this.snackBarService.open({
            message: 'Failed to get catched results',
            type: SnackBarTypes.ErrorAlt
          });
        });

      return;
    }

    // Recursive function to periodically check if all matching types are ready by fetching cached requests.
    // If any matching type is not ready, it waits for 1 second and checks again.
    // Once all matching types are ready, it sets the contentReady flag to true and calls the contentReady method.
    const head = (iterateId: number) => {
      if (!this.matchingType.every((x) => x.contentReady)) {
        this.platformService
          .getCachedRequests(this.selectedRequest().id, iterateId, true)
          .then((resp: { status: number }) => {
            const findex = this.matchingType.findIndex(
              (x) => x.iterationId === iterateId
            );
            if (resp.status === 200) {
              // this.matchingType.update((val) => ({
              //   ...val,
              //   [findex]: { ...val[findex], contentReady: true },
              // }));
              this.matchingType[findex].contentReady = true;
            } else if (
              resp.status === 204 &&
              !this.matchingType[findex].contentReady
            ) {
              setTimeout(() => {
                head(iterateId);
              }, 1000);
            } else {
              // quit the loop silently
              this.loading.set(false);
              this.isContentReady = false;
              Sentry.captureException(
                '[MatchComponent][contentReady] Error in fetching cached requests: ' +
                  JSON.stringify(resp)
              );
            }
            if (this.matchingType.every((x) => x.contentReady)) {
              this.isContentReady = true;
              this.contentReady(iterateId);
            }
          })
          .catch(() => {
            this.snackBarService.open({
              message: 'Failed to get catched results',
              type: SnackBarTypes.ErrorAlt
            });
            this.loading.set(false);
          });
      }
    };
    head(iterateId);
  };

  // Main search function that triggers the search based on the selected search type and context
  async search() {
    this.getLoading.set(false);
    this.response.set(false);
    this.rawPotentialResults = undefined;
    this.matchId = '';
    this.iterateId.set(null);
    this.filterList = [];
    this.loadLength.set(30);
    this.pagination.offset = 100;
    this.potentialResults.set([]);

    const searchType = this.searchType[this.selectedSearchType()];

    matomoMatching(this.matomoTracker, searchType, this.searchContext());
    try {
      if (searchType === MatchingTypeName.ConsultantToConsultant && this.searchContext()?.crmId) {
        this.loading.set(true);
        this.platformService
          .matchconsultanttoconsultants(this.searchContext(), 'v2')
          .then(async (response) => {
            if (response.message) {
              return;
            }
            this.iterateId.set(new Date().valueOf());
            await this.setResults(response, searchType);

            const { potentialResults, rawPotentialResults } =
              await filteringList(
                this.selectedFilters(),
                this.contextService,
                this.potentialResults(),
                this.rawPotentialResults
              );
            this.potentialResults.set(potentialResults);
            this.rawPotentialResults = rawPotentialResults;
            this.response.set(true);
          });
      }
      if (searchType === MatchingTypeName.ConsultantToRequest && this.searchContext()?.crmId) {
        this.loading.set(true);
        this.requestId.set('');

        const { response } = await searchCTR(
          this.platformService,
          this.snackBarService,
          this.searchContext()
        );
        this.setResults(response, searchType);
        this.response.set(true);
      }
      if (searchType === MatchingTypeName.RequestToConsultant) {
        this.loading.set(true);
        if (this.isManual()) {
          this.searchContext.update((val) => ({
            ...val,
            request: this.manualRequest()?.description,
          }));
          const { results, matchingType } = await searchManual(
            [],
            this.searchContext(),
            this.platformService,
            this.snackBarService
          );
          this.matchingType = matchingType;
          this.requestId.set(results[0].id);
          this.requestIdFiles = results[1].id;
          this.iterateId.set(results[0].iterationId);

          this.setResults(results[0], MatchingTypeName.RequestToConsultant);
          this.response.set(true);
          return;
        }
        const { matchingType } = await searchRTC(
          this.platformService,
          this.snackBarService,
          this.searchContext(),
          this.selectedRequest(),
          this.contextService,
          this.matchingType
        );
        this.matchingType = matchingType;
        this.matchingType.forEach((value) => {
          this.contentReady(value.iterationId);
        });
      }
    } catch {
      this.loading.set(false);
      this.snackBarService.open({
        message: 'Failed to get results',
        type: SnackBarTypes.ErrorAlt
      });
    }
  }

  // Sets the results of the search and updates the relevant state variables
  async setResults(
    response:
      | MatchingConsultantRequest
      | MatchingRequestConsultant
      | MatchSalesRequestConsultant,
    searchType: string,
    id?: string
  ) {
    let allResults: matchConsultants[] = [];
    const allRequestResults: requestV3[] = [];
    let potentialRequestResults: requestV3[] = [];

    if (Object.keys(response).length > 0) {
      this.requestId.set(response.matchId ?? response.id);

      if (searchType === MatchingTypeName.ConsultantToRequest) {
        response = response as MatchingConsultantRequest;
        const potentialRequests = response.potentialRequests as requestV3[];
        potentialRequestResults = potentialRequests ?? [];
        this.contextService.resultsLength.next(potentialRequestResults.length);
      }


      // @TODO: check what is srtc
      if (searchType === 'srtc') {
        response = response as MatchSalesRequestConsultant;
        this.requestId.set(response.matchId);

        const potentialCandidates =
          response.potentialFreelancers as matchConsultants[];
        allResults = potentialCandidates;
      }

      if (searchType === MatchingTypeName.RequestToConsultant) {
        response = response as MatchingRequestConsultant;
        const potentialCandidates =
          response.potentialCandidates as matchConsultants[];
        allResults = potentialCandidates.map((elm: matchConsultants) => {
          return elm;
        });
      }
      if (searchType === MatchingTypeName.ConsultantToConsultant) {
        response = response as MatchingRequestConsultant;
        const potentialCandidates =
          response.potentialCandidates as matchConsultants[];
        allResults = potentialCandidates.filter((x) => x);
        this.contextService.resultsLength.next(allResults.length);
      }
    } else {
      this.loading.set(false);
      return;
    }

    this.pagination.id = response.id;

    let potentialResults: matchConsultants[];

    if (allResults.length > 0) {
      potentialResults = allResults.splice(0, this.loadLength());
    } else {
      this.potentialRequestResults.set(potentialRequestResults ?? []);
      this.potentialListResults.set(
        allRequestResults.splice(0, this.loadLength())
      );

      this.loading.set(false);
      if (this.potentialRequestResults.length > 0) {
        return;
      }
      this.allResults = this.potentialListResults();
      this.noResults = this.potentialListResults().length === 0 ? true : false;
      return;
    }
    this.potentialResults.set([...potentialResults, ...allResults]);

    // @TODO: check what is srtc
    if (searchType === 'srtc' || searchType === MatchingTypeName.RequestToConsultant) {
      this.potentialResultsCv = this.potentialResults().filter(
        (x) => x.matchingType === 'CV'
      );
      this.potentialResultsFile = this.potentialResults().filter(
        (x) => x.matchingType === 'Files'
      );

      this.potentialResults.set(this.potentialResultsCv);
    }

    this.loading.set(false);
    this.eachLoading = -1;
    this.allResults = this.potentialResults();
    this.noResults = this.potentialResults().length === 0 ? true : false;
    this.matchId = id ?? response.id;
  }

  updateLoadLength(lenght: number) {
    this.loadLength.update((val) => val + lenght);
  }

  private updateFreelancerShortlistedElement(
    freelancerId: string,
    requestId: string
  ) {
    // update the shortlistedSalesRequests array of the freelancer with the given id
    // forces the re-render of the freelancer element
    const results = this.potentialResults();
    const freelancerIndex = results.findIndex((f) => f.id === freelancerId);

    if (freelancerIndex === -1) {
      return;
    }

    const freelancer = { ...results[freelancerIndex] };
    const isAlreadyShortlisted = freelancer.shortlistedSalesRequests?.some(
      (req) => req.id === requestId
    );

    if (!isAlreadyShortlisted) {
      freelancer.shortlistedSalesRequests = [
        ...(freelancer.shortlistedSalesRequests || []),
        { id: requestId },
      ];

      results[freelancerIndex] = freelancer;
      this.potentialResults.set([...results]);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
