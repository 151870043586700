import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, switchMap } from 'rxjs';
import { ICrmCountry, IFiltersResponse } from '../interfaces/dictionaries';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  private http = inject(HttpClient);

  readonly API_URL = environment.apiUrl;
  readonly FILTERS_API_URL = `${this.API_URL}/agentportal/v1/portal/filters`;

  getCountriesDict(): Observable<Array<ICrmCountry>> {
    return this.http.get<IFiltersResponse>(this.FILTERS_API_URL).pipe(
      switchMap((response) => {
        return of(response.filters.crmCountries);
      })
    );
  }
}
