import { Component, Input, Output, EventEmitter } from '@angular/core';
import { by, ConsultantInfo, shortlistedProfiles } from '../../../../../../interface/shared.interface';

@Component({
  selector: 'app-selected-notification',
  templateUrl: './selected-notification.component.html',
  styleUrls: ['./selected-notification.component.scss'],
})
export class SelectedNotificationComponent {
  @Input() selected: shortlistedProfiles[] = [];
  @Output() clear = new EventEmitter<string>();
  @Output() openMessage = new EventEmitter<string>();

  getFullName(value: by) {
    return (
      value.firstName + ' ' + (value.middleName ?? '') + ' ' + value.lastName
    );
  }
}
