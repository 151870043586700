import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'requests',
    pathMatch: 'full'
  },
  {
    path: 'requests',
    loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule),
  },
  {
    path: 'consultants',
    loadChildren: () => import('./consultants/consultants.module').then(m => m.ConsultantsModule),
  },
  {
    path: 'matching',
    loadChildren: () => import('./matching/matching.module').then(m => m.MatchingModule),
  },
  {
    path: 'candidates',
    loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
