import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  InjectionToken,
  input,
} from '@angular/core';
import { tier } from '../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../services/helperFunctions/helper-functions.service';
import { TierStatus, TierIcon, TIER_POPOVER_REF } from '../tier.component';

export const TIER_DATA_POPOVER = new InjectionToken<tier>(
  'TIER_DATA_POPOVER'
);

@Component({
  selector: 'app-tier-content',
  templateUrl: './tier-content.component.html',
  styleUrls: ['./tier-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierContentComponent {
  tierDataInput = input<tier>(null);
  private helperFunction = inject(HelperFunctionsService);
  private tierDataPopover = inject(TIER_DATA_POPOVER, { optional: true });
  popoverRef = inject(TIER_POPOVER_REF, { optional: true });

  tierContentData = computed(() => {
    return this.tierDataPopover || this.tierDataInput()
  });

  isAnyRedDot = computed(() =>
    ['Tier3WithNotice', 'Tier2WithNotice'].includes(this.tierContentData().tierStatus)
  );

  tierStatus = computed(
    () => this.helperFunction.getStatus(this.tierContentData()) as TierStatus
  );

  icon = computed(() => {
    const status = this.tierStatus();
    return TierIcon[status] ?? TierIcon.default;
  });

  getCompletedDate(recruitedUpdated?: string, amountDays?: string): string {
    if (recruitedUpdated) {
      return this.helperFunction.getCompletedDate(recruitedUpdated);
    }
    if (amountDays) {
      return this.helperFunction.getCompletedDate(
        '',
        false,
        JSON.parse(amountDays)
      );
    }
    return null;
  }

  biggerThanStep(level: number): boolean {
    const recruited = this.tierContentData()?.recruitmentSequence;
    const findex = this.helperFunction.recruitedStatus.findIndex((x) =>
      [recruited].includes(x)
    );
    if (findex >= level) {
      return true;
    }
    return false;
  }

  getRule(id: number) {
    if (!this.tierContentData()?.evaluatedRules) {
      return {
        input: null,
        evaluationResult: true,
      };
    }
    return (
      this.tierContentData()?.evaluatedRules.find(
        (x: any) => x.id === id
      ) ?? {
        input: null,
        evaluationResult: true,
      }
    );
  }

  close() {
    this.popoverRef.detach();
    this.popoverRef.dispose();
  }
}
