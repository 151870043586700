import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() value = 1;
  @Input() minLabel = '';
  @Input() maxLabel = '';
  @Input() title = '';
  @Output() change = new EventEmitter<number>();

  onChange(event: MatSliderChange) {
    this.change.emit(event.value!);
  }
}
