import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option } from '../../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';
import { v4 as uuidv4 } from 'uuid';
import { IClaimingFreelancerStatus } from '../../../../../../shared/interfaces/claiming';
@Component({
  selector: 'app-sales-status-select',
  templateUrl: './sales-status-select.component.html',
  styleUrls: ['./sales-status-select.component.scss']
})
export class SalesStatusSelectComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() salesstatus?: IClaimingFreelancerStatus[];
  options: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.salesstatus) {
      this.options = [];
      this.salesstatus.forEach((status) => {
        const findex = this.selected.findIndex(
          (x) => x.value === status.value
        );
        this.options.push({
          id: findex !== -1 ? this.selected[findex].id : uuidv4(),
          displayName: status.name,
          value: status.value,
          type: 'salesstatus',
        });
      });
    }
  }

  selectOption(option: option) {
    if (option.id === '-1') {
      this.selected = [];
      this.selectedOption.emit(this.selected);
      return;
    }
    const findex = this.selected.findIndex((x) => x.id === option.id);
    if (findex === -1) {
      this.selected.push(option);
      this.selectedOption.emit(this.selected);
      return;
    }
    this.selected.splice(findex, 1);
    this.selectedOption.emit(this.selected);
  }
}
