import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ContextService } from '../services/platform/context.service';
import { PlatformService } from '../services/platform/platform-service.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { HelperFunctionsService } from '../services/helperFunctions/helper-functions.service';
import { FeatureFlagsService } from '../services/feature-flags/feature-flags.service';
import { HttpAxiosInterceptorService } from '../services/interceptors/http-axios-interceptor.service';
import { NavigationService } from './shared/services/navigation/navigation.service';
import { InitialRouteService } from './shared/services/navigation/initial-route.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLogin = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private contextService: ContextService,
    private helperFunctions: HelperFunctionsService,
    private plaformService: PlatformService,
    private featureFlags: FeatureFlagsService,
    private axiosInterceptor: HttpAxiosInterceptorService, // init axios interceptor
    private navigationService: NavigationService,
    private initialRouteService: InitialRouteService
  ) {
    this.initialRouteService.captureInitialRouteData();
    
    let referrerHost = '';
    if (document.referrer) {
      referrerHost = new URL(document.referrer).hostname;
    }
    if (referrerHost) {
      if (referrerHost !== window.location.hostname) {
        this.contextService.referrer = referrerHost;
      }
    }

    this.authenticationService.initializeDone.subscribe(async (status) => {
      if (status) {
        await this.plaformService
          .getUserProfile()
          .then((resp) => {
            resp.features.forEach((feature: { name: string }) => {
              this.featureFlags.setFeatureEnabled(feature.name);
            });

            if (resp.features.some((x: any) => x.name === 'consultantlist')) {
              this.navigationService.allowConsultants();
            }
            if (resp.features.some((x: any) => x.name === 'inbox')) {
              this.navigationService.allowCandidates();
              this.navigationService.allowAccepted();

              //Is only used by Kim, but it gives a cross platform error. TODO need to fix on environment.
              // this.plaformService.getStatusCount();
            }

            this.navigationService.setNavigationReady();
          })
          .catch((error) => {
            console.error('[App] getUserProfile error', error);
          });
      }
      this.isLogin = status;
    });
  }

  title = this.contextService.title();
  signin() {
    this.authenticationService.signin();
  }
  signout() {
    this.authenticationService.signout();
  }

  ngOnInit() {
    this.authenticationService.loginDisplay.subscribe({
      next: (val) => {
        this.router.events.subscribe({
          next: (value) => {
            if (value instanceof NavigationEnd) {
              if (value.url === '/' && val) {
                const redirect = sessionStorage.getItem('redirect');
                if (redirect) {
                  sessionStorage.removeItem('redirect');
                  this.helperFunctions.route([redirect]);
                } else {
                  this.helperFunctions.route(['/requests']);
                }
              }
              const arr = value.url.split('/');
              this.contextService.matchingSlug = arr[arr.length - 1];
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.authenticationService.initialize();
  }
}
