import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';
import {
  IconChevronRight,
  IconChevronDown,
  IconChevronLeft,
  IconChevronUp,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconInbox,
  IconPlus,
  IconCirclePlus,
  IconX,
  IconHome,
  IconBriefcase,
  IconFilter,
  IconUserCircle,
  IconCheck,
  IconMoodSmile,
  IconExternalLink,
  IconAddressBook,
  IconLogout,
  IconLogin,
  IconSearch,
  IconInfoCircleFilled,
  IconTrash,
  IconCalendar,
  IconWorld,
  IconBrandLinkedin,
  IconBrandDribbble,
  IconBrandGithub,
  IconDots,
  IconExchange,
  IconMapPin,
  IconTopologyStarRing3,
  IconArrowRight,
  IconClockHour3,
  IconBalloon,
  IconCreditCard,
  IconCopy,
  IconSend,
  IconAt,
  IconPhone,
  IconPuzzle,
  IconMenu2,
  IconMinusVertical,
  IconPencil,
  IconLoader2,
  IconPlaylistAdd,
  IconSparkles,
  IconSortAscending,
  IconSortDescending,
  IconMessageDots,
  IconFileText,
  IconUser,
  IconUsers,
  IconRefresh,
  IconCircleX,
  IconEye,
  IconTextPlus,
  IconListCheck,
  IconMail,
  IconXboxX,
  IconFileCv,
  IconChecks,
  IconCash,
  IconMap,
  IconEdit,
  IconChecklist,
  IconList,
  IconRosetteNumber1,
  IconDiscountCheck,
  IconSortAscendingLetters,
  IconSortDescendingLetters,
  IconInfoCircle,
  IconFile,
  IconDownload,
  IconCrown,
  IconUserPlus,
  IconStar,
  IconLoader,
  IconCircleCheck,
  IconAlertTriangle,
  IconHourglassEmpty,
  IconNote,
  IconClipboardCheck,
  IconCalendarClock,
  IconCalendarUser,
  IconUserCheck,
  IconSquareRoundedX,
  IconClipboardText,
  IconSquareRoundedCheckFilled,
  IconBuilding,
  IconArmchair2,
  IconDeviceLaptop
} from 'angular-tabler-icons/icons';

const icons = {
  IconTopologyStarRing3,
  IconExchange,
  IconChevronRight,
  IconChevronDown,
  IconChevronLeft,
  IconChevronUp,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconInbox,
  IconPlus,
  IconCirclePlus,
  IconEdit,
  IconX,
  IconCircleX,
  IconHome,
  IconCash,
  IconBriefcase,
  IconFilter,
  IconChecklist,
  IconUserCircle,
  IconCheck,
  IconMoodSmile,
  IconExternalLink,
  IconAddressBook,
  IconLogout,
  IconLogin,
  IconSearch,
  IconInfoCircleFilled,
  IconInfoCircle,
  IconTrash,
  IconMapPin,
  IconMap,
  IconCalendar,
  IconWorld,
  IconBrandLinkedin,
  IconBrandDribbble,
  IconBrandGithub,
  IconDots,
  IconArrowRight,
  IconClockHour3,
  IconBalloon,
  IconCreditCard,
  IconCopy,
  IconSend,
  IconAt,
  IconPhone,
  IconPuzzle,
  IconMenu2,
  IconMinusVertical,
  IconPencil,
  IconLoader2,
  IconLoader,
  IconPlaylistAdd,
  IconSparkles,
  IconSortAscending,
  IconSortDescending,
  IconSortAscendingLetters,
  IconSortDescendingLetters,
  IconMessageDots,
  IconFileText,
  IconUser,
  IconUsers,
  IconRefresh,
  IconEye,
  IconTextPlus,
  IconListCheck,
  IconMail,
  IconXboxX,
  IconFileCv,
  IconFile,
  IconChecks,
  IconList,
  IconRosetteNumber1,
  IconDiscountCheck,
  IconDownload,
  IconCrown,
  IconUserPlus,
  IconStar,
  IconCircleCheck,
  IconAlertTriangle,
  IconHourglassEmpty,
  IconNote,
  IconClipboardCheck,
  IconCalendarClock,
  IconCalendarUser,
  IconUserCheck,
  IconSquareRoundedX,
  IconClipboardText,
  IconSquareRoundedCheckFilled,
  IconBuilding,
  IconArmchair2,
  IconDeviceLaptop
};
@NgModule({
  imports: [CommonModule, TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}
