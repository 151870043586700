import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';

export interface InitialRouteData {
  path: string;
  queryParams: Params;
}

export enum InitialRouteModules {
  CANDIDATES,
  CONSULTANTS,
  MAIN, // '/'
  MATCHING,
  OTHER, // all other that aren't important for this service
  REQUESTS,
}

/*
    This service is used to capture the initial route data and provide it to the components that need it.
    Useful for filters and lists that need to know the initial query params to set their initial state.
*/
@Injectable({
  providedIn: 'root',
})
export class InitialRouteService {
  private _initialRouteData$ = new BehaviorSubject<InitialRouteData>(null);

  constructor(private route: ActivatedRoute, private router: Router) {}

  captureInitialRouteData() {
    if (!this._initialRouteData$.value) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe((event: any) => {
          this._initialRouteData$.next({
            path: event.url.split('?')[0],
            queryParams: this.route.snapshot.queryParams,
          });
        });
    }
  }

  getInitialRouteData(): Observable<InitialRouteData> {
    return this._initialRouteData$.asObservable();
  }

  getInitialRouteModule(data: InitialRouteData): InitialRouteModules {
    if (!data) {
      return InitialRouteModules.OTHER;
    }

    const path = data.path.toLocaleLowerCase();
    const moduleMap = {
      '/requests': InitialRouteModules.REQUESTS,
      '/candidates': InitialRouteModules.CANDIDATES,
      '/consultants': InitialRouteModules.CONSULTANTS,
      '/matching': InitialRouteModules.MATCHING,
    };

    if (path === '/') {
      return InitialRouteModules.MAIN;
    }

    for (const [key, value] of Object.entries(moduleMap)) {
      if (path.startsWith(key)) {
        return value;
      }
    }

    return InitialRouteModules.OTHER;
  }
}
