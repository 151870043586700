import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
  requestLevels2,
  requestV3,
  salesAgent
} from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-request-level2',
  templateUrl: './request-level2.component.html',
  styleUrls: ['./request-level2.component.scss'],
})
export class RequestLevel2Component {
  constructor(private helperFunctions: HelperFunctionsService) {}
  @Input() request: requestV3;
  @Input() requestLevel3: boolean;
  readMore: boolean;
  truncated: boolean;
  @ViewChild('descriptionText') descriptionText: ElementRef<HTMLElement>;

  getDate(value: string) {
    if (!value) {
      return '-';
    }
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }

  getFullname(salesAgent: salesAgent) {
    return salesAgent.firstName + ' ' + salesAgent.lastName;
  }

  observer = new ResizeObserver(() => {
    this.isTruncated();
  });

  ngAfterViewInit() {
    if (this.descriptionText) {
      this.observer.observe(this.descriptionText.nativeElement);
    }
  }
  clickReadMore() {
    this.readMore = !this.readMore;
  }

  isTruncated() {
    if (this.descriptionText) {
      const element = this.descriptionText.nativeElement;
      if (!this.readMore) {
        this.truncated = element.offsetHeight < element.scrollHeight;
      }
    }
  }
}
