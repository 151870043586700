import { Component, Input } from '@angular/core';
import { classes } from '../../../interface/shared.interface';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() leadingIcon = '';
  @Input() trailingIcon = '';
  @Input() number = '';
  @Input() label = '';
  @Input() amount = '';
  @Input() class: classes[] = [];
  @Input() disabled: boolean;
}
