import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SearchAddressResponse, Models } from 'azure-maps-rest';
import { map, Observable } from 'rxjs';
import {
  ICoordinate,
  ILocationRangeInputAddressOption,
  ILocationService,
} from '../../interfaces/location';
import { GlobalStore } from '../../store/global/global.store';

@Injectable({
  providedIn: 'root',
})
export class AzureMapsService implements ILocationService {
  private httpClient = inject(HttpClient);
  private globalStore = inject(GlobalStore);

  VERSION = '1.0';

  getBrowserLanguage(): string {
    const lang =
      navigator.language ||
      (navigator.languages && navigator.languages[0]) ||
      'en';
    // Azure Maps API expects language in the format of 'en-US'
    if (lang.includes('-')) {
      return lang;
    } else {
      return lang + '-' + lang.toUpperCase();
    }
  }

  //  https://learn.microsoft.com/en-us/rest/api/maps/search/get-search-address?view=rest-maps-1.0&preserve-view=true&tabs=HTTP

  getAddressSearchResults(
    address: string
  ): Observable<Array<ILocationRangeInputAddressOption>> {
    const key = this.globalStore.getAzureMapsKey();

    if (!key) {
      throw new Error('[AzureMapsService] Azure Maps API key is not set');
    }

    return this.httpClient
      .get<SearchAddressResponse>(
        `https://atlas.microsoft.com/search/address/json?subscription-key=${key}&api-version=${
          this.VERSION
        }&query=${address}&language=en-EN&limit=50&entityType=${
          Models.EntityType.Municipality // City / Town
        }`
      )
      .pipe(
        map(
          (response) =>
            (response.results || []).filter(
              (result) =>
                result.address && Object.keys(result.address).length > 0
            ) // filter out results without address
        ),
        map((results) =>
          results.map((result) => {
            const item: ILocationRangeInputAddressOption = {
              name: `${result.address?.freeformAddress}`,
              labelOption: {
                flagImgUrl: '',
                countryCode: result.address?.countryCode || '',
                countryName: result.address?.country || '',
                address: `${result.address?.freeformAddress} (${result.address?.countrySubdivisionName})`,
              },
              value: this.getAddressCoordinate(result),
            };
            return item;
          })
        )
      );
  }

  getAddressCoordinate(
    address: Models.SearchAddressResult
  ): ICoordinate | null {
    if (address.position) {
      return {
        lat: address.position.lat,
        lon: address.position.lon,
      };
    }
    return null;
  }
}
