import { matchConsultants, option } from 'src/interface/shared.interface';
import { ContextService } from 'src/services/platform/context.service';

// Function to remove a filter from the selected filters and update query parameters accordingly
export function removeFilterFunc(
  filter: option,
  selectedFilters: option[],
  queryParams: any
) {
  // Find the index of the filter to be removed
  const findex = selectedFilters.findIndex((x) => x.id === filter.id);

  // Handle removal of keyword filters
  if (findex !== -1) {
    const type = selectedFilters[findex].type!;
    if (
      [
        'keywords',
        'relationstatus',
        'status',
        'office',
        'country',
        'team',
        'addressCountry'
      ].includes(type)
    ) {
      selectedFilters.splice(findex, 1);

      const displayName = selectedFilters
        .filter((x) => x.type === type)
        .map((x) => x.type !== 'addressCountry'? x.displayName : x.id);
      queryParams[type] = displayName.join(',');
      if (displayName.length === 0) {
        delete queryParams[type];
      }
    } else if (type === 'mapLocation') {
      // mapLocation can be an array, so we need to handle it differently
      if (Array.isArray(queryParams['mapLocation']) && queryParams['mapLocation'].length > 1) {
        queryParams['mapLocation'] = queryParams['mapLocation'].filter((x) => x !== selectedFilters[findex].displayName);
      } else {
        delete queryParams['mapLocation'];
      }
    }
  } else {
    // Handle removal of other types of filters
    if (selectedFilters[findex].type === 'availability') {
      delete queryParams.availability;
      delete queryParams.range;
    }
    if (selectedFilters[findex].type === 'workedwith7n') {
      delete queryParams.workedwith7n;
    }

    selectedFilters.splice(findex, 1);
  }
  return { selectedFilters, queryParams };
}

// Function to filter a list of potential results based on the selected filter options
export async function filteringList(
  filterOptions: option[],
  contextService: ContextService,
  potentialResults: matchConsultants[],
  rawPotentialResults?: matchConsultants[]
) {
  // If no filters are applied and raw results are available, use raw results
  if (filterOptions.length === 0 && rawPotentialResults) {
    potentialResults = rawPotentialResults;
    contextService.resultsLength.next(potentialResults.length);
    return { potentialResults, rawPotentialResults, filterOptions };
  }
  if (!rawPotentialResults || rawPotentialResults.length === 0) {
    rawPotentialResults = potentialResults ?? [];
  }

  potentialResults = [];
  rawPotentialResults.forEach((x) => {
    const found = [];
    const availability = filterOptions.filter((x) => x.type === 'availability');
    if (availability.length > 0) {
      const option = availability[0];
      const range = option.range;
      if (!x.availability) {
        found.push(false);
        return;
      }
      const availDate = Math.round(new Date(x.availability).getTime() / 1000);
      const d = new Date();
      if (option.id != '3available') {
        let days = -0;
        if (option.id === '0available') {
          days = 7;
        }
        if (option.id === '1available') {
          days = 30;
        }
        if (option.id === '2available') {
          days = 90;
        }
        d.setDate(d.getDate() + days);
        const timestamp = Math.round(d.getTime() / 1000);
        found.push(timestamp >= availDate);
      }
      if (range) {
        if (range.startDate && range.endDate) {
          const startDate = Math.round(
            new Date(range.startDate).getTime() / 1000
          );
          const endDate = Math.round(new Date(range.endDate).getTime() / 1000);
          found.push(availDate >= startDate && availDate <= endDate);
        }
      }
    }
    const workedAs7N = filterOptions.filter((x) => x.type === 'workedwith7n');
    if (workedAs7N.length > 0) {
      found.push(+(x.tier.contracts as string) > 0);
    }
    const location = filterOptions.filter((x) => x.type === 'location');
    if (location.length > 0) {
      found.push(
        x.workLocations.some((z) =>
          z.states.some((s) => location.some((f) => f.displayName === s))
        )
      );
    }
    const statuses = filterOptions.filter((x) => x.type === 'status');
    if (statuses.length > 0) {
      found.push(
        statuses.some((option: option) => {
          return (
            option.displayName.toLocaleLowerCase() ===
            x.crmStatus.toLocaleLowerCase()
          );
        })
      );
    }

    if (found.every((x) => x === true) || found.length === 0) {
      potentialResults.push(x);
    }
  });
  return { potentialResults, rawPotentialResults, filterOptions };
}
