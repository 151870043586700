@if (bulkSelect().length > 0 && !hideSelectNotification()) {
  <app-selected-notification (clear)="bulkSelect.set([])"
                             (openMessage)="openBulkMessaging()"
                             [selected]="bulkSelect()"></app-selected-notification>
}

@if (!firstTimeLoading()) {
  <div class="request surface--2" id="request_view">
    <div class="max-width">
      <div class="section surface--2">
        <div class="top flex flex-between-center">
          <app-button routerLink=".."
                      [class]="['ghost', 'small']"
                      leadingIcon="chevron-left"
                      label="back to list"></app-button>

          <div class="hidden md:flex gap-[6px] ">
            <app-button label="Open in maching" 
                        [class]="['xsmall', 'secondary']"
                        [disable]="!selectedRequest().isDescription"
                        (click)="openRtcMatch($event)"></app-button>

            <app-button label="Open in CRM" 
                        [class]="['xsmall', 'secondary']"
                        [disable]="!selectedRequest()?.crmLink"
                        (click)="openInCrm($event)"></app-button>
          </div>
        </div>
        <div class="request">
          @if (selectedRequest()) {
            <app-request-level1 [level0]="false"
                                [level3]="true"
                                [usedOnDetails]="true"
                                [selectedRequest]="selectedRequest()"
                                [expand]="true"></app-request-level1>
          }
        </div>
      </div>
    </div>
    <div class="max-width">
      <div class="section surface--2 shortlisted">
        <div class="title-button">
          <div class="s2">Shortlisted</div>
          <app-button [class]="['secondary']"
                      leadingIcon="plus"
                      label="Add consultant"
                      (click)="addConsultant()"></app-button>
        </div>
        <div class="profiles">
          <div class="switch-sort">
            <app-switch [isToggled]="showNotSold()"
                        (callbackFunction)="handleNotSold($event)"
                        label="Show not sold"></app-switch>
            <div class="sortedBy">
              <p class="body-small textSecondary">
                Sort by: <span class="bold">Activity</span>
              </p>
            </div>
          </div>
          <div class="enlistedProfiles">
            @if (selectedRequest()) {
              @for (result of enlistedProfiles(); track result.id) {
                <app-shortlisted-profile [selectedRequest]="selectedRequest()"
                                         [consultantStatus]="consultantStatus()"
                                         [consultant]="result"
                                         [selectedProfiles]="bulkSelect()"
                                         (updateResults)="getRequest()"
                                         (checkedStateChange)="onSelectionChange($event)"></app-shortlisted-profile>
              }
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
