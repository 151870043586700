import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
  Signal,
  signal,
} from '@angular/core';
import { requestV3 } from '../../../../../../../interface/shared.interface';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { ConsultantsService } from '../../../../../../consultants/services/consultants.service';
import { ISearchConsultantsItem } from '../../../../../../consultants/interfaces/consultant';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'app-search-consultant',
  templateUrl: './search-consultant.component.html',
  styleUrls: ['./search-consultant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchConsultantComponent {
  private snackBarService = inject(SnackBarService);
  private consultantsApi = inject(ConsultantsService);

  addable = input<boolean>();
  selectedRequest = input.required<requestV3>();
  toggleDropdown = model<boolean>();

  selectconsultant = output<ISearchConsultantsItem>();

  allConsultants = signal<ISearchConsultantsItem[]>([]);
  loading = signal(false);
  isEmptyInput = signal(true);

  readonly CONSULTANTS_LIMIT = 20;

  consultantsSorted: Signal<ISearchConsultantsItem[]> = computed(() => {
    const consultants = this.allConsultants();
    const isEmptyInput = this.isEmptyInput();

    if (isEmptyInput) {
      return [];
    }

    return consultants.sort((a, b) => {
      return `${a.firstName} ${a.lastName}`
        .toLocaleLowerCase()
        .localeCompare(`${b.firstName} ${b.lastName}`.toLocaleLowerCase());
    });
  });

  select(selectconsultant: ISearchConsultantsItem) {
    if (
      selectconsultant.added ||
      (!selectconsultant.applicableForC2ROrC2C && !this.addable())
    ) {
      return;
    }
    this.selectconsultant.emit(selectconsultant);
    this.toggleDropdown.set(false);
  }

  getConsultants(searchString = '') {
    this.loading.set(true);

    const consultantsProfileTypeCvRequest$ = this.consultantsApi.getConsultants(
      `search=${searchString}&profiletype=cv&limit=${this.CONSULTANTS_LIMIT}`
    );

    const consultantsProfileTypeFilesRequest$ =
      this.consultantsApi.getConsultants(
        `search=${searchString}&profiletype=files&limit=${this.CONSULTANTS_LIMIT}`
      );

    return forkJoin([
      consultantsProfileTypeCvRequest$,
      consultantsProfileTypeFilesRequest$,
    ])
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.loading.set(false);

          const results: ISearchConsultantsItem[] = [];

          const consultantsFromResponse = [
            ...response[0].elements,
            ...response[1].elements,
          ];

          consultantsFromResponse?.forEach((x) => {
            const item: ISearchConsultantsItem = {
              ...x,
              added: x.shortlistedSalesRequests.some(
                (z) => z.id === this.selectedRequest()?.id
              ),
            };

            results.push(item);
          });

          this.allConsultants.set(results);
        },
        error: () => {
          this.loading.set(false);
          this.allConsultants.set([]);
          this.snackBarService.open({
            message: `Failed to get consultants!`,
            type: SnackBarTypes.ErrorAlt,
          });
        },
      });
  }

  searchConsultant(value: string) {
    this.isEmptyInput.set(!value.length);
    if (value.length > 1) {
      this.getConsultants(value);
    } else {
      this.allConsultants.set([]);
    }
  }
}
