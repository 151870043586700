<div
  class="location-filter-form"
  [formGroup]="locationFilterFormGroup"
  [ngClass]="{ 'disabled': disabled }"
>
  <ng-container formArrayName="locationRanges">
    <div
      class="location-filter-item-container"
      *ngFor="let filter of filters; let i = index"
      trackByFn="trackByFn"
    >
      <div class="location-filter-item">
        <app-location-range-input [form]="filter" [disabled]="disabled"></app-location-range-input>
        <i-tabler
          *ngIf="showRemoveLocationRangeButton()"
          (click)="removeLocationRange(i)"
          class="location-filter-item-close-btn body-small clear"
          name="X"
        ></i-tabler>
      </div>
    </div>
    <app-button
      *ngIf="showAddLocationRangeButton()"
      [attr.class]= "'add-location-range-input-item-btn'"
      (click)="addLocationRange()"
      [disable]="disabled"
      trailingIcon="circle-plus"
      [label]="'Add another location'"
      [class]="['secondary', 'small', 'body-small']"
    ></app-button>
  </ng-container>
</div>
