import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';
import { option, requestV3 } from '../../../../../interface/shared.interface';
import { routeParams } from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { removeFilterFunc } from '../shared-functions/filtering';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  requestsList: requestV3[] = [];
  requestsListRaw: requestV3[] = [];
  selectedRequest: requestV3;
  rawOptions: requestV3[];
  loading = true;
  requestId: string;
  desAsc: boolean;
  key: string;
  rerender = false;
  selectedFilters: option[];
  queryParams: any;
  isToggled = false;
  extension_FederatedIssuerAssignedId: string;
  onlyMyRequest: boolean;
  constructor(
    private platformService: PlatformService,
    private activeRoute: ActivatedRoute,
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService
  ) {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        this.queryParams = { ...params };
      });
    }
    if (this.contextService.referrer) {
      this.matomoTracker.trackEvent(
        'Referrer',
        'Rquests - ' + this.contextService.referrer
      );
      this.contextService.referrer = '';
    } else {
      this.matomoTracker.trackEvent('Requests', 'Selected in navigation');
    }
  }

  decodeToken(token: string): any {
    const payloadBase64 = token.split('.')[1]; // Extract the payload
    return JSON.parse(atob(payloadBase64)); // Decode and parse it
  }

  myRequests(value: boolean) {
    const decodeToken = this.decodeToken(
      this.authentication.accessTokens['genome']
    );
    if (decodeToken) {
      this.extension_FederatedIssuerAssignedId =
        decodeToken.extension_FederatedIssuerAssignedId;
      this.onlyMyRequest = value;
      this.contextService.globalFilterR.next(this.selectedFilters);
    }
  }

  // Function to remove a filter from the selected filters and update the query parameters
  async removeFilter(filter: option) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = { ...params };
    });
    const { selectedFilters, queryParams } = await removeFilterFunc(
      filter,
      this.selectedFilters,
      this.queryParams
    );
    this.selectedFilters = selectedFilters;

    await this.router.navigate([], { queryParams });
  }

  // Function to clear all selected filters and optionally perform a new search
  clearAll(newSearch?: boolean) {
    this.selectedFilters = [];

    this.contextService.globalFilterR.next([]);
    if (newSearch) {
      this.router.navigate([], {});
    }
  }
  // This lifecycle hook initializes the component by fetching the requests list and subscribing to route parameters
  async ngOnInit() {
    // Subscribe to route parameters to handle deeplinks and set the request ID
    await this.activeRoute.params.subscribe((params) => {
      params = params as routeParams;
      if (params['deeplink']) {
        this.requestId = params['deeplink'].toLowerCase();
      }
    });
    if (this.requestId) {
      return;
    }
    // Subscribe to global filter changes and handle the selected filters
    this.contextService.globalFilterR.subscribe(
      async (selectedFilters: option[]) => {
        this.selectedFilters = selectedFilters;

        this.requestsList = this.requestsListRaw.filter((x) => {
          const include = [];

          const countries = this.selectedFilters.filter(
            (c) => c.type === 'countries'
          );
          const agent = this.selectedFilters.filter((c) => c.type === 'agent');
          const priority = this.selectedFilters.filter(
            (c) => c.type === 'priority'
          );
          const salesstatus = this.selectedFilters.filter(
            (c) => c.type === 'salesstatus'
          );
          const office = this.selectedFilters.filter(
            (c) => c.type === 'office' || c.type === 'country'
          );
          const team = this.selectedFilters.filter((c) => c.type === 'team');

          if (this.onlyMyRequest) {
            include.push(
              this.extension_FederatedIssuerAssignedId ===
              x.ownedBy.federatedIssuerAssignedId ||
              x.recruitmentAgents.some(
                (s1) =>
                  this.extension_FederatedIssuerAssignedId ===
                  s1.federatedIssuerAssignedId
              )
            );
          }

          if (countries.length > 0) {
            include.push(countries.some((s) => s.name === x.country));
          }

          if (agent.length > 0) {
            include.push(
              agent.some(
                (s) =>
                  s.id === x.ownedBy.federatedIssuerAssignedId ||
                  x.recruitmentAgents.some(
                    (s1) => s.id === s1.federatedIssuerAssignedId
                  )
              )
            );
          }
          if (priority.length > 0) {
            include.push(priority.some((s) => s.displayName === x.priority));
          }
          if (salesstatus.length > 0) {
            include.push(
              salesstatus.some(
                (s) => s.displayName === x.highestSalesStatus?.salesStatus
              )
            );
          }
          if (office.length > 0) {
            include.push(
              office.some((s) => s.displayName === x.ownedBy.office) ||
              office.some((s) => s.displayName === x.ownedBy.country)
            );
          }
          if (team.length > 0) {
            include.push(team.some((s) => s.displayName === x.ownedBy.team));
          }
          return include.every((e) => e);
        });
      }
    );
    // Fetch the list of sales requests from the platform service
    await this.platformService.getRequestsList().then((response) => {
      this.requestsList = response.elements.sort((a, b) => b.createdAt - a.createdAt); // sorted by creation date DESC
      this.requestsListRaw = this.requestsList;
      this.requestsList.forEach((x: requestV3) => {
        if (x.country) {
          this.contextService.filterData.countries.push({
            displayName: this.helperFunctions.getCountryName(x.country) ?? '-',
            value: x.country,
          });

          this.contextService.filterData.countries =
            this.contextService.filterData.countries
              .filter(
                (country, index, self) =>
                  index ===
                  self.findIndex((p) => p.displayName === country.displayName)
              )
              .sort((a: any, b: any) => {
                return this.compare(a, b, 'displayName');
              });
        }
        this.contextService.filterData.agents.push(...x.recruitmentAgents);
        if (x.ownedBy) {
          this.contextService.filterData.agents.push(x.ownedBy);
        }
        this.contextService.filterData.agents =
          this.contextService.filterData.agents
            .filter(
              (person, index, self) =>
                index ===
                self.findIndex(
                  (p) =>
                    p.federatedIssuerAssignedId ===
                    person.federatedIssuerAssignedId
                )
            )
            .sort((a: any, b: any) => {
              return this.compare(a, b, 'firstName');
            });
      });
      this.loading = false;
    });

    this.contextService.resultsLength.next(this.requestsList.length);
  }

  // Compares two objects based on a specified key for sorting purposes
  compare(a: any, b: any, key: string) {
    // Handle string comparisons for specific keys
    if (['title', 'customerName', 'owner', 'firstName'].includes(key)) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) {
        return -1;
      }
      if (a[key].toLowerCase() > b[key].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    // Handle numeric comparisons for other keys
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }

  // Sorts the sales requests based on a specified key and toggles ascending/descending order
  sort(value: string) {
    this.desAsc = !this.desAsc;

    // Sort the sales requests using the compare function
    this.requestsList.sort((a: requestV3, b: requestV3) => {
      return this.desAsc
        ? this.compare(a, b, value)
        : this.compare(b, a, value);
    });
  }

  // Routes to the matching page with the selected request ID
  searchInMathing() {
    this.helperFunctions.route(['matching', 'rtc', this.selectedRequest.id]);
  }

  // Routes to the request details page with the given request ID
  openRequest(id: string) {
    this.helperFunctions.route(['requests', id]);
  }

  // Formats and returns a date using the helper functions, or returns a dash if no date is provided
  getDate(date?: number) {
    if (date) {
      return this.helperFunctions.getDate(date);
    }
    return '-';
  }

  // Filters the sales requests based on a search value
  search(value: string) {
    if (!this.rawOptions) {
      this.rawOptions = this.requestsList;
    }

    if (value.length === 0) {
      this.requestsList = [];
    }
    if (value.length === 0) {
      this.requestsList = this.rawOptions;
      return;
    }

    // Filter the sales requests based on title, customer name, or owner
    this.requestsList = this.rawOptions.filter((x) => {
      const findbyTitle = x.title
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(value.toLowerCase().replace(/\s/g, ''));
      const findbyCustomerName = x.customer.name
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(value.toLowerCase().replace(/\s/g, ''));
      // Return true if any of the fields match the search value
      if (!findbyTitle) {
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }
}
