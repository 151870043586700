<div class="each-consultant" [attr.id]="consultant().id">
  <app-select-items (click)="toggleSelection()"
                    [isChecked]="isSelected()"
                    [disabled]="isCheckboxDisabled()"
                    type="checkbox"></app-select-items>
  <div class="bundle surface--1" [ngClass]="{ selected: isSelected() }">
    <div class="status-bar"
         [class]="{ disabled: isDisabled }">
      <div class="process"
           [style]="'width:' + getProcessLength(consultant().salesStatus) + '%'"></div>
      <div class="bundle-left">
        <app-dropdown class="small"
                      trailingIcon="chevron-down"
                      type="salesNotes"
                      (selectedCallback)="selectedSaleNotes(consultant(), $event)"
                      [selected]="getSelected(consultant().salesStatus)"
                      [salesStatus]="consultantStatus()"></app-dropdown>

        <li></li>
        <p class="tiny">
          Added
          {{ getDateTimestamp(consultant().createdOn) }}
          by
          {{ consultant().addedBy }}
        </p>
      </div>
      <div class="bundle-right">
        <p class="tiny">
          <span class="bold changeTitle"> Sales status change </span>
          {{ dateDifference(consultant().modifiedOn) }}
          ago
        </p>
      </div>
    </div>
    <div class="card-info"
         (click)="toggleLevel2()"
         [ngClass]="{ expandLevel2: expandLevel2(), 'disabled': isDisabled }">
      <div class="chevron">
        <i-tabler [name]="'chevron-' + (expandLevel2() ? 'up' : 'down')"></i-tabler>
      </div>
      <app-avatar [by]="consultant()"
                  [tier]="consultant().tier"
                  class="small"
                  [tiers]="true"></app-avatar>

      <div class="text">
        <div class="bundle-cta">
          @if (consultant().tier) {
            <div class="bundle-with-tier">
              <div class="name-title"
                   [class]="{ reverse: consultant().tier.status }">
                <h3 class="bold">
                  {{ getFullName(consultant()) }}

                  @if (consultant().remarks) {
                    <div class="remarks warning--Medium"></div>
                  }
                </h3>

                @if (consultant().lastRole) {
                  <p class="body-large last-role">{{ consultant().lastRole }}</p>
                }
              </div>
              @if (consultant().tier.status) {
                <div class="tier">
                  <app-tier-statuses [tier]="consultant().tier"></app-tier-statuses>
                </div>
              }
            </div>
          }
          <div class="cta">
            @if (consultant().salesNotes) {
              <div class="salesNotes">
                <p class="tiny surface--3">
                  “{{ cleanText(consultant().salesNotes) }}“
                </p>
                <div class="arrow"></div>
              </div>
            }
            <div class="bundle-cta-badge">
              <app-cta classes="brand--Lightest textLink"
                       icon="note"
                       (click)="openNote($event)"></app-cta>

              @if (getLengthOfNotes(consultant().salesNotes)) {
                <app-badge [amount]="getLengthOfNotes(consultant().salesNotes)"
                           color="amount tiny brand--Dark textContrast"></app-badge>
              }
            </div>
            <div class="bundle-cta-badge">
              <app-cta classes="brand--Lightest textLink"
                       icon="send"
                       (click)="openMessaging($event)"></app-cta>
              @if (consultant().declarationInformation) {
                <app-badge [color]="getColor(consultant().declarationInformation) + ' tiny '"
                           [icon]="getStatus(consultant().declarationInformation)"></app-badge>
              }
            </div>
            <app-cta classes="brand--Lightest textLink"
                     icon="user"
                     (click)="openProfile($event)"></app-cta>
            <div (clickOutside)="closeDropdown($event)"
                 class="bundle-dropdown">
              <app-cta [classes]="' textLink'"
                       icon="dots"
                       (click)="openDropdown($event)"></app-cta>

              @if (openedDropdownId() === consultant().id) {
                <app-simple-dropdown (click)="closeDropdown($event)"
                                     [shortlistedProfiles]="consultant()"></app-simple-dropdown>
              }
            </div>
          </div>
        </div>
        <div class="title">
          <p class="body-large">{{ consultant().lastRole }}</p>
        </div>
        <div class="info">
          <div class="country body-large">
            <i-tabler width="16px" height="16px" name="map-pin"></i-tabler>
            <p class="body-small">
              @if (consultant().location.city) {
                <span class="hide-on-mobile">{{ consultant().location.city }}</span>
              }

              @if (consultant().location.stateOrProvince) {
                <span class="hide-on-mobile">
                  {{ consultant().location.stateOrProvince.toLocaleUpperCase() }}
                </span>
              }

              @if (consultant().location.countryCode) {
                <span class="hide-on-mobile">
                  {{ getCountry(consultant().location.countryCode) }}
                </span>
              }
            </p>
          </div>
          <div class="li"></div>
          <p class="body-small">
            <span>Available</span>
            {{ getDate(consultant().availability) }}
          </p>
          <div class="li"></div>
          <p class="body-small">
            {{ validateRate(consultant().expectedRate) }} / h
          </p>
        </div>
      </div>
    </div>
    @if (expandLevel2()) {
      <app-level2 [consultant]="consultant()"></app-level2>
    }
  </div>
</div>
