import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { smartTip } from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';

@Component({
  selector: 'app-smarttip',
  templateUrl: './smarttip.component.html',
  styleUrls: ['./smarttip.component.scss'],
})
export class SmarttipComponent implements OnChanges {
  @Input() smartTip: smartTip;
  allKeywords = false;
  showReadMoreBtn = false;
  @ViewChild('flexContainer') flexContainer: ElementRef;

  constructor(private contextService: ContextService) {}
  getKeywordsLength(): number {
    const length = this.smartTip.keywords.length;
    if (length >= 20) {
      return 20;
    }
    return length;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['smartTip']) {
      if (this.flexContainer?.nativeElement) {
        setTimeout(() => {
          const container = this.flexContainer.nativeElement;
          this.showReadMoreBtn =
            container.scrollHeight > container.clientHeight;
        }, 100);
      }
    }
  }

  openExplainerInfo() {
    this.contextService.openMatchModal = 'explainerInfo';
  }
}
