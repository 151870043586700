import { Injectable } from '@angular/core';
import { searchContext, ProProfile, shortlistedProfiles } from '../../interface/shared.interface';
import { ApiService } from '../api/api.service';
import { HelperFunctionsService } from '../helperFunctions/helper-functions.service';
import { ContextService } from './context.service';
import {
  ConsultantInfo,
  declarationOfInterest
} from '../../interface/shared.interface';
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(
    private apiService: ApiService,
    private contextService: ContextService,
    private helperFunctions: HelperFunctionsService
  ) {}
  async declarationOfInterest(
    id: string,
    type: string,
    payload?: declarationOfInterest,
    consultants?: { freelancers: string[]; languageCode: string }
  ) {
    if (type === 'post') {
      return this.apiService.postDeclarationOfInterest(id, consultants!);
    }
    if (type === 'get') {
      return this.apiService.getDeclarationOfInterest(id);
    }
    if (type === 'getFree') {
      return this.apiService.getDeclarationOfInterestconsultant(id);
    }
    if (type === 'put') {
      return this.apiService.putDeclarationOfInterest(id, payload!);
    }
    return;
  }
  async getSmartTip(entityId: number, query?: string) {
    return this.apiService
      .getSmartTip(entityId, query)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async getAllConsultants(query?: string) {
    return this.apiService
      .consultants(query)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async description(id: string, version = 'v1') {
    return this.apiService
      .getMatch(id + '/description', version)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getRequestsList() {
    return (
      this.apiService
        .getConsultants('requests', 'v3')
        // .getRequest('level2', 'v2')
        // .getConsultants('matching/matches')
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }

  async getRequests(id = '') {
    return this.apiService
      .getRequests('/requests/' + id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async iterateMatch(id: string, searchObject?: searchContext) {
    return this.apiService
      .postMatch(id + '/iterate', searchObject)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async requestId(id: string, version: string) {
    return this.apiService
      .getRequest(id, version)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  async getConsultantStatus() {
    return this.apiService
      .getAgentportal('/matching/freelancer/status', '/v1')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  async putConsultantStatus(matchId: string, consultant: shortlistedProfiles) {
    return this.apiService
      .putAgentportal(
        'v2/match/' + matchId + '/freelancer/' + consultant.id + '/status',
        { salesStatus: consultant.salesStatus }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  async postconsultantsalesStatus(
    matchId: string,
    crmId: string,
    addedNote: string
  ) {
    return this.apiService
      .postAgentportal(
        'v2/match/' + matchId + '/freelancer/' + crmId + '/salesNotes',
        { salesNotes: addedNote }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  async getCachedFilter(matchid: string, itereateId: number, query: string) {
    return this.apiService
      .getCachedFilter(matchid, itereateId, query)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  async getCachedRequests(matchid: string, itereateId: number, head = false) {
    if (head) {
      return this.apiService
        .headCachedMatch(matchid, itereateId)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    }
    return this.apiService
      .getCachedMatch(matchid, itereateId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  async matchRequestId(id: string, head?: boolean) {
    if (head) {
      return this.apiService
        .headMatch(id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    }
    return this.apiService
      .getMatch(id + '?imageVariant=L')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  async longList(
    matchId: string,
    freelance: { freelancerId: string; salesNotes: string }
  ) {
    return this.apiService
      .postMatch(matchId + '/longlist', freelance, 'v2')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async consultant(id = '') {
    return this.apiService
      .getConsultants('matching/consultant/' + id + '?imageVariant=S', 'v2')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getFilters(id: string, iterateId: number, searchType = 'rtc') {
    if (searchType === 'filters') {
      return this.apiService
        .portalFilters()
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    }
    if (searchType === 'ctc') {
      return this.apiService
        .getFilters(id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    }
    return this.apiService
      .getMatchFilters(id, iterateId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  async matchFilters(uri: string, version?: string) {
    return this.apiService
      .getAgentportal(uri, version)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  async matchrequesttoconsultants(
    searchObject: searchContext,
    matchingType: string,
    query?: string
  ) {
    searchObject.matchingType = matchingType;
    return this.apiService
      .getMatching(
        'matching/matchrequesttoconsultants',
        searchObject,
        'v2',
        query
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  async matchconsultanttorequests(
    searchObject: searchContext,
    version?: string
  ) {
    return this.apiService
      .getMatching('matching/matchconsultanttorequests', searchObject, version)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  async matchconsultanttoconsultants(
    searchObject: searchContext,
    version: string,
    query?: string
  ) {
    return this.apiService
      .getMatching(
        'matching/matchconsultanttoconsultants' + (query ? '?' + query : ''),
        searchObject,
        version
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  getStatusCount() {
    const onBoardingStatus = new Promise((resolve) => {
      this.apiService
        .getAgentportal('/portal/metrics/genomesbyonboardingstatus')
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          resolve([]);
        });
    });

    Promise.all([onBoardingStatus]).then((values: any[]) => {
      const findIndox = this.contextService.navs.dashboard.findIndex(
        (x) => x.slug === 'inbox'
      );
      this.contextService.navs.dashboard[findIndox].allowed = true;
      const findAccept = this.contextService.navs.dashboard.findIndex(
        (x) => x.slug === 'accepted'
      );
      this.contextService.navs.dashboard[findIndox].count =
        values[0].newLead +
        values[0].pendingInterview +
        values[0].rejected +
        values[0].registered;
      this.contextService.navs.dashboard[findIndox].submenu![0].count =
        values[0].registered;
      this.contextService.navs.dashboard[findIndox].submenu![1].count =
        values[0].newLead;
      this.contextService.navs.dashboard[findIndox].submenu![2].count =
        values[0].pendingInterview;
      this.contextService.navs.dashboard[findIndox].submenu![3].count =
        values[0].rejected;
      this.contextService.navs.dashboard[findAccept].count = values[0].approved;
    });
  }
  async getData(slug?: string, id?: string) {
    if (['matching'].some((x) => x === slug)) {
      return;
    }

    this.promiseProProfile(slug, id)
      .then((response) => {
        if (response) {
          response.profile.data = response.profile.data.sort(
            this.helperFunctions.createdAtTime
          );
          const campaign: ProProfile[] = response.profile.data
            .filter((x: any) => x.campaign !== '')
            .map((x: any, i: number) => {
              return {
                displayName: x.campaign ?? 'No Campaign',
                id: JSON.stringify(i),
                empty: !x.campaign,
              };
            });
          this.contextService.campaign = this.helperFunctions
            .uniqueProProfile(campaign)
            .sort((a: ProProfile, b: ProProfile) => {
              if (a.empty! > b.empty!) {
                return -1;
              }
              if (a.empty! < b.empty!) {
                return 1;
              }
              return 0;
            });

          response.profile.data = response.profile.data.filter(
            (x: ProProfile) => x.id
          );
          if (slug === 'unsubmitted') {
            response.profile.data = response.profile.data.sort(
              this.helperFunctions.createdAtTime
            );
          }

          this.contextService.profileData = response.profile.data;
          this.contextService.profileData.forEach((val) => {
            const regionNames = new Intl.DisplayNames(['en'], {
              type: 'region',
            });
            const countryCode = val.countryCode;
            if (countryCode) {
              this.contextService.countryCode.push({
                displayName: regionNames.of(countryCode) as string,
                id: countryCode,
              });
            }
          });

          this.contextService.skillsData = response.skills.data.sort(
            this.helperFunctions.compare
          );
          this.contextService.technologiesData =
            response.technologies.data.sort(this.helperFunctions.compare);
          this.contextService.familiesData = response.families.data.sort(
            this.helperFunctions.compare
          );
          this.contextService.industriesData = response.industries.data.sort(
            this.helperFunctions.compare
          );

          this.contextService.consultantData = response.consultant;
          this.contextService.dataLoaded.next('success');
        }
      })
      .catch((error) => {
        this.contextService.dataLoaded.next('error:' + error);
      });
  }
  async getUserProfile() {
    return this.apiService
      .getAgentportal('/userprofile')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async profile(id: string, imageVariant = 'XL') {
    return this.apiService
      .getAgentportal('/profile/' + id + '?imageVariant=' + imageVariant, 'v2')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getTimeline(id: string) {
    return this.apiService
      .getAgentportal('/profile/' + id + '/timeline', 'v2')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getDownloadUrl(id: string) {
    return this.apiService
      .downloadFile('/file/' + id, 'v1')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async promiseProProfile(slug?: string, id?: string) {
    this.contextService.tableList.map((e) => {
      if (e.typeOf.includes('submissionTime')) {
        e.disable = slug === 'accepted' && true;
      }
      if (e.typeOf.includes('status')) {
        e.disable = slug === 'accepted' && true;
      }
      if (e.typeOf.includes('category')) {
        e.disable = slug === 'accepted' && true;
      }
    });

    this.contextService.dataLoaded.next('fetching');
    // return false
    let profile: any;
    let skills: any;
    let technologies: any;
    let families: any;
    let industries: any;
    let consultant: any;
    let promiseMyGeneome = {};
    let promiseSkills = {};
    let promiseTechnologies = {};
    let promiseFamilies = {};
    let promiseIndustries = {};
    let promiseConsultant = {};

    promiseSkills = new Promise((resolve) => {
      this.apiService
        .getAgentportal('/portal/skills')
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          resolve([]);
        });
    });
    promiseTechnologies = new Promise((resolve) => {
      this.apiService
        .getAgentportal('/portal/technologies')
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          resolve([]);
        });
    });
    promiseFamilies = new Promise((resolve) => {
      this.apiService
        .getAgentportal('/portal/families')
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          resolve([]);
        });
    });
    promiseIndustries = new Promise((resolve) => {
      this.apiService
        .getAgentportal('/portal/industries')
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          resolve([]);
        });
    });
    if (slug === 'inbox') {
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getAgentportal(
            '/overview/list?status=PendingInterview,NewLead,Rejected,Registered'
          )
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
    if (slug === 'unsubmitted') {
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getAgentportal('/overview/list?status=Registered')
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
    if (id) {
      promiseConsultant = new Promise((resolve) => {
        this.apiService
          .getAgentportal('/dashboard/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
    if (slug === 'new-leads') {
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getAgentportal('/overview/list?status=NewLead')
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
    if (slug === 'pending-interview') {
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getAgentportal('/overview/list?status=PendingInterview')
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
    if (slug === 'accepted') {
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getAgentportal('/overview/list?status=Approved')
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
    if (slug === 'rejected') {
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getAgentportal('/overview/list?status=Rejected')
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }

    await Promise.all([
      promiseMyGeneome,
      promiseSkills,
      promiseTechnologies,
      promiseFamilies,
      promiseIndustries,
      promiseConsultant,
    ])
      .then((values) => {
        profile = values[0];
        skills = values[1];
        technologies = values[2];
        families = values[3];
        industries = values[4];
        consultant = values[5];
      })
      .catch((error) => {
        console.log(error);
      });
    return {
      profile,
      skills,
      technologies,
      families,
      industries,
      consultant,
    };
  }
}

