export enum MatchingType {
    RequestToConsultant,
    ConsultantToRequest,
    ConsultantToConsultant,
}

export enum MatchingTypeName {
    RequestToConsultant = 'rtc',
    ConsultantToRequest = 'ctr',
    ConsultantToConsultant = 'ctc',
}
