import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
  OnDestroy,
  signal,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  filter as appFilter,
  FilterData,
  option,
} from '../../../../../../interface/shared.interface';
import { FeatureFlagsService } from '../../../../../../services/feature-flags/feature-flags.service';
import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';
import { ILocationRangeFilterResultValue } from '../../../../../shared/interfaces/location';
import { ContextService } from '../../../../../../services/platform/context.service';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-consultant-filters',
  templateUrl: './consultant-filters.component.html',
  styleUrls: ['./consultant-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultantFiltersComponent implements OnDestroy {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private contextService = inject(ContextService);
  private helperFunctions = inject(HelperFunctionsService);
  public featureFlags = inject(FeatureFlagsService);

  disable = input(false);
  filterLevel = input.required<boolean>();
  matchId = input.required<string>();
  iterateId = input.required<number>();
  selectedSearchType = input<number>();
  modal = input(false);
  selectedCvType = model<number>();
  selectedNav = input<string>();

  selectedFilters = signal<option[]>([]);
  availability = signal<{
    option: option;
    range?: { startDate: Date; endDate: Date };
  }>({ option: { id: '-1', displayName: '', type: '' } });
  isChecked = signal(false);
  queryParams = signal<Params>(null);

  // expandFilter: number[] = [0, 1, 2];

  filters: appFilter[] = [
    {
      name: 'Country',
      showCounter: true,
      option: [],
      disable: false,
      type: 'addressCountry',
    },
    {
      name: 'Location',
      showCounter: true,
      option: [],
      disable: false,
      type: 'mapLocation',
    },
    {
      disable: false,
      showCounter: true,
      name: 'Keywords',
      option: [],
      type: 'keywords',
    },
    { disable: false, name: 'Availability', option: [], type: 'availability' },
    {
      disable: false,
      name: 'Worked with 7N',
      option: [],
      type: 'workedwith7n',
    },
    {
      disable: false,
      showCounter: true,
      name: '7N status',
      option: [],
      type: 'status',
    },
    {
      disable: false,
      showCounter: true,
      name: 'Consultant status',
      option: [],
      type: 'relationstatus',
    },
    {
      disable: false,
      showCounter: true,
      title: 'Team',
      name: 'Team',
      option: [],
      type: 'teamfilter',
    },
  ];
  filterData: FilterData = this.contextService.filterData;
  mapAvailable = [
    { id: '0available', value: 'now', displayName: 'Now' },
    { id: '1available', value: 'next30days', displayName: 'Next 30 days' },
    { id: '2available', value: 'next60days', displayName: 'Next 60 days' },
    { id: '3available', value: 'range', displayName: 'Custom range' },
  ];
  subs = new Subscription();

  constructor() {
    if (this.route.queryParams) {
      this.subs.add(
        this.route.queryParams.subscribe((params) => {
          const paramsObject = { ...params };
          this.selectedCvType.set(
            paramsObject['cvtype'] ? JSON.parse(paramsObject['cvtype']) : 0
          );
          if (Object.keys(paramsObject).length === 0 && !this.queryParams) {
            this.queryParams.set(paramsObject);
            return;
          }

          this.queryParams.set(paramsObject);

          setTimeout(() => {
            this.queryFilter();
          });
        })
      );
    }
  }

  getOption(type: string): appFilter {
    return this.filters.find((x) => x.type === type);
  }

  isFilterAvailable(type: string): boolean {
    return (
      this.selectedSearchType() !== 2 ||
      ['availability', 'workedwith7n', 'status'].includes(type)
    );
  }

  async clearFilter(i: number) {
    this.filters[i].option = [];
    switch (this.filters[i].type) {
      case 'teamfilter':
        delete this.queryParams()['team'];
        delete this.queryParams()['country'];
        delete this.queryParams()['office'];
        break;
      case 'availability':
        delete this.queryParams()['range'];
        this.availability.set({
          option: { id: '-1', displayName: '', type: '' },
        });
        delete this.queryParams()['availability'];
        break;
      case 'workedwith7n':
        this.isChecked.set(false);
        delete this.queryParams()['workedwith7n'];
        break;
      case 'addressCountry':
        delete this.queryParams()['addressCountry'];
        break;
      case 'mapLocation':
        delete this.queryParams()['mapLocation'];
        break;
      default:
        delete this.queryParams()[this.filters[i].type];
        break;
    }

    await this.router.navigate([], {
      queryParams: this.queryParams(),
    });
    this.setFilteringList();
  }

  async queryFilter() {
    const findIndex = this.filters.findIndex((x) => x.type === 'teamfilter');
    if (Object.keys(this.queryParams()).includes('team')) {
      const team = this.queryParams()
        ['team'].split(',')
        .filter((x: string) =>
          this.filterData?.team?.some((z) => z.name === x)
        );
      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'team'
      );

      team.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'team' });
      });

      this.getFilterFunc(option, 'team');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'team'
      );
    }

    if (Object.keys(this.queryParams()).includes('country')) {
      const location = this.queryParams()
        ['country'].split(',')
        .filter((x: string) =>
          this.filterData?.location?.some((z) => z.displayName === x)
        );
      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'country'
      );
      location.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'country' });
      });
      this.getFilterFunc(option, 'country');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'country'
      );
    }

    if (Object.keys(this.queryParams()).includes('addressCountry')) {
      const locationCountryFiltersIndex = this.filters.findIndex(
        (x) => x.type === 'addressCountry'
      );
      const countries = this.queryParams()['addressCountry'].split(',');
      const listOptions = this.filterData.locationCountries?.filter(
        (dictItem) => countries.includes(dictItem.id)
      );
      const currentOptions: option[] = this.filters[
        locationCountryFiltersIndex
      ].option.filter((x) => x.type !== 'addressCountry');

      listOptions.forEach((option) => {
        currentOptions.push({
          id: option.id,
          displayName: option.displayName,
          name: option.displayName,
          type: 'addressCountry',
        });
      });
      this.getFilterFunc(currentOptions, 'addressCountry');
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'addressCountry'
      );
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams()).includes('mapLocation')) {
      const locationRangeFiltersIndex = this.filters.findIndex(
        (x) => x.type === 'mapLocation'
      );
      let rangeQueryParams = this.queryParams()['mapLocation'];
      const currentOptions: option[] = this.filters[
        locationRangeFiltersIndex
      ].option.filter((x) => x.type !== 'mapLocation');

      if (typeof rangeQueryParams === 'string') {
        rangeQueryParams = [rangeQueryParams];
      }

      rangeQueryParams.forEach((x: string) => {
        const convertedValue = this.getLocationRangeFilterValueFromString(x);
        currentOptions.push({
          id: uuidv4(),
          displayName: x,
          type: 'mapLocation',
          value: {
            lat: convertedValue?.coordinates?.lat,
            lon: convertedValue?.coordinates?.lon,
            range: convertedValue?.range,
            search: convertedValue?.search,
          },
        });
      });
      this.getFilterFunc(currentOptions, 'mapLocation');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'mapLocation');
      this.filters[findIndex].option = [];
    }

    if (this.queryParams()['office']) {
      const office = this.queryParams()
        ['office'].split(',')
        .filter((x: string) => {
          return this.filterData?.location?.some((z) => z.subNames.includes(x));
        });

      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'office'
      );

      office.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'office' });
      });
      this.getFilterFunc(option, 'office');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'office'
      );
    }

    if (Object.keys(this.queryParams()).includes('status')) {
      const statuses = this.queryParams()
        ['status'].split(',')
        .filter((x: string) => this.filterData?.statuses?.some((z) => z === x));

      const option: option[] = [];
      statuses.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'status' });
      });
      this.getFilterFunc(option, 'status');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'status');
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams()).includes('relationstatus')) {
      const relationstatus: any[] = [];
      this.queryParams()
        ['relationstatus'].split(',')
        .forEach((x: string) => {
          const findex = this.filterData.tiers?.findIndex(
            (z) => z.status.contractStatus === x
          );
          if (findex !== -1 && this.filterData.tiers && findex !== undefined) {
            relationstatus.push({
              relationStatuses:
                this.filterData?.tiers[findex].status.contractStatus,
              displayName: this.filterData?.tiers[findex].status.description,
            });
          }
          return this.filterData?.tiers?.some((z) =>
            z.status.contractStatus.includes(x)
          );
        });

      const option: option[] = [];
      relationstatus.forEach((x: any) => {
        option.push({
          id: uuidv4(),
          displayName: x.relationStatuses,
          name: x.displayName,
          type: 'relationstatus',
        });
      });
      this.getFilterFunc(option, 'relationstatus');
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'relationstatus'
      );
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams()).includes('workedwith7n')) {
      const value = this.queryParams()['workedwith7n'] !== false;

      const findIndex = this.filters.findIndex(
        (x) => x.type === 'workedwith7n'
      );
      this.filters[findIndex].option = this.getWorkAs7N(value);
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'workedwith7n'
      );
      if (findIndex !== -1) {
        this.filters[findIndex].option = this.getWorkAs7N(false);
      }
    }

    if (Object.keys(this.queryParams()).includes('keywords')) {
      if (this.queryParams()['keywords']) {
        const split = this.queryParams()['keywords'].split(',');
        const option = split.map((x: string) => {
          return { id: uuidv4(), displayName: x, type: 'keywords' };
        });
        this.getFilterFunc(option, 'keywords');
      }
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'keywords');
      this.filters[findIndex].option = [];
    }
    if (Object.keys(this.queryParams()).includes('range')) {
      const find = this.mapAvailable.find((x) => x.value === 'range');
      if (find) {
        delete this.queryParams()['available'];
        const split = this.queryParams()['range'].split(',');
        const sd = new Date(split[0]);
        const ed = new Date(split[1]);
        const findIndex = this.filters.findIndex(
          (x) => x.type === 'availability'
        );
        this.filters[findIndex].option = this.getAvailability({
          option: {
            id: find.id,
            displayName: find.displayName,
            type: 'availability',
          },
          range: {
            startDate: sd,
            endDate: ed,
          },
        });
      }
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'availability'
      );
      if (
        findIndex !== -1 &&
        this.filters[findIndex].option.some((x) => x.range)
      ) {
        this.filters[findIndex].option = [];
        this.availability.set({
          option: { id: '-1', displayName: '', type: '' },
        });
      }
    }
    if (Object.keys(this.queryParams()).includes('availability')) {
      delete this.queryParams()['range'];
      const find = this.mapAvailable.find(
        (x) => x.value === this.queryParams()['availability']
      );

      if (find) {
        const findIndex = this.filters.findIndex(
          (x) => x.type === 'availability'
        );
        this.filters[findIndex].option = this.getAvailability({
          option: {
            id: find.id,
            displayName: find.displayName,
            type: 'availability',
          },
        });
      }
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'availability'
      );
      if (
        findIndex !== -1 &&
        this.filters[findIndex].option.some((x) => !x.range)
      ) {
        this.filters[findIndex].option = [];
        this.availability.set({
          option: { id: '-1', displayName: '', type: '' },
        });
      }
    }

    this.setFilteringList();
  }

  setFilteringList() {
    this.selectedFilters.set([]);
    this.filters.forEach((x) => {
      this.selectedFilters.update((filters) => [...filters, ...x.option]);
    });

    if (
      this.selectedNav() === 'consultants' &&
      !this.helperFunctions.arrayEquals(
        this.selectedFilters(),
        this.contextService.globalFilterC.value
      )
    ) {
      this.contextService.globalFilterC.next(this.selectedFilters());
    }
    if (
      this.selectedNav() === 'matching' &&
      !this.helperFunctions.arrayEquals(
        this.selectedFilters(),
        this.contextService.globalFilterM.value
      )
    ) {
      this.contextService.globalFilterM.next(this.selectedFilters());
    }
  }

  getWorkAs7N(
    value: boolean
  ): Array<{ id: string; displayName: string; type: string }> {
    this.isChecked.set(value);
    if (value) {
      return [
        {
          id: uuidv4(),
          displayName: 'Worked with 7N',
          type: 'workedwith7n',
        },
      ];
    }
    return [];
  }

  async setWorkAs7N(value: boolean) {
    const findIndex = this.filters.findIndex((x) => x.type === 'workedwith7n');
    this.filters[findIndex].option = this.getWorkAs7N(value);

    this.queryParams()['workedwith7n'] = JSON.stringify(value);

    if (!value) {
      delete this.queryParams()['workedwith7n'];
    }
    await this.router.navigate([], {
      queryParams: this.queryParams(),
    });
  }

  getAvailability(availability: {
    option: option;
    range?: { startDate: Date; endDate: Date };
  }): option[] {
    this.availability.set(availability);
    if (this.availability()?.range) {
      this.availability().option.range = availability.range;
      delete this.queryParams()['availability'];

      this.queryParams()['range'] =
        this.helperFunctions.getDateISO8601(
          this.availability().range.startDate
        ) +
        ',' +
        this.helperFunctions.getDateISO8601(this.availability().range.endDate);
    }
    if (this.availability()?.option?.id !== '3available') {
      delete this.queryParams()['range'];
      const find = this.mapAvailable.find(
        (x) => x.id === availability.option.id
      );
      this.queryParams()['availability'] = find?.value;
    }
    this.availability().option.type = 'availability';

    return [this.availability().option];
  }

  async setAvailability(availability: {
    option: option;
    range?: { startDate: Date; endDate: Date };
  }) {
    const findIndex = this.filters.findIndex((x) => x.type === 'availability');
    this.filters[findIndex].option = this.getAvailability(availability);

    await this.router.navigate([], {
      queryParams: this.queryParams(),
    });
  }

  getFilterFunc(event: option[], filterType: string) {
    let type = filterType;

    const teamFilter = ['country', 'team', 'office', 'location'];
    if (teamFilter.includes(type)) {
      type = 'teamfilter';
    }
    const findIndex = this.filters.findIndex((x) => x.type === type);

    this.filters[findIndex].option = event;
    if (type === 'keywords') {
      const displayName = this.filters[findIndex].option.map(
        (x) => x.displayName
      );
      this.queryParams()['keywords'] = displayName.join(',');
      if (displayName.length === 0) {
        delete this.queryParams()['keywords'];
      }
    }
    if (type === 'status') {
      const displayName = this.filters[findIndex].option.map(
        (x) => x.displayName
      );
      this.queryParams()['status'] = displayName.join(',');
    }
    if (type === 'relationstatus') {
      const displayName = this.filters[findIndex].option.map(
        (x) => x.displayName
      );
      this.queryParams()['relationstatus'] = displayName.join(',');
    }

    if (filterType === 'team') {
      const displayNameTeam = this.filters[findIndex].option
        .filter((x) => x.type === 'team')
        .map((x) => {
          return x.displayName;
        });
      if (filterType === 'team') {
        this.queryParams()['team'] = displayNameTeam.join(',');
      }
    }

    if (filterType === 'country' || filterType === 'location') {
      const displayNameCountry = this.filters[findIndex].option
        .filter((x) => x.type === 'country')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams()['country'] = displayNameCountry.join(',');
    }
    if (filterType === 'office' || filterType === 'location') {
      const displayNameOffice = this.filters[findIndex].option
        .filter((x) => x.type === 'office')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams()['office'] = displayNameOffice.join(',');
    }

    if (filterType === 'addressCountry') {
      const countryIds = this.filters[findIndex].option
        .filter((x) => x.type === 'addressCountry')
        .map((x) => {
          return x.id;
        });

      if (countryIds.length > 0) {
        this.queryParams()['addressCountry'] = countryIds.join(',');
      } else {
        delete this.queryParams()['addressCountry'];
      }
    }

    if (filterType === 'mapLocation') {
      const filterValues = this.filters[findIndex].option
        .filter((x) => x.type === 'mapLocation')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams()['mapLocation'] = filterValues;
    }
  }

  async selectedFiltersFunc(event: option[], filterType: string) {
    event.map((x) => {
      if (!x.type) {
        x.type = filterType;
      }
      if (filterType === 'keywords') {
        x.cvOrFiles = 0;
      }

      return x;
    });
    this.getFilterFunc(event, filterType);
    await this.router.navigate([], {
      queryParams: this.queryParams(),
    });
  }

  async locationFilterUpdated(value: Array<ILocationRangeFilterResultValue>) {
    const findIndex = this.filters.findIndex((x) => x.type === 'mapLocation');
    if (findIndex !== -1) {
      this.filters[findIndex].option = value.map((filter) => ({
        id: uuidv4(),
        displayName: `${filter.coordinates.lat},${filter.coordinates.lon},${filter.range},${filter.search}`,
        type: 'mapLocation',
        value: filter,
      }));
    }

    this.getFilterFunc(this.filters[findIndex].option, 'mapLocation');

    await this.router.navigate([], {
      queryParams: this.queryParams(),
    });
  }

  getFilterConfigByType(type: string): appFilter {
    return this.filters.find((x) => x.type === type);
  }

  private getLocationRangeFilterValueFromString(
    displayName: string
  ): ILocationRangeFilterResultValue {
    if (displayName) {
      return {
        coordinates: {
          lat: parseFloat(displayName.split(',')[0]),
          lon: parseFloat(displayName.split(',')[1]),
        },
        range: parseFloat(displayName.split(',')[2]),
        search: displayName.split(',')[3],
      };
    }

    return null;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
