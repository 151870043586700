import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { delay } from 'rxjs';

@Component({
  selector: 'app-matching-smart-tip',
  templateUrl: './matching-smart-tip.component.html',
  styleUrls: ['./matching-smart-tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchingSmartTipComponent {
  smartTip = input.required<string>();
  loading = input<boolean>(false);

  openExplainerInfo = output<void>();

  smartTipContainer = viewChild<ElementRef>('smartTipContainer');
  showAll = signal(false);

  showReadMoreBtn = toSignal(
    toObservable(
      computed(() => {
        this.smartTip(); // to trigger the computed on smartTip change
        const container = this.smartTipContainer()?.nativeElement;
        return container
          ? container.scrollHeight > container.clientHeight
          : false;
      })
    ).pipe(delay(0))
  );
}
