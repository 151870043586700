import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as GlobalSelectors from '../store/global/global.selectors';
import * as GlobalActions from '../store/global/global.actions';
import { IAppSettings } from '../interfaces/app-settings';

@Injectable({
  providedIn: 'root',
})
export class GlobalFacadeService {
  private store = inject(Store);

  get appVersion$(): Observable<string> {
    return this.store.select(GlobalSelectors.selectAppVersion);
  }

  queryAppVersion() {
    this.store.dispatch(GlobalActions.queryAppVersion());
  }

  get appSettings$(): Observable<IAppSettings> {
    return this.store.select(GlobalSelectors.selectAppSettings);
  }

  queryAppSettings() {
    this.store.dispatch(GlobalActions.queryAppSettings());
  }

  get azureMapsKey$(): Observable<string> {
    return this.store.select(GlobalSelectors.selectAzureMapsKey);
  }
}
