import { Injectable } from '@angular/core';
import axios, { CancelTokenSource } from 'axios';
import { environment } from '../../environments/environment';
import { declarationOfInterest } from '../../interface/shared.interface';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;
  cancelTokenSource: CancelTokenSource | null = null;
  constructor(private authenticationService: AuthenticationService) {}

  // Sends a HEAD request to check the cached match status
  async headCachedMatch(matchedId: string, iterateId: number) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/v3/request/cached/' +
      matchedId +
      '/results/' +
      iterateId;
    return axios
      .head(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Fetches cached filter results for a given match
  async getCachedFilter(matchedId: string, iterateId: number, query: string) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/v3/request/cached/' +
      matchedId +
      '/results/' +
      iterateId +
      '?' +
      query;
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Fetches cached match results
  async getCachedMatch(matchedId: string, iterateId: number) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/v3/request/cached/' +
      matchedId +
      '/results/' +
      iterateId;
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Sends a HEAD request to check the match status
  async headMatch(type: string) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/v2/match/' + type;
    return axios
      .head(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Fetches smartTip of consultant based on a query
  async getSmartTip(entityId: number, query = '') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/v1/smarttip/' +
      entityId +
      (query ? '?' + query : '');
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches a list of consultants based on a query
  async consultants(query = '') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl + '/agentportal/v2/profiles' + (query ? '?' + query : '');
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Posts a declaration of interest for a consultant
  async postDeclarationOfInterest(
    id: string,
    consultants: { freelancers: string[]; languageCode: string }
  ) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl + '/agentportal/v2/match/' + id + '/declarationofinterest';
    return axios
      .post(url, consultants, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches declaration of interest for a specific consultant
  async getDeclarationOfInterestconsultant(id: string) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/v2/match/declarationofinterest/freelancer/' +
      id;
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches filters for a specific match
  async portalFilters(version = 'v1') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + '/portal/filters/';
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches filters for a specific match
  async getFilters(id: string, version = 'v2') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl + '/agentportal/' + version + '/matching/' + id + '/filters';
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches match filters for a specific request
  async getMatchFilters(id: string, iterateId: number, version = 'v3') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/' +
      version +
      '/request/cached/' +
      id +
      '/results/' +
      iterateId +
      '/filters';
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Posts a declaration of interest
  async getDeclarationOfInterest(id: string) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl + '/agentportal/v2/match/declarationofinterest' + id;
    return axios
      .post(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Updates a declaration of interest with an optional submit flag
  async putDeclarationOfInterest(
    id: string,
    payload: declarationOfInterest,
    submit = false
  ) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/v2/match/declarationofinterest/' +
      id +
      (submit ? '/submit' : '');
    return axios
      .put(url, payload ?? '', {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches match details for a specific type
  async getMatch(type: string, version = 'v3') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + '/match/' + type;
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches request details for a specific type
  async getRequest(type: string, version = 'v3') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + '/request/' + type;
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Fetches a list of requests based on the specified endpoint
  async getRequests(endpoint = '/requests/', version = 'v2') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + endpoint;
    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  // Posts a match request with optional search object
  async postMatch(type: string, searchObject?: unknown, version = 'v2') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + '/match/' + type;
    return axios
      .post(url, searchObject, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Sends a HEAD request for match details
  async getMatchHead(type: string, version = 'v3') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + '/match/' + type;
    return axios
      .head(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Fetches consultant details for a specific type
  async getConsultants(type: string, version = 'v1') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + '/' + type;

    // Cancel the previous request if it exists
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled due to new request.');
    }

    // Create a new cancel token
    this.cancelTokenSource = axios.CancelToken.source();

    return axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
        cancelToken: this.cancelTokenSource.token,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  // Posts a matching request with the specified query parameters
  async getMatching(
    type: string,
    searchObject: unknown,
    version = 'v3',
    query = ''
  ) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url =
      this.apiUrl +
      '/agentportal/' +
      version +
      '/' +
      type +
      (query ? '?' + query : '');
    return axios
      .post(url, searchObject, {
        timeout: 30000,
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        return error;
      });
  }

  // Fetches data from the agent portal for a specific type
  async getAgentportal(type: string, version = 'v1') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + type;
    if (accessToken) {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + accessToken['genome'],
          },
        })
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }

  // Downloads a file from the agent portal for a specific type
  async downloadFile(type: string, version = 'v1') {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + version + type;
    if (accessToken) {
      return axios
        .get(url, {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + accessToken['genome'],
          },
        })
        .then((response) => {
          return response;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }

  // Updates data on the agent portal for a specific type
  async putAgentportal(type: string, selected: unknown) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + type;
    return axios
      .put(url, selected, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  // Posts data to the agent portal for a specific type
  async postAgentportal(type: string, selected: unknown) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/' + type;
    return axios
      .put(url, selected, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  // Saves data to the agent portal for a specific type
  async saveAgentportal(type: string, selected: unknown) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/v1/' + type;
    return axios
      .post(url, selected, {
        headers: {
          Authorization: 'Bearer ' + accessToken['genome'],
        },
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  }

  // Sets the status on the agent portal for a specific type
  async setStatus(type: string) {
    const accessToken = await this.authenticationService.acquireTokenSilent();
    const url = this.apiUrl + '/agentportal/v1/dashboard/' + type;
    return axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + accessToken['genome'],
          },
        }
      )
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  }
}
