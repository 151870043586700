import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGlobal from './global.reducer';

export const selectGlobalState = createFeatureSelector<fromGlobal.State>(
  fromGlobal.globalFeatureKey
);

export const selectAppVersion = createSelector(
  selectGlobalState,
  fromGlobal.getAppVersion
);

export const selectAppSettings = createSelector(
  selectGlobalState,
  fromGlobal.getAppSettings
);

export const selectAzureMapsKey = createSelector(
  selectGlobalState,
  fromGlobal.getAzureMapsKey
);
