<div class="filter-list" [class]="{ 'open-filter': modal, disable }">
  <ng-container *ngFor="let filter of filters; let i = index">
    <div *ngIf="filter.hide !== false">
      <div class="filter body-large">
        <p class="bold">
          {{ filter.name }}
          <app-badge
            *ngIf="filter.option.length > 0"
            [label]="filter.option.length + ''"
            color="neutral--Grey-900 textContrast amount"
          ></app-badge>
        </p>
        <p
          *ngIf="filter.option.length > 0"
          (click)="clearFilter(i)"
          class="body-small textLink clear"
        >
          Clear
        </p>
      </div>
      <div>
        <app-country
          [selected]="getOption('countries').option"
          [placeholder]="'Find country'"
          [disabled]="disable"
          (selectedOption)="selectedFiltersFunc($event, 'countries')"
          [countries]="filterData.countries"
          *ngIf="filter.type === 'countries' && filterReady"
        ></app-country>
        <app-agent-select
          [selected]="getOption('agent').option"
          [agents]="filterData ? filterData.agents : []"
          (selectedOption)="selectedFiltersFunc($event, 'agent')"
          *ngIf="filter.type === 'agent' && filterReady"
        ></app-agent-select>
        <app-team-filter
          [disabled]="disable"
          [filter]="getOption('teamfilter')"
          [selected]="getOption('teamfilter').option"
          (selectedOptionTeam)="selectedFiltersFunc($event, 'team')"
          (selectedOptionLocation)="selectedFiltersFunc($event, 'location')"
          *ngIf="filter.type === 'teamfilter' && filterReady"
        ></app-team-filter>
        <app-priority-select
          [selected]="getOption('priority').option"
          [priority]="filterData ? filterData.priority : []"
          (selectedOption)="selectedFiltersFunc($event, 'priority')"
          *ngIf="filter.type === 'priority' && filterReady"
        ></app-priority-select>
        <app-sales-status-select
          [selected]="getOption('salesstatus').option"
          [salesstatus]="filterData ? filterData.salesstatus : []"
          (selectedOption)="selectedFiltersFunc($event, 'salesstatus')"
          *ngIf="filter.type === 'salesstatus' && filterReady"
        ></app-sales-status-select>
      </div>
    </div>
  </ng-container>
</div>
