<app-selected-notification
  *ngIf="bulkSelect.length > 0 && !hideSelectNotification"
  (clear)="bulkSelect = []"
  (openMessage)="openBulkMessaging()"
  [selected]="bulkSelect"
></app-selected-notification>
<div class="request surface--2" *ngIf="!loading">
  <div class="max-width">
    <div class="section surface--2">
      <div class="top">
        <app-button
          routerLink=".."
          [class]="['ghost', 'small']"
          leadingIcon="chevron-left"
          label="back to list"
        ></app-button>
      </div>
      <div class="request">
        <app-request-level1
          *ngIf="selectedRequest"
          [level0]="false"
          [level3]="true"
          [selectedRequest]="selectedRequest"
        ></app-request-level1>
      </div>
    </div>
  </div>
  <div class="max-width">
    <div class="section surface--2 shortlisted">
      <div class="title-button">
        <div class="s2">Shortlisted</div>
        <app-button
          [class]="['secondary']"
          leadingIcon="plus"
          label="Add consultant"
          (click)="addconsultant()"
        ></app-button>
      </div>
      <div class="profiles">
        <div class="switch-sort">
          <app-switch
            [isToggled]="showNotSold"
            (callbackFunction)="handleNotSold($event)"
            label="Show not sold"
          ></app-switch>
          <div class="sortedBy">
            <p class="body-small textSecondary">
              Sort by: <span class="bold">Activity</span>
            </p>
          </div>
        </div>
        <div class="enlistedProfiles">
          <ng-container *ngIf="selectedRequest">
            <app-shortlisted-profile
              [bulkSelect]="bulkSelect"
              [selectedRequest]="selectedRequest"
              *ngFor="let result of enlistedProfiles"
              [consultantstatus]="consultantstatus"
              [consultant]="result"
              (updateResults)="getRequest()"
            ></app-shortlisted-profile>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
