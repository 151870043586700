import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import {
  IConsultantDetails,
  ConsultantPorfilePhotoVariant,
  IConsultantsResponse,
  IConsultantTimelineItem,
} from '../interfaces/consultant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsultantsService {
  private http = inject(HttpClient);

  apiUrl = `${environment.apiUrl}/agentportal`;

  getConsultants(query = '', apiVersion = 'v2'): Observable<IConsultantsResponse> {
    const url = `${this.apiUrl}/${apiVersion}/profiles${
      query ? '?' + query : ''
    }`;

    return this.http.get<IConsultantsResponse>(url).pipe(
      catchError((error) => {
        console.error('[ConsultantsService] Error fetching consultants', error);
        throw error;
      })
    );
  }

  // Fetches data from the agent portal for a specific type
  getConsultantDetails(
    id: string,
    imageVariant = ConsultantPorfilePhotoVariant.XL,
    apiVersion = 'v2'
  ): Observable<IConsultantDetails> {
    const url = `${this.apiUrl}/${apiVersion}/profile/${id}?imageVariant=${imageVariant}`;

    return this.http.get<IConsultantDetails>(url).pipe(
      catchError((error) => {
        console.error(
          `[ConsultantsService] Error fetching consultant details (ID=${id})`,
          error
        );
        throw error;
      })
    );
  }

  getConsultantTimeline(
    id: string,
    apiVersion = 'v2'
  ): Observable<IConsultantTimelineItem[]> {
    const url = `${this.apiUrl}/${apiVersion}/profile/${id}/timeline`;

    return this.http.get<IConsultantTimelineItem[]>(url).pipe(
      catchError((error) => {
        console.error(
          `[ConsultantsService] Error fetching consultant timeline notes (ID=${id})`,
          error
        );
        throw error;
      })
    );
  }
}
