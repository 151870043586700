<a [routerLink]="navItem.link"
   [attr.data-cy]="'navbar-item-'+navItem?.id"
   class="navbar label2 textSecondary"
   [ngClass]="{
    'active': active
   }">
  <div>
    <i-tabler *ngIf="navItem.icon" [name]="navItem.icon"></i-tabler>
    <div class="label" *ngIf="navItem.displayName">{{ navItem.displayName }}</div>
  </div>
  <div>
    <div *ngIf="navItem.count" class="amount caption">{{ navItem.count }}</div>
    <i-tabler *ngIf="navItem.state" [name]="navItem.state ? 'check' : ''"></i-tabler>
  </div>
</a>
