import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option, salesAgent } from '../../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-agent-select',
  templateUrl: './agent-select.component.html',
  styleUrls: ['./agent-select.component.scss']
})
export class AgentSelectComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() agents?: salesAgent[];
  options: option[] = [];
  @Input() selected: option[] = [];
  @Output() selectedOption = new EventEmitter<option[]>();

  public constructor(private helperFunctions: HelperFunctionsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.agents) {
      this.options = [];
      this.agents.forEach((agent: salesAgent) => {
        const findex = this.selected.findIndex(
          (x) => x.id === agent.federatedIssuerAssignedId
        );
        this.options.push({
          id:
            findex !== -1
              ? this.selected[findex].id
              : agent.federatedIssuerAssignedId,
          displayName: this.helperFunctions.fullName(agent),
          type: 'agent',
        });
      });
    }
  }

  selectOption(option: option) {
    if (option.id === '-1') {
      this.selected = [];
      this.selectedOption.emit(this.selected);
      return;
    }
    const findex = this.selected.findIndex((x) => x.id === option.id);
    if (findex === -1) {
      this.selected.push(option);
      this.selectedOption.emit(this.selected);
      return;
    }
    this.selected.splice(findex, 1);
    this.selectedOption.emit(this.selected);
  }
}
