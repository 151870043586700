import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
  @Input() openSidePanel = false
  @Output() callbackFunction = new EventEmitter()

  toggleSide() {
    this.callbackFunction.emit()
  }
}
