<div
  class="profile"
  [class]="{ disable: freelanceAddedToRequest() || disable }"
>
  <div [style]="style" class="level0 surface--1">
    <div>
      <div class="image">
        <i-tabler name="file-text"></i-tabler>
      </div>
      <div *ngIf="request" class="info">
        <div class="title" [class]="{ isDescription: request.isDescription }">
          <h3>{{ request.title }}</h3>
          <app-badge
            *ngIf="type !== 'longlist'"
            label="No description"
            color="neutral--Grey-300"
          ></app-badge>
        </div>
        <div class="details body-small">
          <p>{{ request.customer.name }}</p>
          <li></li>
          <p>{{ getRequestStartingDate(request.contractStartDate) }}</p>
        </div>
      </div>
      <div *ngIf="manualRequest" class="info">
        <div class="details body-small">
          <p>{{ manualRequest.title }}</p>
        </div>
        <div class="shadow"></div>
      </div>
    </div>

    <div class="ctas" *ngIf="contextService.selectedConsultant">
      <app-cta
        *ngIf="freelanceAddedToRequest()"
        [imgIcon]="'/assets/svg/list-check.svg'"
        classes="success--Lighter"
      >
      </app-cta>
    </div>
    <div class="ctas" *ngIf="ctas">
      <app-cta
        classes="brand--Lightest textLink"
        *ngIf="ctas.selected"
        [icon]="'X'"
        (click)="cta.emit('close')"
      >
      </app-cta>
      <app-cta
        classes="brand--Lightest textLink"
        *ngIf="ctas.view"
        [icon]="'file-text'"
        (click)="openInternal($event)"
      >
      </app-cta>
      <app-cta
        classes="brand--Lightest textLink"
        *ngIf="ctas.edit"
        [icon]="'pencil'"
        (click)="cta.emit('edit')"
      >
      </app-cta>
    </div>
  </div>
</div>
