import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  OnDestroy,
  output,
  signal,
} from '@angular/core';
import {
  matchConsultants,
  matchType,
  matches,
  requestV3,
} from '../../../../../interface/shared.interface';
import { modalType } from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import countries from '../../../../../assets/countries';
import { combineLatest, filter, Subscription } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-find-match',
  templateUrl: './find-match.component.html',
  styleUrls: ['./find-match.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindMatchComponent implements OnDestroy {
  private contextService = inject(ContextService);

  selectedRequest = input<requestV3>();
  manualRequest = input<matches>();
  matchTypeNumber = input.required<number>();
  loading = input.required<boolean>();
  isManual = input<boolean>();
  selectedConsultant = input<matchConsultants>();
  matchType = input.required<matchType[]>();

  click = signal(false);
  selectedAdvanced = signal('');

  callbackFunction = output<{
    event: Event;
    type: string;
  }>();
  cta = output<string>();

  subs = new Subscription();

  constructor() {
    effect(
      () => {
        const selectedRequest = this.selectedRequest();
        const manualRequest = this.manualRequest();

        if (selectedRequest || manualRequest) {
          this.contextService.advancedSearch.next({
            ...this.contextService.advancedSearch.value,
            qualification: '',
            qualificationImportance: 'Minimal',
          });
        }
      },
      { allowSignalWrites: true }
    );

    this.subs.add(
      combineLatest([
        toObservable(this.matchTypeNumber),
        this.contextService.advancedSearch.asObservable(),
      ])
        .pipe(
          filter((values) => values[0] !== null && values[1] !== null)
        )
        .subscribe(([matchTypeNumber, advancedSearch]) => {
        const select = [];
        if (matchTypeNumber !== 1) {
          const findCountry = countries.list.find(
            (x) => x.value === advancedSearch.countryCode
          );

          if (findCountry) {
            select.push(findCountry.displayName);
          } else {
            select.push('All countries');
          }
        }
        if (advancedSearch.qualification) {
          select.push(advancedSearch.qualification);
        }
        this.selectedAdvanced.set(select.join(' + '));
      })
    );
  }

  openModal(value: modalType) {
    // TODO: remove click, it only sets the background color, should be replaced with MatDialog
    this.click.set(true);
    setTimeout(() => {
      this.click.set(false);
    }, 100);
    this.contextService.openMatchModal = value;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
