<div class="neutral--Grey-300 dashboard"
     data-cy="dashboard">
  @if (matchModalMode() !== 'close') {
    <app-match-modal></app-match-modal>
  }
  <div class="left-content"
       [ngClass]="{
          'open': openSidePanel
       }"
       data-cy="left-content">
    <app-heading [openSidePanel]="openSidePanel"
                 (callbackFunction)="toggleSide()"></app-heading>

    <app-navigation [openSidePanel]="openSidePanel"></app-navigation>

    <div class="surface--2 get-in-touch">
      <p class="bold body-large">Get in touch</p>
      <div>
        <p class="body-small">
          Ideas, bugs or general feedback? We'd like to hear from you at:
          <a class="textLink body-small" href="mailto:feedback@7n.com">feedback&#64;7n.com</a>
        </p>
      </div>
    </div>
    <div class="surface--3 signout">
      @if (contextService.userInfo) {
        <app-button style="width: 100%"
                    trailingIcon="logout"
                    [class]="['ghost']"
                    (click)="signout()"
                    [label]="'Sign Out - ' + contextService.userInfo['given_name']"
                    data-cy="sign-out-btn"></app-button>
      }
    </div>
  </div>
  <div class="right-content">
    <router-outlet></router-outlet>
  </div>
</div>
