@if (requestId()) {
  <app-request [requestId]="requestId()"
               [loading]="loading()"
               (loadingStateChange)="loading.set($event)"></app-request>
}

<div class="surface--2">
@if (loading()) {
  <app-loader [loading]="loading()"></app-loader>
}
</div>

@if (!loading() && !requestId()) {
  <div class="requests">
    <div class="top surface--2">
      <app-page-title label="Requests"></app-page-title>

      <div class="search">
        <app-input (inputs)="search($event)"
                   leadingIcon="search"
                   placeholder="Search for request title or client name"
                   inputType="search"></app-input>
      </div>
    </div>
    <div class="content">
      <div class="filters surface--2">
        <app-filters [disable]="loading()" filterType="request"></app-filters>
      </div>
      <div class="requestList surface--2">
        <div class="bundle-headline-switch">
          <div>
            <h3>All requests</h3>
            <p class="body-small textSecondary">
              {{ requestsList().length }} active requests
            </p>
          </div>
          <div class="my-request">
            <app-switch [isToggled]="onlyMyRequest()"
                        (callbackFunction)="myRequests($event)"
                        label="Only show my requests"></app-switch>
          </div>
        </div>
        @if (selectedFilters().length > 0) {
          <div class="selectedFilters">
            @for (filter of selectedFilters(); track filter.id; let i = $index) {
              <app-badge [label]="filter.displayName"
                         [trailingIcon]="{ type: 'icon', icon: 'X' }"
                         color="neutral--Grey-300"
                         (callbackFunction)="removeFilter(filter)"></app-badge>
            }
            <p class="textLink clear-all body-small"
               (click)="clearAll(true)">
              Clear all
            </p>
          </div>
        }
        <div class="max-width">
          <div class="sortedBy">
            <p class="body-small textSecondary">
              Sort by: <span class="bold">Created date</span>
            </p>
          </div>
          @if (requestsList()) {
            @for (request of requestsList(); track request.id) {
              <app-request-level1 [level0]="false"
                                  [selectedRequest]="request"></app-request-level1>
            }
          }
        </div>
      </div>
    </div>
  </div>
}
