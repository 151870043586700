import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import {
  MatchSalesRequestConsultant,
  MatchingConsultantRequest,
  MatchingRequestConsultant,
  cvType,
  matchConsultants,
  matches,
  matchingType,
  requestV3,
  searchContext
} from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';

// Searches for consultants based on the provided filters and request ID
// @TODO!!!!! SERVICES PASSED AS ARGUMENTS!!!!!
export async function searchRequest(
  filters: string[],
  platformService: PlatformService,
  snackBarService: SnackBarService,
  requestId: string, // this.requestIdFiles ?? this.requestId
  matchingType: cvType[],
  selectedCvType: number,
  contextService: ContextService,
  pagenation?: boolean
) {
  const query = filters.map((x) => x.replace(/&/g, '%26')).join('&');
  // Fetches cached filter results for CV and file types
  const CV = platformService.getCachedFilter(
        matchingType[0].id ?? requestId,
        matchingType[0].iterationId,
        query
      ) as Promise<MatchSalesRequestConsultant>;

  const file = platformService.getCachedFilter(
        matchingType[1].id ?? requestId,
        matchingType[1].iterationId,
        query
      ) as Promise<MatchSalesRequestConsultant>;

  let potentialResults: matchConsultants[] = [];
  let potentialResultsCv: matchConsultants[] = [];
  let potentialResultsFile: matchConsultants[] = [];

  // Waits for both CV and file results to be fetched
  await Promise.all([CV, file]).then((values: any[]) => {
    if (pagenation) {
      potentialResultsCv = [
        ...potentialResultsCv,
        ...values[0].potentialFreelancers,
      ];
      if (values[1]) {
        potentialResultsFile = [
          ...potentialResultsFile,
          ...values[1].potentialFreelancers,
        ];
      }
    } else {
      potentialResultsCv = values[0].potentialFreelancers;
      if (values[1]) {
        potentialResultsFile = values[1].potentialFreelancers;
      }
    }

    // Updates the matching type amounts based on the results
    let total = 0;
    total = values[1].pagination.total;
    matchingType[1].amount = total;
    total = values[0].pagination.total;
    matchingType[0].amount = total;

    // Sets the potential results based on the selected CV type
    if (selectedCvType === 1) {
      potentialResults = potentialResultsFile;
      contextService.resultsLength.next(values[1].pagination.total);
    } else {
      contextService.resultsLength.next(values[0].pagination.total);
      potentialResults = potentialResultsCv;
    }
  })
  .catch(() => {
    snackBarService.open({
      message: 'Failed to get search results',
      type: SnackBarTypes.ErrorAlt,
    });
  });

  return { potentialResults, potentialResultsCv, potentialResultsFile };
}

// Searches manually for consultants based on the provided filters and search context
// @TODO!!!!! SERVICES PASSED AS ARGUMENTS!!!!!
export async function searchManual(
  filters: string[],
  searchContext: searchContext,
  platformService: PlatformService,
  snackBarService: SnackBarService
) {
  // Fetches matching consultants for CV and file types
  const file = platformService.matchrequesttoconsultants({
    ...searchContext,
    matchingType: 'Files'
  }, filters.join('&')) as Promise<MatchingRequestConsultant>;
  const CV = platformService.matchrequesttoconsultants({
    ...searchContext,
    matchingType: 'CV'
  }, filters.join('&')) as Promise<MatchingRequestConsultant>;

  const matchingType: cvType[] = [];
  let results: MatchingRequestConsultant[] = [];

  // Waits for both CV and file results to be fetched
  await Promise.all([CV, file]).then((values: any[]) => {
    results = values;

    // Merges the results and updates pagination information
    const mergedArray = [
      ...results[0].potentialCandidates,
      ...results[1].potentialCandidates,
    ];
    results[0].paginationCv = results[0].pagination;
    results[0].paginationFile = results[1].pagination;
    results[0].potentialCandidates = mergedArray;

    // Updates the matching type information
    matchingType.push(
      {
        matchingType: 'CV',
        displayName: 'My7N CVs',
        icon: 'discount-check',
        id: results[0].id,
        iterationId: results[0].iterationId,
        amount: results[0].pagination.total,
      },
      {
        matchingType: 'Files',
        displayName: 'Other CVs',
        icon: 'file-cv',
        id: results[1].id,
        iterationId: results[1].iterationId,
        amount: results[1].pagination.total,
      }
    );
  })
  .catch((error) => {
    snackBarService.open({
      message: 'Failed to get search results',
      type: SnackBarTypes.ErrorAlt,
    });

    return error;
  });

  return {
    results,
    matchingType,
  };
}

// Searches for consultants to match requests based on the provided search context
// @TODO!!!!! SERVICES PASSED AS ARGUMENTS!!!!!
export async function searchCTR(
  platformService: PlatformService,
  snackBarService: SnackBarService,
  searchContext: searchContext
) {
  return await platformService
    .matchconsultanttorequests(searchContext, 'v2')
    .then(async (response) => {
      return { response };
    })
    .catch((error) => {
      snackBarService.open({
        message: 'Failed to get search results',
        type: SnackBarTypes.ErrorAlt,
      });

      return error;
    });
}

// Searches for requests to match consultants based on the provided search context
// @TODO!!!!! SERVICES PASSED AS ARGUMENTS!!!!!
export async function searchRTC(
  platformService: PlatformService,
  snackBarService: SnackBarService,
  searchContext: searchContext,
  selectedRequest: requestV3 | undefined,
  contextService: ContextService,
  matchingType: cvType[]
) {
  if (selectedRequest) {
    matchingType = [];

    // Iterates over matching results and updates the matching type information
    await platformService
      .iterateMatch(selectedRequest.id, searchContext)
      .then((resp: any) => {
        if (resp.status === 202 && selectedRequest) {
          const iterations = resp.data.iterations.sort((a: any, b: any) =>
            a.matchingType.localeCompare(b.matchingType)
          );

          iterations.forEach((value: any) => {
            if (value.matchingType === 'CV') {
              matchingType.push({
                matchingType: 'CV',
                displayName: 'My7N CVs',
                icon: 'discount-check',
                iterationId: value.iterationId,
                contentReady: false,
                amount: 0,
                id: selectedRequest.id,
              });
            }
            if (value.matchingType === 'Files') {
              matchingType.push({
                matchingType: 'Files',
                displayName: 'Other CVs',
                icon: 'file-cv',
                iterationId: value.iterationId,
                contentReady: false,
                amount: 0,
                id: selectedRequest.id,
              });
            }
            matchingType.map((x) => (x.contentReady = false));
          });
        } else {
          // Displays a notification if the matching process fails
          // @TODO: is it needed?
          snackBarService.open({
            message: 'Failed to get search results',
            type: SnackBarTypes.ErrorAlt,
          });
        }
      })
      .catch((error) => {
        snackBarService.open({
          message: 'Failed to get search results',
          type: SnackBarTypes.ErrorAlt,
        });

        return error;
      });
  }

  return { matchingType };
}
