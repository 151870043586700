import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { IConsultant } from '../../../../consultants/interfaces/consultant';
@Component({
  selector: 'app-level0',
  templateUrl: './level0.component.html',
  styleUrls: ['./level0.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Level0Component {
  consultant = input.required<IConsultant>();
  disable = input<boolean>();
  ctas = input<{ edit?: boolean; selected?: boolean; user?: boolean }>();
  selected = input<boolean>();
  shortListed = input<boolean>();

  cta = output<string>();

  private helperFunctions = inject(HelperFunctionsService);
  private router = inject(Router);
  private location = inject(Location);

  consultantCity = computed(() => {
    const city = this.consultant()?.location?.city;
    if (city) {
      return city + ', ';
    } else {
      return '';
    }
  });

  consultantCountry = computed(() => {
    const country = this.consultant()?.location?.countryCode;
    if (country) {
      return this.helperFunctions.getCountry(country.toLocaleUpperCase());
    } else {
      return '';
    }
  });

  consultantRate = computed(() => {
    const expectedRate = this.consultant()?.expectedRate;
    if (expectedRate?.currency && expectedRate?.ratePerHour) {
      return expectedRate.currency + expectedRate.ratePerHour;
    } else {
      return '-';
    }
  });

  consultantAvailability = computed(() => {
    const availability = this.consultant()?.availability;
    if (availability) {
      const timestamp = Math.round(new Date(availability).getTime() / 1000);
      return this.helperFunctions.getDate(timestamp, false);
    } else {
      return '-';
    }
  });

  openProfile(event: Event, id: string) {
    event.stopPropagation();
    if (this.router.url.includes('matching')) {
      this.location.go('/matching/consultant/' + id);
    }
  }
}
