import {
  deepComputed,
  signalStoreFeature,
  withComputed,
  withState,
} from '@ngrx/signals';
import { computed } from '@angular/core';
import { IOption } from '../../interfaces/option';

export type RelationStatusesDictionaryState = {
  _relationStatuses: string[];
};

export const initialRelationStatusesDictionaryState: RelationStatusesDictionaryState =
  {
    _relationStatuses: null,
  };

export function withRelationStatusesDictionary() {
  return signalStoreFeature(
    withState({
      _relationStatusesDictionary: initialRelationStatusesDictionaryState,
    }),
    withComputed(({ _relationStatusesDictionary }) => ({
      relationStatusesDictionary: deepComputed(() => ({
        values: computed(() => _relationStatusesDictionary._relationStatuses()),
        valuesSortedAsc: computed(() => {
          if (_relationStatusesDictionary._relationStatuses() === null) {
            return [];
          } else {
            return _relationStatusesDictionary
              ._relationStatuses()
              .sort((a, b) => a.localeCompare(b));
          }
        }),
        valuesAsOptions: computed<IOption[]>(() => {
          if (_relationStatusesDictionary._relationStatuses() === null) {
            return [];
          } else {
            return _relationStatusesDictionary
              ._relationStatuses()
              .map((relationStatus) => ({
                id: relationStatus,
                name: relationStatus,
              }));
          }
        }),
      })),
    }))
  );
}
