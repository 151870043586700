import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  matches
} from '../../../../../../interface/shared.interface';
import { IRequest, IRequestDetails } from '../../../../../../app/shared/interfaces/requests';

@Component({
  selector: 'app-add-request',
  templateUrl: './add-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRequestComponent implements OnInit {
  private activeRoute = inject(ActivatedRoute);

  selectedRequest = input<matches | IRequest | IRequestDetails>();
  requestsList = input.required<IRequest[]>();
  requestsListLoading = input.required<boolean>();
  requestsListError = input.required<boolean>();

  selectRequest = output<IRequest | IRequestDetails>();
  manualRequest = output<string>();

  selectedSearchType = signal(0);

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      if (params['id'] && params['id'].includes('manual')) {
        this.selectedSearchType.set(1);
      }
    });
  }
}
