<div class="modal-content">
  <div class="selectedRequest">
    <app-request-level0 [request]="selectedRequest!"></app-request-level0>
  </div>

  <div class="selectconsultant">
    <app-search-consultant
      *ngIf="clearConsultant || !selectedconsultant"
      (selectconsultant)="selectconsultant($event)"
      [selectedRequest]="selectedRequest"
      [addable]="true"
    ></app-search-consultant>
    <div
      *ngIf="selectedconsultant && !clearConsultant"
      class="selectedconsultant"
    >
      <p class="body-large">Add to request</p>
      <app-level0
        [consultant]="selectedconsultant"
        [ctas]="{ selected: true }"
        (cta)="cta($event)"
      ></app-level0>
    </div>
  </div>
</div>
