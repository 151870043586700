<div class="keywords" [class]="{ disabled: disabled }">
  <div class="select-input body-small">
    <div
      *ngIf="selected.length > 0"
      class="selected-keywords"
    >
      <app-badge
        *ngFor="let select of selected; let i = index"
        [color]="'neutral--Grey-300 textPrimary'"
        [label]="select.displayName"
        [trailingIcon]="{ type: 'icon', icon: 'X' }"
        (callbackFunction)="removeFilter(i)"
      ></app-badge>
    </div>
    <div class="bundle-actions">
      <div
        (keyup)="onKeyDown($event)"
        (keydown)="createOnEnter($event)"
        class="input"
        [class]="{ placeholder: !writing }"
        [attr.placeholder]="'Type keywords'"
        [contentEditable]="disabled ? false : true"
      ></div>
      <div (click)="createOnCheck()" [class]="{ active: writing }">
        <i-tabler name="check"></i-tabler>
      </div>
    </div>
  </div>
</div>
