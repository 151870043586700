import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  filter,
  FilterData,
  option,
  salesAgent
} from '../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../../services/platform/context.service';
import { v4 as uuidv4 } from 'uuid';
import { IClaimingFreelancerStatus } from '../../../../../shared/interfaces/claiming';

@Component({
  selector: 'app-request-filters',
  templateUrl: './request-filters.component.html',
  styleUrls: ['./request-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestFiltersComponent {
  disable = input<boolean>(false);
  modal = input<boolean>(false);
  selectedNav = input<string>('');
  filterData = signal<FilterData>(this.contextService.filterData);
  filters: filter[] = this.contextService.requestFilters;
  filterReady = signal<boolean>(false);

  selectedFilters = signal<option[]>( []);
  queryParams: any;

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contextService: ContextService,
    private helperFunctions: HelperFunctionsService
  ) {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        const object = { ...params };
        if (Object.keys(object).length === 0 && !this.queryParams) {
          this.queryParams = object;
          this.filterReady.set(true);
          return;
        }
        this.queryParams = object;
        setTimeout(() => {
          this.queryFilter();
        });
      });
    }
  }

  getOption(type: string): filter {
    return this.filters.find((x) => x.type === type)!;
  }

  async queryFilter() {
    if (Object.keys(this.queryParams).includes('agent')) {
      const agent: string[] = this.queryParams['agent']
        .split(',')
        .filter((x: string) =>
          this.filterData()?.agents?.some(
            (z: salesAgent) => z.federatedIssuerAssignedId === x
          )
        );
      const findIndex = this.filters.findIndex((x) => x.type === 'agent');
      if (findIndex !== -1) {
        this.filters[findIndex].option = [];
      }
      const option: option[] = [];
      agent.forEach((x: string) => {
        const agent = this.filterData().agents?.find(
          (a) => a.federatedIssuerAssignedId === x
        );
        if (agent) {
          option.push({
            id: x,
            displayName: this.helperFunctions.fullName(agent),
            type: 'agent',
          });
        }
      });
      this.getFilterFunc(option, 'agent');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'agent');
      this.filters[findIndex].option = [];
    }
    if (Object.keys(this.queryParams).includes('salesstatus')) {
      // options
      const options: option[] = [];

      // Get sales statuses from query params
      const salesStatusNamesFromQueryParams: string[] = this.queryParams['salesstatus'].split(',')

      // Transform sales statuses from query params to options
      this.filterData()?.salesstatus?.filter((status: IClaimingFreelancerStatus) => {
        return salesStatusNamesFromQueryParams.includes(status.value);
      }).forEach((x: IClaimingFreelancerStatus) => {
        options.push({ id: uuidv4(), displayName: x.name, type: 'salesstatus', value: x.value });
      });

      this.getFilterFunc(options, 'salesstatus');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'salesstatus');
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams).includes('priority')) {
      const priority = this.queryParams['priority']
        .split(',')
        .filter((x: string) => this.filterData()?.priority?.some((z) => z === x));

      const option: option[] = [];
      priority.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'priority' });
      });
      this.getFilterFunc(option, 'priority');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'priority');
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams).includes('countries')) {
      const countries: {
        displayName: string;
        value: string;
      }[] = [];
      this.queryParams['countries'].split(',').forEach((x: string) => {
        const findex = this.contextService.filterData.countries.findIndex((c) => c.value === x);
        if (findex !== -1) {
          countries.push(this.contextService.filterData.countries[findex]);
        }
      });

      const option: option[] = [];
      countries.forEach((x: { displayName: string; value: string }) => {
        option.push({
          id: uuidv4(),
          displayName: x.displayName,
          type: 'countries',
          name: x.value,
        });
      });
      this.getFilterFunc(option, 'countries');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'countries');
      this.filters[findIndex].option = [];
    }

    const findIndex = this.filters.findIndex((x) => x.type === 'teamfilter');

    if (Object.keys(this.queryParams).includes('team')) {
      const team = this.queryParams['team']
        .split(',')
        .filter((x: string) =>
          this.filterData()?.team?.some((z) => z.name === x)
        );

      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'team'
      );

      team.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'team' });
      });

      this.getFilterFunc(option, 'team');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'team'
      );
    }
    if (Object.keys(this.queryParams).includes('country')) {
      const location = this.queryParams['country']
        .split(',')
        .filter((x: string) =>
          this.filterData()?.location?.some((z) => z.displayName === x)
        );
      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'country'
      );
      location.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'country' });
      });
      this.getFilterFunc(option, 'country');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'country'
      );
    }

    if (this.queryParams['office']) {
      const office = this.queryParams['office']
        .split(',')
        .filter((x: string) => {
          return this.filterData()?.location?.some((z) => z.subNames.includes(x));
        });

      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'office'
      );

      office.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'office' });
      });
      this.getFilterFunc(option, 'office');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'office'
      );
    }
    this.setFilteringList();
  }

  setFilteringList() {
    this.selectedFilters.set([]);
    this.filterReady.set(false);
    this.filters.forEach((x) => {
      this.selectedFilters.update((filters) => [...filters, ...x.option]);
    });
    if (this.selectedNav() === 'matching') {
      this.contextService.globalFilterM.next(this.selectedFilters());
    } else {
      this.contextService.globalFilterR.next(this.selectedFilters());
    }

    this.filterReady.set(true);
  }

  async clearFilter(i: number) {
    this.filters[i].option = [];
    if (this.filters[i].type === 'teamfilter') {
      delete this.queryParams['team'];
      delete this.queryParams['country'];
      delete this.queryParams['office'];
    } else {
      delete this.queryParams[this.filters[i].type];
    }

    await this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }

  async selectedFiltersFunc(event: option[], filterType: string) {
    event.map((x) => {
      if (!x.type) {
        x.type = filterType;
      }
      if (filterType === 'keywords') {
        x.cvOrFiles = 0;
      }
      return x;
    });
    this.getFilterFunc(event, filterType);
    await this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }

  /**
   * Build query params for filtering
   * @param event
   * @param filterType
   */
  getFilterFunc(event: option[], filterType: string) {
    let type = filterType;

    const teamFilter = ['country', 'team', 'office', 'location'];
    if (teamFilter.includes(type)) {
      type = 'teamfilter';
    }

    const findIndex = this.filters.findIndex((x) => x.type === type);

    this.filters[findIndex].option = event;
    if (filterType === 'agent') {
      const displayAgent = this.filters[findIndex].option
        .filter((x) => x.type === 'agent')
        .map((x) => {
          return x.id;
        });
      this.queryParams['agent'] = displayAgent.join(',');
    }
    if (filterType === 'salesstatus') {
      const displaySaleStatuses = this.filters[findIndex].option
        .filter((x) => x.type === 'salesstatus')
        .map((x) => {
          return x.value;
        });
      this.queryParams['salesstatus'] = displaySaleStatuses.join(',');
    }
    if (filterType === 'priority') {
      const displayPriority = this.filters[findIndex].option
        .filter((x) => x.type === 'priority')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams['priority'] = displayPriority.join(',');
    }

    if (filterType === 'countries') {
      const countryCode = this.filters[findIndex].option
        .filter((x) => x.type === 'countries')
        .map((x) => {
          return x.name;
        });
      this.queryParams['countries'] = countryCode.join(',');
    }

    if (filterType === 'team') {
      const displayNameTeam = this.filters[findIndex].option
        .filter((x) => x.type === 'team')
        .map((x) => {
          return x.displayName;
        });

      if (filterType === 'team') {
        this.queryParams['team'] = displayNameTeam.join(',');
      }
    }

    if (filterType === 'country' || filterType === 'location') {
      const displayNameCountry = this.filters[findIndex].option
        .filter((x) => x.type === 'country')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams['country'] = displayNameCountry.join(',');
    }
    if (filterType === 'office' || filterType === 'location') {
      const displayNameOffice = this.filters[findIndex].option
        .filter((x) => x.type === 'office')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams['office'] = displayNameOffice.join(',');
    }
  }
}
