import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ContextService } from '../../../services/platform/context.service';
import { INavigationItem } from '../../shared/interfaces/navigation';
import { combineLatest, filter, Observable } from 'rxjs';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit {
  @Input() openSidePanel: boolean;
  mobile = false;

  navigationData$: Observable<{ navigation: INavigationItem[]; activeItem: INavigationItem }>;
  navigationItems$: Observable<INavigationItem[]> | null = null;

  constructor(
    private navigationService: NavigationService,
    public contextService: ContextService
  ) {
    window.onresize = () => (this.mobile = window.innerWidth <= 1180);
  }

  ngOnInit() {
    this.navigationItems$ = this.navigationService.navigationReady$.pipe(
      filter((isReady) => isReady), // Wait until navigation is ready
      switchMap(() => this.navigationService.navigation$) // Fetch navigation items
    );

    this.navigationData$ = combineLatest([
      this.navigationItems$,
      this.navigationService.activeNavigationItem$
    ]).pipe(
      map(([navigation, activeItem]) => ({ navigation, activeItem }))
    );
  }
}
