import { createReducer, on } from '@ngrx/store';
import * as GlobalActions from './global.actions';
import { IAppSettings } from '../../interfaces/app-settings';

export const globalFeatureKey = 'global';

export interface State {
  appVersion: string;
  appSettings: IAppSettings;
}

export const initialState: State = {
  appVersion: null,
  appSettings: null,
};

export const reducer = createReducer(
  initialState,
  on(
    GlobalActions.queryAppVersionSuccess,
    (state, { appVersion }): State => ({
      ...state,
      appVersion,
    })
  ),
  on(
    GlobalActions.queryAppSettingsSuccess,
    (state, { appSettings }): State => ({
      ...state,
      appSettings,
    })
  ),
  on(
    GlobalActions.queryAppSettingsError,
    (state): State => ({
      ...state,
      appSettings: null,
    })
  )
);

export const getAppVersion = (state: State) => {
  return state.appVersion;
};

export const getAppSettings = (state: State) => {
  return state.appSettings;
};

export const getAzureMapsKey = (state: State) => {
  return state.appSettings?.mapsSubscriptionKey;
};
