import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faPlus,
  faTimes,
  faChevronDown,
  faFilter,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { select } from '../../../../../interface/shared.interface';
import {
  FilterData,
  filterOption,
  ProProfile,
  SelectedFilter
} from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() filterList: filterOption[] = [];
  @Input() type = '';
  faPlus = faPlus;
  faTimes = faTimes;
  faFilter = faFilter;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;
  filterActive = false;
  listActive = '';
  defaultListActive = '';
  hover: number;
  defaultFilter: filterOption[];
  selectedFilter: SelectedFilter[] =
    JSON.parse(sessionStorage.getItem('selectedFilter')!) ?? [];
  @Output() callbackFunction = new EventEmitter();

  constructor(public contextService: ContextService) {}
  ngOnInit(): void {
    this.defaultFilter = this.filterList.filter((x) => x.default);
    this.selectedFilter = [];
  }

  getProData(list: filterOption) {
    return list.data as ProProfile[];
  }
  getSelectData(list: filterOption) {
    return list.data as select[];
  }
  anyAdvanced() {
    return this.filterList.filter((x) => !x.default).length > 0;
  }
  uniqueProProfile(array: ProProfile[]) {
    return array.filter((item, pos, self) => {
      return self.findIndex((x) => x.displayName === item.displayName) === pos;
    });
  }

  setSearch(type: string) {
    this.defaultListActive = type;
    this.filterList.find((x) => x.type === type)!.data = this.uniqueProProfile(
      this.filterList.find((x) => x.type === type)!.raw
    );
  }
  getGroupName(group: string) {
    if (group === '1') {
      return 'JOB FIELD';
    }
    if (group === '2') {
      return 'JOB LOCATION';
    }
    if (group === '3') {
      return 'LEVEL & EXPERIENCE';
    }
    if (group === '4') {
      return 'OTHER';
    }
    return '';
  }
  getSelected(id: string, type: string) {
    if (type === 'monthRange') {
      if (id === '1') {
        const d = new Date();
        return (
          d.toLocaleString('default', { month: 'short' }) +
          ', ' +
          d.getFullYear()
        );
      }
      if (id === '2') {
        return '+ 30';
      }
      if (id === '3') {
        return '+ 45';
      }
      if (id === '4') {
        return '+ 60';
      }
      const d = new Date(id);
      return (
        d.toLocaleString('default', { month: 'short' }) + ', ' + d.getFullYear()
      );
    }

    const find = this.filterList.find((x) => x.type === type);

    return find ? find.raw.find((x) => x.id === id)?.displayName : '';
  }
  searchInput(value: string, type: string) {
    const data = this.uniqueProProfile(
      JSON.parse(
        JSON.stringify(this.filterList.find((x) => x.type === type)?.raw)
      )
    );
    const find = this.filterList.find((x) => x.type === type);
    if (find) {
      find.data = data.filter((x: ProProfile) =>
        x.displayName.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
  search(e: Event, type: string) {
    const element = e.target as HTMLInputElement;
    const data = this.uniqueProProfile(
      JSON.parse(
        JSON.stringify(this.filterList.find((x) => x.type === type)?.raw)
      )
    );
    const find = this.filterList.find((x) => x.type === type);
    if (find) {
      find.data = data.filter((x: ProProfile) =>
        x.displayName.toLowerCase().includes(element.value.toLowerCase())
      );
    }
  }

  openFilter() {
    this.filterActive = !this.filterActive;
  }
  setMonthRange(value: string, remove: boolean) {
    const findType = this.filterList.find((x) => x.type === 'availability');
    if (!findType) {
      return;
    }
    if (remove) {
      const findex = this.selectedFilter.findIndex(
        (x) => x.type === 'monthRange'
      );

      this.selectedFilter.splice(findex, 1);
      this.defaultFilter.find((x) => x.type === 'availability');
      findType!.monthRange = '0';

      findType!.monthRange = value;
      if (!['1', '2', '3', '4'].includes(findType.monthRange!)) {
        findType!.monthRange = '5';
      }

      const find = this.selectedFilter.find((x) => x.type === 'monthRange');
      if (find) {
        find.id = value;
        return;
      }

      this.selectedFilter.push({ id: value, type: 'monthRange' });
    }
  }
  selectedValue(value: string, type: string, remove?: boolean) {
    if (!value || (value === '5' && type === 'monthRange')) {
      return;
    }

    let findType = this.filterList.find((x) => x.type === type);

    if (type === 'monthRange') {
      findType = this.filterList.find((x) => x.type === 'availability');
      if (value === '0') {
        this.selectedFilter = [];
        findType!.selected = [];
        return;
      }
      this.setMonthRange(value, remove ?? false);
    }

    if (!findType) {
      sessionStorage.setItem(
        'selectedFilter',
        JSON.stringify(this.selectedFilter)
      );

      this.callbackFunction.emit();
      return;
    }

    const findex = findType.selected.findIndex((x: string) => x === value);
    findType.data = findType.data as FilterData[];
    const childs = findType.data.filter((x: FilterData) => {
      return x.parentId === value;
    });
    const newValue = [value];
    childs.forEach((elm: FilterData | ProProfile) => {
      const id = elm.id as string;
      newValue.push(id);
    });

    if (findex === -1) {
      if (!findType?.multiSelect) {
        findType!.selected = [...new Set(newValue)];
        const dynamicType = type;
        this.selectedFilter = this.selectedFilter.filter(
          (x) => x.type !== type
        );
        this.selectedFilter.push({ id: value, type: dynamicType });
      } else {
        const selected = findType!.selected;
        selected.push(...newValue);
        findType!.selected = [...new Set(selected)];

        this.selectedFilter.push({ id: value, type });
      }
    } else {
      const findI = this.selectedFilter.findIndex((x) => x.id === value);

      this.selectedFilter.splice(findI, 1);
      if (this.filterList.find((x) => x.type === type)) {
        newValue.forEach((id) => {
          const findex = this.filterList
            .find((x) => x.type === type)!
            .selected.findIndex((x) => x === id);

          this.filterList
            .find((x) => x.type === type)!
            .selected.splice(findex, 1);

          findType!.combineSelected = undefined;
        });
      }
    }
    if ((type as string) === 'workLocations') {
      this.filterList.forEach((list) => {
        if ((list.type as string) === 'workLocations') {
          const newSelect: FilterData[] = [];

          for (let i = 0; i < list.selected.length; i++) {
            list.data = list.data as FilterData[];
            const findex = list.data.findIndex((y: FilterData) => {
              return y.id === list.selected[i];
            });
            if (findex !== -1) {
              newSelect.push(list.data[findex] as FilterData);
            }
          }

          const countChild = newSelect.filter((x: FilterData) => x?.subValue);

          if (countChild[0]?.childLength === countChild.length) {
            list.combineSelected = [countChild[0].parentId as string];
            if (
              list.selected.find((x) => x === countChild[0].parentId) ===
              undefined
            ) {
              list.selected.push(countChild[0].parentId as string);
            }
          }
        }
      });
    }

    sessionStorage.setItem(
      'selectedFilter',
      JSON.stringify(this.selectedFilter)
    );
    this.callbackFunction.emit();
  }
  anyFilter() {
    return this.filterList.some(
      (list) => list.selected.length > 0 || list.combineSelected
    );
  }
  clearAll() {
    sessionStorage.removeItem('selectedFilter');
    this.filterList.map((list) => {
      if (list.type === 'availability') {
        this.selectedValue('0', 'monthRange');
      }
      if (list.selected.length > 0) {
        list.selected = [];
      }
      if (list.combineSelected) {
        list.combineSelected = [];
      }
      return list;
    });
    this.selectedFilter = [];
    this.callbackFunction.emit();
  }
  selectList(filter: string) {
    clearTimeout(this.hover);
    this.hover = window.setTimeout(() => {
      this.listActive = filter;
    }, 50);
  }
}
