import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  consultantId = input.required<string>();
  timelines = signal<any[]>(null);
  ascDesc = signal(false);

  private platformService = inject(PlatformService);
  private snackBarService = inject(SnackBarService);

  constructor() {
    effect(() => {
      const id = this.consultantId();

      untracked(() => {
        this.platformService.getTimeline(id)
        .then((response) => {
          this.timelines.set(response);
        })
        .catch(() => {
            this.snackBarService.open({
            message: 'Failed to get timeline',
            type: SnackBarTypes.ErrorAlt
          });
        });
      });
    });
  }

  sortByReverse() {
    this.ascDesc.update((order) => !order);
    this.timelines().reverse();
  }
}
