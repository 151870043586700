import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatomoTracker } from 'ngx-matomo-client';
import {
  declarationOfInterest,
  requestMatches,
  requestV3,
  requestV3Level3,
  shortlistedProfiles
} from '../../../../../../interface/shared.interface';
import { freeStatus } from '../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent {
  @Input() rerender: boolean;
  @Input() requestId: string;
  @Input() loading = true;
  @Output() callbackFunction = new EventEmitter<boolean>();
  bulkSelect: shortlistedProfiles[] = [];
  salesRequests: requestMatches[];
  selectedRequest: requestV3Level3;
  viewLess = true;

  loadLength = 10;
  consultantstatus: freeStatus[];
  enlistedProfiles: shortlistedProfiles[];
  showNotSold = true;
  hideSelectNotification: boolean;
  constructor(
    private platformService: PlatformService,
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker,
    private location: Location
  ) {
    this.contextService.reRender.subscribe((val) => {
      if (val === 'sentMessage') {
        this.bulkSelect = [];
      }
      if (val === 'close') {
        this.hideSelectNotification = false;
      }
      if (val === 'saveNote') {
        this.getRequest();
      }
    });
  }

  ngOnInit(): void {
    this.getRequest();
  }

  openBulkMessaging() {
    this.contextService.openMatchModal = 'messaging';
    const freelancers: string[] = [];
    this.bulkSelect.forEach((val: shortlistedProfiles) => {
      freelancers.push(val.id);
    });
    this.platformService
      .declarationOfInterest(this.selectedRequest.id, 'post', undefined, {
        freelancers,
        languageCode: '',
      })
      .then((response: declarationOfInterest) => {
        this.hideSelectNotification = true;
        this.contextService.claiming = response;
        this.contextService.reRender.next('messaging');
      });
  }

  handleNotSold(value: boolean) {
    this.showNotSold = value;

    if (this.showNotSold) {
      // show all profiles
      this.enlistedProfiles = this.selectedRequest.enlistedProfiles;
    } else {
      // hide not sold profiles
      this.enlistedProfiles = this.selectedRequest.enlistedProfiles.filter(
        (x) => x.salesStatus !== 'NotSold'
      );
    }
  }

  loadMore(event: number) {
    this.loadLength += event;
  }
  getRequest() {
    if (this.loading === false) {
      this.callbackFunction.emit(true);
    }
    this.platformService
      .requestId(this.requestId, 'v3')
      .then(async (response) => {
        if (!response) {
          this.contextService.notification.next({
            description: 'Oops, unable get request',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
        this.selectedRequest = response;
        this.enlistedProfiles = this.selectedRequest.enlistedProfiles;
        this.contextService.selectedRequest = this.selectedRequest;

        this.selectedRequest.enlistedProfiles.sort(
          (a: any, b: any) => b.modifiedOn - a.modifiedOn
        );
        await this.platformService.getConsultantStatus().then((response) => {
          this.consultantstatus = response;
          this.consultantstatus.map((x, i) => {
            if (x.name === 'Added') {
              x.leadingIcon = 'checklist';
            }
            if (x.name === 'Contacted') {
              x.leadingIcon = 'send';
            }
            if (x.name === 'Claimed') {
              x.leadingIcon = 'check';
            }
            if (x.name === 'CVSent') {
              x.leadingIcon = 'clipboard-text';
            }
            if (x.name === 'InterviewScheduled') {
              x.leadingIcon = 'calendar-clock';
            }
            if (x.name === 'InterviewedByCustomer') {
              x.leadingIcon = 'calendar-user';
            }
            if (x.name === 'CustomerApproved') {
              x.leadingIcon = 'user-check';
            }
            if (x.name === 'Sold') {
              x.leadingIcon = 'discount-check';
            }
            if (x.name === 'NotSold') {
              x.leadingIcon = 'square-rounded-x';
              x.disable = true;
            }
            if (x.name === 'CVSent') {
              x.displayName = 'CV Sent';
              return x;
            }
            if (i > 2) {
              return (x.displayName = x.name.split(/(?=[A-Z])/).join(' '));
            }
            return (x.displayName = x.name.split(/(?=[A-Z])/).join(' '));
          });
        });
        this.callbackFunction.emit(false);
      });
  }

  addconsultant() {
    this.matomoTracker.trackEvent('Request', 'Open modal - Add consultant');
    this.contextService.selectedConsultant = <any>undefined;
    this.contextService.openMatchModal = 'addconsultant';
  }
  searchInMathing() {
    this.matomoTracker.trackEvent('Request', 'Clicked - Open in matching');
    this.helperFunctions.route(['matching', 'rtc', this.selectedRequest.id]);
  }
  view() {
    this.viewLess = !this.viewLess;
  }

  getDate(date?: number) {
    if (date) {
      return this.helperFunctions.getDate(date);
    }
    return '-';
  }
  ifEmptyReturnDash(value: string) {
    return value ?? '-';
  }
}

