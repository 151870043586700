<div class="longlist-content">
  <div class="selectedFreelance">
    <app-level0 [consultant]="selectedConsultant"></app-level0>
  </div>

  <div class="selectRequest">
    <p class="body-large bold">Choose request</p>
    <div>
      <app-search-request
        [type]="'longlist'"
        [selectedSearchType]="0"
        [selectedRequest]="selectedRequest"
        (selectrequest)="selectRequest($event)"
      ></app-search-request>
    </div>
  </div>
</div>
