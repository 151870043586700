import { Component, EventEmitter, Input, Output } from '@angular/core';
import { matchConsultants, modalType } from 'src/interface/shared.interface';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-level0',
  templateUrl: './level0.component.html',
  styleUrls: ['./level0.component.scss'],
})
export class Level0Component {
  @Input() consultant: matchConsultants;
  @Input() disable: boolean;
  @Input() ctas: {
    edit?: boolean;
    selected?: boolean;
    user?: boolean;
  };
  @Input() selected: boolean;
  @Input() shortListed: boolean | undefined;
  @Output() cta = new EventEmitter<string>();

  constructor(
    private helperFunctions: HelperFunctionsService,
    private router: Router,
    private location: Location
  ) {}

  openProfile(event: Event, id: string) {
    event.stopPropagation();
    if (this.router.url.includes('matching')) {
      this.location.go('/matching/consultant/' + id);
    }
  }

  getCity(value: string) {
    if (value) {
      return value + ', ';
    }
    return '';
  }
  getCountry(value: string) {
    if (value) {
      return this.helperFunctions.getCountry(value.toLocaleUpperCase());
    }
    return '';
  }
  validateRate(expectedRate: { currency: string; ratePerHour: number }) {
    if (expectedRate.currency && expectedRate.ratePerHour) {
      return expectedRate.currency + expectedRate.ratePerHour;
    }
    return '-';
  }
  getDate(value: string) {
    if (!value) {
      return '-';
    }
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }
}
