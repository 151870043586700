import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { ContextService } from '../../../../../../services/platform/context.service';
import { IConsultant } from '../../../../../consultants/interfaces/consultant';
import { IRequest } from '../../../../../shared/interfaces/requests';

@Component({
  selector: 'app-longlist-content',
  templateUrl: './longlist-content.component.html',
  styleUrls: ['./longlist-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LonglistContentComponent implements OnInit {
  private contextService = inject(ContextService);

  requestsList = input.required<IRequest[]>();
  requestsListLoading = input.required<boolean>();
  requestsListError = input.required<boolean>();

  selectedRequest = signal<IRequest>(null);

  selected = output<IRequest>();

  selectedConsultant: IConsultant = this.contextService
    .selectedLonglistConsultant as IConsultant;

  ngOnInit() {
    if (this.contextService.matchModalMode.value === 'longlist') {
      this.selectedRequest.set(this.contextService.selectedRequest as IRequest);
    }
  }

  selectRequest(value: IRequest) {
    this.selectedRequest.set(value);
    this.selected.emit(value);
  }
}
