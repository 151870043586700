import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeadingComponent } from './dashboard/right-content/components/heading/heading.component';
import { NavigationComponent } from './dashboard/navigation/navigation.component';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MatomoModule } from 'ngx-matomo-client';
import { environment } from '../environments/environment';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { JsonPipe } from '@angular/common';
import { CommunityComponent } from './dashboard/right-content/content/community/community.component';
import * as Sentry from '@sentry/angular';
import { HttpAxiosInterceptorService } from '../services/interceptors/http-axios-interceptor.service';
import { UnauthorizedInterceptorService } from '../services/interceptors/unauthorized-interceptor.service';
import { handleSentryBeforeSend } from '../services/helperFunctions/sentryAxiosErrorHandler';
import { MainComponent } from './main/main.component';
import { NavigationItemComponent } from './dashboard/navigation/navigation-item/navigation-item.component';
import { SharedModule } from './shared/modules/shared.module';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
    environment.b2c + 'user.read',
  ]);

  protectedResourceMap.set(environment.apiUrl, [environment.scopes.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.b2c + 'user.read'],
    },
    loginFailedRoute: '/',
  };
}

Sentry.init({
  dsn: environment.dsn,
  environment: environment.production ? 'production' : 'staging',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  beforeSend: handleSentryBeforeSend,
  tracePropagationTargets: environment.tracePropagationTargets,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: environment.sentryReplaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.sentryReplaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavigationComponent,
    NavigationItemComponent,
    HeadingComponent,
    CommunityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    JsonPipe,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatomoModule.forRoot({
      siteId: environment.matomo,
      trackerUrl: 'https://syvntyve.matomo.cloud/matomo.php',
      scriptUrl: '//cdn.matomo.cloud/syvntyve.matomo.cloud/matomo.js',
    }),
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -15,
      outerStrokeWidth: 15,
      outerStrokeLinecap: 'butt',
      innerStrokeWidth: 15,
      animateTitle: false,
      responsive: true,
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.showDialog,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        return;
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptorsFromDi()),
    HttpAxiosInterceptorService,
    UnauthorizedInterceptorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
