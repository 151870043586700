import { Injectable } from '@angular/core';
import axios, { CancelTokenSource } from 'axios';
import { environment } from '../../environments/environment';
import { 
  IClaimingDeclarationOfInterest,
  IClaimingDeclarationOfInterestLanguagePayload
} from '../../app/shared/interfaces/claiming';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;
  cancelTokenSource: CancelTokenSource | null = null;

  // Sends a HEAD request to check the cached match status
  async headCachedMatch(matchedId: string, iterateId: number) {
    const url =
      this.apiUrl +
      '/agentportal/v3/request/cached/' +
      matchedId +
      '/results/' +
      iterateId;

    return axios
      .head(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches cached filter results for a given match
  async getCachedFilter(matchedId: string, iterateId: number, query: string) {
    const url =
      this.apiUrl +
      '/agentportal/v3/request/cached/' +
      matchedId +
      '/results/' +
      iterateId +
      '?' +
      query;

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches cached match results
  async getCachedMatch(matchedId: string, iterateId: number) {
    const url =
      this.apiUrl +
      '/agentportal/v3/request/cached/' +
      matchedId +
      '/results/' +
      iterateId;

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Sends a HEAD request to check the match status
  async headMatch(type: string) {
    const url = this.apiUrl + '/agentportal/v2/match/' + type;

    return axios
      .head(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Posts a declaration of interest for a consultant
  async postDeclarationOfInterest(
    id: string,
    consultants: IClaimingDeclarationOfInterestLanguagePayload
  ) {
    const url =
      this.apiUrl + '/agentportal/v2/match/' + id + '/declarationofinterest';

    return axios
      .post(url, consultants)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches declaration of interest for a specific consultant
  async getDeclarationOfInterestconsultant(id: string) {
    const url =
      this.apiUrl +
      '/agentportal/v2/match/declarationofinterest/freelancer/' +
      id;

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches filters for a specific match
  async portalFilters(version = 'v1') {
    const url = this.apiUrl + '/agentportal/' + version + '/portal/filters/';

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches filters for a specific match
  async getFilters(id: string, version = 'v2') {
    const url =
      this.apiUrl + '/agentportal/' + version + '/matching/' + id + '/filters';

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches match filters for a specific request
  async getMatchFilters(id: string, iterateId: number, version = 'v3') {
    const url =
      this.apiUrl +
      '/agentportal/' +
      version +
      '/request/cached/' +
      id +
      '/results/' +
      iterateId +
      '/filters';

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Posts a declaration of interest
  async getDeclarationOfInterest(id: string) {
    const url =
      this.apiUrl + '/agentportal/v2/match/declarationofinterest' + id;

    return axios
      .post(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Updates a declaration of interest with an optional submit flag
  async putDeclarationOfInterest(
    id: string,
    payload: IClaimingDeclarationOfInterest,
    submit = false
  ) {
    const url =
      this.apiUrl +
      '/agentportal/v2/match/declarationofinterest/' +
      id +
      (submit ? '/submit' : '');

    return axios
      .put(url, payload ?? '')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches match details for a specific type
  async getMatch(type: string, version = 'v3') {
    const url = this.apiUrl + '/agentportal/' + version + '/match/' + type;

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches request details for a specific type
  async getRequest(type: string, version = 'v3') {
    const url = this.apiUrl + '/agentportal/' + version + '/request/' + type;

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches a list of requests based on the specified endpoint
  async getRequests(endpoint = '/requests/', version = 'v2') {
    const url = this.apiUrl + '/agentportal/' + version + endpoint;

    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Posts a match request with optional search object
  async postMatch(type: string, searchObject?: unknown, version = 'v2') {
    const url = this.apiUrl + '/agentportal/' + version + '/match/' + type;

    return axios
      .post(url, searchObject)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Sends a HEAD request for match details
  async getMatchHead(type: string, version = 'v3') {
    const url = this.apiUrl + '/agentportal/' + version + '/match/' + type;

    return axios
      .head(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches consultant details for a specific type
  async getConsultants(type: string, version = 'v1') {
    const url = this.apiUrl + '/agentportal/' + version + '/' + type;

    // Cancel the previous request if it exists
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled due to new request.');
    }

    // Create a new cancel token
    this.cancelTokenSource = axios.CancelToken.source();

    return axios
      .get(url, {
        cancelToken: this.cancelTokenSource.token,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Posts a matching request with the specified query parameters
  async getMatching(
    type: string,
    searchObject: unknown,
    version = 'v3',
    query = ''
  ) {
    const url =
      this.apiUrl +
      '/agentportal/' +
      version +
      '/' +
      type +
      (query ? '?' + query : '');

    return axios
      .post(url, searchObject, {
        timeout: 30000,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Fetches data from the agent portal for a specific type
  async getAgentportal(type: string, version = 'v1') {
    const url = this.apiUrl + '/agentportal/' + version + type;
    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Downloads a file from the agent portal for a specific type
  async downloadFile(type: string, version = 'v1') {
    const url = this.apiUrl + '/agentportal/' + version + type;
    return axios
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Updates data on the agent portal for a specific type
  async putAgentportal(type: string, selected: unknown) {
    const url = this.apiUrl + '/agentportal/' + type;

    return axios
      .put(url, selected)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Posts data to the agent portal for a specific type
  async postAgentportal(type: string, selected: unknown) {
    const url = this.apiUrl + '/agentportal/' + type;

    return axios
      .put(url, selected)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Saves data to the agent portal for a specific type
  async saveAgentportal(type: string, selected: unknown) {
    const url = this.apiUrl + '/agentportal/v1/' + type;

    return axios
      .post(url, selected)
      .then(() => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  }

  // Sets the status on the agent portal for a specific type
  async setStatus(type: string) {
    const url = this.apiUrl + '/agentportal/v1/dashboard/' + type;

    return axios
      .post(url, {})
      .then(() => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  }
}
