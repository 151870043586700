@if (navigationData$ | async; as navigationData) {
  <div [class]="openSidePanel ? 'open' : ''"
       class="surface--3 navigation"
       data-cy="navigation">
    <div class="nav"
         [class]="openSidePanel ? 'open' : ''"
         *ngFor="let navItem of navigationData.navigation; let i = index">
      @if (navItem.allowed) {
        <div class="wrap-nav">
          <app-navigation-item [navItem]="navItem"
                               [active]="navItem.id === navigationData.activeItem.id"></app-navigation-item>
        </div>
        <div class="submenu" *ngFor="let subItem of navItem.submenu">
          <app-navigation-item [navItem]="subItem"
                               [active]="subItem.id === navigationData.activeItem.id"
                               class="submenu-item"></app-navigation-item>
        </div>
      }
    </div>
  </div>
}

