import { Injectable } from '@angular/core';
import { ServiceNames } from '../interfaces/services';
import { environment } from '../../../environments/environment';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class ApiService {
    serviceUrl(serviceName: ServiceNames = ServiceNames.Core, version = 'v1'): string {
      if (!environment[serviceName]) {
        console.error(`[ApiService] service ${serviceName} is missing in configuration, empty string returned`);
        return '';
      }
  
      // Add trailing slash if it's missing
      let serviceUrl =  `${environment[serviceName].replace(/\/?$/, '/')}agentportal/`;
  
      // optional API version
      if (version) {
        serviceUrl += version + '/';
      }
  
      return serviceUrl;
    }

}