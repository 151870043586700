<div class="messaging-content">
  <app-loader [loading]="loading"></app-loader>
  <ng-container *ngIf="claiming">
    <div class="message-steps">
      <div *ngFor="let step of steps; let i = index; let isLast = last">
        <app-badge
          *ngIf="selectedStep <= i"
          [amount]="i + 1"
          [color]="
            'amount tiny ' +
            (selectedStep === i
              ? 'brand--Dark textContrast'
              : 'brand--Light textSecondary')
          "
        ></app-badge>
        <app-badge
          *ngIf="selectedStep > i"
          [icon]="{ type: 'icon', icon: 'check' }"
          [color]="'amount tiny brand--Dark textContrast'"
        ></app-badge>

        <span *ngIf="!isLast"></span>
      </div>
    </div>
    <div *ngIf="selectedStep === 0" class="header">
      <div>
        <h3>Send to</h3>
        <app-badge
          [amount]="claiming.freelancers.length"
          [color]="'amount tiny textSecondary neutral--Grey-300'"
        ></app-badge>
      </div>
      <div class="sms-notificaton">
        <app-select-items
          label="Also send an SMS notification"
          (checked)="claiming.notifyThroughSms = !$event"
          [type]="'checkbox'"
          [isChecked]="claiming.notifyThroughSms"
        ></app-select-items>
      </div>
    </div>
    <div class="content" *ngIf="selectedStep === 0">
      <div class="consultants">
        <div *ngFor="let consultant of claiming.freelancers">
          <div class="profile">
            <div class="image label1">{{ consultant.fullName![0] }}</div>
            <div class="label1 fullName">{{ consultant.fullName }}</div>
          </div>
          <div class="contact">
            <app-input
              [disabled]="true"
              [value]="consultant.email!"
              placeholder="Email"
            ></app-input>
            <app-input
              (inputs)="phoneNumber($event, consultant.freelancerId)"
              inputType="tel"
              [value]="beautifyNumber(consultant.mobilephone)"
              placeholder="Phone number"
            ></app-input>
          </div>
        </div>
      </div>
      <div class="bottom-area">
        <app-notification [message]="notification[0]"></app-notification>
      </div>
    </div>
    <div class="content" *ngIf="selectedStep === 1">
      <h3>Request</h3>
      <app-input
        (inputs)="title($event)"
        label="Title"
        [value]="claiming.title"
      ></app-input>
      <app-input
        (selectedCallback)="description($event)"
        styles="height: 380px"
        label="Request description"
        [value]="clamingDescription"
        type="content-field"
      ></app-input>
    </div>
    <div class="content" *ngIf="selectedStep === 2">
      <div class="bundle-reset-lang">
        <div>
          <h3>Message</h3>
          <p class="tiny textSecondary">
            To change the language of your message, select it from the dropdown.
          </p>
        </div>
        <div>
          <app-dropdown
            [class]="''"
            trailingIcon="chevron-down"
            [selected]="selected"
            [options]="language"
            (selectedCallback)="selectOption($event)"
            [styles]="'width: fit-content'"
          ></app-dropdown>
          <div (click)="reset()" class="reset label2">Reset message</div>
        </div>
      </div>
      <app-input
        [changeValue]="changeValue"
        (selectedCallback)="message($event)"
        styles="height: 380px"
        valueType="innerHtml"
        [value]="messageTemplate"
        type="content-field"
      >
      </app-input>
      <app-notification [message]="notification[1]"></app-notification>
    </div>
  </ng-container>
</div>
