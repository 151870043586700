<div class="each-consultant">
  <div class="bundle surface--1">
    <div
      [class]="{ noSmartTip: !isSmartTip && expandLevel2 }"
      (click)="expand()"
    >
      <div class="chevron">
        <i-tabler
          [name]="'chevron-' + (expandLevel2 ? 'up' : 'down')"
        ></i-tabler>
      </div>
      <app-avatar
        [consultant]="consultant"
        [tier]="consultant.tier"
        class="small"
        [tiers]="true"
      ></app-avatar>

      <div class="text">
        <div class="bundle-cta">
          <div *ngIf="consultant.tier">
            <div
              class="name-title"
              [class]="{ reverse: consultant.tier.status }"
            >
              <h3
                [class]="{
                  truncate: isTruncate('name')
                }"
                class="bold"
              >
                {{ consultant.firstName }} {{ consultant.middleName }}
                {{ consultant.lastName }}
                <div
                  *ngIf="consultant.remarks"
                  class="remarks warning--Medium"
                ></div>
              </h3>
              <p
                class="body-large last-role"
                *ngIf="consultant.lastRole"
                [class]="{
                  truncate: isTruncate('lastRole')
                }"
              >
                {{ consultant.lastRole }}
              </p>
            </div>
            <div *ngIf="consultant.tier.status">
              <app-tier-statuses [tier]="consultant.tier"></app-tier-statuses>

            </div>
          </div>
          <div class="cta">
            <app-cta
              *ngIf="freelanceAddedToRequest"
              [imgIcon]="'/assets/svg/list-check.svg'"
              classes="success--Lighter"
            >
            </app-cta>
            <app-cta
              classes="brand--Lightest textLink"
              *ngIf="
                !freelanceAddedToRequest &&
                !isManual &&
                selectedSearchType !== 2
              "
              [icon]="'text-plus'"
              (click)="openMatchModal($event)"
            >
            </app-cta>
            <app-cta
              classes="brand--Lightest textLink"
              [icon]="'user'"
              (click)="openProfile($event, consultant.id)"
            >
            </app-cta>
            <div
              (clickOutside)="toggleDropdown($event, '')"
              class="bundle-dropdown"
            >
              <app-cta
                [classes]="' textLink'"
                [icon]="'dots'"
                (click)="toggleDropdown($event, consultant.id)"
              >
              </app-cta>
              <app-simple-dropdown
                *ngIf="openDropdown === consultant.id"
                (click)="toggleDropdown($event, '')"
                [consultant]="consultant"
                [isManual]="false"
                [selectedSearchType]="selectedSearchType"
              ></app-simple-dropdown>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="country body-large">
            <i-tabler width="16px" height="16px" name="map-pin"></i-tabler>
            <p class="body-small">
              <span *ngIf="consultant.location.city" class="hide-on-mobile">{{ consultant.location.city }}</span>
              <span *ngIf="consultant.location.stateOrProvince" class="hide-on-mobile">{{
                  consultant.location.stateOrProvince.toLocaleUpperCase()
                }}</span>
              <span *ngIf="consultant.location.countryCode">{{ getCountry(consultant.location.countryCode) }}</span>
            </p>
          </div>
          <div class="li"></div>
          <p class="body-small">
            <span>Available</span> {{ getDate(consultant.availability) }}
          </p>
          <div class="li"></div>
          <p class="body-small">
            {{ validateRate(consultant.expectedRate) }} / h
          </p>
          <ng-container
            *ngIf="featureFlags.isFeatureEnabled('filebasedmatching')"
          >
            <div class="li"></div>
            <app-badge
              [icon]="getCvTypeIcon(consultant.cvFile)"
              [label]="getCvTypeLabel(consultant.cvFile)"
              [color]="getCvTypeColor(consultant.cvFile)"
            ></app-badge>
          </ng-container>

          <ng-container
            *ngIf="
              featureFlags.isFeatureEnabled('explainAbility') && isSmartTip
            "
          >
            <div class="li"></div>
            <app-badge
              [icon]="{ type: 'icon', icon: 'sparkles' }"
              [label]="'See smart tips'"
              [color]="'highlight--Lighter textHighlightDark'"
            ></app-badge>
          </ng-container>
        </div>
      </div>
    </div>
    <app-smarttip
      *ngIf="
        expandLevel2 &&
        featureFlags.isFeatureEnabled('explainAbility') &&
        isSmartTip
      "
      [smartTip]="smartTip"
    ></app-smarttip>
    <app-level2
      [consultant]="consultant"
      [selectedCvType]="selectedCvType"
      *ngIf="expandLevel2"
    ></app-level2>
  </div>
</div>
